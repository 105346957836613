import { useState } from 'react';
import { useDataProvider, useNotify, useUnselectAll, useRefresh } from 'react-admin';
import { Button, Backdrop, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

import { AssignVerifierTransitionDialog } from './applicationDialogs';

export const ApplicationsBulkActionButtons = (props) => {
  const [isMassAssignVerifierDialogOpened, setIsMassAssignVerifierDialogOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();

  const handleClose = () => setIsMassAssignVerifierDialogOpened(false);

  const handleSubmit = (verifierId) => {
    setLoading(true);
    dataProvider.query('applications/verifiers', {
      method: 'POST',
      body: JSON.stringify({
        applications: props.selectedIds,
        verifier_id: verifierId,
      }),
    })
      .then(() => {
        notify('Verifier succesfully assigned', 'success');
        unselectAll('applications');
        refresh();
        handleClose();
        setLoading(false);
      })
      .catch(error => {
        handleClose();
        notify(`Error: ${error.message}`, 'error');
        setLoading(false);
      });
  };

  const handleDialogOpen = () => setIsMassAssignVerifierDialogOpened(true);

  if (loading) return <Backdrop open={true}><CircularProgress /></Backdrop>;

  return (
    <>
      <Button
        onClick={handleDialogOpen}
        disabled={props.selectedIds.length === 0}
        variant="outlined"
      >
        Assign verifier
      </Button>
      <AssignVerifierTransitionDialog
        isOpened={isMassAssignVerifierDialogOpened}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
    </>
  );
};

ApplicationsBulkActionButtons.propTypes = {
  selectedIds: PropTypes.array,
};
