import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel,
  Select, MenuItem, DialogContent, DialogTitle, DialogContentText, DialogActions, Dialog, Button, Box,
} from '@material-ui/core';

const Regula = ({ object, setObject }) => {
  const ACTION_OPTIONS = {
    process: [
      <FormControl variant={'outlined'} fullWidth key={'process'}>
        <InputLabel id={'label-select-label'}>File</InputLabel>
        <Select
          label={'File'}
          labelId={'label-select-label'}
          value={object.engine_data.label || ''}
          onChange={e => setObject({ ...object, engine_data: { label: e.target.value } })}
        > {
            [
              { value: 'id_card', name: 'ID Card (front)' },
              { value: 'id_card_back_side', name: 'ID Card (back)' },
            ].map((i) => <MenuItem key={i.value} value={i.value}>{i.name}</MenuItem>)}
        </Select>
      </FormControl>,
    ],
  };

  return (
    <>
      <FormControl variant={'outlined'} fullWidth>
        <InputLabel id={'label-select-label'}>Action type</InputLabel>
        <Select
          label={'Action type'}
          labelId={'label-select-label'}
          value={object.action || ''}
          onChange={(e) => setObject({ ...object, action: e.target.value })}
        > {
            [{ value: 'process', name: 'Process' }]
              .map((i) => <MenuItem key={i.value} value={i.value}>{i.name}</MenuItem>)}
        </Select>
      </FormControl>
      {ACTION_OPTIONS[object.action || 'process'].map((i) => i)}
    </>
  );
};

Regula.propTypes = {
  object: PropTypes.object,
  setObject: PropTypes.func,
};

export const AddOcrRequestDialog = ({ isOpened, onClose, onSubmit }) => {
  const [object, setObject] = useState({
    engine: 'regula',
    action: 'process',
    engine_data: {
      label: null,
    },
  });

  const ENGINE_OPTIONS = {
    regula: [<Regula key={'regula'} object={object} setObject={setObject} />],
  };

  const handleEngineChange = (e) => setObject({ ...object, engine: e.target.value });
  const handleSubmit = () => onSubmit(object);
  return (
    <Dialog open={isOpened} onClose={onClose}>
      <DialogTitle id={'form-dialog-title'}>Select engine</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <FormControl variant={'outlined'} fullWidth>
            <InputLabel id={'label-select-label'}>Engine</InputLabel>
            <Select label={'Engine'} labelId={'label-select-label'} value={object.engine} onChange={handleEngineChange}>
              <MenuItem value={'regula'}>Regula</MenuItem>
            </Select>
          </FormControl>
          {ENGINE_OPTIONS[object.engine || 'regula'].map((i) => i)}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color={'primary'} onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          color={'primary'}
          disabled={(() => {
            if (!object.engine_data.label) {
              return true;
            }
          })()}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

AddOcrRequestDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
