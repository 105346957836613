import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  NumberInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  FunctionField,
} from 'react-admin';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <TextInput label="Link" source="link" />
      <TextInput label="Slug" source="slug" />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      { hasCreate ? (<CreateButton basePath={ basePath } />) : null }
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

export default props => (
  <List
    pagination={ <Pagination /> }
    bulkActionButtons={ false }
    sort={{ field: 'id', order: 'DESC' }}
    filters={ <ListFilter /> }
    actions={ <ListActions /> }
    { ...props }
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="link" />
      <TextField source="slug" />
      <FunctionField render={({ is_enabled }) => {
        if (is_enabled) {
          return <DoneIcon style={{ color: green[500] }}/>;
        } else {
          return <CloseIcon style={{ color: red[500] }}/>;
        }
      }} label={'Is Enabled'}/>
    </Datagrid>
  </List>
);
