import { Filter, NumberInput, SelectInput, TextInput } from 'react-admin';

import { useHandbook } from '../../../hooks';
import { DateFilterInput, QuickFilterInput } from '../../filters';
import { AsyncSelectInput } from '../../input';
import { validateNIDA } from '../../../utils';

export const UserListFilter = (props) => {
  const { choices: employmentTypeChoices } = useHandbook('employments');
  return (
    <Filter {...props} variant={'outlined'} style={{ marginTop: 0, marginBottom: '20px' }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <TextInput source="search_by_name" label="Search by name" alwaysOn />
      <NumberInput label="Phone number" source="phone_book_number" alwaysOn />
      <NumberInput label="Registration step" source="registration_step" />
      <SelectInput label="Status" source="state" choices={[
        { id: 'created', name: 'Created' },
        { id: 'confirmed', name: 'Confirmed' },
        { id: 'active', name: 'Active' },
        { id: 'blocked', name: 'Blocked' },
      ]} />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <TextInput label="Contract number" source="applications->contractNumber" />
      <TextInput label="Father surname" source="profile->father_surname" />
      <TextInput label="Mother surname" source="profile->mother_surname" />
      <TextInput validate={validateNIDA.validate} parse={validateNIDA.parser} format={validateNIDA.formatter} label="Document number" source="userDocuments->number" />
      <TextInput label="Email" source="profile->email" />
      <TextInput label="Account number" source="bankAccount->account_number" />
      <SelectInput label="Empl. type" source="employment->type->code" choices={employmentTypeChoices} />
      <QuickFilterInput source="sleeping_clients" label="Sleeping clients" defaultValue={true} />
      <AsyncSelectInput
        label="Affiliate"
        source="affiliate"
        query={{ resource: 'affiliates', payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} } }}
        mapper={({ id, name }) => ({ value: id, name })}
      />
      <QuickFilterInput source="enable_changed_phone_number" label="Phone changed" defaultValue={true} />
    </Filter>
  );
};
