import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Table, TableBody, TableCell, TableHead, TableRow,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../../utils';
import { TransactionDirection, TransactionType } from '../../../field';
import * as Dialogs from '../../../dialogs';
import { DateTimeBoxComponent } from '../../../DateTimeBoxComponent';

export const ExecutedTransactions = ({ loanId, isActionsDisabled }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [transactions, setTransactions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddExecutedTransactionDialogOpen, setIsAddExecutedTransactionDialogOpen] = useState(false);

  const toggle = (id) => setSelected(selected.indexOf(id) === -1 ? selected.concat(id) : selected.filter((item) => item !== id));

  useEffect(() => {
    setSelected([]);
    dataProvider.query(`loans/${loanId}/executed_transactions`, { method: 'GET' })
      .then(({ data }) => {
        setTransactions(data);
        setLoading(false);
      })
      .catch((error) => {
        notify(`Error: ${error.message}`, 'error');
        setLoading(false);
      })
    ;
  }, [dataProvider, loanId, notify]);

  const deleteExecutedTransactions = (ids) => {
    setIsDeleting(true);
    dataProvider.query(`loans/${loanId}/executed_transactions`, { method: 'DELETE', body: JSON.stringify(ids) })
      .then(() => notify('Transactions removed', 'success'))
      .catch((error) => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        setIsDeleting(false);
        refresh();
      });
  };
  const handleExecutedTransactionDialogOpen = () => setIsAddExecutedTransactionDialogOpen(true);
  const handleExecutedTransactionDialogClose = () => setIsAddExecutedTransactionDialogOpen(false);
  const handleExecutedTransactionSubmit = (type, direction, amount, executedAt) => {
    setIsAddExecutedTransactionDialogOpen(false);
    dataProvider
      .query(`loans/${loanId}/executed_transactions`, {
        body: JSON.stringify({ type, direction, amount, executed_at: executedAt }),
      })
      .then(() => refresh())
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  };

  return (
    <>
      <Card>
        <CardContent>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h6'} gutterBottom={false}>Executed transactions</Typography>
            <ButtonGroup
              color={'secondary'}
              size={'small'}
              variant={'outlined'}
              disabled={isDeleting || isActionsDisabled}
            >
              {selected.length !== 0 && (
                <Button onClick={() => deleteExecutedTransactions(selected)}>Delete</Button>
              )}
              <Button onClick={handleExecutedTransactionDialogOpen}>Add</Button>
            </ButtonGroup>
          </Box>
        </CardContent>
        <Divider />
        <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Direction</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction, key) =>
                <TableRow key={key}>
                  <TableCell>
                    <Box display={'flex'} alignItems={'center'}>
                      <Checkbox
                        color={'secondary'}
                        size={'small'}
                        checked={selected.includes(transaction.id)}
                        onChange={() => toggle(transaction.id)}
                        disabled={isActionsDisabled}
                      />
                      <Typography variant={'caption'}>{`#${transaction.id}`}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <DateTimeBoxComponent value={transaction.executed_at} />
                  </TableCell>
                  <TableCell><TransactionDirection direction={transaction.direction} /></TableCell>
                  <TableCell><TransactionType type={transaction.type} direction={transaction.direction} /></TableCell>
                  <TableCell>{formatCurrency(transaction.amount)}</TableCell>
                </TableRow>,
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      {isAddExecutedTransactionDialogOpen && <Dialogs.AddExecutedTransactionDialog
        isOpened={isAddExecutedTransactionDialogOpen}
        onClose={handleExecutedTransactionDialogClose}
        onSubmit={handleExecutedTransactionSubmit}
      />}
    </>
  );
};

ExecutedTransactions.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  refresh: PropTypes.func,
  isActionsDisabled: PropTypes.bool,
};
