import { useEffect, useState } from 'react';
import formatISO from 'date-fns/formatISO';
import { Error, useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Box, Button, ButtonGroup, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

import { getSubstringAfterSymbol } from '../../../utils';
import DebounceButton from '../../button/DebounceButton';
import { RejectTransitionDialog } from '../application';

import * as LoanDialogs from './loan_dialogs';

export const LoanTransitions = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const [transitions, setTransitions] = useState();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState();
  const [isActivateTransitionDialogOpened, setIsActivateTransitionDialogOpened] = useState(false);
  const [isCloseTransitionDialogOpened, setIsCloseTransitionDialogOpened] = useState(false);
  const [isChangeTermsTransitionDialogOpened, setIsChangeTermsTransitionDialogOpened] = useState(false);
  const [isTransferMoneyTransitionDialogOpened, setIsTransferMoneyTransitionDialogOpened] = useState(false);
  const [isReadyForDisbursementTransitionDialogOpened, setIsReadyForDisbursementTransitionDialogOpened] = useState(false);
  const [isCancelTransitionDialogOpened, setIsCancelTransitionDialogOpened] = useState(false);
  const [isAssignCollectorTransitionDialogOpened, setIsAssignCollectorTransitionDialogOpened] = useState(false);

  useEffect(() => {
    dataProvider.query(`loans/${record.id}/transitions`, { method: 'GET' })
      .then(({ data }) => {
        setTransitions(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, record.id]);

  if (loading) return <CircularProgress />;
  if (error) return <Error />;
  if (!transitions) return null;

  const applyTransition = (name, params = {}) => {
    setDisabled((state) => !state);
    dataProvider.query(`loans/${record.id}/apply_transition`, {
      method: 'POST',
      body: JSON.stringify({ name, params }),
    })
      .then(() => refresh())
      .catch((error) => notify(`Error: ${error.message}`, 'error'))
      .then(() => setDisabled((state) => !state));
  };
  const applyCollectionSpecialist = (params = {}) => {
    setDisabled((state) => !state);
    dataProvider.query('phone_call_loan_assigns', {
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then(() => refresh())
      .catch((error) => notify(`Error: ${error.message}`, 'error'))
      .then(() => setDisabled((state) => !state));
  };
  const handleAssignCollectorIdDialogClose = () => setIsAssignCollectorTransitionDialogOpened(false);
  const handleAssignCollectorId = (id) => {
    handleAssignCollectorIdDialogClose();
    applyCollectionSpecialist({ loan_id: record.id, admin_id: id, context: 'loan_collection_loans' });
  };

  return (
    <>
      <ButtonGroup variant={'contained'} color={'primary'}>
        {transitions.includes('activate') && <Button
          onClick={() => record.state === 'closed' ? applyTransition('activate') : setIsActivateTransitionDialogOpened(true)}
          disabled={disabled}>Activate</Button>}
        {transitions.includes('cancel') &&
          <Button onClick={() => setIsCancelTransitionDialogOpened(true)} disabled={disabled}>Cancel</Button>}
        {transitions.includes('close') &&
          <Button onClick={() => setIsCloseTransitionDialogOpened(true)} disabled={disabled}>Close</Button>}
        {transitions.includes('default') &&
          <Button onClick={() => applyTransition('default')} disabled={disabled}>Default</Button>}
        {transitions.includes('change_terms') &&
          <Button onClick={() => setIsChangeTermsTransitionDialogOpened(true)} disabled={disabled}>Change
            terms</Button>}
        {transitions.includes('transfer_money') &&
          <Button onClick={() => setIsTransferMoneyTransitionDialogOpened(true)} disabled={disabled}>Transfer
            money</Button>}
        {transitions.includes('ready_for_disbursement') &&
          <Button onClick={() => setIsReadyForDisbursementTransitionDialogOpened(true)}
            disabled={disabled}>{record.is_ready_for_disbursement ? 'Unmark' : 'Mark'} ready for
            disbursement</Button>}
        {transitions.includes('reset') &&
          <Button onClick={() => applyTransition('reset')} disabled={disabled}>Reset</Button>}
        {(transitions.includes('assign_collector') || transitions.includes('assign_defaulted_collector')) &&
          <Button onClick={() => setIsAssignCollectorTransitionDialogOpened(true)} disabled={disabled}>Assign
            collector</Button>}
        {(transitions.includes('unassign_collector') || transitions.includes('unassign_defaulted_collector')) &&
          <Button
            onClick={() => applyTransition(transitions.includes('unassign_collector') ? 'unassign_collector' : 'unassign_defaulted_collector', {})}
            disabled={disabled}>Unassign collector</Button>}
        <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
      </ButtonGroup>
      {record.is_repeat &&
        record.days_past_due >= -3 &&
        record.days_past_due <= 15 &&
        record.state === 'active' &&
        <Box
          alignItems={'center'}
          display={'flex'}
          sx={{ backgroundColor: '#4caf50', color: 'white', borderRadius: '4px', padding: '6px' }}
        >
          OFFER EXTENSION
        </Box>
      }
      {isActivateTransitionDialogOpened &&
        <LoanDialogs.ActivateTransitionDialog
          isOpened={isActivateTransitionDialogOpened}
          onClose={() => setIsActivateTransitionDialogOpened(false)}
          onSubmit={disbursementDate => {
            setIsActivateTransitionDialogOpened(false);
            applyTransition('activate', { disbursement_date: formatISO(disbursementDate) });
          }}
        />
      }
      {isCloseTransitionDialogOpened &&
        <LoanDialogs.CloseTransitionDialog
          isOpened={isCloseTransitionDialogOpened}
          onClose={() => setIsCloseTransitionDialogOpened(false)}
          onSubmit={closureDate => {
            setIsCloseTransitionDialogOpened(false);
            applyTransition('close', { closure_date: formatISO(closureDate) });
          }}
        />
      }
      {isChangeTermsTransitionDialogOpened &&
        <LoanDialogs.ChangeTermsTransitionDialog
          isOpened={isChangeTermsTransitionDialogOpened}
          onClose={() => setIsChangeTermsTransitionDialogOpened(false)}
          onSubmit={(payload) => {
            setIsChangeTermsTransitionDialogOpened(false);
            applyTransition('change_terms', payload);
          }}
          record={record}
        />
      }
      {isTransferMoneyTransitionDialogOpened &&
        <LoanDialogs.TransferMoneyTransitionDialog
          isOpened={isTransferMoneyTransitionDialogOpened}
          onClose={() => setIsTransferMoneyTransitionDialogOpened(false)}
          onSubmit={gateway => {
            setIsTransferMoneyTransitionDialogOpened(false);
            applyTransition('transfer_money', { gateway });
          }}
        />
      }
      {isCancelTransitionDialogOpened &&
        <RejectTransitionDialog
          title={'Cancel loan'}
          text={'Please select cancellation reason'}
          isOpened={isCancelTransitionDialogOpened}
          onClose={() => setIsCancelTransitionDialogOpened(false)}
          onSubmit={cancel_reason_code => {
            setIsCancelTransitionDialogOpened(false);
            applyTransition('cancel', { cancel_reason_code });
          }}
        />
      }
      {isReadyForDisbursementTransitionDialogOpened &&
        <LoanDialogs.ReadyForDisbursementTransitionDialog
          isOpened={isReadyForDisbursementTransitionDialogOpened}
          onClose={() => setIsReadyForDisbursementTransitionDialogOpened(false)}
          onSubmit={value => {
            setIsReadyForDisbursementTransitionDialogOpened(false);
            applyTransition('ready_for_disbursement', { value });
          }}
          record={record}
        />
      }
      {isAssignCollectorTransitionDialogOpened &&
        <LoanDialogs.AssignCollectorTransitionDialog
          collectorId={getSubstringAfterSymbol(record?.assigned_collector, '#')}
          isOpened={isAssignCollectorTransitionDialogOpened}
          onClose={handleAssignCollectorIdDialogClose}
          onSubmit={handleAssignCollectorId}
        />
      }
    </>
  );
};

LoanTransitions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    assigned_collector: PropTypes.string,
    state: PropTypes.string,
    user_id: PropTypes.number,
    product_id: PropTypes.number,
    principal: PropTypes.number,
    tenor: PropTypes.number,
    promo_code: PropTypes.string,
    is_repeat: PropTypes.bool,
    is_ready_for_disbursement: PropTypes.bool,
  }),
};
