import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Checkbox, FormControlLabel,
} from '@material-ui/core';
import { DateTimePicker, DatePicker } from '@material-ui/pickers';

import { timezone } from '../../utils';

export const ActivateExtensionDialog = ({ isOpened, onClose, onSubmit }) => {
  const [extensionDate, setExtensionDate] = useState(new Date());
  const [time, setTime] = useState(false);

  const handleSubmit = () => onSubmit(extensionDate);
  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Activate extension</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        {time
          ? (
            <DateTimePicker
              inputVariant={'outlined'}
              label={'Extension date'}
              fullWidth
              value={timezone.shift(extensionDate)}
              onChange={(value) => setExtensionDate(timezone.unshift(value))}
            />
          )
          : (
            <DatePicker
              inputVariant={'outlined'}
              label={'Extension date'}
              fullWidth
              value={timezone.shift(extensionDate)}
              onChange={(value) => setExtensionDate(timezone.setTime(timezone.unshift(value), 2, 0, 0))}
            />
          )
        }
        <FormControlLabel
          control={
            <Checkbox checked={time} onChange={() => setTime(() => !time)} />}
          label={'Change extension time'}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleSubmit} color={'primary'} disabled={!extensionDate}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

ActivateExtensionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
