import { MASK_SYMBOL } from '../constants';

export const applyMask = (inputValue, mask) => {
  let result = '';
  let inputIndex = 0;

  for (let i = 0; i < mask.length; i++) {
    const maskChar = mask[i];

    if (maskChar === MASK_SYMBOL) {
      result += inputValue[inputIndex] || '';
      inputIndex++;
    } else {
      result += maskChar;
    }
  }

  return result;
};
