import React from 'react';
import PropTypes from 'prop-types';
import { DeleteButton, Edit, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  defaultToolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const CollectionContactTypeValueFormWrapper = ({ permissions, save, record, ...props }) => {
  const classes = useStyles();
  const isEditable = permissions.indexOf('CAN_COLLECTION_CALL_TREE_EDIT') !== -1;
  const redirectTo = `/collection_contact_types/${record.type}`;

  return (
    <SimpleForm
      save={(data, redirectTo, ...rest) => save(...[{ ...data, _params: { method: 'PATCH' } }, redirectTo, ...rest])}
      redirect={redirectTo}
      toolbar={(
        <Toolbar className={classes.defaultToolbar}>
          <SaveButton />
          <DeleteButton redirect={redirectTo} />
        </Toolbar>
      )}
      {...props}
    >
      <TextInput disabled source="id" />
      <TextInput disabled={!isEditable} source="value" />
    </SimpleForm>
  );
};

CollectionContactTypeValueFormWrapper.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  save: PropTypes.func,
  record: PropTypes.shape({
    type: PropTypes.string,
  }),
};

const CollectionContactTypeValueEdit = ({ permissions, ...props }) => {
  return (
    <Edit undoable={false} {...props}>
      <CollectionContactTypeValueFormWrapper permissions={permissions} />
    </Edit>
  );
};

CollectionContactTypeValueEdit.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default CollectionContactTypeValueEdit;
