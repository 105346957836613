import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Paper, Grid, Link } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

export const BlackListMatchComponent = ({ findInBlackList, username, summary }) => {
  const theme = useTheme();
  const matchedField = 'Main phone number is listed in blacklisted user field: ';
  return (
    <Accordion>
      <AccordionSummary
        style={{ backgroundColor: theme.palette.error.main }}
        expandIcon={<ExpandMoreIcon style={{ color: theme.palette.error.contrastText }} />}
        aria-controls="black-list-match-panel-content"
        id="black-list-match-panel-header"
      >
        <Typography style={{ color: theme.palette.error.contrastText }}>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {findInBlackList.map((item) => (
            <Paper key={item.id} variant="outlined">
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography variant='body2'>
                  <b>Blacklisted User ID: </b>
                  {item.user && <Link href={`#users/${item.user}`}>{`#${item.user}`}</Link>}
                </Typography>
                {item.phoneNumber === username && <Typography variant='body2'><b>{matchedField}</b>Phone number</Typography>}
                {item.contactPhoneNumber === username && <Typography variant='body2'><b>{matchedField}</b>Contact phone number</Typography>}
                {item.companyPhoneNumber === username && <Typography variant='body2'><b>{matchedField}</b>Company phone number</Typography>}
              </Grid>
            </Paper>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

BlackListMatchComponent.propTypes = {
  findInBlackList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    user: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    phoneNumber: PropTypes.string,
    contactPhoneNumber: PropTypes.string,
    companyPhoneNumber: PropTypes.string,
  })),
  username: PropTypes.string,
  summary: PropTypes.string,
};

BlackListMatchComponent.defaultProps = {
  findInBlackList: [
    {
      id: 0,
      user: '',
      phoneNumber: '',
      contactPhoneNumber: '',
      companyPhoneNumber: '',
    },
  ],
  username: '',
  summary: 'User data is matched with blacklisted user',
};
