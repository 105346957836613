import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Edit, FormWithRedirect, TextInput, SaveButton, DeleteButton, useNotify, useDataProvider, useRefresh,
} from 'react-admin';
import { makeStyles, Paper, Grid, Divider, Typography, Toolbar, Box, Button, Chip, ButtonGroup } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { AffiliateActionTable, AddAffiliateActionDialog, EditAffiliateActionDialog, AddAffiliatePostbackDialog } from '../../affiliate';
import { JsonParamsInput } from '../../input';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

const FormWrapper = ({ permissions, save, ...props }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const isEditable = permissions.indexOf('CAN_AFFILIATE_EDIT') !== -1;
  const [refreshedAt, setRefreshedAt] = useState();
  const [editAffiliateActionId, setEditAffiliateActionId] = useState(0);
  const [isAddAffiliateActionDialogOpened, setIsAddAffiliateActionDialogOpened] = useState(false);
  const [isEditAffiliateActionDialogOpened, setIsEditAffiliateActionDialogOpened] = useState(false);
  const [isAddAffiliatePostbackDialogOpened, setIsAddAffiliatePostbackDialogOpened] = useState(false);

  const onDeleteAffiliateAction = id => {
    dataProvider.delete('affiliate_actions', { id })
      .then(() => setRefreshedAt(Date.now()))
      .catch(error => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  const onEditAffiliateAction = id => {
    setEditAffiliateActionId(id);
    setIsEditAffiliateActionDialogOpened(true);
  };

  const onApplyAffiliateActionTransition = (id, name) => {
    dataProvider.query(
      `affiliate_actions/${id}/apply_transition`,
      {
        method: 'POST',
        body: JSON.stringify({ name, params: {} }),
      },
    )
      .then(() => setRefreshedAt(Date.now()))
      .catch(error => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  const onApplyAffiliateTransition = (id, name) => {
    dataProvider.query(
      `affiliates/${id}/apply_transition`,
      {
        method: 'POST',
        body: JSON.stringify({ name, params: {} }),
      },
    )
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" gutterBottom={ false }>
                Affiliate
              </Typography>
              <Chip size="small" label={props.record.state} />
            </Grid>
            <Box pt={1}>
              <Divider />
            </Box>
            <FormWithRedirect
              save={(data, ...rest) => {
                for (const p in data.target_params) {
                  if (data.target_params[p] === null) {
                    delete data.target_params[p];
                  }
                }
                save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest]);
              }}
              {...props}
              render={formProps => (
                <>
                  <TextInput disabled={!isEditable} source="name" fullWidth />
                  <JsonParamsInput isEditable={isEditable} source="target_params" label="Target url params" />
                  <Toolbar disableGutters>
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <ButtonGroup disabled={!isEditable} variant="contained" color="primary" size="small">
                        <SaveButton
                          saving={formProps.saving}
                          handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                        />
                        {(props.record.enabled_transitions || []).indexOf('enable') !== -1 && <Button onClick={() => onApplyAffiliateTransition(props.record.id, 'enable')}>Enable</Button>}
                        {(props.record.enabled_transitions || []).indexOf('disable') !== -1 && <Button onClick={() => onApplyAffiliateTransition(props.record.id, 'disable')}>Disable</Button>}
                      </ButtonGroup>
                    </Box>
                  </Toolbar>
                  <Box display="flex" justifyContent="space-between" width="100%">
                    <Button
                      startIcon={<ArrowForwardIosIcon/>}
                      variant='contained'
                      color='primary'
                      size='small'
                      onClick={() => setIsAddAffiliatePostbackDialogOpened(true)}
                    >
                      Affiliate test
                    </Button>
                    <DeleteButton
                      disabled={!isEditable}
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                    />
                  </Box>
                </>
              )}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" className={ classes.affiliateActionsTypography } gutterBottom={ false }>
                Actions
              </Typography>
              <Button
                disabled={!isEditable}
                color="primary"
                size="small"
                variant="contained"
                onClick={() => setIsAddAffiliateActionDialogOpened(true)}
              >
                Add
              </Button>
            </Grid>
            <Box pt={1}>
              <Divider />
            </Box>
            <AffiliateActionTable
              isEditable={isEditable}
              affiliateId={props.record.id}
              refreshedAt={refreshedAt}
              onDelete={onDeleteAffiliateAction}
              onEdit={onEditAffiliateAction}
              onApplyTransition={onApplyAffiliateActionTransition}
            />
          </Paper>
        </Grid>
      </Grid>
      {isEditable
        ? (
          <AddAffiliateActionDialog
            isOpened={isAddAffiliateActionDialogOpened}
            onClose={() => setIsAddAffiliateActionDialogOpened(false)}
            onSubmit={(triggerEvent, callbackUrl, callbackParams) => {
              setIsAddAffiliateActionDialogOpened(false);
              dataProvider.query(
                `affiliates/${props.record.id}/affiliate_actions`,
                {
                  method: 'POST',
                  body: JSON.stringify({
                    trigger_event: triggerEvent,
                    callback_url: callbackUrl,
                    callback_params: callbackParams,
                  }),
                },
              )
                .then(() => setRefreshedAt(Date.now()))
                .catch(error => notify(`Error: ${error.message}`, 'error'))
              ;
            }}
          />
        )
        : null
      }
      {isEditable
        ? (
          <AddAffiliatePostbackDialog
            isOpened={isAddAffiliatePostbackDialogOpened}
            onClose={() => setIsAddAffiliatePostbackDialogOpened(false)}
            onSubmit={(state) => {
              setIsAddAffiliatePostbackDialogOpened(false);
              dataProvider.query(
                `affiliates/${props.record.id}/postback_check`,
                {
                  method: 'POST',
                  body: JSON.stringify(state),
                },
              )
                .then(({ data }) => {
                  setRefreshedAt(Date.now());
                  const json_string = JSON.stringify(data, undefined, 2);
                  const blob = new Blob([json_string], { type: 'txt' });
                  const link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  link.download = new Date() + '_response.txt';
                  link.click();
                  notify(`Success: ${json_string}`, 'success');
                })
                .catch(error => notify(`Error: ${error.message}`, 'error'))
              ;
            }}
          />
        )
        : null
      }
      {isEditable && ((editAffiliateActionId && isEditAffiliateActionDialogOpened) || null)
        ? (
          <EditAffiliateActionDialog
            affiliateActionId={editAffiliateActionId}
            isOpened={isEditAffiliateActionDialogOpened}
            onClose={() => {
              setEditAffiliateActionId(0);
              setIsAddAffiliateActionDialogOpened(false);
            }}
            onSubmit={(affiliateActionId, triggerEvent, callbackUrl, callbackParams) => {
              setEditAffiliateActionId(0);
              setIsEditAffiliateActionDialogOpened(false);
              dataProvider.update('affiliate_actions', {
                id: affiliateActionId,
                data: {
                  _params: { method: 'PATCH' },
                  trigger_event: triggerEvent,
                  callback_url: callbackUrl,
                  callback_params: callbackParams,
                },
              })
                .then(() => setRefreshedAt(Date.now()))
                .catch(error => notify(`Error: ${error.message}`, 'error'))
              ;
            }}
          />
        )
        : null
      }
    </>
  );
};

FormWrapper.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  save: PropTypes.func,
  record: PropTypes.shape({
    state: PropTypes.string,
    enabled_transitions: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.number,
  }),
};

export const AffiliateEdit = ({ permissions = [], ...props }) => (
  <Edit component="div" undoable={false} {...props}>
    <FormWrapper permissions={permissions} />
  </Edit>
);

AffiliateEdit.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default AffiliateEdit;
