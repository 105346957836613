import { useState } from 'react';
import { Button, useNotify, useDataProvider, Confirm, useListContext } from 'react-admin';
import PropTypes from 'prop-types';

import { TransferMoneyTransitionDialog } from '../pages/loan/loan_dialogs';

export const TransferMoneyButton = ({ selectedIds }) => {
  const { data } = useListContext();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [transferMoneyDialogOpen, setTransferMoneyDialogOpen] = useState(false);
  const [disabled, setDisabled] = useState();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleConfirmTransferMoneyDialogClose = () => setTransferMoneyDialogOpen(false);
  const applyTransition = async (name, params = {}, id) => {
    if (data[id].enabled_transitions && data[id].enabled_transitions.indexOf('transfer_money') !== -1) {
      await dataProvider.query(`loans/${id}/apply_transition`, { method: 'POST', body: JSON.stringify({ name, params }) })
        .then(() => notify(`Transfer money: [loan id ${id}] please activate or cancel`, 'success'))
        .catch(error => notify(`Error: ${error.message || 'transfer money error'}`, 'error'));
    }
  };
  const handleConfirmDialogOpen = () => setConfirmDialogOpen(true);
  const handleConfirmDialogClose = () => setConfirmDialogOpen(false);
  const handleConfirm = (gateway, ids) => {
    applyTransition('transfer_money', { gateway }, ids[0])
      .then(() => {
        ids.shift();
        if (ids.length) {
          handleConfirm(gateway, ids);
        } else {
          setDisabled((state) => !state);
        }
      });
  };
  const handleConfirmTransferMoney = () => {
    setTransferMoneyDialogOpen(true);
    handleConfirmDialogClose();
  };
  const handleTrnasferMoneySubmit = (gateway) => {
    const ids = selectedIds.slice();
    handleConfirm(gateway, ids);
    handleConfirmTransferMoneyDialogClose();
    setDisabled((state) => !state);
  };
  return (
    <>
      <Button label={'Transfer money'} variant={'contained'} onClick={handleConfirmDialogOpen} color={'primary'} disabled={disabled} />
      {confirmDialogOpen && <Confirm
        isOpen={confirmDialogOpen}
        title={'Transfer money'}
        content={`Are you sure to disburse ${selectedIds.length} loans`}
        onConfirm={handleConfirmTransferMoney}
        onClose={handleConfirmDialogClose}
      />}
      {transferMoneyDialogOpen && <TransferMoneyTransitionDialog
        isOpened={transferMoneyDialogOpen}
        onClose={handleConfirmTransferMoneyDialogClose}
        onSubmit={handleTrnasferMoneySubmit}/>}
    </>
  );
};

TransferMoneyButton.propTypes = {
  selectedIds: PropTypes.array.isRequired,
};
