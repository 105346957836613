import {
  BooleanInput, maxLength, NumberInput, required, SaveButton, SelectArrayInput, SelectInput, TextInput,
} from 'react-admin';
import { Box, CardContent, Checkbox, Divider, FormControlLabel, Grid, InputLabel, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { time as timeValidator } from '../../../utils';

export const DialerBasesForm = ({
  formProps, audienceChoices, transmitters, transmitter, dialerId, contactSource,
  getAllowedValues, frequencyPeriod, phoneStatus, contactType, phoneValidation, onChange, weekDays, variables,
}) => {
  const isEdit = Object.keys(formProps.record)?.length > 0;

  return (
    <>
      <Box display={'flex'} alignItems={'center'} padding={'16px'} justifyContent={'space-between'}>
        <Typography variant={'subtitle1'}>{isEdit ? 'Edit' : 'Create'} Dialer Base</Typography>
        <BooleanInput helperText={false} label={'Enabled'} source={'is_enabled'} initialValue={true} />
      </Box>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} key='name'>
            <TextInput variant={'outlined'} size={'small'} source='name' validate={[required(), maxLength(255)]} fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} key='start_time'>
            {/* TODO: yup validate */}
            <TextInput
              variant={'outlined'} size={'small'}
              helperText='Format: hh:mm:ss'
              source='start_time'
              validate={[required(), (value) => timeValidator(value)]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} key='end_time'>
            <TextInput
              variant={'outlined'} size={'small'}
              helperText='Format: hh:mm:ss'
              source='end_time'
              validate={[required(), (value) => timeValidator(value)]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} key={isEdit ? 'mass_sending_audience_id' : 'mass_sending_audience'}>
            <SelectInput
              variant={'outlined'} size={'small'}
              helperText={false}
              source={isEdit ? 'mass_sending_audience_id' : 'mass_sending_audience'}
              validate={[required()]}
              choices={audienceChoices}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} key={isEdit ? 'transmitter_id' : 'transmitters'}>
            <SelectInput
              variant={'outlined'} size={'small'}
              helperText={false}
              onChange={(e) => transmitter.setTransmitter(e.target.value)}
              source={isEdit ? 'transmitter_id' : 'transmitters'}
              validate={[required()]}
              choices={Object.keys(transmitters)?.map((key) => ({ id: key, name: key }))}
              fullWidth
            />
          </Grid>
          {transmitter.transmitter === 'webitel' && (
            <>
              <Grid item xs={12} sm={6} key='dialerID'>
                <NumberInput
                  variant={'outlined'} size={'small'}
                  source='dialer_id'
                  onChange={(e) => dialerId.setDialerId(e.target.value)}
                  validate={[required(), maxLength(255)]}
                  fullWidth
                  initialValue={dialerId.dialerId}
                />
              </Grid>
              <Grid item xs={12} sm={6} key='frequency_period'>
                <SelectInput
                  variant={'outlined'} size={'small'}
                  helperText={false}
                  onChange={(e) => frequencyPeriod.setFrequencyPeriod(e.target.value)}
                  source='frequency_period'
                  validate={[required()]}
                  choices={getAllowedValues('frequency_period')}
                  initialValue={frequencyPeriod.frequencyPeriod}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} key='phone_status'>
                <SelectInput
                  variant={'outlined'} size={'small'}
                  allowEmpty
                  helperText={false}
                  onChange={(e) => phoneStatus.setPhoneStatus(e.target.value)}
                  source='phone_status'
                  choices={getAllowedValues('phone_status')}
                  initialValue={phoneStatus.phoneStatus}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} key='contact_type'>
                <SelectArrayInput
                  variant={'outlined'}
                  size={'small'}
                  allowEmpty
                  helperText={false}
                  onChange={(e) => contactType.setContactType(e.target.value)}
                  source='contact_type'
                  choices={getAllowedValues('contact_type')}
                  initialValue={contactType.contactType}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} key='contact_source'>
                <SelectArrayInput
                  variant={'outlined'}
                  size={'small'}
                  allowEmpty
                  label={'Contact source'}
                  source='contact_source'
                  helperText={false}
                  initialValue={contactSource.contactSource}
                  onChange={(e) => contactSource.setContactSource(e.target.value)}
                  choices={getAllowedValues('contact_source')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} key='phone_validation'>
                <SelectArrayInput
                  variant={'outlined'} size={'small'}
                  allowEmpty
                  helperText={false}
                  onChange={(e) => phoneValidation.setPhoneValidation(e.target.value)}
                  source='phone_validation'
                  choices={getAllowedValues('phone_validation')}
                  initialValue={phoneValidation.phoneValidation}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} key='week_days'>
                <InputLabel id='week_days_label'>Week days</InputLabel>
                {getAllowedValues('week_days').map((day) => (
                  <FormControlLabel
                    key={day.id}
                    control={
                      <Checkbox
                        onChange={() => onChange(day.id, 'week_days')}
                        checked={weekDays?.includes(day.id)}
                      />
                    }
                    label={day.label}
                  />
                ))}
              </Grid>
              <Grid item xs={12} sm={12} key='variables'>
                <InputLabel id='week_days_label'>Variables</InputLabel>
                {getAllowedValues('variables').map((variable) => (
                  <FormControlLabel
                    key={variable.id}
                    control={
                      <Checkbox
                        onChange={() => onChange(variable.id, 'variables')}
                        checked={variables?.includes(variable.id)}
                      />
                    }
                    label={variable.label}
                  />
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <Divider />
      <Box padding={'16px'}>
        <SaveButton
          label='Submit'
          saving={formProps.saving}
          handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
        />
      </Box>
    </>
  );
};

DialerBasesForm.propTypes = {
  isEdit: PropTypes.bool,
  audienceChoices: PropTypes.array,
  formProps: PropTypes.object,
  transmitters: PropTypes.object,
  transmitter: PropTypes.object,
  dialerId: PropTypes.object,
  getAllowedValues: PropTypes.func,
  frequencyPeriod: PropTypes.object,
  phoneStatus: PropTypes.object,
  contactType: PropTypes.object,
  contactSource: PropTypes.object,
  phoneValidation: PropTypes.object,
  onChange: PropTypes.func,
  weekDays: PropTypes.array,
  variables: PropTypes.array,
};
