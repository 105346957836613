import React, { useState } from 'react';
import {
  Datagrid, FunctionField, List, TextField, useDataProvider, useNotify, useRefresh,
} from 'react-admin';
import { Chip, Button, IconButton, Box, Tooltip, ButtonGroup, Link } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { WarningRounded } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types';

import {
  calculateAge, formatPhoneNumber, timezone, CALL_CENTER_USER_STATUS_UNCOMPLETED_REG,
} from '../../../utils';
import Pagination from '../../Pagination';
import { UserListActions } from '../user';
import { CallCenterLogTable } from '../../tables';
import { Call } from '../../Call';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

import { assignEssence, changeStatus } from './actions';
import { DropdownDialog, LogDialog } from './dialogs';
import { CallCenterListFilter } from './CallCenterListFilter';

const RedMark = ({ title }) => <Tooltip title={title}><WarningRounded color="error" /></Tooltip>;

RedMark.propTypes = {
  phone: PropTypes.string,
  title: PropTypes.string,
};

const ROWS = [
  <TextField key={'id'} source={'id'} />,
  <FunctionField key={'phone_number'} source={'phone_number'}
    render={({ id, phone_number, birthday, employment_type }) => {
      const age = calculateAge(
        new Date(birthday).getMonth(),
        new Date(birthday).getDate(),
        new Date(birthday).getFullYear(),
        timezone.shift(new Date()));
      return <Box display="flex" alignItems="center">
        {age < 20 && <RedMark title={'Age < 20'} />}
        {employment_type === 'Temporarily Unemployed' && <RedMark title={'Employment type is a ' + employment_type} />}
        {phone_number && <Call userId={id} userPhone={phone_number}>{formatPhoneNumber(phone_number)}</Call>}
      </Box>;
    }} />,
  <FunctionField
    key={'name-email'}
    label={'Name / E-mail'}
    render={({ first_name, last_name, gender, email }) => (
      <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'}>
        {first_name && last_name && <Box style={{ whiteSpace: 'nowrap' }}>{`${first_name} ${last_name}`}</Box>}
        {gender && <Chip size={'small'} label={gender} />}
        {email && <Link style={{ whiteSpace: 'nowrap' }} color={'secondary'} href={`mailto: ${email}`}>{email}</Link>}
      </Box>
    )}
  />,
  <TextField style={{ whiteSpace: 'nowrap' }} key={'employment_type'} source={'employment_type'} />,
  <FunctionField
    key={'created_at'}
    source={'created_at'}
    label={'Registered at'}
    sortBy={'created_at'}
    render={(record) => <DateTimeBoxComponent value={record.created_at}/>}
  />,
];

export const CallCenterList = ({ permissions, ...props }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [refreshedAt, setRefreshedAt] = useState();
  const [openLogDialog, setOpenLogDialog] = useState(false);
  const [openDropdownDialog, setOpenDropdownDialog] = useState(false);
  const [userIdLog, setUserIdLog] = useState();
  const [userIdStatus, setUserIdStatus] = useState();

  const handleAssign = (id) => assignEssence(
    dataProvider,
    notify,
    refresh,
    'phone_call_user_assigns',
    { user_id: id, context: 'user_uncompleted_registrations' },
  );
  const handleDropDownDialogOpen = () => setOpenDropdownDialog((state) => !state);
  const handleChangeStatus = (id) => {
    setUserIdStatus(id);
    setOpenDropdownDialog((state) => !state);
  };
  const handleSubmitStatusChange = (userStatus) =>
    changeStatus(
      userStatus,
      dataProvider,
      notify,
      setOpenDropdownDialog,
      refresh,
      'phone_calls',
      {
        user_id: userIdStatus,
        result: userStatus,
        context: 'user_uncompleted_registrations',
      },
    );

  const handleImpersonate = (userId, registration_step) => {
    dataProvider.query(`users/${userId}/token`, { method: 'GET' })
      .then(({ data }) => window.open(`${process.env.REACT_APP_FRONT_HOST}/user/${registration_step > 9 ? 'account' : `registration/step${registration_step}`}?impersonate=${encodeURIComponent(JSON.stringify(data))}`))
      .catch((error) => notify(`Error: ${error.message || 'token not found'}`, 'error'));
  };
  const handleShowStatusLogs = (id) => {
    setUserIdLog(id);
    setRefreshedAt(new Date().valueOf());
    setOpenLogDialog((state) => !state);
  };
  return (
    <>
      <LogDialog open={openLogDialog} close={setOpenLogDialog} title={'User status log'}>
        <CallCenterLogTable
          refreshedAt={refreshedAt}
          filter={{ 'user.id': userIdLog, context: 'user_uncompleted_registrations' }}
          isDialog
        />
      </LogDialog>
      {openDropdownDialog && <DropdownDialog
        title={'Change user status'}
        isOpened={openDropdownDialog}
        onClose={handleDropDownDialogOpen}
        onSubmit={handleSubmitStatusChange}
        selectOptions={CALL_CENTER_USER_STATUS_UNCOMPLETED_REG}
      />}
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<CallCenterListFilter />}
        actions={<UserListActions />}
        permissions={permissions}
        {...props}
      >
        <Datagrid rowClick={'edit'}>
          {ROWS.map((i) => i)}
          <TextField label={'Operator'} source={'last_phone_calls_by_context.user_uncompleted_registrations.operator'} />
          <FunctionField
            onClick={(e) => e.stopPropagation()}
            label={'Last status'}
            render={({ last_phone_calls_by_context, id }) =>
              last_phone_calls_by_context?.user_uncompleted_registrations?.result &&
              <Chip size={'small'} label={last_phone_calls_by_context?.user_uncompleted_registrations?.result} />}
          />
          <FunctionField
            onClick={(e) => e.stopPropagation()}
            label={'Status actions'}
            render={({ last_phone_calls_by_context, id }) => {
              if (last_phone_calls_by_context?.user_uncompleted_registrations?.operator) {
                return (
                  <ButtonGroup color={'secondary'}>
                    <Button size={'small'} onClick={() => handleChangeStatus(id)} disabled={permissions.indexOf('CAN_PHONE_CALL_EDIT') === -1}>
                      <RefreshIcon />
                    </Button>
                    <Button
                      size={'small'}
                      onClick={handleShowStatusLogs}
                      disabled={permissions.indexOf('CAN_PHONE_CALL_VIEW') === -1}
                    >
                      Log
                    </Button>
                  </ButtonGroup>
                );
              } else {
                return (
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => handleAssign(id)}
                    disabled={permissions.indexOf('CAN_PHONE_CALL_EDIT') === -1}
                  >Assign</Button>
                );
              }
            }
            }
          />
          <FunctionField
            label={'Last activity'}
            render={(record) =>
              <DateTimeBoxComponent value={record.last_phone_calls_by_context?.user_uncompleted_registrations?.called_at} />}
          />
          <FunctionField
            label={'Step'} source={'registration_step'}
            render={(record, key) => record[key] ? <Chip size={'small'} label={record[key]} /> : null}
          />
          <FunctionField
            label={'Affiliate'} source={'affiliate'}
            render={(record, key) => record[key] ? <Chip size="small" label={record[key]} /> : null}
          />
          <FunctionField onClick={(e) => e.stopPropagation()} render={({ id, registration_step }) => (
            <IconButton color={'secondary'} size={'small'} onClick={() => handleImpersonate(id, registration_step)}>
              <OpenInNewIcon />
            </IconButton>
          )}
          />
        </Datagrid>
      </List>
    </>
  );
};

CallCenterList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};
