import { cloneElement } from 'react';
import { TopToolbar, useListContext, sanitizeListRestProps } from 'react-admin';
import PropTypes from 'prop-types';

export const LoanListActions = ({ filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    </TopToolbar>
  );
};

LoanListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};
