import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { DashboardMenuItem, getResources, MenuItemLink } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import BookIcon from '@material-ui/icons/Book';
import DefaultIcon from '@material-ui/icons/ViewList';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Folder';
import NextWeekIcon from '@material-ui/icons/NextWeek';
import PropTypes from 'prop-types';
import UserIcon from '@material-ui/icons/Group';

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
  },
  listItemIcon: {
    minWidth: theme.spacing(5),
  },
  listItemText: {
    marginTop: 0,
    marginBottom: 0,
  },
  nested: {
    paddingLeft: theme.spacing(2.5),
  },
}));

const MenuGroup = ({ id, label, icon, resources, onMenuClick, isSidebarOpen, children }) => {
  const classes = useStyles();
  const [isOpened, setIsOpened] = useState(false);
  const items = resources.filter(item => item.options.menuGroup === id && !item.options.subMenuGroup);

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <ListItem className={classes.listItem} button onClick={() => setIsOpened(!isOpened)}>
        <ListItemIcon className={classes.listItemIcon}>
          {icon || <SettingsIcon />}
        </ListItemIcon>
        <ListItemText className={classes.listItemText} primary={label} />
        {isOpened ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={isOpened} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={isSidebarOpen ? classes.nested : null}>
          {items.map(resource => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && resource.options.label) ||
                resource.name
              }
              leftIcon={
                resource.icon ? <resource.icon /> : <DefaultIcon />
              }
              onClick={onMenuClick}
              sidebarIsOpen={isSidebarOpen}
            />
          ))}
          {children}
        </List>
      </Collapse>
    </>
  );
};

MenuGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.object,
  resources: PropTypes.array,
  onMenuClick: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  children: PropTypes.node,
};

export const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  return (
    <>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      {resources.map(resource => {
        if (resource.options.menuGroup) {
          return null;
        }

        return (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) ||
              resource.name
            }
            leftIcon={
              resource.icon ? <resource.icon /> : <DefaultIcon />
            }
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        );
      })}
      <MenuGroup
        id="users"
        label="Users"
        icon={<UserIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup
        id="requests"
        label="Applications"
        icon={<FolderIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup
        id="call_centers"
        label="Call center"
        icon={<FolderIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup
        id="loans"
        label="Loans"
        icon={<FolderIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      >
        <MenuGroup
          id="pending"
          label="Pending"
          icon={<FolderIcon />}
          resources={resources}
          onMenuClick={onMenuClick}
          isSidebarOpen={open}
        />
      </MenuGroup>

      <MenuGroup
        id="marketing"
        label="Marketing"
        icon={<FolderIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup
        id="management"
        label="Management"
        icon={<FolderIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup
        id="handbook"
        label="Handbook"
        icon={<BookIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup
        id="collection"
        label="Collection"
        icon={<NextWeekIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup
        id="settings"
        label="Settings"
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      {isXSmall && logout}
    </>
  );
};

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.object,
};
