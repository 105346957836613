import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { JsonParamsInput } from '../../input';

export const AffiliateCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <JsonParamsInput source="target_params" label="Target url params" />
    </SimpleForm>
  </Create>
);
