import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Create, FormDataConsumer, FormWithRedirect, maxLength, required, SaveButton, SelectInput, TextInput, useDataProvider,
} from 'react-admin';
import { Button, Checkbox, Divider, FormControlLabel, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { ALPHA_NAMES, formatChoices, TRANSMITTERSLIST_DEFAULT } from '../../../utils';
import { DateTimePickerInput } from '../../input';
import { withServerAutocomplete, SelectAutoComplete } from '../../select';
import { useHandbook } from '../../../hooks';

const AutoComplete = withServerAutocomplete(SelectAutoComplete);

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();
  const [options, setOptions] = useState({
    audience: null,
  });
  const [params, setParams] = useState([]);
  const [usePhoneBook, setUsePhoneBook] = useState(false);
  const [audiencesList, setAudiencesList] = useState([]);
  const dataProvider = useDataProvider();
  const [valueKey, setValueKey] = useState('');
  const [value, setValue] = useState('');

  const { choices: contactRelationshipChoices } = useHandbook('phone_relations');
  const { choices: phoneTypeChoices } = useHandbook('phone_types');
  const { choices: phoneSourceChoices } = useHandbook('phone_sources');

  useEffect(() => {
    dataProvider.query('mass_sending_audiences?pagination=false', { method: 'GET' })
      .then(({ data }) => setAudiencesList(data));
  }, [dataProvider]);

  const addItem = () => {
    setParams([...params, {
      id: Date.now(),
      type: null,
      relation: null,
      source: null,
    }]);
  };

  const removeItem = (id) => {
    setParams([...params.filter(item => item.id !== id)]);
  };
  const showOptions = (value) => {
    setUsePhoneBook(value);
    setParams([]);
  };
  const setSelectValue = (id, type, value) => {
    setParams([...params.map(item => {
      if (item.id === id && type === 'type' && value !== 'contact_person') {
        item[type] = value;
        item.relation = null;
      } else if (item.id === id) {
        item[type] = value;
      }
      return item;
    })]);
  };
  const AUDIENCE_CHOICES = [
    ...audiencesList?.map(item => ({ id: item.id, name: item.name })),
  ];

  const defaultFieldSet = [
    <Grid item xs={12} sm={6} key="transmitter_id">
      <SelectInput
        helperText={false}
        source="transmitter_id"
        validate={[required()]}
        choices={TRANSMITTERSLIST_DEFAULT}
        fullWidth
      />
    </Grid>,
    <FormDataConsumer key='consumer'>
      {({ formData, ...rest }) =>
        (formData.transmitter_id === 'text_ware' || formData.transmitter_id === 'text_ware_promo') && (
          <Grid item xs={12} sm={6} key='alpha_name'>
            <SelectInput
              helperText={false}
              source='alpha_name'
              validate={[required()]}
              choices={formData.transmitter_id ? formatChoices(ALPHA_NAMES[formData.transmitter_id]) : []}
              {...rest}
              fullWidth
            />
          </Grid>
        )}
    </FormDataConsumer>,
    <Grid item xs={12} sm={6} key='template_key'>
      <AutoComplete
        perOnePage={15}
        resource="admin/templates"
        label="Template Key"
        value={value}
        filter={valueKey ? { key: valueKey } : {}}
        onChangeInput={val => setValueKey(val)}
        setValue={setValue}
        prop={'key'}
      />
    </Grid>,
    <Grid item xs={12} sm={6} key="scheduled_at">
      <DateTimePickerInput
        label="Scheduled at"
        source="scheduled_at"
        inputVariant="filled"
        fullWidth
        margin="dense"
      />
    </Grid>,
    <Grid item xs={12} sm={6} key="name">
      <TextInput
        helperText={false}
        source="name"
        validate={[maxLength(255)]}
        fullWidth
      />
    </Grid>,
  ];

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom={false}>
            Create mass sending process
          </Typography>
          <Divider className={classes.my2} />
          <FormWithRedirect
            save={(
              {
                audience,
                transmitter_id,
                scheduled_at,
                name,
                alpha_name,
              },
              ...rest
            ) => {
              save(
                ...[
                  {
                    audience,
                    name,
                    transmitter_id,
                    template_key: value,
                    scheduled_at,
                    params: {
                      use_phone_book: usePhoneBook,
                      phone_book_filters: params,
                      alpha_name,
                    },
                  },
                  ...rest,
                ],
              );
            }}
            {...props}
            render={(formProps) => {
              return (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        helperText={false}
                        onChange={(e) => {
                          setOptions({
                            ...options,
                            target_group_id: e.target.value,
                          });
                        }}
                        source="audience"
                        validate={[required()]}
                        choices={AUDIENCE_CHOICES}
                        fullWidth
                      />
                    </Grid>
                    {defaultFieldSet}
                  </Grid>
                  <Divider className={classes.my2} />
                  <FormControlLabel
                    control={
                      <Checkbox value={usePhoneBook} onChange={() => showOptions(!usePhoneBook)} />
                    }
                    label="Use alternative contacts"
                  />
                  {usePhoneBook
                    ? <Fragment>
                      <Button onClick={addItem} color="primary" variant="contained">add</Button>
                      {
                        params.map(item => <Grid container alignItems={'center'} spacing={2} key={item.id}>
                          <Grid item xs={12} sm={4}>
                            <SelectInput
                              key={`contact-sources-input-${phoneSourceChoices.length}`}
                              helperText={false}
                              label={'Contact source'}
                              source={`contact_source->${item.id}`}
                              validate={[required()]}
                              choices={phoneSourceChoices}
                              onChange={(e) => setSelectValue(item.id, 'source', e.target.value)}
                              value={item.source}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <SelectInput
                              key={`contact-types-input-${phoneTypeChoices.length}`}
                              helperText={false}
                              label={'Contact type'}
                              source={`contact_type->${item.id}`}
                              validate={[required()]}
                              choices={phoneTypeChoices}
                              onChange={(e) => setSelectValue(item.id, 'type', e.target.value)}
                              value={item.type}
                              fullWidth
                            />
                          </Grid>
                          {item.type === 'contact_person'
                            ? <Grid item xs={12} sm={3}>
                              <SelectInput
                                key={`contact-relationship-input-${contactRelationshipChoices.length}`}
                                emptyValue={null}
                                helperText={false}
                                validate={[required()]}
                                label={'Contact relation'}
                                source={`contact_relation->${item.id}`}
                                choices={contactRelationshipChoices}
                                value={item.relation}
                                onChange={(e) => setSelectValue(item.id, 'relation', e.target.value)}
                                fullWidth
                              />
                            </Grid>
                            : null
                          }
                          <Grid item xs={12} sm={2}>
                            <Button onClick={() => removeItem(item.id)} color="primary" variant="contained">
                              <Delete />
                            </Button>
                          </Grid>
                          <Divider className={classes.my2} />
                        </Grid>)
                      }
                    </Fragment>
                    : null
                  }
                  <Divider className={classes.my2} />

                  <SaveButton
                    label="Submit"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </>
              );
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

export const MassSendingProcessCreate = ({ permissions = [], ...props }) => (
  <Create component="div" {...props}>
    <FormWrapper permissions={permissions} />
  </Create>
);

MassSendingProcessCreate.propTypes = {
  permissions: PropTypes.array,
};
