import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export const AddExtensionDialog = ({ isOpened, onClose, onSubmit, extensionTenors = [] }) => {
  const [days, setDays] = useState();
  const handleInDaysChange = (e) => setDays(e.target.value);
  const handleSubmit = () => onSubmit(days);
  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Request extention</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <FormControl fullWidth>
          <InputLabel id={'label-select-label'}>In days</InputLabel>
          <Select
            label={'In days'}
            labelId={'label-select-label'}
            value={days}
            onChange={handleInDaysChange}
          >
            {extensionTenors.map((i) => <MenuItem key={i + '_days'} value={i}>{i} days</MenuItem>)}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleSubmit} color={'primary'} disabled={!days}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

AddExtensionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  extensionTenors: PropTypes.array,
};

