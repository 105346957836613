import React, { useEffect, useState } from 'react';
import { TopToolbar, useDataProvider, useNotify } from 'react-admin';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, TableSortLabel } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';

import { useCollectionStyles } from './collectionStyles';

export const CollectionContactTypeValuesTable = ({ typeId, onClickCreate, refresh }) => {
  const classes = useCollectionStyles();
  const dataProvider = useDataProvider();
  const history = useHistory();
  const notify = useNotify();

  const [rows, setRows] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  useEffect(() => {
    dataProvider
      .getList('collection_contact_type_values', {
        filter: {
          ...(typeId ? { type: typeId } : {}),
        },
        pagination: { page, perPage },
        sort: { field: sortField, order: sortOrder },
      })
      .then(({ data, total }) => {
        setRows(data);
        setTotal(total);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataProvider, refresh, typeId, page, perPage, sortField, sortOrder]);

  const hasToolbar = !!onClickCreate;

  const buildHandleClickSort = (field) => {
    return () => {
      if (field === sortField) {
        setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
      } else {
        setSortField(field);
        setSortOrder('ASC');
      }
    };
  };

  const buildHandleClickRow = (id) => {
    return (e) => {
      e.preventDefault();

      history.push(`/collection_contact_type_values/${id}`);
    };
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <div className={classes.tableWrapper}>
      {hasToolbar && (
        <TopToolbar>
          {!!onClickCreate && (
            <Button
              color="primary"
              size="small"
              type="button"
              onClick={onClickCreate}
              startIcon={<IconAdd />}
            >
              Create
            </Button>
          )}
        </TopToolbar>
      )}

      <Card>
        <CardHeader title="Contact type values"/>
        <CardContent>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'id'}
                    direction={sortOrder === 'ASC' ? 'asc' : 'desc'}
                    onClick={buildHandleClickSort('id')}
                  >
                    Id
                  </TableSortLabel>
                </TableCell>
                <TableCell>Enabled</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id} onClick={buildHandleClickRow(row.id)} className={classes.clickableRow}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.enabled ? <DoneIcon /> : <ClearIcon />}</TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={total}
            rowsPerPage={perPage}
            page={page - 1}
            onPageChange={(e, page) => setPage(page + 1)}
            onRowsPerPageChange={e => {
              setPerPage(parseInt(e.target.value, 10));
              setPage(1);
            }}
          />
        </CardContent>
      </Card>
    </div>
  );
};

CollectionContactTypeValuesTable.propTypes = {
  typeId: PropTypes.number,
  onClickCreate: PropTypes.func,
  refresh: PropTypes.number,
};
