import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';

import { TitleCloseDialog } from '../dialogs';

export const EngineDataDialog = ({
  isOpened,
  onClose,
  title = 'Engine data',
  array = [{ name: 'left-table-cell', value: 'right-table-cell' }],
}) => {
  return (
    <TitleCloseDialog open={isOpened} close={onClose} title={title} fullWidth>
      <Table size="small">
        <TableBody>
          {array.map((i, id) => {
            return (
              <TableRow key={id + name}>
                <TableCell>{i.name}</TableCell>
                <TableCell align='right' style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>{i.value}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TitleCloseDialog>
  );
};
EngineDataDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  array: PropTypes.array.isRequired,
};
