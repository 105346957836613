import { useEffect, useState } from 'react';
import ImgsViewer from 'react-images-viewer';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useDataProvider, useNotify } from 'react-admin';
import { Typography, ButtonGroup, Box, Button, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RefreshIcon from '@material-ui/icons/Refresh';
import { bool, number, oneOfType, string } from 'prop-types';

import { dateNewFormat } from '../../utils';
import { DATE_TIME_FULL_FORMAT } from '../../constants';

export const findImage = (array = [], name) => array.includes(name);

export const ImageZoom = ({ lastUploadTime, isExist, userId, label }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [imageAngle, setImageAngle] = useState(0);
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [currImg, setCurrImg] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [stateID, setStateID] = useState(Math.random());

  useEffect(() => {
    if (isExist || lastUploadTime) {
      dataProvider.getList('files', {
        pagination: { page: 1, perPage: 30 },
        sort: { field: 'created_at', order: 'DESC' },
        filter: { label, user: userId },
      })
        .then((res) => {
          if (res?.data) {
            const { data } = res;
            const promises = data.map((item) => dataProvider.getImage(`files/${item.id}/get_file`));
            Promise.all(promises).then((results) => {
              const images = results.map((item, index) => ({
                src: item.data, caption: `Created at: ${dateNewFormat(DATE_TIME_FULL_FORMAT).format(new Date(data[index].created_at))}`,
              }));
              if (images) {
                setImages(images);
                if (images.length > 0) {
                  setImage(images[0].src);
                }
              }
            });
          }
        })
        .catch((error) => notify(`Error: ${error.message || 'User files not found'}`, 'error'));
    }
  }, [dataProvider, isExist, lastUploadTime, notify, userId, label, stateID]);

  const handleImageViewerOpen = () => setIsViewerOpen(true);

  const handleImageViewerClose = () => setIsViewerOpen(false);
  return (
    (isExist || lastUploadTime)
      ? image
        ? <>
          <TransformWrapper>
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <Box onClick={handleImageViewerOpen}>
                  <TransformComponent>
                    <img alt={'User document'} src={image} style={{ maxWidth: '100%', cursor: 'pointer', transform: 'rotate(' + imageAngle + 'deg)' }} />
                  </TransformComponent>
                </Box>
                <ButtonGroup fullWidth color={'secondary'}>
                  <Button onClick={() => zoomIn()}>
                    <ZoomInIcon />
                  </Button>
                  <Button onClick={() => zoomOut()}>
                    <ZoomOutIcon />
                  </Button>
                  <Button onClick={() => resetTransform()}>
                    <ZoomOutMapIcon />
                  </Button>
                  <Button onClick={() => setImageAngle((state) => state + 90)}>
                    <RotateRightIcon />
                  </Button>
                  <Button onClick={() => setStateID(Math.random())}>
                    <RefreshIcon />
                  </Button>
                </ButtonGroup>
              </>
            )}
          </TransformWrapper>
          {isViewerOpen && (
            <ImgsViewer
              isOpen={isViewerOpen}
              onClose={handleImageViewerClose}
              imgs={images}
              spinnerSize={10}
              currImg={currImg}
              onClickPrev={() => setCurrImg((prev) => prev - 1)}
              onClickNext={() => setCurrImg((prev) => prev + 1)}
            />
          )}
        </>
        : <Skeleton variant={'rect'} style={{ paddingTop: '56.25%' }} />
      : <CardContent>No images uploaded</CardContent>
  );
};

ImageZoom.propTypes = {
  lastUploadTime: oneOfType([string, number]),
  isExist: bool,
  userId: number,
  label: string,
};
