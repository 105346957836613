import { useState } from 'react';
import { Box, Checkbox, FormControlLabel, useTheme } from '@material-ui/core';
import { array, object, func } from 'prop-types';

export const ClickableCell = ({ isMarked, markTypes, onSelect }) => {
  const theme = useTheme();
  const [checked, setChecked] = useState(false);
  const fetchColorOnType = (type) => {
    const [item] = markTypes.filter((mark) => mark.value === type);
    return item.color;
  };
  const backgroundColor = checked ? theme.palette.default.light : isMarked ? fetchColorOnType(isMarked.type) : '';
  const handleCheck = (event) => {
    const checked = event.target.checked;
    setChecked(checked);
    onSelect(checked);
  };

  return (
    <Box cursor={'pointer'} flexGrow={1} borderRadius={'5px'} border={'1px solid #ddd'} style={{ backgroundColor, transition: '.2s' }}>
      <FormControlLabel
        size={'small'}
        style={{ margin: 0, width: '100%', height: '100%', opacity: 0 }}
        onChange={handleCheck}
        control={<Checkbox checked={checked} />}
      />
    </Box>
  );
};

ClickableCell.propTypes = {
  isMarked: object,
  markTypes: array,
  onSelect: func,
};
