import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { required, useDataProvider } from 'react-admin';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import { useHandbook } from '../../hooks';

export const CollectionContactFormDialog = ({ isOpened, onClose, onSubmit, editId, parentId, collectionGroupId, contactsTree }) => {
  const dataProvider = useDataProvider();

  const [editedContact, setEditedContact] = useState(null);
  const { data: types } = useHandbook('collection_contact_types', { items_per_page: 1000 });
  const { data: typeValues } = useHandbook('collection_contact_type_values', { items_per_page: 1000 });
  const { data: motivators } = useHandbook('collection_motivators', { items_per_page: 1000 });

  const groupMotivators = motivators.filter((item) => item.parent === null).map((parent) => ({
    id: parent.id,
    parent: parent.parent,
    name: parent.name,
    enabled: parent.enabled,
    children: motivators.filter((item) => item.parent === parent.id).map((child) => ({
      id: child.id,
      parent: child.parent,
      name: child.name,
      enabled: child.enabled,
    })),
  }));

  const childrenMotivatorIds = motivators.filter((item) => item.parent !== null).map((child) => child.id);

  const filledGroupMotivators = groupMotivators.filter(({ children }) => children.length > 0);
  const alreadyCreatedContacts = [...new Set(contactsTree.map(contact => contact.type_value).filter(item => Number.isInteger(item)))];

  const renderMotivatorOptions = (values) => {
    const motivatorOptions = [];

    for (const filledGroupMotivator of filledGroupMotivators) {
      motivatorOptions.push(<ListSubheader>{filledGroupMotivator.name}</ListSubheader>);

      for (const child of filledGroupMotivator.children) {
        motivatorOptions.push(
          <MenuItem key={child.id} value={child.id}>
            <Checkbox checked={Array.isArray(values) && values.includes(child.id)} />
            <ListItemText>{child.name}</ListItemText>
          </MenuItem>,
        );
      }
    }

    return motivatorOptions;
  };

  useEffect(() => {
    if (editId) {
      dataProvider
        .getOne('collection_contacts', { id: editId })
        .then(({ data }) => {
          setEditedContact(data);
        });
    } else {
      setEditedContact(null);
    }
  }, [dataProvider, editId]);

  const handleSubmit = (values) => {
    const uri = editId ? `collection_contacts/${editId}` : 'collection_contacts';
    const method = editId ? 'PATCH' : 'POST';
    const parent = editId ? editedContact.parent : (parentId || null);
    let motivators = Array.isArray(values.motivators) ? values.motivators : [];

    motivators = motivators.filter(i => typeof i === 'number' && childrenMotivatorIds.includes(i));
    motivators = [...new Set(motivators)];

    dataProvider
      .query(uri, {
        method,
        body: JSON.stringify({
          parent,
          type_value: values.type_value,
          collection_group: collectionGroupId,
          status: 1,
          is_required: false,
          priority: 0,
          motivators,
        }),
      })
      .then(() => {
        onSubmit();
      });
  };

  const initialValues = {
    type: '',
    type_value: '',
    motivators: [],
  };

  if (editId) {
    if (editedContact) {
      initialValues.type_value = editedContact.type_value;
      initialValues.type = typeValues.find(({ id }) => id === editedContact.type_value)?.type;
      initialValues.motivators = editedContact.motivators;
    }
  } else {
    initialValues.type = parentId ? '' : types.find((type) => type.root)?.id || '';
  }

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <DialogTitle id="collection-contact-form-dialog-title">Create new contact action</DialogTitle>
      <FinalForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent style={{ minHeight: 160, width: 480 }}>
              <Field
                name="type"
                validate={required('Can not be empty.')}
              >
                {({ input, meta }) => (
                  <FormControl fullWidth variant='filled'>
                    <InputLabel id="collection-contact-type-label">Contact type</InputLabel>

                    {types.length > 0 && (
                      <Select
                        id="collection-contact-type-input"
                        margin='dense'
                        value={input.value}
                        error={meta.touched && meta.error}
                        onChange={(e) => {
                          input.onChange(e.target.value ? parseInt(e.target.value) : null);
                        }}
                        fullWidth
                        disabled={!parentId && !editId && !!initialValues.type}
                      >
                        {types.map((type) => (
                          <MenuItem
                            key={type.id}
                            value={type.id}
                            disabled={(!parentId && !type.root) || (!!parentId && type.root)}
                          >
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}

                    <FormHelperText error={meta.touched && meta.error}>{meta.error || ' '}</FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Field
                name="type_value"
                validate={required('Can not be empty.')}
              >
                {({ input, meta }) => {
                  const filteredTypeValues = typeValues.filter((i) => i.type === values.type);

                  return (
                    <FormControl fullWidth variant='filled'>
                      <InputLabel id="collection-contact-type-value-label">Type value</InputLabel>

                      <Select
                        id="collection-contact-type-value-input"
                        margin='dense'
                        value={input.value}
                        error={meta.touched && meta.error}
                        onChange={(e) => {
                          input.onChange(e.target.value ? parseInt(e.target.value) : null);
                        }}
                        fullWidth
                        disabled={!values.type}
                      >
                        {filteredTypeValues.map((type) => (
                          <MenuItem key={type.id} value={type.id} disabled={alreadyCreatedContacts.includes(type.id)}>{type.value}</MenuItem>
                        ))}
                      </Select>

                      <FormHelperText error={meta.touched && meta.error}>{meta.error || ' '}</FormHelperText>
                    </FormControl>
                  );
                }}
              </Field>

              <Field
                name="motivators"
              >
                {({ input, meta }) => (
                  <FormControl fullWidth variant='filled'>
                    <InputLabel id="collection-contact-motivator-label">Motivators</InputLabel>

                    <Select
                      id="collection-contact-motivator-input"
                      margin='dense'
                      value={input.value}
                      error={meta.touched && meta.error}
                      fullWidth
                      multiple
                      onChange={(e) => {
                        input.onChange(e.target.value);
                      }}
                      renderValue={(value) => {
                        return value
                          .map((id) => motivators.find((motivator) => motivator.id === id)?.name)
                          .filter(i => !!i)
                          .join(', ');
                      }}
                    >
                      {renderMotivatorOptions(input.value)}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button type='submit' color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

CollectionContactFormDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  editId: PropTypes.number,
  parentId: PropTypes.number,
  collectionGroupId: PropTypes.number,
  contactsTree: PropTypes.any,
  preSelectedType: PropTypes.string,
};
