import React from 'react';
import { Edit } from 'react-admin';

import { RejectionReasonsForm } from './RejectionReasonsForm';

export const RejectionReasonsEdit = (props) => (
  <Edit component="div" {...props}>
    <RejectionReasonsForm isEdit />
  </Edit>
);
