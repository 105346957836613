import React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

export const CheckboxListInput = ({ isEditable = true, ...props }) => {
  const input = useInput(props).input;
  const { value = [], onChange } = typeof props.onChange === 'function' ? props : input;
  const choices = Array.isArray(props.choices) ? props.choices : [];

  const handleChange = v => {
    if (value.indexOf(v) === -1) {
      onChange([...value, v]);
    } else {
      onChange(value.filter(item => item !== v));
    }
  };

  return (
    <List>
      { choices.map((choice, idx) => (
        <ListItem key={ idx } dense button onClick={ () => isEditable ? handleChange(choice.id) : null }>
          <ListItemText primary={ choice.name } />
          <ListItemSecondaryAction>
            <Checkbox
              disabled={ !isEditable }
              edge="end"
              checked={ value.indexOf(choice.id) !== -1 }
              onClick={ () => isEditable ? handleChange(choice.id) : null }
            />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

CheckboxListInput.propTypes = {
  isEditable: PropTypes.bool,
  onChange: PropTypes.func,
  choices: PropTypes.array,
};
