import React, { useEffect, useState } from 'react';
import { Loading, useDataProvider, useNotify } from 'react-admin';
import {
  Table, TableBody, TableRow, TableCell, TableHead, Chip, Tooltip, TablePagination, Button, ButtonGroup,
} from '@material-ui/core';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';

import { openIntoNewTab } from '../../utils';
import { EngineDataDialog } from '../engines';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

export const OcrTable = ({ userId, refreshedAt }) => {
  const [data, setData] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    dataProvider.getList('ocr_requests', {
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
      filter: { user: userId },
    }).then(({ data, total }) => {
      setData(data);
      setTotal(total);
      setLoading(() => false);
    }).catch((error) => {
      notify(`Error: ${error.message || 'OCR not found'}`, 'error');
      setLoading(() => false);
    });
  }, [notify, userId, refreshedAt, dataProvider, page, perPage]);
  if (loading) return <Loading />;

  const downloadFile = (url, err) => {
    setLoading(() => true);
    dataProvider
      .downloadFile(url, {})
      .then(({ data: objUrl }) => openIntoNewTab(objUrl))
      .catch((error) => {
        notify(`Error: ${error.message || err}`, 'error');
      })
      .finally(() => {
        setLoading(() => false);
      });
  };

  const formatValue = (name, value) => {
    if (typeof value === 'boolean') {
      return value ? 'TRUE' : 'FALSE';
    }

    const multilineFields = [
      'SURNAME_AND_GIVEN_NAMES',
      'ADDRESS',
      'MRZ_STRINGS',
    ];

    if (multilineFields.includes(name) && typeof value === 'string') {
      return value.replace(/\^/mgui, '\n^');
    }

    return value;
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  const handleCloseDialog = () => setDialog((state) => !state);
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            {['ID', 'Engine', 'Action', 'State', 'Created at', 'Updated at', 'Finished at', 'Actions']
              .map((i) => <TableCell key={i}>{i}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((i) => (
            <TableRow key={i.id}>
              <TableCell>{i.id}</TableCell>
              <TableCell><Chip size={'small'} label={i.engine} /></TableCell>
              <TableCell>{i.action}</TableCell>
              <TableCell><Chip size={'small'} label={i.state} /></TableCell>
              <TableCell><DateTimeBoxComponent value={i?.created_at} /></TableCell>
              <TableCell><DateTimeBoxComponent value={i?.updated_at} /></TableCell>
              <TableCell><DateTimeBoxComponent value={i?.finished_at} /></TableCell>
              <TableCell width={'150px'}>
                <ButtonGroup size={'small'} color={'secondary'}>
                  <Button
                    edge={'start'}
                    disabled={!i.engine_data}
                    onClick={() => {
                      setDialog({
                        ...(typeof i.engine_data === 'object' && i.engine_data !== null ? i.engine_data : {}),
                        ...(typeof i.decoded_data === 'object' && i.decoded_data !== null ? i.decoded_data : {}),
                      });
                    }}
                  >
                    <Tooltip title={'open engine data'} arrow>
                      <OpenInBrowserIcon />
                    </Tooltip>
                  </Button>
                  <Button
                    onClick={
                      () => downloadFile(
                        `ocr_requests/${i.id}/response`,
                        'engine data download error',
                      )}
                    disabled={i.state !== 'processed'}
                  >
                    <Tooltip title={'download engine data'} arrow>
                      <GetAppIcon />
                    </Tooltip>
                  </Button>
                  <Button
                    onClick={() => downloadFile(
                      `ocr_requests/${i.id}/transmission_log`,
                      'transmission log download error',
                    )}
                    disabled={i.state === 'pending'}
                  >
                    <Tooltip title={'download transmission log'} arrow>
                      <GetAppIcon />
                    </Tooltip>
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
      <EngineDataDialog
        array={
          Object
            .entries(dialog)
            .map(([name, value]) => ({
              name,
              value: formatValue(name, value),
            }))
        }
        isOpened={!!dialog}
        onClose={handleCloseDialog}
      />
    </>
  );
};

OcrTable.propTypes = {
  userId: PropTypes.number.isRequired,
  refreshedAt: PropTypes.number,
};
