import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';

export const FacebookTable = ({ record }) => {
  const { facebook_id, facebook_email, facebook_first_name, facebook_last_name } = record;

  return (
    <Table size={'small'}>
      <TableBody>
        <TableRow>
          <TableCell>Facebook ID</TableCell>
          <TableCell>{facebook_id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>{facebook_email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Firts Name</TableCell>
          <TableCell>{facebook_first_name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Last name</TableCell>
          <TableCell>{facebook_last_name}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

FacebookTable.propTypes = {
  record: PropTypes.shape({
    facebook_id: PropTypes.string,
    facebook_email: PropTypes.string,
    facebook_first_name: PropTypes.string,
    facebook_last_name: PropTypes.string,
  }),
};
