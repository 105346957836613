import * as React from 'react';
import { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import { HideOnScroll, useRefresh, UserMenu, AppBar as MuiAppBar } from 'react-admin';
import { Toolbar, Typography, useMediaQuery, makeStyles, Button, Menu, Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import { getAdminTimezone, TIMEZONE_OFFSET, timezoneOffsetLabel } from '../../utils';
import { APP_NAME } from '../../constants';

const useStyles = makeStyles((theme) => ({
  w100: {
    width: '100%',
  },
  flexACenteredJBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  gap3: {
    gap: theme.spacing(3),
  },
}),
);
export const AppBar = ({ children, className, color = 'primary', logout, open, header, userMenu, ...rest }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const getUserName = () => {
    const userJson = localStorage.getItem('admin_fields');
    if (!userJson) {
      return 'Unknown';
    }
    const userObject = JSON.parse(userJson);
    return (userObject || {}).username || 'Unknown';
  };
  const [timezone, setTimezone] = useState(getAdminTimezone(false));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleChangeTimeZone = (value) => {
    setTimezone(value);
    localStorage.setItem('admin_timezone', value);
    refresh();
    handleClose();
  };
  return (
    <MuiAppBar className={className} color={color} {...rest}>
      <Toolbar disableGutters variant={'dense'} className={`${classes.w100} ${classes.gap3} ${classes.flexACenteredJBetween}`}>
        <Box className={`${classes.gap3} ${classes.flexACenteredJBetween}`}>
          <Typography>{APP_NAME}</Typography>
          <Typography variant={'h6'} color={'inherit'} id={'react-admin-title'} />
        </Box>
        <Box className={`${classes.gap3} ${classes.flexACenteredJBetween}`}>
          <Button color={'inherit'} size={'small'} aria-controls={'simple-menu'} aria-haspopup={'true'} onClick={handleClick}>
            {`Timezone: ${timezone}`}
          </Button>
          <Menu id={'simple-menu'} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {Object.entries(TIMEZONE_OFFSET).map(([tz, offsetFunc]) => (
              <MenuItem key={tz} value={tz} onClick={() => handleChangeTimeZone(tz, offsetFunc)}>
                {`${tz} ${tz !== 'UTC' ? ` (UTC${timezoneOffsetLabel(offsetFunc())})` : ''}`}
              </MenuItem>
            ))}
          </Menu>
          <Typography>{`Welcome, ${getUserName()}`}</Typography>
          {cloneElement(userMenu, { logout })}
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};
AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'inherit',
    'primary',
    'secondary',
    'transparent',
  ]),
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.element,
  header: PropTypes.string,
  title: PropTypes.string,
};
AppBar.defaultProps = {
  userMenu: <UserMenu />,
};
