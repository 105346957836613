import { format } from 'date-fns';
import {
  Box, Card, CardHeader, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../utils';
import { DATE_FORMAT } from '../../constants';

const smSkeletStyles = { height: '20px', margin: 0 };

export const CreateAppCalculation = ({ calculation }) => {
  const {
    principal,
    discount: { principal: discPrincipal, interest: discInterest, settlement_amount: discSetlAmount },
    interest,
    settlement_amount,
    maturity_date,
  } = calculation;
  return (
    <Card>
      <CardHeader title={'Calculation preview'} />
      <Divider />
      {calculation
        ? <>
          <Table size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell align={'right'}><b>Amount</b></TableCell>
                <TableCell align={'right'}><b>Discount</b></TableCell>
                <TableCell align={'right'}><b>Total</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component={'th'} scope={'row'}><b>Loan amount</b></TableCell>
                <TableCell align={'right'}>{principal ? formatCurrency(principal) : <Skeleton style={smSkeletStyles} variant={'rect'} />}</TableCell>
                <TableCell align={'right'}>{formatCurrency(discPrincipal)}</TableCell>
                <TableCell align={'right'}>{principal ? formatCurrency(principal - discPrincipal) : <Skeleton style={smSkeletStyles} variant={'rect'} />}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={'th'} scope={'row'}><b>Interest</b></TableCell>
                <TableCell align={'right'}>{interest ? formatCurrency(interest) : <Skeleton style={smSkeletStyles} variant={'rect'} />}</TableCell>
                <TableCell align={'right'}>{formatCurrency(discInterest)}</TableCell>
                <TableCell align={'right'}>{interest ? formatCurrency(interest - discInterest) : <Skeleton style={smSkeletStyles} variant={'rect'} />}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={'th'} scope={'row'}><b>Settlement amount</b></TableCell>
                <TableCell align={'right'}>{settlement_amount ? formatCurrency(settlement_amount) : <Skeleton style={smSkeletStyles} variant={'rect'} />}</TableCell>
                <TableCell align={'right'}>{formatCurrency(discSetlAmount)}</TableCell>
                <TableCell align={'right'}>{settlement_amount ? formatCurrency(settlement_amount - discSetlAmount) : <Skeleton style={smSkeletStyles} variant={'rect'} />}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box boxSizing={'border-box'} padding={'6px 16px'} display={'flex'} width={'100%'} justifyContent={'space-between'}>
            <Typography variant={'subtitle2'}><b>Payment date</b></Typography>
            <Typography variant={'subtitle2'} align={'right'}>{maturity_date ? format(new Date(maturity_date), DATE_FORMAT) : <Skeleton style={smSkeletStyles} variant={'rect'} />}</Typography>
          </Box>
        </>
        : <Typography variant={'subtitle1'}>{'Can\'t calculate'}</Typography>}
    </Card>
  );
};

CreateAppCalculation.propTypes = {
  calculation: PropTypes.shape({
    discount: PropTypes.shape({
      principal: PropTypes.number,
      interest: PropTypes.number,
      settlement_amount: PropTypes.number,
    }),
    principal: PropTypes.number,
    interest: PropTypes.number,
    settlement_amount: PropTypes.number,
    maturity_date: PropTypes.string,
  }),
};
