import { cloneElement } from 'react';
import { sanitizeListRestProps, TopToolbar, useListContext } from 'react-admin';
import PropTypes from 'prop-types';

export const ExternalAgencyLoansActions = ({ filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    </TopToolbar>
  );
};

ExternalAgencyLoansActions.propTypes = {
  filters: PropTypes.element,
};
