import React from 'react';
import PropTypes from 'prop-types';
import { Edit, TextInput, BooleanInput, SaveButton, DeleteButton, FormWithRedirect, required, maxLength } from 'react-admin';
import { Grid, Typography, Box, Paper, Divider } from '@material-ui/core';

const FormWrapper = ({ permissions, save, ...props }) => {
  const isEditable = permissions.indexOf('CAN_SHORT_LINK_EDIT') !== -1;

  return (
    <FormWithRedirect
      save={(data, ...rest) =>
        save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest])
      }
      {...props}
      render={(formProps) => (
        <form>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={12} md={6}>
              <Paper>
                <Box p={2}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" gutterBottom={false}>
                      Short link
                    </Typography>
                    <BooleanInput
                      disabled={!isEditable}
                      source="is_enabled"
                      helperText={false}
                      label={'Enabled'}
                    />
                  </Grid>
                  <Divider />
                </Box>
                <Box p={2}>
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12} sm={12}>
                      <TextInput
                        disabled={!isEditable}
                        source="slug"
                        validate={[required(), maxLength(255)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInput
                        disabled={!isEditable}
                        source="link"
                        validate={[required(), maxLength(255)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box display="flex" justifyContent="space-between">
                        <SaveButton
                          disabled={!isEditable}
                          label="Save"
                          redirect="list"
                          saving={formProps.saving}
                          handleSubmitWithRedirect={
                            formProps.handleSubmitWithRedirect
                          }
                        />
                        <DeleteButton
                          undoable={false}
                          disabled={!isEditable}
                          basePath={formProps.basePath}
                          record={formProps.record}
                          resource={formProps.resource}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  permissions: PropTypes.array,
  save: PropTypes.func,
};

const ShortLinkEdit = ({ permissions = [], ...props }) => (
  <Edit component="div" undoable={false} {...props}>
    <FormWrapper permissions={permissions} />
  </Edit>
);

ShortLinkEdit.propTypes = {
  permissions: PropTypes.array,
};

export default ShortLinkEdit;
