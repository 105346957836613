import { useState, useEffect } from 'react';
import { Edit, FormWithRedirect, useDataProvider, Loading } from 'react-admin';
import { Grid, Card } from '@material-ui/core';
import PropTypes from 'prop-types';

import { DialerBasesForm } from './DialerBasesForm';

const FormWrapper = ({ save, record, ...props }) => {
  const dataProvider = useDataProvider();

  const [transmitters, setTransmitters] = useState({});
  const [audiencesList, setAudiencesList] = useState([]);
  const [transmitter, setTransmitter] = useState(null);
  const [weekDays, setWeekDays] = useState([]);
  const [variables, setVariables] = useState([]);
  const [dialerId, setDialerId] = useState(null);
  const [frequencyPeriod, setFrequencyPeriod] = useState(null);
  const [contactSource, setContactSource] = useState([]);
  const [contactType, setContactType] = useState([]);
  const [phoneStatus, setPhoneStatus] = useState(null);
  const [phoneValidation, setPhoneValidation] = useState([]);

  useEffect(() => {
    dataProvider
      .query('mass_sending_audiences?pagination=false', { method: 'GET' })
      .then(({ data }) => setAudiencesList(data));

    dataProvider
      .query('dialer_transmitters', { method: 'GET' })
      .then((res) => {
        if (res) {
          let newData = {};

          for (const i of res.data) {
            let params = {};

            for (const j of i.params_description) {
              params = { ...params, [j.id]: j };
            }

            newData = { ...newData, [i.id]: { ...i, params_description: params } };
          }

          setTransmitters(newData);
        }
      });
  }, [dataProvider]);

  useEffect(() => {
    if (record.transmitter_id) {
      setTransmitter(record.transmitter_id);
    }

    if (record.params) {
      // eslint-disable-next-line react/prop-types
      const { dialer_id, frequency_period, contact_source, contact_type, phone_status, phone_validation, week_days, variables } = record.params;

      if (dialer_id) setDialerId(dialer_id);
      if (frequency_period) setFrequencyPeriod(frequency_period);
      if (contact_source) setContactSource(contact_source);
      if (contact_type) setContactType(contact_type);
      if (phone_status) setPhoneStatus(phone_status);
      if (phone_validation) setPhoneValidation(phone_validation);
      if (week_days) setWeekDays(week_days);
      if (variables) setVariables(variables);
    }
  }, [record]);

  const getAllowedValues = (id) => {
    if (transmitters?.[transmitter]?.params_description?.[id]?.allowed_values) {
      const { allowed_values } = transmitters?.[transmitter]?.params_description[id] || {};
      const values = Object.entries(allowed_values).map((item) => ({
        id: item[1],
        [id === 'frequency_period' || id === 'contact_source' || id === 'contact_type' || id === 'phone_status' || id === 'phone_validation' ? 'name' : 'label']: item[0],
      }));

      return values;
    }
  };

  const onChange = (id, paramsId) => {
    const params = paramsId === 'week_days' ? weekDays : variables;
    const setParams = paramsId === 'week_days' ? setWeekDays : setVariables;
    const element = params.includes(id);

    if (element) {
      setParams(params.filter((i) => i !== id));
    } else {
      setParams([...params, id]);
    }
  };

  const getParams = () => {
    const params = {};
    if (contactSource.length) {
      params.contact_source = contactSource;
    }
    if (contactType.length) {
      params.contact_type = contactType;
    }
    if (dialerId) {
      params.dialer_id = +dialerId;
    }
    if (frequencyPeriod) {
      params.frequency_period = frequencyPeriod;
    }
    if (phoneStatus) {
      params.phone_status = phoneStatus;
    }
    if (phoneValidation.length) {
      params.phone_validation = phoneValidation;
    }
    if (variables.length) {
      params.variables = variables;
    }
    if (weekDays.length) {
      params.week_days = weekDays;
    }
    return params;
  };

  const AUDIENCE_CHOICES = audiencesList?.map((item) => ({ id: item.id, name: item.name }));

  if (!Object.keys(transmitters).length) return <Loading />;

  return (
    <Grid container justifyContent='center' spacing={4}>
      <Grid item xs={12} sm={8}>
        <Card>
          <FormWithRedirect
            save={({ name, start_time, end_time, mass_sending_audience_id, is_enabled }, ...rest) => {
              save(
                ...[
                  {
                    name,
                    start_time,
                    end_time,
                    mass_sending_audience: mass_sending_audience_id,
                    is_enabled,
                    transmitter: transmitter.transmitter,
                    params: getParams(),
                    _params: { method: 'PATCH' },
                  },
                  ...rest,
                ],
              );
            }}
            {...props}
            render={(formProps) =>
              <DialerBasesForm
                isEdit
                transmitters={transmitters}
                transmitter={{ transmitter, setTransmitter }}
                dialerId={{ dialerId, setDialerId }}
                getAllowedValues={getAllowedValues}
                frequencyPeriod={{ frequencyPeriod, setFrequencyPeriod }}
                phoneStatus={{ phoneStatus, setPhoneStatus }}
                contactType={{ contactType, setContactType }}
                contactSource={{ contactSource, setContactSource }}
                phoneValidation={{ phoneValidation, setPhoneValidation }}
                onChange={onChange}
                weekDays={weekDays}
                variables={variables}
                audienceChoices={AUDIENCE_CHOICES}
                formProps={formProps}
              />
            }
          />
        </Card>
      </Grid>
    </Grid>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    transmitter_id: PropTypes.string,
    params: PropTypes.oneOfType([
      PropTypes.shape({
        dialer_id: PropTypes.number,
        variables: PropTypes.arrayOf(PropTypes.string),
        week_days: PropTypes.array,
        frequency_period: PropTypes.number,
        contact_source: PropTypes.arrayOf(PropTypes.number),
        contact_type: PropTypes.arrayOf(PropTypes.number),
        phone_status: PropTypes.number,
        phone_validation: PropTypes.arrayOf(PropTypes.number),
      }),
      PropTypes.array,
    ]),
  }),
};

export const DialerBasesEdit = (props) => (
  <Edit component='div' undoable={false} {...props}>
    <FormWrapper />
  </Edit>
);
