import { useState } from 'react';
import { useInput, useNotify, useQuery } from 'react-admin';
import { CircularProgress, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

export const AsyncSelectInput = ({ query: { resource, payload }, mapper, label, type = 'getList', variant, additionalData, ...props }) => {
  const notify = useNotify();
  const [newPayload, setNewPayload] = useState(payload);
  const { input: { value, onChange } } = useInput(props);
  const { data, loading, error, loaded, total } = useQuery({ type, resource, payload: newPayload });

  const loadMore = (e) => {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;

    if (bottom && total > data.length && !!newPayload.filter.items_per_page) {
      setNewPayload({
        ...newPayload,
        filter: {
          ...newPayload.filter,
          items_per_page: newPayload.filter.items_per_page + 10,
        },
      });
    }
  };

  if (loading && !loaded) return <CircularProgress />;
  if (error) notify(`Error: ${error.message}`, 'error');

  const newData = [...additionalData || [], ...data];

  return (
    <FormControl style={{ minWidth: '200px' }} variant={'outlined'} margin={'dense'} size={'small'} fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={value}
        onChange={onChange}
        MenuProps={{
          PaperProps: {
            onScroll: loadMore,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          style: {
            maxHeight: 400,
          },
          getContentAnchorEl: null,
        }}>
        {(newData || [])
          .map((item) => mapper(item))
          .map(({ value, name }, idx) => <MenuItem key={idx} value={value}>{name}</MenuItem>)}
      </Select>
    </FormControl>
  );
};

AsyncSelectInput.propTypes = {
  query: PropTypes.shape({
    resource: PropTypes.string,
    payload: PropTypes.object,
  }),
  mapper: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
  additionalData: PropTypes.string,
  variant: PropTypes.string,
};
