import React, { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  Filter,
  NumberInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  FunctionField,
} from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { LinearProgressField } from '../../field';
import Pagination from '../../Pagination';
import { marginZeroStyles } from '../../../constants';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {hasCreate ? (<CreateButton basePath={basePath} />) : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const CancelledIncomesDialog = ({ isOpened, onClose, array }) => {
  return (
    <Dialog open={isOpened} onClose={onClose}>
      <DialogTitle id="form-dialog-title">Cancelled incomes</DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {array.map((item, key) => {
              return (
                <TableRow key={key + '_log'}>
                  <TableCell>
                    <Chip label={item} size="small" />
                  </TableCell>
                  <TableCell>
                    <Button size="small" component={Link} to={`/loans/${item}/show`}>Open loan</Button>
                  </TableCell>
                </TableRow>
              );
            })
            }
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CancelledIncomesDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  array: PropTypes.array,
};

export const ImportProcessList = props => {
  const [openCancelledIncomes, setOpenCancelledIncomes] = useState(false);
  const [incomesId, setIncomesId] = useState([]);
  return (
    <>
      <CancelledIncomesDialog
        isOpened={openCancelledIncomes}
        onClose={() => setOpenCancelledIncomes(state => !state)}
        array={incomesId}
      />
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<ListFilter />}
        actions={<ListActions />}
        {...props}
      >
        <Datagrid>
          <TextField source="id" />
          <FunctionField label="State"
            render={({ state, error_message }) =>
              <Tooltip title={error_message || ''}
                placement="bottom"
                disableHoverListener={!error_message}
              >
                <div style={{ display: 'inline' }}>
                  <Chip label={state} size="small" />
                </div>
              </Tooltip>}
          />
          <ChipField size="small" source="importer_id" />
          <LinearProgressField source="progress" />
          <FunctionField render={(record) => <DateTimeBoxComponent value={record?.created_at} />} label="Created at" />
          <FunctionField render={(record) => <DateTimeBoxComponent value={record?.finished_at} />} label="Finished at" />
          <FunctionField render={({ params: { loan_ids_with_cancelled_incomes = [] } }) => {
            return (
              <Button variant='outlined' size="small" onClick={() => {
                setOpenCancelledIncomes(state => !state);
                setIncomesId(loan_ids_with_cancelled_incomes);
              }}>
                Cancelled incomes ({loan_ids_with_cancelled_incomes.length})
              </Button>
            );
          }} label='Actions' />
        </Datagrid>
      </List>
    </>
  );
};
