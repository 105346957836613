import {
  BooleanInput, maxValue,
  minValue, number,
  NumberInput,
  required,
  SaveButton,
  useRedirect,
} from 'react-admin';
import {
  Box,
  Button,
  ButtonGroup,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const ProlongationForm = ({ permissions, save, formProps, isCreate, ...props }) => {
  const redirect = useRedirect();
  const classes = useStyles();
  const handleGoToList = () => redirect('/extension_discounts');

  return (
    <CardContent>
      <Grid container justifyContent='center' spacing={2} style={{ backgroundColor: 'white' }} >
        <Grid item xs={12} sm={6}>
          <Box p={2}>
            <Typography variant="h6" gutterBottom={ false }>{isCreate ? 'Create' : 'Edit'} extension discount.</Typography>
          </Box>
          <Divider />
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <NumberInput
                  validate={[required(), minValue(1), maxValue(300), number()]}
                  source={'lower_limit'}
                  type={'number'}
                  step={0.01}
                  label={'Lower limit'}
                  variant={'outlined'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberInput
                  validate={[required(), minValue(1), maxValue(300), number()]}
                  source={'upper_limit'}
                  type={'number'}
                  step={0.01}
                  label={'Upper limit'}
                  variant={'outlined'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberInput
                  validate={[required(), minValue(1), maxValue(99), number()]}
                  source={'discount'}
                  label={'Discount'}
                  variant={'outlined'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <BooleanInput label="Active" source="active" helperText={ false } initialValue={false}/>
              </Grid>
            </Grid>
          </Box>
          <Toolbar variant={'regular'} className={ classes.toolbar } {...props}>
            <ButtonGroup variant={'contained'} color={'primary'} {...props}>
              <SaveButton redirect={'list'} saving={formProps.saving} handleSubmitWithRedirect={formProps.handleSubmitWithRedirect} label={'Save'} />
              <Button startIcon={<ListIcon />} onClick={handleGoToList}>Back to list</Button>
            </ButtonGroup>
          </Toolbar>
        </Grid>
      </Grid>
    </CardContent>);
};

