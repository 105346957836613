import { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { ButtonGroup, Button } from '@material-ui/core';
import { number, string, shape, func, array } from 'prop-types';

import { AddCancelNoteDialog } from '../../dialogs';
import { getUserId } from '../../../utils';

import * as Dialogs from './applicationDialogs';

export const ApplicationTransitions = ({ permissions, record, refreshedAt, refresh }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [transitions, setTransitions] = useState([]);
  const [isStartProcessingDialogOpen, setIsStartProcessingDialogOpen] = useState(false);
  const [isManualVerifDialogOpen, setIsManualVerifDialogOpen] = useState(false);
  const [isAssignVerifierDialogOpen, setIsAssignVerifierDialogOpen] = useState(false);
  const [isPostponeDialogOpen, setIsPostponeDialogOpen] = useState(false);
  const [isApproveDialogOpen, setIsApproveDialogOpen] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isAddCancelNoteDialogOpen, setIsAddCancelNoteDialogOpen] = useState(false);

  useEffect(() => {
    dataProvider.query(`applications/${record.id}/transitions`, { method: 'GET' })
      .then((res) => {
        if (res?.data) {
          const { data } = res;
          setTransitions(data);
        }
      })
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, record.id, refreshedAt, notify]);

  const isManualVerif = record.state === 'processing' && record.decision_engine_id === 'manual' && record.verifier_id && getUserId() === record.verifier_id;
  const isConfirm = permissions.includes('CAN_APPLICATION_CONFIRM') && transitions.includes('confirm');

  const applyTransition = (name, params = {}) => {
    dataProvider.query(`applications/${record.id}/apply_transition`, {
      method: 'POST',
      body: JSON.stringify({ name, params }),
    })
      .then(() => refresh())
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  };

  const handleManualVerifDialogOpen = () => setIsManualVerifDialogOpen(true);
  const handleManualVerifDialogClose = () => setIsManualVerifDialogOpen(false);
  const handleManualVerifSubmit = (results) => {
    handleManualVerifDialogClose();
    Promise.allSettled(Object.keys(results).map((id) =>
      dataProvider.update('manual_verification_steps', { id, data: { result: results[id] || null } })))
      .then(() => refresh())
      .catch((error) => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  const handleStartProcessDialogOpen = () => !setIsStartProcessingDialogOpen(true);
  const handleStartProcessDialogClose = () => setIsStartProcessingDialogOpen(false);
  const handleStartProcessingSubmit = (decision_engine_id) => {
    handleStartProcessDialogClose();
    applyTransition('start_processing', { decision_engine_id });
  };

  const handleAssignVerifierDialogOpen = () => setIsAssignVerifierDialogOpen(true);
  const handleAssignVerifierDialogClose = () => setIsAssignVerifierDialogOpen(false);
  const handleAssignVerifierSubmit = (verifier_id) => {
    handleAssignVerifierDialogClose();
    applyTransition('assign_verifier', { verifier_id });
  };

  const handlePostponeDialogOpen = () => setIsPostponeDialogOpen(true);
  const handlePostponeDialogClose = () => setIsPostponeDialogOpen(false);
  const handlePostponeSubmit = (postponed_until) => {
    handlePostponeDialogClose();
    applyTransition('postpone', { postponed_until });
  };

  const handleApproveDialogOpen = () => setIsApproveDialogOpen(true);
  const handleApproveDialogClose = () => setIsApproveDialogOpen(false);
  const handleApproveSubmit = (payload) => applyTransition('approve', payload);

  const handleRejectDialogOpen = () => setIsRejectDialogOpen(true);
  const handleRejectDialogClose = () => setIsRejectDialogOpen(false);
  const handleRejectSubmit = (rejection_reason_code) => {
    handleRejectDialogClose();
    applyTransition('reject', { rejection_reason_code });
  };

  const handleConfirmDialogOpen = () => setIsConfirmDialogOpen(true);
  const handleConfirmDialogClose = () => setIsConfirmDialogOpen(false);
  const handleConfirmSubmit = (confirmation_code) => {
    handleConfirmDialogClose();
    applyTransition('confirm', { confirmation_code });
  };

  const handleIsAddCancelNoteDialogOpen = () => setIsAddCancelNoteDialogOpen(true);
  const handleIsAddCancelNoteDialogClose = () => setIsAddCancelNoteDialogOpen(false);
  const handleAddCancelNoteSubmit = (cancellation_reason) => {
    applyTransition('cancel', { cancellation_reason });
    handleIsAddCancelNoteDialogClose();
  };

  const handleResetTransition = () => applyTransition('reset');

  if (!transitions) return null;

  return (
    <>
      <ButtonGroup variant={'contained'} color={'primary'}>
        {isManualVerif && <Button onClick={handleManualVerifDialogOpen}>Manual verification</Button>}
        {transitions.includes('start_processing') && <Button onClick={handleStartProcessDialogOpen}>Start processing</Button>}
        {transitions.includes('assign_verifier') && <Button onClick={handleAssignVerifierDialogOpen}>Assign verifier</Button>}
        {transitions.includes('postpone') && <Button onClick={handlePostponeDialogOpen}>Postpone</Button>}
        {transitions.includes('approve') && <Button onClick={handleApproveDialogOpen}>Approve</Button>}
        {transitions.includes('reject') && <Button onClick={handleRejectDialogOpen}>Reject</Button>}
        {isConfirm && <Button onClick={handleConfirmDialogOpen}>Confirm</Button>}
        {transitions.includes('cancel') && <Button onClick={handleIsAddCancelNoteDialogOpen}>Cancel</Button>}
        {transitions.includes('reset') && <Button onClick={handleResetTransition}>Reset</Button>}
      </ButtonGroup>

      {isStartProcessingDialogOpen &&
        <Dialogs.StartProcessingTransitionDialog
          isOpened={isStartProcessingDialogOpen}
          onClose={handleStartProcessDialogClose}
          onSubmit={handleStartProcessingSubmit}
        />}
      {isAddCancelNoteDialogOpen && <AddCancelNoteDialog
        isOpened={isAddCancelNoteDialogOpen}
        onClose={handleIsAddCancelNoteDialogClose}
        onSubmit={handleAddCancelNoteSubmit}
      />}
      {isManualVerifDialogOpen &&
        <Dialogs.ManualVerificationDialog
          applicationId={record.id}
          isOpened={isManualVerifDialogOpen}
          onClose={handleManualVerifDialogClose}
          onSubmit={handleManualVerifSubmit}
        />
      }
      {isAssignVerifierDialogOpen &&
        <Dialogs.AssignVerifierTransitionDialog
          isOpened={isAssignVerifierDialogOpen}
          onClose={handleAssignVerifierDialogClose}
          onSubmit={handleAssignVerifierSubmit}
        />
      }
      {isPostponeDialogOpen &&
        <Dialogs.PostponeTransitionDialog
          isOpened={isPostponeDialogOpen}
          onClose={handlePostponeDialogClose}
          onSubmit={handlePostponeSubmit}
          initialValue={record.postponed_until}
        />
      }
      {isApproveDialogOpen &&
        <Dialogs.ApproveTransitionDialog
          record={record}
          isOpened={isApproveDialogOpen}
          onClose={handleApproveDialogClose}
          onSubmit={handleApproveSubmit} />
      }
      {isRejectDialogOpen &&
        <Dialogs.RejectTransitionDialog
          title={'Reject application.'}
          text={'Please select rejection reason.'}
          isOpened={isRejectDialogOpen}
          onClose={handleRejectDialogClose}
          onSubmit={handleRejectSubmit}
        />
      }
      {isConfirmDialogOpen &&
        <Dialogs.ConfirmTransitionDialog
          isOpened={isConfirmDialogOpen}
          onClose={handleConfirmDialogClose}
          onSubmit={handleConfirmSubmit}
        />
      }
    </>
  );
};

ApplicationTransitions.propTypes = {
  record: shape({
    id: number,
    state: string,
    decision_engine_id: string,
    verifier_id: number,
    postponed_until: string,
  }),
  refreshedAt: number,
  refresh: func,
  permissions: array,
};
