import React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

import { timezone } from '../../utils';

const useStyles = makeStyles((theme) => ({
  my2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wide: {
    minWidth: '210px',
  },
}));

export const DateFilterInput = props => {
  const { after, before, label, wide = false } = props;
  const classes = useStyles();
  const { input: { value, onChange } } = useInput(props);

  let time = {
    h: 0,
    m: 0,
    s: 0,
  };

  if (after) {
    time = { h: 0, m: 0, s: 0 };
  }

  if (before) {
    time = { h: 23, m: 59, s: 59 };
  }

  return (
    <DatePicker
      inputVariant={'outlined'}
      label={label}
      className={wide ? [classes.my2, classes.wide] : classes.my2}
      value={value ? timezone.shift(new Date(value)) : null}
      onChange={value => onChange(timezone.setTime(value, time.h, time.m, time.s).toISOString())}
    />
  );
};

DateFilterInput.propTypes = {
  label: PropTypes.string,
  after: PropTypes.bool,
  before: PropTypes.bool,
};
