import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import {
  Button, ButtonGroup, Chip, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../utils';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

export const IncomesTable = ({ loanId, refreshedAt, onTransition, isTransitionsDisabled }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [incomes, setIncomes] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider.query(`loans/${loanId}/incomes`, { method: 'GET' })
      .then(({ data }) => {
        setIncomes(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, loanId, refreshedAt]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Table size={'small'}>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>State</TableCell>
          <TableCell>Gateway</TableCell>
          <TableCell align={'right'}>Amount</TableCell>
          <TableCell>Payment type</TableCell>
          <TableCell>Received at</TableCell>
          <TableCell>Processed at</TableCell>
          <TableCell>Paid via</TableCell>
          <TableCell align={'right'}>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {incomes.map((row) => (
          <TableRow key={row.id}>
            <TableCell>{row.id}</TableCell>
            <TableCell><Chip size={'small'} label={row.state} /></TableCell>
            <TableCell>{row.gateway}</TableCell>
            <TableCell align={'right'}>{formatCurrency(row.amount)}</TableCell>
            <TableCell>{row.payment_type}</TableCell>
            <TableCell><DateTimeBoxComponent value={row?.received_at}/></TableCell>
            <TableCell><DateTimeBoxComponent value={row?.processed_at}/></TableCell>
            <TableCell>{row.bank_name}</TableCell>
            <TableCell align={'right'}>
              <ButtonGroup size={'small'} color={'secondary'} disabled={isTransitionsDisabled}>
                {row.enabled_transitions.includes('cancel') &&
                  <Button onClick={() => onTransition(row.id, 'cancel')}>Cancel</Button>}
                {row.enabled_transitions.includes('reset') &&
                  <Button onClick={() => onTransition(row.id, 'reset')}>Reset</Button>}
              </ButtonGroup>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

IncomesTable.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onTransition: PropTypes.func,
  isTransitionsDisabled: PropTypes.bool,
};
