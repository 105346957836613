import { List, Datagrid, TextField, NumberField, ChipField, FunctionField, Filter, TextInput, NumberInput } from 'react-admin';
import { Link, Chip, IconButton, ButtonGroup } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';

import { formatPhoneNumber } from '../../../utils';
import { DateFilterInput, QuickFilterInput } from '../../filters';
import { CURRENCY } from '../../../constants';
import { ActivateButton, MarkAllButton } from '../../buttons';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';
import { Call } from '../../Call';
import Pagination from '../../Pagination';

import { LoanListActions } from './LoanListActions';

export const LoanList = ({ statefilterchoices = [], listfilters: ListFilters, ...props }) => {
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilters statefilterchoices={statefilterchoices} />}
      actions={<LoanListActions />}
      syncWithLocation={true}
      {...props}
    >
      <Datagrid rowClick="show">
        {LOAN_LIST_ROWS.map(i => i)}
      </Datagrid>
    </List>
  );
};

LoanList.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
};

export const LOAN_LIST_ROWS = [
  <TextField key={'id'} source={'id'} />,
  <FunctionField onClick={(e) => e.stopPropagation()} key={'name'} label={'Name / Phone number'}
    render={({ user_id, user_first_name = '', user_last_name = '', user_phone_number }) => <>
      <Link color={'secondary'} href={`#users/${user_id}`}>{`#${user_id} ${user_first_name} ${user_last_name}`}</Link>
      <br />
      <Call userId={user_id} userPhone={user_phone_number}>{formatPhoneNumber(user_phone_number)}</Call>
    </>}
  />,
  <NumberField key={'principal'} source={'principal'} options={{ style: 'currency', currency: CURRENCY }} />,
  <NumberField key={'tenor'} source={'tenor'} />,
  <ChipField key={'state'} size={'small'} source={'state'} sortable={false} />,
  <FunctionField key={'is_repeat'} label={'Loan type'} source={'is_repeat'} sortable={false}
    render={(record, key) => <Chip size="small" label={record[key] ? 'Repeated' : 'New'} />}
  />,
  <FunctionField key={'created_at'} label={'Created at'} source={'created_at'}
    render={(record) => <DateTimeBoxComponent value={record.created_at} />}
  />,
  <FunctionField key={'disbursed_at'} label={'Disbursed at'} source={'disbursed_at'}
    render={(record) => record.disbursed_at && <DateTimeBoxComponent value={record.disbursed_at} />}
  />,
  <FunctionField key={'matured_at'} label={'Matured at'} source={'matured_at'}
    render={(record) => <DateTimeBoxComponent value={record.matured_at} />}
  />,
  <FunctionField key={'button_tab'}
    render={(record) => (
      <IconButton color={'secondary'} size={'small'} onClick={(e) => {
        e.stopPropagation();
        window.open(`#loans/${record.id}/show`, '_blank');
      }}>
        <OpenInNewIcon fontSize={'small'} />
      </IconButton>
    )}
  />,
];

const ListFilterExtensionRequest = (props) => {
  return (
    <Filter {...props} variant={'outlined'} style={{ marginTop: 0, marginBottom: '20px' }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="User name" source="search_by_name"/>
      <TextInput label="Document number" source="user->userDocuments->number" />
      <DateFilterInput label="Matured before" source="matured_at|before" before />
      <DateFilterInput label="Matured after" source="matured_at|after" after />
      <DateFilterInput label="Extension requested before" source="extension_requested_at|before" before wide />
      <DateFilterInput label="Extension requested after" source="extension_requested_at|after" after wide />
      <QuickFilterInput label="Extension min payment = 0" source="income_amount_for_extension|lte" defaultValue={'0'} />
      <QuickFilterInput label="Extension min payment > 0" source="income_amount_for_extension|gt" defaultValue={'0'} />
    </Filter>
  );
};

const BulkActionButtonsExtension = (props) => (
  <ButtonGroup {...props}>
    <MarkAllButton {...props} />
    <ActivateButton {...props} />
  </ButtonGroup>
);

export const LoanListExtensionRequest = (props) => {
  return <List
    pagination={<Pagination />}
    bulkActionButtons={<BulkActionButtonsExtension/>}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<ListFilterExtensionRequest />}
    actions={<LoanListActions />}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <FunctionField label="Name" onClick={(e) => e.stopPropagation()} render={({ user_id, user_first_name = '', user_last_name = '' }) =>
        <Link color={'secondary'} href={`#users/${user_id}`}>{`#${user_id} ${user_first_name} ${user_last_name}`}</Link>}
      />,
      <NumberField source="principal" options={{ style: 'currency', currency: CURRENCY }} />
      <NumberField source="tenor" />
      <ChipField size="small" source="state" sortable={false} />
      <FunctionField render={(record) => <DateTimeBoxComponent value={record.extension_requested_at} />} label="Extension requested at" />
      <FunctionField render={(record) => <DateTimeBoxComponent value={record.matured_at} />} source="matured_at" label="Matured at" />
      <NumberField source="income_amount_for_extension" label="Extension min payment" sortable={false} />
      <FunctionField render={({ last_paid_amount }) => last_paid_amount} label="Last paid amount" />
      <FunctionField render={(record) => <DateTimeBoxComponent value={record.last_payment_date} />} label="Last payment date" />
      <FunctionField render={({ extension_activation_message }) => extension_activation_message || '' } label="Activation message" />
      <FunctionField render={({ extension_activation_status }) => extension_activation_status || ''} label="Activation status" />
    </Datagrid>
  </List>;
};
