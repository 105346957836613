import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, usePermissions, useRedirect } from 'react-admin';
import {
  Grid,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Box,
  Button,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { TIMEZONE_OFFSET, getAdminTimezone, timezoneOffsetLabel } from '../../../utils';

import { GLOBAL_SETTINGS_OPTIONS_MAP, SettingItem } from './SettingItem';

export const Settings = () => {
  const [timezone, setTimezone] = useState(getAdminTimezone(false));
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();
  const [globalSettings, setGlobalSettings] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const redirect = useRedirect();

  useEffect(() => {
    const onChangeTimezone = () => {
      setTimezone(getAdminTimezone(false));
    };

    document.addEventListener('change-timezone', onChangeTimezone);

    return () => {
      document.removeEventListener('change-timezone', onChangeTimezone);
    };
  }, []);

  useEffect(() => {
    if (timezone !== getAdminTimezone(false)) {
      localStorage.setItem('admin_timezone', timezone);
      document.dispatchEvent(new Event('change-timezone'));
    }
  }, [timezone]);

  useEffect(() => {
    if (!permissions.length) {
      return;
    }

    if (permissions.indexOf('CAN_GLOBAL_SETTINGS_VIEW') === -1) {
      setLoading(false);
      return;
    }

    dataProvider
      .getList('global_settings', {
        filter: {},
        pagination: {},
        sort: {},
      })
      .then(({ data }) => {
        setGlobalSettings(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  }, [dataProvider, permissions]);

  if (error) {
    notify(`Error: ${error.message}`, 'error');
  }

  const onChangeGlobalSetting = (id, value) => {
    if (id === 7 && !value) {
      handleChangeNestedSettings(id, 'auto_money_transfer_config', value, true);
      return;
    }
    dataProvider
      .update('global_settings', { id, data: { value } })
      .then(({ data }) =>
        setGlobalSettings(globalSettings.map((item) => (item.id === data.id ? data : item))),
      )
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  };

  const handleChangeNestedSettings = (id, name, value, isDefault) => {
    const nested_settings = Object.keys(
      GLOBAL_SETTINGS_OPTIONS_MAP.auto_money_transfer_config.nested_settings,
    ).map((nested_setting_name) => ({
      name: nested_setting_name,
      value: isDefault
        ? 0
        : name === nested_setting_name
          ? value
          : globalSettings
            .find((item) => item.id === id)
            .nested_settings.find((item) => item.name === nested_setting_name).value,
    }));
    dataProvider
      .update('global_settings', {
        id,
        data: {
          value: isDefault ? 0 : 1,
          nested_settings,
        },
      })
      .then(({ data }) =>
        setGlobalSettings(globalSettings.map((item) => (item.id === data.id ? data : item))),
      )
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  };

  const handleTimeZoneChange = (event) => setTimezone(event.target.value);
  const handleGoRefreshOutlookToken = () => redirect(`${process.env.REACT_APP_API_HOST}/outlook/auth`);

  return (
    <Grid container spacing={3} justifyContent={'center'}>
      <Grid item sm={12}>
        <Card variant={'outlined'}>
          <CardHeader title={'UI SETTINGS'} />
          <Divider />
          <CardContent>
            <Box display={'flex'} gridGap={10} justifyContent={'space-between'}>
              <Box display={'flex'} alignItems={'center'} gridGap={10} flexDirection={'row'}>
                <AccessTimeIcon color={'secondary'} />
                <Typography variant={'body1'}>{'Timezone'}</Typography>
              </Box>
              <FormControl size={'small'} variant={'outlined'}>
                <Select
                  value={timezone}
                  onChange={handleTimeZoneChange}>
                  {Object.entries(TIMEZONE_OFFSET).map(([tz, offsetFunc]) => (
                    <MenuItem key={tz} value={tz}>
                      {tz}
                      {tz !== 'UTC' ? ` (UTC${timezoneOffsetLabel(offsetFunc())})` : ''}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12}>
        {permissions.indexOf('CAN_GLOBAL_SETTINGS_VIEW') !== -1
          ? (
            <Card variant={'outlined'}>
              <CardHeader title={'SYSTEM SETTINGS'} />
              <Divider />
              <CardContent>
                {loading || error || !Array.isArray(globalSettings)
                  ? (
                    <CircularProgress />
                  )
                  : (
                    <>{
                      globalSettings.map((item, idx) => (
                        <SettingItem
                          permissions={permissions}
                          globalSettings={globalSettings}
                          key={idx}
                          item={item}
                          onChangeNested={handleChangeNestedSettings}
                          onChange={onChangeGlobalSetting}
                        />
                      ))
                    }
                    {permissions.includes('CAN_GLOBAL_SETTINGS_VIEW') && <Button color='primary' variant="contained" onClick={handleGoRefreshOutlookToken}>Refresh outlook token</Button>}
                    </>)}
              </CardContent>
            </Card>
          )
          : null}
      </Grid>
    </Grid>
  );
};
