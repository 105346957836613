import React, { cloneElement } from 'react';
import {
  List, Datagrid, TextField, Filter, TextInput, NumberInput, FunctionField, TopToolbar, CreateButton,
  useListContext, sanitizeListRestProps,
} from 'react-admin';
import { Box, Link, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import Pagination from '../../Pagination';
import { formatPhoneNumber } from '../../../utils';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

const ListFilter = (props) => {
  return (
    <Filter {...props} variant={'outlined'} style={{ marginTop: 0, marginBottom: '20px' }}>
      <NumberInput label="User id" source="user" alwaysOn/>
      <TextInput label="NIDA" source="nida_id" />
      <TextInput label="Created by" source="createdBy->username" />
      <TextInput label="First name" source="firstName" />
      <TextInput label="Last name" source="lastName" />
      <TextInput label="Phone number" source="phoneNumber" />
      <TextInput label="Contact phone" source="contactPhoneNumber" />
      <TextInput label="Company phone" source="companyPhoneNumber" />
    </Filter>
  );
};

const ListActions = ({ filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {hasCreate && <CreateButton basePath={basePath} />}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  filters: PropTypes.element,
};

export const BlackList = (props) => (
  <List
    pagination={<Pagination />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<ListFilter />}
    actions={<ListActions />}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" label="Id"/>
      <FunctionField
        label={'Name / NIDA'}
        render={({ first_name, last_name, nida_id, user_id }) => (
          <>
            {user_id && <Box><b>{`#${user_id} `}</b>{`${first_name} ${last_name}`}</Box>}
            {nida_id && <Box>{nida_id}</Box>}
          </>
        )}
      />
      <FunctionField
        label={'Name / Phone number / E-mail'}
        render={({ email, phone_number }) => (
          <>
            {phone_number && <Box>{formatPhoneNumber(phone_number)}</Box>}
            {email && <Link color={'secondary'} href={`mailto: ${email}`}>{email}</Link>}
          </>
        )}
      />
      <FunctionField source="contact_phone_number" render={(record, key) => record[key] && <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>{formatPhoneNumber(record[key])}</Typography>} />
      <FunctionField source="company_phone_number" render={(record, key) => record[key] && <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>{formatPhoneNumber(record[key])}</Typography>} />
      <TextField source="comment" />
      <FunctionField source="created_at" render={(record) => <DateTimeBoxComponent value={record.created_at} />}/>
      <TextField source="created_by" />
    </Datagrid>
  </List>
);
