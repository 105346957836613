import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { Table, TableHead, Chip, TableBody, TableRow, TableCell, TablePagination, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { DateTimeBoxComponent } from '../DateTimeBoxComponent';
import { TableCellSort } from '../TableCellSort';

export const MatchesTable = ({ permissions, recordId, refreshedAt }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [matches, setMatches] = useState();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [activeFilter, setActiveFilter] = useState('id');
  const [rejectionReasons, setRejectionReasons] = useState(null);

  const getRejectReason = (errors, id) => {
    if (!Array.isArray(errors) || !id) {
      return null;
    }

    return errors.find(i => i.id === id);
  };

  useEffect(() => {
    dataProvider.query(`applications/${recordId}/user-field-matches`, {})
      .then(({ data }) => {
        const sort = data.slice().sort((a, b) => b.id - a.id);
        setMatches(sort);
        setLoading(false);
        setTotal(data.length);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
    dataProvider.query('rejection_reasons?pagination=false', { method: 'GET' })
      .then(({ data }) => setRejectionReasons(data))
      .catch(({ error }) => {
        setRejectionReasons(null);
        setError(error);
      });
  }, [recordId, dataProvider, refreshedAt]);

  if (loading) return <CircularProgress />;

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  if (!Array.isArray(matches)) return null;

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCellSort
              changeArray={setMatches}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              filter={'id'}>
              ID
            </TableCellSort>
            <TableCellSort
              changeArray={setMatches}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              filter={'user_id'}>
              User id
            </TableCellSort>
            {['Created at', 'Name', 'Approved principal',
              'Rejection reason code', 'State', 'Max paid day', 'Similar value',
              'Type value',
            ].map((i) => <TableCell key={i}>{i}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {matches.map((row, id) => {
            const reject = getRejectReason(rejectionReasons, row.rejection_reason_code);
            return <TableRow key={`${id}match`}>
              <TableCell>
                {
                  permissions.indexOf('CAN_APPLICATION_VIEW') !== -1
                    ? (
                      <Link to={`/applications/${row.id}/show`}>{row.id}</Link>
                    )
                    : row.id
                }
              </TableCell>
              <TableCell>
                {
                  permissions.indexOf('CAN_USER_VIEW') !== -1
                    ? (
                      <Link to={`/users/${row.user_id}/show`}>{row.user_id}</Link>
                    )
                    : row.user_id
                }
              </TableCell>
              <TableCell><DateTimeBoxComponent value={row?.created_at} /></TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.approved_principal}</TableCell>
              <TableCell>{reject ? reject.name : row.rejection_reason_code}</TableCell>
              <TableCell><Chip size={'small'} label={row.state} /></TableCell>
              <TableCell>{row.max_paid_day}</TableCell>
              <TableCell>{row.similar_value}</TableCell>
              <TableCell>{row.type_value}</TableCell>
            </TableRow>;
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
    </>
  );
};

MatchesTable.propTypes = {
  permissions: PropTypes.array,
  recordId: PropTypes.number.isRequired,
  refreshedAt: PropTypes.number,
};

MatchesTable.defaultProps = {
  recordId: null,
};
