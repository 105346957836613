export const COUNTRY_CODE = '+255';
export const CURRENCY = 'Tsh';
export const PHONE_NUMBER_PLACEHOLDER = '××× ××× ×××';
export const NIDA_PLACEHOLDER = '××××××××-×××××-×××××-××';
export const MASK_SYMBOL = '×';
export const DATE_FORMAT = 'dd MMM yyyy';
export const TIME_FORMAT = 'hh:mm a';
export const TIME_FORMAT_SECONDS = 'hh:mm:ss a';
export const DATE_TIME_FULL_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT_SECONDS}`;
export const APP_NAME = 'PESAPLUS TZ';

export const marginZeroStyles = {
  marginZero: {
    marginTop: 0,
    paddingBottom: '0.5rem',
  },
};

export const LOAN_TYPES = [
  { id: 'false', name: 'New' },
  { id: 'true', name: 'Repeated' },
];

export const YES_NO_CHOICES = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
];

export const DAYS_MODE_DOW = 'dow';
export const DAYS_MODE_DOM = 'dom';

export const MONTH_DAYS = [
  ...[...Array(31).keys()].map(i => ({
    label: i + 1,
    value: i + 1,
  })),
  {
    label: 'Last',
    value: -1,
  },
];

export const WEEK_DAYS = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

export const FIELD_MIN_LENGTH = 'err_field_min_length';
export const FIELD_NO_ZERO = 'err_field_no_zero';

// FORM FIELDS MODELS
export const createAppFormFields = {
  product_code: 'product_code',
  principal: 'principal',
  tenor: 'tenor',
  target_url: 'target_url',
  promo_code: 'promo_code',
};

export const approveAppFormFields = {
  principal: 'principal',
  tenor: 'tenor',
  promocode: 'promo_code',
};
