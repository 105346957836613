import React from 'react';
import Divider from '@material-ui/core/Divider';
import { RadioButtonGroupInput } from 'react-admin';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

import { YES_NO_CHOICES } from '../../constants';

export const BooleanFilter = ({ id, title, filter = {}, form }) => {
  return (
    <>
      <Divider />
      <FormControlLabel
        control={
          <Checkbox
            color="secondary"
            defaultChecked={filter?.active}
            onChange={(e) => form.change(`${id}.active`, e.target.checked ? [id] : [])}
          />
        }
        label={<b>{title.toUpperCase()}</b>}
      />
      <RadioButtonGroupInput
        label={false}
        source={`${id}.values.value`}
        choices={YES_NO_CHOICES}
        defaultValue={filter?.values?.[0]?.value}
      />
    </>
  );
};

BooleanFilter.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  filter: PropTypes.shape({
    active: PropTypes.bool,
    values: PropTypes.array,
  }),
  form: PropTypes.shape({
    change: PropTypes.func,
  }),
};

BooleanFilter.defaultProps = {
  filter: {},
};
