import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const DebounceButton = ({ onClick, ...props }) => {
  const [disabled, setDisabled] = useState(false);
  const handleClick = () => {
    onClick();
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };

  return <Button {...props} disabled={disabled} onClick={handleClick} />;
};

DebounceButton.propTypes = {
  onClick: PropTypes.func,
};

export default DebounceButton;
