import { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, Select, MenuItem, DialogActions, Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { GetEnabledRejectionReasons } from '../../../dynamic_dictionary/DynamicDictionary';

export const RejectTransitionDialog = ({ isOpened, onClose, onSubmit, title, text }) => {
  const [rejectionReasonCode, setRejectionReasonCode] = useState();

  const handleSubmit = () => onSubmit(rejectionReasonCode);

  return (
    <Dialog fullWidth open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <Select
          variant={'outlined'}
          id={'rejection-reason-select'}
          value={rejectionReasonCode || ''}
          onChange={e => setRejectionReasonCode(e.target.value)}
          fullWidth
        >
          {GetEnabledRejectionReasons().map(({ code, name }) => <MenuItem key={code} value={code}>{name}</MenuItem>)}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleSubmit} color={'primary'} disabled={!rejectionReasonCode}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

RejectTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
};
