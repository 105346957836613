import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDataProvider, useNotify } from 'react-admin';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../utils';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

export const ApplicationsTable = ({ userId, refreshedAt }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [applications, setApplications] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    dataProvider.getList('applications', {
      filter: { 'user.id': userId },
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
    })
      .then(({ data, total }) => {
        setApplications(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(applications)) return null;

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>Request id</TableCell>
            <TableCell>Request date</TableCell>
            <TableCell>Сonfirmed at</TableCell>
            <TableCell>Repayment date</TableCell>
            <TableCell>Principal</TableCell>
            <TableCell>Repaid by client</TableCell>
            <TableCell>Max DPD</TableCell>
            <TableCell>Request decision</TableCell>
            <TableCell>Verifier</TableCell>
            <TableCell>Rejection reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applications.map((row) => (
            <TableRow key={row.id}>
              <TableCell><Link to={`/applications/${row.id}/show`}>{ row.id }</Link></TableCell>
              <TableCell><DateTimeBoxComponent value={row?.created_at}/></TableCell>
              <TableCell><DateTimeBoxComponent value={row?.confirmed_at}/></TableCell>
              <TableCell><DateTimeBoxComponent value={row?.repayment_date}/></TableCell>
              <TableCell>{formatCurrency(row.approved_principal ? row.approved_principal : row.requested_principal)}</TableCell>
              <TableCell>{formatCurrency(row.repaid_by_client)}</TableCell>
              <TableCell>{row.max_paid_day }</TableCell>
              <TableCell><Chip size={'small'} label={row.state} /></TableCell>
              <TableCell>{row.verifier_username}</TableCell>
              <TableCell>{row.rejection_reason}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
    </>
  );
};

ApplicationsTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
