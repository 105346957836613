import { Button } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { copyToClipboard } from '../../utils';

export const CopyToClipboardButton = ({ url }) => {
  const handleCopyToClipboard = () => copyToClipboard(url);
  return (
    <>
      {url}
      <Button variant={'outlined'} color={'secondary'} size={'small'} onClick={handleCopyToClipboard}>
        <FileCopy />
      </Button>
    </>
  );
};

CopyToClipboardButton.propTypes = {
  url: PropTypes.string,
};
