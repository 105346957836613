import React, { useEffect, useState } from 'react';
import {
  Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Button, FormControl, TextField, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDataProvider, usePermissions } from 'react-admin';

import { formatPhoneNumber, TRANSMITTERSLIST_DEFAULT } from '../../utils';
import { useHandbook } from '../../hooks';

export const AddNotificationDialog = ({ transmittersList, isOpened, onClose, onSubmit, defaultPhoneNumber, defaultEmailAddress, userId }) => {
  const [transmitterId, setTransmitterId] = useState('');
  const [isTransmitterEmail, setIsTransmitterEmail] = useState(false);
  const [destination, setDestination] = useState('');
  const [template, setTemplate] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(null);
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const [userPhoneBooks, setUserPhoneBooks] = useState([]);

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    setIsTransmitterEmail(typeof transmitterId === 'string' && /mail/.test(transmitterId));
  }, [transmitterId]);
  useEffect(() => {
    if (userId) {
      dataProvider
        .getOne('users', { id: userId })
        .then(({ data }) => {
          setPhoneNumber(data.phone_number || null);
        });
      dataProvider
        .getList('phone_books', {
          filter: { 'user.id': userId },
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'DESC' },
        })
        .then(({ data }) => {
          const existingPhoneNumbers = [];
          const uniquePhoneBooks = data.filter((phoneBook) => {
            const exists = existingPhoneNumbers.includes(phoneBook.phone_number);

            existingPhoneNumbers.push(phoneBook.phone_number);

            return !exists;
          });

          setUserPhoneBooks(uniquePhoneBooks);
        });
    } else {
      setPhoneNumber(null);
    }
  }, [dataProvider, userId]);
  useEffect(() => {
    setTemplate('');
    setSubject('');
    setMessage('');
  }, [isTransmitterEmail, defaultEmailAddress, defaultPhoneNumber]);

  useEffect(() => {
    if (!isTransmitterEmail) {
      setDestination(defaultPhoneNumber);
    } else {
      setDestination('');
    }
  }, [isTransmitterEmail, defaultPhoneNumber]);
  useEffect(() => {
    if (permissions) {
      dataProvider.query(`admin/templates?manual_sending_roles=${JSON.stringify(permissions)}`, { method: 'GET' }).then((result) => {
        setTemplates(result.data);
      });
    }
  }, [permissions]);
  useEffect(() => {
    setTransmitterId('');
    setIsTransmitterEmail(false);
    setTemplate('manual one-time sending');
    setSubject('');
    setMessage('');
  }, [isOpened, defaultPhoneNumber]);

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit(
      transmitterId,
      destination,
      template,
      'en_TZ',
      isTransmitterEmail ? `Send email from template ${template}` : message,
      subject,
    );
  };

  const handleTransmittersChange = (e) => {
    setTransmitterId(e.target.value);
    if (e.target.value === 'email') {
      setDestination('');
    }
  };
  const { data: phoneTypes } = useHandbook('phone_types');

  const phoneTypeMap = Object.fromEntries(phoneTypes.map(phoneType => [phoneType.code, phoneType]));
  const handleMessageChange = (e) => setMessage(e.target.value);
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleTemplateSelectChange = (e) => {
    setTemplate(e.target.value.key);
    setMessage(e.target.value.template);
  };
  const isSubmitDisabled = !transmitterId || !destination || !template || !(isTransmitterEmail || message);
  const handleDestinationChange = (e) => {
    setPhoneNumber(e.target.value || null);
  };

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Add user notification</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id={'transmitter-select-label'}>Transmitter</InputLabel>
              <Select label={'Transmitter'} labelId={'transmitter-select-label'} value={transmitterId} onChange={handleTransmittersChange}>
                {transmittersList.map((option, idx) => <MenuItem key={idx} value={option.id}>{option.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant={'outlined'} size={'small'}>
              <InputLabel id={'destination-select-input-label'}>Destination</InputLabel>
              <Select
                label={'Destination'}
                labelId={'destination-select-input'}
                value={phoneNumber || ''}
                onChange={handleDestinationChange}
              >
                {userPhoneBooks.map(({ id, phone_number, type }) => (
                  <MenuItem key={id} value={phone_number}>
                    <div>
                      <b>{phoneTypeMap[type]?.name || type}</b>
                      <br />
                      <span>{formatPhoneNumber(phone_number)}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {templates.length > 0 && <Grid item xs={12}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id={'notification-template-select-label'}>Template</InputLabel>
              <Select labelId={'notification-template-select-label'} value={template.key}
                onChange={handleTemplateSelectChange}>
                {templates.map((template) => (
                  <MenuItem key={template.key}
                    value={template}><b>{template.key}</b>&nbsp;({template.subject})</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>}
        </Grid>
        {!isTransmitterEmail && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                variant={'outlined'}
                multiline
                disabled
                fullWidth
                label={'Message'}
                value={message}
                onChange={handleMessageChange}
              />
            </Grid>
          </Grid>
        )}
        {isTransmitterEmail && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                variant={'outlined'}
                fullWidth
                label={'Subject'}
                value={subject}
                onChange={handleSubjectChange}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleSubmit} color={'primary'} disabled={isSubmitDisabled}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

AddNotificationDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultPhoneNumber: PropTypes.string,
  defaultEmailAddress: PropTypes.string,
  transmittersList: PropTypes.array,
  userId: PropTypes.number,
};

AddNotificationDialog.defaultProps = {
  defaultPhoneNumber: '',
  defaultEmailAddress: '',
  transmittersList: TRANSMITTERSLIST_DEFAULT,
};
