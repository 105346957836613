import userPermissions from '../../../permissions/user.json';
import loanPermissions from '../../../permissions/loan.json';
import collectionPermissions from '../../../permissions/collection.json';
import marketingPermissions from '../../../permissions/marketing.json';
import managementPermissions from '../../../permissions/management.json';
import blacklistPermissions from '../../../permissions/blacklist.json';
import reportsPermissions from '../../../permissions/reports.json';
import settingsPermissions from '../../../permissions/settings.json';
import behaviorPermissions from '../../../permissions/behavior.json';
import uncategorizedPermissions from '../../../permissions/uncategorized.json';

export default [
  {
    name: 'User',
    permissions: userPermissions,
  },
  {
    name: 'Loan',
    permissions: loanPermissions,
  },
  {
    name: 'Collection',
    permissions: collectionPermissions,
  },
  {
    name: 'Marketing',
    permissions: marketingPermissions,
  },
  {
    name: 'Management',
    permissions: managementPermissions,
  },
  {
    name: 'Blacklist',
    permissions: blacklistPermissions,
  },
  {
    name: 'Reports',
    permissions: reportsPermissions,
  },
  {
    name: 'Behaviour',
    permissions: behaviorPermissions,
  },
  {
    name: 'Settings',
    permissions: settingsPermissions,
  },
  {
    name: 'Uncategorized',
    permissions: uncategorizedPermissions,
  },
];
