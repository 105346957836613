import { cloneElement, useState } from 'react';
import {
  List, Datagrid, TextField, BooleanField, ChipField, Filter, NumberInput, TopToolbar,
  CreateButton, useListContext, sanitizeListRestProps, FunctionField, useDataProvider, useNotify, useRefresh,
} from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PropTypes from 'prop-types';

import Pagination from '../../Pagination';
import { ConfirmToDeleteDialog, DialerProcessesTableDialog } from '../../dialogs';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

const ListFilter = (props) => (
  <Filter {...props} variant={'outlined'} style={{ marginTop: 0, marginBottom: '20px' }}>
    <NumberInput label='Id' source='id' alwaysOn />
  </Filter>
);

const ListActions = ({ filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate && <CreateButton basePath={basePath} />}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  filters: PropTypes.element,
};

const DeleteAction = ({ record = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const deleteHandler = (e) => {
    e.stopPropagation();
    setLoading(true);

    dataProvider
      .delete('dialer_campaigns', { id: record.id })
      .catch((error) => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };
  const handleConfirmDeleteDialogOpen = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };
  const handleConfirmDeleteDialogClose = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <Button color={'secondary'} onClick={handleConfirmDeleteDialogOpen}>
        <HighlightOffIcon />
      </Button>
      {isOpen && <ConfirmToDeleteDialog
        isOpened={isOpen}
        onClose={handleConfirmDeleteDialogClose}
        onSubmit={deleteHandler}
        title={record.name}
      />}
    </>
  );
};

DeleteAction.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export const DialerBasesList = (props) => {
  const [showProcessing, setShowProcessing] = useState(false);
  const [id, setId] = useState();
  const handleHideProcessing = () => setShowProcessing(false);
  const handleSetShowProcessing = (id) => {
    setId(id);
    setShowProcessing(true);
  };

  return (
    <>
      {showProcessing && (
        <DialerProcessesTableDialog
          id={id}
          isOpened={showProcessing}
          onClose={handleHideProcessing}
        />
      )}
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<ListFilter />}
        actions={<ListActions />}
        {...props}>
        <Datagrid rowClick='edit'>
          <TextField source='id' />
          <TextField source='name' />
          <ChipField label={'Created by'} source='created_by_username' size='small' />
          <FunctionField label='Created at' source='created_at' render={(record) =>
            <DateTimeBoxComponent value={record.created_at} />} />
          <FunctionField label='Updated at' source='updated_at' render={(record, key) =>
            <DateTimeBoxComponent value={record?.updated_at} />} />
          <FunctionField
            label='Frequency of imports'
            source='frequency_of_imports'
            render={({ params }) =>
              params?.frequency_period &&
              `Every ${params?.frequency_period?.[0] || params?.frequency_period} min`
            }
          />
          <FunctionField label='Start time - End time' render={({ start_time, end_time }) => <>{start_time} - {end_time}</>} />
          <BooleanField source='is_enabled' />
          <FunctionField
            label='Last import state'
            source='last_import_state'
            render={({ processes }) => processes?.[processes?.length - 1]?.state}
          />
          <FunctionField
            label='Processes'
            onClick={(e) => e.stopPropagation()}
            render={({ id }) =>
              <Button variant={'outlined'} color={'secondary'} value={id} size={'small'} onClick={() => handleSetShowProcessing(id)}>Show</Button>}
          />
          <DeleteAction label='Delete' />
        </Datagrid>
      </List>
    </>
  );
};
