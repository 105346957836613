import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import PropTypes from 'prop-types';

import { BlackListForm } from './BlackListForm';

const validation = (values) => {
  const errors = {};
  if (Object.keys(values).length < 2) {
    const message = 'Please fill in one any field';
    errors.nida_id = message;
    errors.first_name = message;
    errors.last_name = message;
    errors.phone_number = message;
    errors.email = message;
    errors.contact_phone_number = message;
    errors.company_phone_number = message;
  }
  return errors;
};

const FormWrapper = ({ ...props }) => (
  <SimpleForm validate={validation} {...props}>
    <BlackListForm record={props.record} />
  </SimpleForm>
);

FormWrapper.propTypes = {
  record: PropTypes.object,
};

export const BlackListCreate = ({ permissions, ...props }) => (
  <Create {...props}>
    <FormWrapper permissions={permissions} />
  </Create>
);

BlackListCreate.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};
