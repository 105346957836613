import React from 'react';
import PropTypes from 'prop-types';
import { Create, TextInput, SaveButton, Toolbar, FormWithRedirect, BooleanInput } from 'react-admin';
import { makeStyles, Box, Paper, Grid, Divider, Typography, Tabs, Tab } from '@material-ui/core';

import TabPanel from './TabPanel';
import CheckboxListInput from './CheckboxListInput';
import permissionGroups from './permissionGroups';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  mt4: {
    marginTop: theme.spacing(4),
  },

  tabLink: {
    minWidth: 100,
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const classes = useStyles();

  const handleChange = (e, value) => setActiveTab(() => value);

  return (
    <FormWithRedirect
      save={(data, ...rest) => {
        data.is_enabled = data.is_enabled || false;

        save(...[data, ...rest]);
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Paper>
                <Grid item xs={6}>
                  <Box p={ 2 }>
                    <Typography variant="h6" gutterBottom={ false }>Create a role</Typography>
                    <TextInput className={ classes.mt4 } label="Role code" source="code" fullWidth={ true } helperText={ false } />
                    <Typography variant="caption">Important: the role code must begin with &apos;ROLE_&apos;</Typography>
                    <TextInput label="Role name" source="name" fullWidth={ true } helperText={ false } />
                    <BooleanInput label="Is enabled" source="is_enabled" defaultValue={true} helperText={ false } />
                  </Box>
                </Grid>
                <Divider />
                <Tabs value={activeTab} variant="scrollable" scrollButtons="auto" onChange={handleChange}>
                  {permissionGroups.map((group) => (
                    <Tab key={group.name} label={group.name} className={classes.tabLink}/>
                  ))}
                </Tabs>
                {permissionGroups.map((group, index) => (
                  <TabPanel key={group.name} value={activeTab} index={index}>
                    <CheckboxListInput source="permissions" choices={group.permissions} />
                  </TabPanel>
                ))}
                <Toolbar className={ classes.toolbar }>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const RoleCreate = props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
export default RoleCreate;
