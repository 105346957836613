import { isValid } from 'date-fns';

import { COUNTRY_CODE, CURRENCY } from '../constants';

import { getAdminTimezone } from './getAdminTimezone';

const numberFormat = new Intl.NumberFormat(navigator.language, {
  style: 'currency',
  currency: CURRENCY,
});

const dateFormat = () => new Intl.DateTimeFormat(navigator.language, {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  timeZone: getAdminTimezone(),
});

export const datetimeFormat = () => new Intl.DateTimeFormat(navigator.language, {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: getAdminTimezone(),
});

export const dateNewFormat = () => new Intl.DateTimeFormat(navigator.language, {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  timeZone: getAdminTimezone(),
});

export const timeFormat = () => new Intl.DateTimeFormat(navigator.language, {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: getAdminTimezone(),
});

export const formatCurrency = (value, defaultValue = '---') => {
  if (typeof value === 'number') {
    return numberFormat.format(value);
  }

  return defaultValue;
};
export const formatDate = (value, defaultValue = '---') => {
  if (!value) {
    return defaultValue;
  }

  const date = new Date(value);

  if (isValid(date) === false) {
    return defaultValue;
  }

  return dateFormat().format(new Date(value));
};

export const formatDatetime = (value, defaultValue = '---') => {
  if (!value) {
    return defaultValue;
  }

  const date = new Date(value);

  if (isValid(date) === false) {
    return defaultValue;
  }

  return datetimeFormat().format(new Date(value));
};

export const formatPhoneNumber = (value, defaultValue = '---') => {
  if (!value) {
    return defaultValue;
  }

  if (typeof value !== 'string' || value.length === 0) {
    return defaultValue;
  }

  if (value.length > 10) {
    return `${COUNTRY_CODE} ${value.slice(0, 3)} ${value.slice(3, 6)} ${value.slice(6)}`;
  } else if (value.length === 10) {
    return `${COUNTRY_CODE} ${value.slice(0, 3)} ${value.slice(3, 6)} ${value.slice(6)}`;
  } else if (value.length >= 7) {
    return `${COUNTRY_CODE} ${value.slice(0, 3)} ${value.slice(3, 6)} ${value.slice(6)}`;
  } else if (value.length >= 3) {
    return `${COUNTRY_CODE} ${value.slice(0, 2)} ${value.slice(2)}`;
  } else {
    return `${COUNTRY_CODE} ${value}`;
  }
};

export const calculateAge = (birth_month, birth_day, birth_year, today_date) => {
  const today_year = today_date.getFullYear();
  const today_month = today_date.getMonth();
  const today_day = today_date.getDate();
  let age = today_year - birth_year;

  if (today_month < birth_month) {
    age--;
  }
  if ((today_month === birth_month) && (today_day < birth_day)) {
    age--;
  }
  return age;
};

export const formatChoices = dictionary => dictionary.map(item => ({ id: item, name: item }));

export const formatFilter = (type, id, filter) => {
  let newFilter = {};

  if (type === 'boolean' || type === 'date_range') {
    newFilter = { values: [{ ...filter?.values, type }] };
  }

  if (type === 'list') {
    newFilter = { values: filter?.values?.map((item) => ({ type: 'list', include: item.include, value: item.value || item })) };
  }

  if (type === 'sign' || type === 'number_with_operation_sign') {
    newFilter = {
      values: filter?.values?.map((item) => ({
        include: item.include,
        type: 'number_with_operation_sign',
        number: +item.number,
        operation_sign: item.operation_sign,
      })),
    };
  }

  return {
    id,
    active: !!filter?.active?.length,
    ...newFilter,
  };
};

// NEW

export const getSubstringAfterSymbol = (inputString, sym) => {
  const hashIndex = inputString?.indexOf(sym) || -1;
  if (hashIndex !== -1) {
    return inputString.substring(hashIndex + 1);
  } else {
    return '';
  }
};

export const formatLabel = (str) => {
  if (!str || str.length === '') return 'Select';

  return `${str.charAt(0).toUpperCase()}${str.slice(1, str.length)}`.replaceAll('_', ' ');
};

export const getFormattedDay = (year, month, day) => new Date(Date.UTC(year, month - 1, day)).toJSON().split('T')[0];
