import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDataProvider, useNotify } from 'react-admin';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Chip } from '@material-ui/core';

import { formatCurrency } from '../../utils';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

export const LoansTable = ({ userId, refreshedAt }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loans, setLoans] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    dataProvider.getList('loans', {
      filter: { 'user.id': userId },
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
    })
      .then(({ data, total }) => {
        setLoans(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(loans)) return null;

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Appl.</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Principal</TableCell>
            <TableCell>Tenor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loans.map(row => (
            <TableRow key={row.id}>
              <TableCell><Link to={`/loans/${row.id}/show`}>{row.id}</Link></TableCell>
              <TableCell><Link to={`/applications/${row.application_id}/show`}>{row.application_id}</Link></TableCell>
              <TableCell><DateTimeBoxComponent value={row?.created_at} /></TableCell>
              <TableCell><Chip size={'small'} label={row.state} /></TableCell>
              <TableCell><Chip size={'small'} label={row.product_name} /></TableCell>
              <TableCell>{formatCurrency(row.principal)}</TableCell>
              <TableCell>{row.tenor}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
    </>
  );
};

LoansTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
