import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import {
  CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Chip,
} from '@material-ui/core';

import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

export const NotesTable = ({ userId = null, applicationId = null, loanId = null, refreshedAt }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [notes, setNotes] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    dataProvider.getList('notes', {
      filter: {
        ...(userId ? { 'user.id': userId } : {}),
        ...(applicationId ? { 'application.id': applicationId } : {}),
        ...(loanId ? { 'loan.id': loanId } : {}),
      },
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
    })
      .then(({ data, total }) => {
        setNotes(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, applicationId, loanId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(notes)) return null;
  const handleSetPage = () => setPage(page + 1);

  const handleRowsPerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Label</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Created by</TableCell>
            <TableCell align={'right'}>Created at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notes.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell><Chip size={'small'} label={row.label} /></TableCell>
              <TableCell>{row.message}</TableCell>
              <TableCell>{row.created_by_username && row.created_by_username.concat(` #${row.created_by_id}`)}</TableCell>
              <TableCell align={'right'}><DateTimeBoxComponent value={row?.created_at} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={handleSetPage}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  );
};

NotesTable.propTypes = {
  userId: PropTypes.number,
  applicationId: PropTypes.number,
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
