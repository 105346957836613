import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Create, TextInput, SelectInput, SaveButton, FormWithRedirect, maxLength, useDataProvider, useNotify,
} from 'react-admin';
import {
  makeStyles, Box, InputLabel, MenuItem, FormControl, Select, Chip, Paper, Grid, Divider, Typography,
} from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';

import { AUDIENCE_FILTERS_IDS, formatFilter, CHOICES, formatChoices } from '../../../utils';

import { Filter } from './Filter';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btn: {
    fontSize: 11,
    marginRight: 5,
    marginBottom: 5,
  },
  chip: {
    fontSize: '14px',
    backgroundColor: '#c2c2c1',
  },
}));

const FormWrapper = ({ save, filters, ...props }) => {
  const classes = useStyles();
  const [activeType, setActiveType] = useState('');
  const [filterName, setFilterName] = useState([]);
  const sortedFilters = filters.filter((filter) => filter.supported_audience_types.includes(activeType));
  const notify = useNotify();

  let filterObject = {};

  AUDIENCE_FILTERS_IDS.forEach(({ id, index, ...rest }) => {
    filterObject = {
      ...filterObject,
      [id]: (form) =>
        <Filter
          key={index}
          id={id}
          form={form}
          choice={formatChoices(filters.find(i => i.id === id)?.description?.allowed_values || [])}
          {...rest}
        />,
    };
  });

  const handleChange = (event) => setFilterName(event.target.value);

  const handleChangeActiveType = (e) => {
    setActiveType(e.target.value);
    setFilterName([]);
  };

  const getFormDate = (currentDate) => {
    const year = currentDate.getFullYear();
    const mounth = currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1;
    const day = currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate();

    return `${year}-${mounth}-${day}T03:30`;
  };

  return (
    <FormWithRedirect
      save={(
        {
          registration_date,
          name,
          type,
          user_is_blocked,
          last_application_rejection_reason,
          last_application_state,
          last_call_result_promo,
          last_call_result_unfinished_registration,
          last_call_result_unsigned_request,
          last_loan_state,
          registration_step,
          closed_loan_number,
          days_since_last_rejected_application,
          days_since_last_user_unlock,
          extension_number,
          last_loan_max_dpd,
          user_days_before_birthday,
          days_without_loan,
          last_moratorium_days,
          user_in_blacklist,
          last_sent_sms_template,
          days_since_last_sms_sent,
          last_user_activity,
          last_loan_has_active_promo_code,
          last_loan_sequence_number,
          last_loan_term,
          last_loan_type,
          user_has_do_not_call_marker,
          registration_date_from_today,
          last_loan_collection_score_group,
          last_loan_collection_score_priority,
          dpd,
          collection_group,
          last_loan_tenor,
          collector,
          days_from_the_last_call_client,
          days_from_the_last_call_tpc,
          days_from_the_last_call,
          days_from_last_ptp_date,
          ptp_stage,
          total,
          principal,
          days_from_the_last_pay,
          income_for_loan,
          user_age,
          amount_loan,
          closed_loan,
          days_before_due_date,
          days_after_PTP_date,
          days_before_PTP_date,
          user_gender,
          last_contact_type_client_phone,
          last_contact_type_client_promise_phone,
          days_from_last_payment_date,
          last_logged_into_personal_account_general,
          last_logged_into_personal_account,
          last_logged_into_short_personal_account,
          last_logged_payment_total_link_personal_account,
          last_logged_payment_prolong_link_personal_account,
          external_agency,
          count_of_broken_ptp,
          collection_result,
          days_after_last_contact_adding,
          max_dpd_ever,
          rpc_ever,
        },
        ...rest
      ) => {
        const currentDate = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        const newRegistrationDate = registration_date?.values
          ? registration_date
          : {
            ...registration_date,
            values: {
              from: getFormDate(yesterday),
              to: getFormDate(currentDate),
            },
          };

        const filtersIDs = {
          registration_date: formatFilter('date_range', 'registration_date', newRegistrationDate),
          user_is_blocked: formatFilter('boolean', 'user_is_blocked', user_is_blocked),
          user_in_blacklist: formatFilter('boolean', 'user_in_blacklist', user_in_blacklist),
          last_application_rejection_reason: formatFilter('list', 'last_application_rejection_reason', last_application_rejection_reason),
          last_application_state: formatFilter('list', 'last_application_state', last_application_state),
          last_call_result_promo: formatFilter('list', 'last_call_result_promo', last_call_result_promo),
          last_call_result_unfinished_registration: formatFilter('list', 'last_call_result_unfinished_registration', last_call_result_unfinished_registration),
          last_call_result_unsigned_request: formatFilter('list', 'last_call_result_unsigned_request', last_call_result_unsigned_request),
          last_loan_state: formatFilter('list', 'last_loan_state', last_loan_state),
          registration_step: formatFilter('list', 'registration_step', registration_step),
          closed_loan_number: formatFilter('sign', 'closed_loan_number', closed_loan_number),
          days_since_last_rejected_application: formatFilter('sign', 'days_since_last_rejected_application', days_since_last_rejected_application),
          days_since_last_user_unlock: formatFilter('sign', 'days_since_last_user_unlock', days_since_last_user_unlock),
          extension_number: formatFilter('sign', 'extension_number', extension_number),
          last_loan_max_dpd: formatFilter('sign', 'last_loan_max_dpd', last_loan_max_dpd),
          user_days_before_birthday: formatFilter('sign', 'user_days_before_birthday', user_days_before_birthday),
          days_without_loan: formatFilter('sign', 'days_without_loan', days_without_loan),
          last_moratorium_days: formatFilter('sign', 'last_moratorium_days', last_moratorium_days),
          last_user_activity: formatFilter('sign', 'last_user_activity', last_user_activity),
          last_sent_sms_template: formatFilter('list', 'last_sent_sms_template', last_sent_sms_template),
          days_since_last_sms_sent: formatFilter('sign', 'days_since_last_sms_sent', days_since_last_sms_sent),
          last_loan_has_active_promo_code: formatFilter('boolean', 'last_loan_has_active_promo_code', last_loan_has_active_promo_code),
          last_loan_sequence_number: formatFilter('sign', 'last_loan_sequence_number', last_loan_sequence_number),
          last_loan_term: formatFilter('sign', 'last_loan_term', last_loan_term),
          last_loan_type: formatFilter('list', 'last_loan_type', last_loan_type),
          last_loan_collection_score_group: formatFilter('list', 'last_loan_collection_score_group', last_loan_collection_score_group),
          last_loan_collection_score_priority: formatFilter('list', 'last_loan_collection_score_priority', last_loan_collection_score_priority),
          user_has_do_not_call_marker: formatFilter('boolean', 'user_has_do_not_call_marker', user_has_do_not_call_marker),
          registration_date_from_today: formatFilter('sign', 'registration_date_from_today', registration_date_from_today),
          dpd: formatFilter('sign', 'dpd', dpd),
          collection_group: formatFilter('list', 'collection_group', collection_group),
          last_loan_tenor: formatFilter('sign', 'last_loan_tenor', last_loan_tenor),
          collector: formatFilter('list', 'collector', collector),
          days_from_the_last_call_client: formatFilter('sign', 'days_from_the_last_call_client', days_from_the_last_call_client),
          days_from_the_last_call_tpc: formatFilter('sign', 'days_from_the_last_call_tpc', days_from_the_last_call_tpc),
          days_from_the_last_call: formatFilter('sign', 'days_from_the_last_call', days_from_the_last_call),
          days_from_last_ptp_date: formatFilter('sign', 'days_from_last_ptp_date', days_from_last_ptp_date),
          ptp_stage: formatFilter('list', 'ptp_stage', ptp_stage),
          total: formatFilter('sign', 'total', total),
          principal: formatFilter('sign', 'principal', principal),
          user_gender: formatFilter('list', 'user_gender', user_gender),
          user_age: formatFilter('sign', 'user_age', user_age),
          amount_loan: formatFilter('sign', 'amount_loan', amount_loan),
          closed_loan: formatFilter('sign', 'closed_loan', closed_loan),
          days_before_PTP_date: formatFilter('sign', 'days_before_PTP_date', days_before_PTP_date),
          days_after_PTP_date: formatFilter('sign', 'days_after_PTP_date', days_after_PTP_date),
          days_before_due_date: formatFilter('sign', 'days_before_due_date', days_before_due_date),
          days_from_the_last_pay: formatFilter('sign', 'days_from_the_last_pay', days_from_the_last_pay),
          income_for_loan: formatFilter('boolean', 'income_for_loan', income_for_loan),
          last_contact_type_client_phone: formatFilter('sign', 'last_contact_type_client_phone', last_contact_type_client_phone),
          last_contact_type_client_promise_phone: formatFilter('sign', 'last_contact_type_client_promise_phone', last_contact_type_client_promise_phone),
          days_from_last_payment_date: formatFilter('sign', 'days_from_last_payment_date', days_from_last_payment_date),
          last_logged_into_personal_account_general: formatFilter('sign', 'last_logged_into_personal_account_general', last_logged_into_personal_account_general),
          last_logged_into_personal_account: formatFilter('sign', 'last_logged_into_personal_account', last_logged_into_personal_account),
          last_logged_into_short_personal_account: formatFilter('sign', 'last_logged_into_short_personal_account', last_logged_into_short_personal_account),
          last_logged_payment_total_link_personal_account: formatFilter('sign', 'last_logged_payment_total_link_personal_account', last_logged_payment_total_link_personal_account),
          last_logged_payment_prolong_link_personal_account: formatFilter('sign', 'last_logged_payment_prolong_link_personal_account', last_logged_payment_prolong_link_personal_account),
          external_agency: formatFilter('list', 'external_agency', external_agency),
          count_of_broken_ptp: formatFilter('sign', 'count_of_broken_ptp', count_of_broken_ptp),
          collection_result: formatFilter('list', 'collection_result', collection_result),
          days_after_last_contact_adding: formatFilter('sign', 'days_after_last_contact_adding', days_after_last_contact_adding),
          max_dpd_ever: formatFilter('sign', 'max_dpd_ever', rpc_ever),
          rpc_ever: formatFilter('list', 'rpc_ever', rpc_ever),
        };
        const newFilters = [];
        filterName.forEach((i) => {
          newFilters.push(filtersIDs[i.id]);
        });
        const params = {
          name,
          type,
          filters: newFilters,
        };

        params.filters.some(filter => filter.active)
          ? save(...[params, ...rest])
          : notify(
            'Error:no filters are selected for this audience', 'error',
          );
      }}
      {...props}
      render={(formProps) => (
        <Grid container justifyContent='center' spacing={4}>
          <Grid item xs={12} sm={8}>
            <Paper>
              <Box p={2}>
                <Typography variant='h6' gutterBottom={false}>
                  Create new audience
                </Typography>
              </Box>
              <Divider />
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      variant={'outlined'}
                      className={classes.mt4}
                      source='name'
                      validate={[maxLength(255)]}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectInput
                      variant={'outlined'}
                      source='type'
                      choices={CHOICES}
                      onChange={handleChangeActiveType}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel>Filters</InputLabel>
                      <Select
                        variant='outlined'
                        multiple
                        label='Filters'
                        value={filterName}
                        onChange={handleChange}
                        disabled={!activeType}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            {selected.map(({ id }) => (
                              <Chip
                                className={classes.chip}
                                key={id}
                                label={id.replaceAll('_', ' ')}
                              />
                            ))}
                          </Box>
                        )}>
                        {sortedFilters.map((filter) =>
                          <MenuItem key={filter.id} value={filter}>
                            {filter.id.replaceAll('_', ' ')}
                          </MenuItem>,
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box p={2}>{filterName.map((filter) => filterObject[filter.id](formProps.form))}    </Box>
              <Box p={2}>
                <SaveButton
                  icon={<ListIcon />}
                  label='Save and back to list'
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  redirect='list'
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    />
  );
};

FormWrapper.propTypes = {
  filters: PropTypes.any,
  save: PropTypes.func,
};

export default ({ ...props }) => {
  const notify = useNotify();
  const [filters, setFilters] = useState([]);
  const [error, setError] = useState();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.query('mass_sending_audiences/filters', { method: 'GET' })
      .then(({ data }) => setFilters(data))
      .catch(error => setError(error));

    return () => {
      setFilters([]);
    };
  }, [dataProvider]);

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Create component='div' {...props}>
      <FormWrapper filters={filters} />
    </Create>
  );
};
