import React, { useState } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { IconButton, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { timezone } from '../../../../utils';

export const PostponeTransitionDialog = ({ isOpened, onClose, onSubmit, initialValue = null }) => {
  const [postponedUntil, setPostponedUntil] = useState(initialValue ? new Date(initialValue) : null);

  const handleSubmit = () => onSubmit(postponedUntil);

  return (
    <Dialog open={isOpened} fullWidth onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Postpone application</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, choose a date</DialogContentText>
        <DateTimePicker
          inputVariant={'outlined'}
          fullWidth
          value={postponedUntil ? timezone.shift(postponedUntil) : null}
          onChange={value => setPostponedUntil(value ? timezone.unshift(value) : null)}
          clearable
          InputProps={{
            endAdornment: (
              <IconButton>
                <CalendarToday />
              </IconButton>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleSubmit} color={'primary'}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

PostponeTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  initialValue: PropTypes.oneOfType([PropTypes.string]),
};
