import { useState, useEffect } from 'react';
import { MenuItem, Select, Button } from '@material-ui/core';
import { useNotify, useDataProvider, useRefresh, useUnselectAll } from 'react-admin';
import { Skeleton } from '@material-ui/lab';

export const CollectionLoanListBulkActionButtons = ({ selectedIds, resource }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [newCollector, setNewCollector] = useState({});
  const [collectors, setCollectors] = useState([]);
  const unselectAll = useUnselectAll(resource);

  useEffect(() => {
    dataProvider.query('admins/collection_specialists', { method: 'GET' })
      .then((res) => {
        if (res.data) {
          const { data } = res;
          setCollectors(data);
        }
      })
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  const handleCollectorChange = (e) => {
    setNewCollector({
      admin_id: e.target.value.id,
      admin_name: e.target.value.username,
    });
  };
  const handleCollectorSubmit = (e) => {
    dataProvider.query('phone_call_loan_assigns_batch', {
      body: JSON.stringify({
        loan_ids: selectedIds,
        context: 'loan_collection_loans',
        ...newCollector,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => {
        if (res.data) {
          notify('Assigned successfully!', 'success');
          refresh();
          unselectAll();
        }
      })
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  };

  return (
    <>
      {collectors.length > 0
        ? <Select
          size={'small'}
          variant={'outlined'}
          id={'assign-collector-select'}
          onChange={handleCollectorChange}
          value={newCollector.adminId}
        >
          {collectors.map((collector, key) =>
            <MenuItem key={key} value={collector}>
              {collector.username}
            </MenuItem>)}
        </Select>
        : <Skeleton variant={'rect'} />
      }
      <Button
        onClick={handleCollectorSubmit}
        variant="contained"
        size="medium"
        color="primary"
        disabled={!newCollector?.admin_id}
      >Assign collector</Button>
    </>
  );
};
