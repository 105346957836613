import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider } from 'react-admin';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination, CircularProgress } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';

import { DateTimeBoxComponent } from '../DateTimeBoxComponent';
import { openIntoNewTab, AVENTUS_DECISION_ENGINE_STATUSES as statuses } from '../../utils';
import { TableCellSort } from '../TableCellSort';

export const AventusDecisionEngineDataTable = ({ recordId, refreshedAt }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [aventusDecisionEngineData, setAventusDecisionEngineData] = useState();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [activeFilter, setActiveFilter] = useState('id');
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    dataProvider.query(`applications/${recordId}/aventus-decision-engine-data`, {})
      .then(({ data }) => {
        const sort = data.slice().sort((a, b) => b.id - a.id);
        setAventusDecisionEngineData(sort);
        setLoading(false);
        setTotal(data.length);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [recordId, dataProvider, refreshedAt]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(aventusDecisionEngineData)) {
    return null;
  }

  const downloadHandler = id => {
    setIsDownloading(true);
    dataProvider.downloadFile(`aventus_decision_engine_log/${id}`)
      .then(({ data: objUrl }) => openIntoNewTab(objUrl))
      .catch(error => {
        notify(`Error: ${error.message}`, 'error');
      })
      .finally(() => setIsDownloading(false));
  };
  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCellSort
              changeArray={setAventusDecisionEngineData}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              filter={'id'}>
              ID
            </TableCellSort>
            {['Created at', 'Update At', 'State', 'ADE decision', 'Approved amount',
              'Stoplist term', 'Rejection reasons', 'Verification is needed rules', 'Log File',
            ].map(i => <TableCell key={i}>{i}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {aventusDecisionEngineData.map((row, id) => (
            <TableRow key={`${id}ade`}>
              <TableCell>{row.id}</TableCell>
              <TableCell><DateTimeBoxComponent value={row?.created_at} /></TableCell>
              <TableCell><DateTimeBoxComponent value={row?.updated_at} /></TableCell>
              <TableCell>{row.state}</TableCell>
              <TableCell>{ (statuses.find(item => item.id === row.status) || { name: 'unknown' }).name}</TableCell>
              <TableCell>{row.approved_amount}</TableCell>
              <TableCell>{row.stop_list_term}</TableCell>
              <TableCell>{row.rejected_reasons.join(', ')}</TableCell>
              <TableCell>{row.verification_is_needed_rules.join(', ')}</TableCell>
              <TableCell>
                <IconButton
                  color={'primary'}
                  size={'small'}
                  disabled={row.state === 'pending' || isDownloading}
                  onClick={() => downloadHandler(row.id)}
                >
                  <CloudDownloadIcon/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
    </>
  );
};

AventusDecisionEngineDataTable.propTypes = {
  recordId: PropTypes.number.isRequired,
  refreshedAt: PropTypes.number,
};
AventusDecisionEngineDataTable.defaultProps = {
  recordId: null,
  refreshedAt: undefined,
};
