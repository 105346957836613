import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

import { TitleCloseDialog } from './TitleCloseDialog';

export const ServiceInfoDialog = ({
  isOpened,
  onClose,
  title = 'Service info',
  array = [{ name: 'left-table-cell', value: 'right-table-cell' }],
}) => {
  return (
    <TitleCloseDialog open={isOpened} close={onClose} title={title} fullWidth>
      <Table size={'small'}>
        <TableBody>
          {array.map((i, id) => <TableRow key={id + name}>
            <TableCell>{i.name}</TableCell>
            <TableCell align={'right'}>{i.value}</TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TitleCloseDialog>
  );
};

ServiceInfoDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  array: PropTypes.arrayOf(PropTypes.object),
};
