import { ChipField, Datagrid, FunctionField, List, NumberField, TextField, usePermissions } from 'react-admin';
import { Box, Avatar, Chip, Link, CircularProgress, Backdrop } from '@material-ui/core';

import { formatPhoneNumber, getUserId } from '../../../utils';
import Pagination from '../../Pagination';
import { Call } from '../../Call';
import { useCollectionGroups } from '../../../hooks';
import { CURRENCY } from '../../../constants';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

import { CollectionLoanListFilter } from './CollectionLoanListFilter';
import { CollectionLoanListActions } from './CollectionLoanListActions';
import { CollectionLoanListBulkActionButtons } from './CollectionLoanListBulkActionButtons';

const resolveCollectionGroup = (daysPastDue, collectionGroups) => {
  if (daysPastDue >= 91) {
    return 'G6';
  }
  const group = collectionGroups.find((group) => daysPastDue >= group.dpd_from && daysPastDue <= group.dpd_to);
  return group?.code ?? 'Er';
};

export const CollectionLoanList = (props) => {
  const { data: collectionGroups, isLoading } = useCollectionGroups();
  const userId = getUserId();
  const { permissions = [] } = usePermissions();

  if (isLoading) return <Backdrop open={true}><CircularProgress /></Backdrop>;

  return (
    <List
      pagination={<Pagination />}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<CollectionLoanListFilter collectionGroups={collectionGroups} />}
      filter={{ enum_state: 'active,defaulted', 'exists[external_agency]': false }}
      filterDefaultValues={permissions.includes('CAN_VIEW_ALL_COLLECTION_LOANS') ? null : { 'collector.id': userId }}
      actions={<CollectionLoanListActions />}
      bulkActionButtons={<CollectionLoanListBulkActionButtons />}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <FunctionField sortBy="days_past_due" label="DPD" render={(record) =>
          <Chip avatar={<Avatar>{resolveCollectionGroup(record.days_past_due, collectionGroups)}</Avatar>} label={record.days_past_due} />
        }/>
        <FunctionField onClick={(e) => e.stopPropagation()} label="Client" render={({ user_id, user_first_name = '', user_last_name = '', user_phone_number }) => <>
          <Link color={'secondary'} href={`#users/${user_id}`}>{`#${user_id} ${user_first_name} ${user_last_name}`}</Link>
          <br />
          <Call userId={user_id} userPhone={user_phone_number}>{formatPhoneNumber(user_phone_number)}</Call>
        </>} />
        <TextField source="assigned_collector" label="Collector" sortable={false} />
        <NumberField source="accrued_total" label="Debt amount" options={{ style: 'currency', currency: CURRENCY }} sortable={false} />
        <NumberField source="tenor" />
        <ChipField size="small" source="state" sortable={false} />
        <FunctionField
          source="created_at"
          render={(record) => <DateTimeBoxComponent value={record.created_at} />}
          label="Created at" />
        <FunctionField
          source="matured_at"
          render={(record) => <DateTimeBoxComponent value={record.matured_at} />}
          label="Matured at" />
        <FunctionField
          source="lastContactCall.createdAt"
          render={(record) => <DateTimeBoxComponent value={record.last_call_date} />}
          label="Last call date" />
        <FunctionField
          render={(record) =>
            <Box>
              {record.last_call_contact_type && <Box whiteSpace={'nowrap'}><b>Contact type:</b> {record.last_call_contact_type}</Box>}
              {record.last_call_contact_result && <Box whiteSpace={'nowrap'}><b>Contact result:</b> {record.last_call_contact_result}</Box>}
              {record.last_call_promise_type && <Box whiteSpace={'nowrap'}><b>Promise Type:</b> {record.last_call_promise_type}</Box>}
            </Box>}
          label="Last call result"
        />
        <FunctionField
          source="lastPromiseToPay.promiseDate"
          render={(record) => <DateTimeBoxComponent value={record.last_promise_to_pay_date} />}
          label="Last promise to pay date" />
        <FunctionField
          source={undefined}
          render={(record) => <DateTimeBoxComponent value={record.last_extension_date} />}
          label="Last extension date" />
        <FunctionField
          source={undefined}
          render={(record) => <DateTimeBoxComponent value={record.last_payment_date} />}
          label="Last payment date" />
      </Datagrid>
    </List>
  );
};
