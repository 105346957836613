import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  TablePagination,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';

export const ResidencesTable = ({ userId, refreshedAt }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [residences, setResidences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  useEffect(() => {
    dataProvider.getOne('users', { id: userId })
      .then(({ data }) => {
        setResidences(data.residences);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(residences)) return null;

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Region, District</TableCell>
            <TableCell>City</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Residence source</TableCell>
            <TableCell>Residence type</TableCell>
            <TableCell>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {residences.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.region} <br /> {row.district}</TableCell>
              <TableCell>{row.city}</TableCell>
              <TableCell>{row.postal_code} {row.street}, {row.external_house}, {row.flat}</TableCell>
              <TableCell>{row.duration}</TableCell>
              <TableCell>{row.residence_source}</TableCell>
              <TableCell>{row.residence_type}</TableCell>
              <TableCell>{row.note}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={residences.length}
        rowsPerPage={ perPage }
        page={ page - 1 }
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
    </>
  );
};

ResidencesTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
