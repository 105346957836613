import { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Checkbox, FormControlLabel,
  Select, MenuItem, FormControl, InputLabel, Typography,
} from '@material-ui/core';
import { DateTimePicker, DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import { timezone } from '../../utils';
import { useHandbook } from '../../hooks';

export const AddIncomeDialog = ({ isOpened, onClose, onSubmit, record }) => {
  const { choices: banksOptions } = useHandbook('payment/bank_list');
  const [paymentType, setPaymentType] = useState('');
  const [amount, setAmount] = useState(0);
  const [paidVia, setPaidVia] = useState('');
  const [receivedAt, setReceivedAt] = useState(new Date());
  const [time, setTime] = useState(false);

  const correctTime = new Date(record && record.disbursed_at) < receivedAt;
  const isSubmitDisabled = !paymentType || !amount || !receivedAt || !correctTime;

  const handleClearFields = () => {
    setPaymentType('');
    setAmount(0);
    setPaidVia('');
    setReceivedAt(new Date());
    setTime(false);
  };

  const handlePaymentTypeChange = (e) => setPaymentType(e.target.value);
  const handlePaidViaChange = (e) => setPaidVia(e.target.value);
  const handleAmountChange = (e) => setAmount(parseFloat(e.target.value));
  const handleSubmit = () => {
    onSubmit(amount, receivedAt, paidVia, paymentType);
    handleClearFields();
  };
  const handleReceivedAtTimeChange = (value) => setReceivedAt(timezone.unshift(value));
  const handleReceivedAtChange = (value) => setReceivedAt(timezone.setTime(value));

  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Add loan income</DialogTitle>
      <DialogContent style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <FormControl size={'small'} variant={'outlined'} fullWidth>
          <InputLabel id={'income-payment-type-input-label'}>Payment type</InputLabel>
          <Select
            labelId={'income-payment-type-input-label'}
            id={'income-payment-type-input'}
            label={'Payment type'}
            value={paymentType}
            onChange={handlePaymentTypeChange}
          >
            <MenuItem value={'full_repayment'}>Full repayment</MenuItem>
            <MenuItem value={'partial_repayment'}>Partial repayment</MenuItem>
            <MenuItem value={'repayment_for_extension'}>Repayment for extension</MenuItem>
          </Select>
        </FormControl>
        <TextField size={'small'} variant={'outlined'} type={'number'} fullWidth label={'Amount'} value={amount} onChange={handleAmountChange} />
        <FormControl size={'small'} variant={'outlined'} fullWidth>
          <InputLabel id={'income-payment-type-input-label'}>Paid via</InputLabel>
          <Select label={'Paid via'} onChange={handlePaidViaChange} value={paidVia}>
            <MenuItem value={'other'}>Other</MenuItem>
            {banksOptions.map((option, idx) => <MenuItem key={idx} value={option.id}>{option.name}</MenuItem>)}
          </Select>
        </FormControl>
        {time
          ? (
            <DateTimePicker
              size={'small'}
              label={'Received at'}
              fullWidth
              value={receivedAt && timezone.shift(receivedAt)}
              onChange={handleReceivedAtTimeChange}
              inputVariant={'outlined'}
            />
          )
          : (
            <DatePicker
              size={'small'}
              label={'Received at'}
              fullWidth
              value={receivedAt && timezone.shift(receivedAt)}
              onChange={handleReceivedAtChange}
              inputVariant={'outlined'}
            />
          )
        }
        {receivedAt && !correctTime && <Typography variant={'subtitle1'} color={'error'}>Income date is earlier than loan disbursement date</Typography>}
        <FormControlLabel
          control={<Checkbox checked={time} onChange={() => setTime(() => !time)} />}
          label={'Change income time'}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleSubmit} color={'primary'} disabled={isSubmitDisabled}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

AddIncomeDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  record: PropTypes.shape({
    disbursed_at: PropTypes.string,
  }),
};
