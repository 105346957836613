import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, usePermissions, useQuery } from 'react-admin';
import {
  Box, Typography, CircularProgress, Checkbox, Button, FormControl, InputLabel, Select, MenuItem, ListItemText, Card,
  ButtonGroup, CardContent, useTheme,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Alert } from '@material-ui/lab';
import { func, array } from 'prop-types';

import { useHandbook } from '../../../hooks';
import { formatLabel, getFormattedDay } from '../../../utils';
import { ClickableGrid } from '../../ClickableGrid';

const Actions = ({ onActionClick, markTypes }) => {
  const handleMarkAsChange = (value) => () => onActionClick(value);
  return (
    <Box display={'flex'} gridGap={'20px'} alignItems={'center'}>
      <Typography variant={'subtitle1'}>Mark and save as: </Typography>
      <ButtonGroup size={'small'} variant={'contained'}>
        {markTypes.map(({ color, value }, key) =>
          <Button key={key} onClick={handleMarkAsChange(value)} value={value} style={{ backgroundColor: color, minWidth: '80px' }}>{formatLabel(value)}</Button>)}
      </ButtonGroup>
    </Box>
  );
};

Actions.propTypes = {
  onActionClick: func,
  markTypes: array,
};

export const WorkSchedule = () => {
  const notify = useNotify();
  const theme = useTheme();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();
  const isWSEditPermitted = permissions.includes('CAN_WORK_SCHEDULE_EDIT');
  const { data: roles } = useHandbook('admin_auth_roles');
  const { data: collectionGroups } = useHandbook('collection_groups');

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const dates = Array.from(Array(new Date(year, month, 0).getDate()), (_, index) => index + 1);
  const [rolesFilter, setRolesFilter] = useState([]);
  const [collectionGroupsFilter, setCollectionGroupsFilter] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [adminsData, setAdminsData] = useState();
  const [adminsLoaded, setAdminsLoaded] = useState(false);
  const [adminsTotal, setAdminsTotal] = useState();
  const [adminsError, setAdminsError] = useState();

  const { data: markedCells = [], loaded: markedCellsLoaded, error: markedCellsError } = useQuery({
    type: 'getList',
    resource: 'work_schedules',
    payload: {
      pagination: {},
      sort: {},
      filter: {
        page: 1,
        items_per_page: 1000,
        'day[after]': getFormattedDay(year, month, dates[0]),
        'day[before]': getFormattedDay(year, month, dates[dates.length - 1]),
      },
    },
    deps: [rolesFilter, collectionGroupsFilter],
  });

  const admins = (!adminsError && adminsLoaded) ? Object.values(adminsData) : [];
  useEffect(() => {
    dataProvider
      .getList('admins', {
        filter: { 'roles.code': rolesFilter, 'collection_groups.code': collectionGroupsFilter },
        pagination: { page, perPage },
        sort: {},
      })
      .then(({ data }) => {
        setAdminsData(data);
        setAdminsTotal(data.length);
      })
      .catch((error) => {
        setAdminsError(error);
      })
      .finally(() => setAdminsLoaded(true));
  }, [dataProvider, permissions, rolesFilter, collectionGroupsFilter]);

  const getDaysFromSelectedValue = (value) => {
    const date = new Date(value);
    const month = date.getMonth() + 1;
    setYear(date.getFullYear());
    setMonth(month);
  };

  const handleSelectCell = (payload) => {
    const { day, id, isMarked } = payload;
    const selectedTemp = [...selected];
    const dayFormatted = getFormattedDay(year, month, day);
    if (isMarked) {
      selectedTemp.push({ admin: id, day: dayFormatted });
    } else {
      const index = selectedTemp.findIndex((item) => item.admin === id && item.day === dayFormatted);
      selectedTemp.splice(index, 1);
    }
    setSelected(selectedTemp);
  };

  const markDays = async (type) => {
    const items = selected.map((day) => ({ ...day, type }));
    setSelected([]);
    await dataProvider.query('work_schedules/items', { method: 'POST', body: JSON.stringify({ items }) })
      .then(() => notify(`Marked as ${type} successfully`, 'success'))
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  };

  const handlePerPageChange = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleSetPage = (page) => setPage(page);

  const handleSelectMonthFwd = () => {
    if (month === 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const handleSelectMonthBack = () => {
    if (month === 1) {
      setMonth(12);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const handleRolesFilterChange = (event) => {
    setRolesFilter(event.target.value);
    setPage(1);
  };

  const handleCollectionGroupsFilterChange = (event) => {
    setCollectionGroupsFilter(event.target.value);
    setPage(1);
  };

  const markTypes = [
    { color: theme.palette.success.light, value: 'work' },
    { color: theme.palette.warningAlt.light, value: 'holiday' },
    { color: theme.palette.error.light, value: 'absence' },
    { color: theme.palette.blue.light, value: 'ill' },
    { color: theme.palette.purple.light, value: 'vacation' },
  ];

  if (adminsError) return notify(`Error: ${adminsError.message}`, 'error');

  return (
    <Box display={'flex'} flexDirection={'column'} gridGap={'20px'}>
      <Card>
        <CardContent style={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'space-between' }}>
          <Box display={'grid'} gridGap={'15px'} gridTemplateColumns={'repeat(2, 240px)'}>
            <FormControl size={'small'} variant={'outlined'} fullWidth>
              <InputLabel id={'roles-mutiple-checkbox-label'}>Roles</InputLabel>
              <Select
                label={'Roles'}
                labelId={'roles-mutiple-checkbox-label'}
                id={'roles-mutiple-checkbox'}
                multiple
                value={rolesFilter}
                onChange={handleRolesFilterChange}
                renderValue={(selected) => selected.join(', ')}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.code}>
                    <Checkbox checked={rolesFilter.indexOf(role.code) > -1} />
                    <ListItemText primary={role.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size={'small'} variant={'outlined'} fullWidth>
              <InputLabel id={'collection-groups-mutiple-checkbox-label'}>Groups</InputLabel>
              <Select
                label={'Groups'}
                labelId={'collection-groups-mutiple-checkbox-label'}
                id={'collection-groups-mutiple-checkbox'}
                multiple
                value={collectionGroupsFilter}
                onChange={handleCollectionGroupsFilterChange}
                renderValue={(selected) => selected.join(', ')}
              >
                {collectionGroups.map((role) => (
                  <MenuItem key={role.id} value={role.code}>
                    <Checkbox checked={collectionGroupsFilter.indexOf(role.code) > -1} />
                    <ListItemText primary={role.code} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {isWSEditPermitted && <Actions onActionClick={markDays} markTypes={markTypes} />}
        </CardContent>
        <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ButtonGroup color={'secondary'} variant={'outlined'} size={'small'}>
            <Button onClick={handleSelectMonthBack}>
              <ArrowBackIosIcon />
            </Button>
            <DatePicker
              size={'small'}
              inputVariant={'outlined'}
              label={'Select year and month'}
              value={new Date(year, month, 0)}
              views={['year', 'month']}
              onChange={(value) => getDaysFromSelectedValue(value)}
            />
            <Button onClick={handleSelectMonthFwd}>
              <ArrowForwardIosIcon />
            </Button>
          </ButtonGroup>
        </CardContent>
      </Card>
      {markedCellsError && <Alert color={'error'}>{'Can\'t load schedule:('}</Alert>}
      {(markedCellsLoaded && !markedCellsError && dates.length !== 0)
        ? <ClickableGrid
          total={adminsTotal}
          onSetPage={handleSetPage}
          onPerPageChange={handlePerPageChange}
          onSelectCell={handleSelectCell}
          rowsData={admins}
          columnsData={dates}
          markedCells={markedCells}
          year={year}
          month={month}
          markTypes={markTypes}
          page={page}
          perPage={perPage}
        />
        : <CircularProgress />}
    </Box>
  );
};
