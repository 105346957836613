import React, { useEffect, useState } from 'react';
import {
  DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, Dialog, Box,
  ListSubheader, ListItemText, TextField, DialogActions, Button, FormHelperText, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDataProvider } from 'react-admin';
import { DatePicker } from '@material-ui/pickers';

import { formatPhoneNumber } from '../../utils';
import { useCollectionGroups, useHandbook } from '../../hooks';
import { CURRENCY } from '../../constants';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

import { useDialogStyles } from './useDialogStyle';

function isPromiseOfPayment (name) {
  return /promise/mui.test(name) && /pay/mui.test(name);
}

const CollectionCallFormDialogTypeRecursiveInput = ({
  contactsTree,
  onChange,
  onStepChange,
  error,
  userHasActivePromise,
  depth,
}) => {
  const [value, setValue] = useState(null);

  const contact = contactsTree.find((contact) => contact.id === value) || null;

  const handleChange = (e) => {
    setValue(e?.target?.value ? parseInt(`${e.target.value}`) : null);
  };

  useEffect(() => {
    const valueContact = contactsTree.find((contact) => contact.id === value) || null;

    if (value !== null && valueContact !== null && valueContact.children.length === 0) {
      onChange(valueContact);
    } else {
      onChange(null);
    }

    onStepChange(valueContact);
    // eslint-disable-next-line
  }, [value]);

  if (contactsTree.length === 0) {
    return null;
  }

  let fixedErrorText = error;

  if (error === 'Contact is required') {
    fixedErrorText = `${contactsTree[0].type_name} is required`;
  }

  return (
    <>
      <FormControl fullWidth variant={'outlined'} size={'small'}>
        <InputLabel id={`collection-call-contact-${depth}-select-label`}>{contactsTree[0].type_name}</InputLabel>
        <Select
          label={contactsTree[0].type_name}
          labelId={`collection-call-contact-${depth}-select-label`}
          onChange={handleChange}
          value={value || ''}
          error={contact === null && !!error}
        >
          {contactsTree.map((contact) => (
            <MenuItem
              key={contact.id}
              value={contact.id}
              disabled={userHasActivePromise && isPromiseOfPayment(contact.value_name)}
            >
              {contact.value_name} {userHasActivePromise && isPromiseOfPayment(contact.value_name) && '(has active promise)'}
            </MenuItem>
          ))}
        </Select>

        {contact === null && error && (
          <FormHelperText error>{fixedErrorText}</FormHelperText>
        )}
      </FormControl>

      {contact !== null && (
        <CollectionCallFormDialogTypeRecursiveInput
          contactsTree={contact.children}
          onChange={onChange}
          onStepChange={onStepChange}
          error={error}
          depth={depth + 1}
          userHasActivePromise={userHasActivePromise}
        />
      )}
    </>
  );
};

CollectionCallFormDialogTypeRecursiveInput.propTypes = {
  contactsTree: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    type_value: PropTypes.number.isRequired,
    type_name: PropTypes.string.isRequired,
    value_name: PropTypes.string.isRequired,
    children: PropTypes.array,
  })),
  onChange: PropTypes.func,
  onStepChange: PropTypes.func,
  error: PropTypes.string,
  depth: PropTypes.number,
  userHasActivePromise: PropTypes.bool,
};

CollectionCallFormDialogTypeRecursiveInput.defaultProps = {
  depth: 0,
  error: null,
};

export const CollectionCallFormDialog = ({ userId, loanId, isOpened, onClose, onSubmit }) => {
  const classes = useDialogStyles();
  const dataProvider = useDataProvider();

  const [loans, setLoans] = useState([]);

  const { data: groups } = useCollectionGroups();
  const { data: motivators } = useHandbook('collection_motivators', { items_per_page: 1000 });
  const { data: phoneTypes } = useHandbook('phone_types');

  const phoneTypeMap = Object.fromEntries(phoneTypes.map(phoneType => [phoneType.code, phoneType]));

  const groupMotivators = motivators.filter((item) => item.parent === null).map((parent) => ({
    id: parent.id,
    parent: parent.parent,
    name: parent.name,
    enabled: parent.enabled,
    children: motivators.filter((item) => item.parent === parent.id).map((child) => ({
      id: child.id,
      parent: child.parent,
      name: child.name,
      enabled: child.enabled,
    })),
  }));

  const filledGroupMotivators = groupMotivators.filter(({ children }) => children.length > 0);

  const [userHasActivePromise, setUserHasActivePromise] = useState(false);
  const [userPhoneBooks, setUserPhoneBooks] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneBookId, setPhoneBookId] = useState(null);
  const [loan, setLoan] = useState(loanId);
  const [groupId, setGroupId] = useState(null);
  const [contact, setContact] = useState(null);
  const [contactStep, setContactStep] = useState(null);
  const [motivator, setMotivator] = useState(null);
  const [comment, setComment] = useState(null);
  const [promiseAmount, setPromiseAmount] = useState(null);
  const [promiseDatetime, setPromiseDatetime] = useState(null);

  const [errors, setErrors] = useState({});
  const [withPromise, setWithPromise] = useState(false);

  const [contactsTree, setContactsTree] = useState([]);

  const shouldSelectMotivator = Array.isArray(contact?.motivators) && contact.motivators.length > 0;

  const loanIds = loans.map(({ id }) => id);
  const selectedLoan = loan ? loans.find(({ id }) => id === loan) : null;
  const selectedGroup = groupId ? groups.find((group) => group.id === groupId) : null;

  useEffect(() => {
    if (isOpened) {
      setContact(null);
      setContactStep(null);
      setMotivator(null);
      setComment(null);
      setPromiseAmount(null);
      setPromiseDatetime(null);
      setWithPromise(false);
      setErrors({});

      if (!loan) {
        setContactsTree([]);
      }
    }
  }, [isOpened, loan]);

  useEffect(() => {
    if (userId) {
      dataProvider
        .getOne('users', { id: userId })
        .then(({ data }) => {
          setPhoneNumber(data.phone_number || null);
          setUserHasActivePromise(!!data.active_promise_amount);
        });

      dataProvider
        .getList('phone_books', {
          filter: { 'user.id': userId },
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'DESC' },
        })
        .then(({ data }) => {
          const existingPhoneNumbers = [];
          const uniquePhoneBooks = data.filter((phoneBook) => {
            const exists = existingPhoneNumbers.includes(phoneBook.phone_number);

            existingPhoneNumbers.push(phoneBook.phone_number);

            return !exists;
          });

          setUserPhoneBooks(uniquePhoneBooks);
        });
    } else {
      setPhoneNumber(null);
    }
  }, [dataProvider, userId]);

  useEffect(() => {
    setContactsTree([]);
    setContact(null);
    setMotivator(null);
    setWithPromise(false);

    if (groupId) {
      dataProvider
        .query(`collection_contacts/tree-by-group/${groupId}`, { method: 'GET' })
        .then(({ data }) => {
          setContactsTree(data);
        });
    }
  }, [dataProvider, groupId]);

  useEffect(() => {
    setMotivator(null);
  }, [contact]);

  useEffect(() => {
    dataProvider.getList('loans', {
      filter: { 'user.id': userId },
      pagination: { page: 1, perPage: 30 },
      sort: { field: 'id', order: 'DESC' },
    })
      .then(({ data }) => {
        setLoans(data);

        if (!loanId && data.length > 0) {
          setLoan(data[0].id);
        }
      });
  }, [dataProvider, loanId, userId]);

  useEffect(() => {
    const sortedByDpdGroups = [...groups];

    sortedByDpdGroups.sort((a, b) => {
      if (a.dpd_from === null) {
        return 1;
      }

      if (b.dpd_from === null) {
        return -1;
      }

      return Math.sign(a.dpd_from - b.dpd_from);
    });

    let groupByDpdId = sortedByDpdGroups[0]?.id || null;

    if (loan && loanIds.includes(loan) && groups.length > 0) {
      const selectedLoan = loans.find(({ id }) => id === loan);
      const groupByDpd = selectedLoan.days_past_due !== null
        ? sortedByDpdGroups.find(function ({ dpd_from, dpd_to }) {
          const from = dpd_from;
          const to = dpd_to === null ? 1000 : dpd_to;
          const dpd = selectedLoan.days_past_due;

          return dpd >= from && dpd <= to;
        })
        : null;

      if (groupByDpd) {
        groupByDpdId = groupByDpd.id;
      }
    }

    setGroupId(groupByDpdId);
  }, [loan, loans, loanIds, groups]);

  useEffect(() => {
    function foundContactTreeElement (tree, id) {
      if (!id) {
        return null;
      }

      if (!Array.isArray(tree)) {
        return null;
      }

      for (const el of tree) {
        if (el.id === id) {
          return el;
        }

        const foundChild = foundContactTreeElement(el.children, id);

        if (foundChild) {
          return foundChild;
        }
      }

      return null;
    }

    if (!contactStep) {
      setWithPromise(false);

      return;
    }

    let contactChain = [contactStep];

    while (contactChain[0].parent) {
      const foundParent = foundContactTreeElement(contactsTree, contactChain[0].parent);

      if (!foundParent) {
        break;
      }

      contactChain = [foundParent, ...contactChain];
    }

    const contactWithPromiseValue = contactChain.find((contact) => isPromiseOfPayment(contact.value_name)) || null;

    setWithPromise(contactWithPromiseValue !== null);
  }, [contactsTree, contactStep]);

  useEffect(() => {
    if (userPhoneBooks.length > 0) {
      setPhoneBookId(userPhoneBooks[0].id);
    }
  }, [userPhoneBooks]);

  const removeError = (...fields) => {
    setErrors(
      Object.fromEntries(
        Object
          .entries(errors)
          .filter(([name]) => fields.includes(name) === false),
      ),
    );
  };

  const handleChangePhoneNumber = (e) => {
    removeError('phoneNumber');
    setPhoneNumber(e.target.value || null);
    const [PBId] = userPhoneBooks.filter((book) => book.phone_number === e.target.value);
    setPhoneBookId(PBId?.id);
  };

  const handleChangeLoan = (e) => {
    removeError('loan');

    setLoan(e.target.value ? parseInt(`${e.target.value}`) : null);
  };

  const handleChangeContact = (contact) => {
    removeError('contact');

    setContact(contact);
  };

  const handleChangeContactStep = (contactStep) => {
    setContactStep(contactStep);
  };

  const handleChangeMotivator = (e) => {
    removeError('motivator');

    setMotivator(e.target.value ? parseInt(`${e.target.value}`) : null);
  };

  const handleChangeComment = (e) => {
    removeError('comment');

    setComment(e.target.value || null);
  };

  const handleChangePromiseAmount = (e) => {
    removeError('promiseAmount');

    setPromiseAmount(e.target.value ? parseFloat(`${e.target.value}`) : null);
  };

  const handleChangePromiseDatetime = (date) => {
    removeError('promiseDatetime');

    setPromiseDatetime(date);
  };

  const validate = () => {
    const formErrors = {};

    if (!phoneNumber) {
      formErrors.phoneNumber = 'Phone number is required';
    }

    if (!loan) {
      formErrors.loan = 'Loan is required';
    }

    if (!groupId) {
      formErrors.groupId = 'Group is required';
    }

    if (!contact) {
      formErrors.contact = 'Contact is required';
    }

    if (shouldSelectMotivator) {
      if (!motivator) {
        formErrors.motivator = 'Motivator is required';
      }
    }

    if (withPromise) {
      if (!promiseAmount) {
        formErrors.promiseAmount = 'Amount is required';
      } else if (promiseAmount < 0) {
        formErrors.promiseAmount = 'Amount should be positive';
      }

      if (!promiseDatetime) {
        formErrors.promiseDatetime = 'Date is required';
      }
    }

    const isValid = Object.keys(formErrors).length === 0;

    setErrors(formErrors);

    return isValid;
  };

  const handleSubmit = () => {
    if (validate()) {
      const phoneBook = userPhoneBooks.find((phoneBook) => phoneBook.phone_number === phoneNumber);

      dataProvider
        .query('collection_contact_calls', {
          method: 'POST',
          body: JSON.stringify({
            loan,
            contact: contact?.id,
            motivator,
            comment: (comment || '').trim() || null,
            promise_date: promiseDatetime ? promiseDatetime.toISOString() : null,
            promise_amount: promiseAmount,
            phone_number: phoneNumber,
            phone_type: phoneBook.type,
            phone_book_id: phoneBookId,
          }),
        })
        .then(() => onSubmit());
    }
  };

  const renderMotivatorOptions = (availableMotivators) => {
    const availableParentMotivators = new Set();

    for (const motivator of motivators) {
      if (availableMotivators.includes(motivator.id) && motivator.parent) {
        availableParentMotivators.add(motivator.parent);
      }
    }

    const motivatorOptions = [];

    for (const filledGroupMotivator of filledGroupMotivators) {
      if (availableParentMotivators.has(filledGroupMotivator.id) === false) {
        continue;
      }

      motivatorOptions.push(<ListSubheader key={filledGroupMotivator.id}>{filledGroupMotivator.name}</ListSubheader>);

      for (const child of filledGroupMotivator.children) {
        if (availableMotivators.includes(child.id) === false) {
          continue;
        }

        motivatorOptions.push(
          <MenuItem key={child.id} value={child.id}>
            <ListItemText>{child.name}</ListItemText>
          </MenuItem>,
        );
      }
    }

    return motivatorOptions;
  };

  const handleOnClose = (e, eventName) => {
    if (eventName === 'backdropClick') {
      return;
    }

    onClose();
  };

  let maxDateOffset = 0;
  const maxDate = new Date();

  switch (selectedGroup?.code) {
    case 'G0':
    case 'G2':
      maxDateOffset = 3;
      break;

    case 'G1':
      maxDateOffset = 2;
      break;

    case 'G3':
      maxDateOffset = 4;
      break;

    case 'G4':
    case 'G5':
    case 'G6':
    default:
      maxDateOffset = 6;
      break;
  }

  if (selectedLoan?.days_past_due > 0 && selectedGroup?.dpd_to > 0) {
    maxDateOffset = Math.min(selectedGroup.dpd_to - selectedLoan.days_past_due, maxDateOffset);
  }

  maxDate.setDate(maxDate.getDate() + maxDateOffset);

  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpened} onClose={handleOnClose} aria-labelledby={'collection-call-form-dialog-title'}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id={'collection-call-form-dialog-title'}>Create new collection call</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <FormControl fullWidth variant={'outlined'} size={'small'}>
              <InputLabel id={'collection-call-phone-number-select-label'}>Phone number</InputLabel>
              <Select
                label={'Phone number'}
                labelId={'collection-call-phone-number-select-input'}
                value={phoneNumber || ''}
                onChange={handleChangePhoneNumber}
                error={!!errors.phoneNumber}
              >
                {userPhoneBooks.map(({ id, phone_number, type }) => (
                  <MenuItem key={id} value={phone_number}>
                    <div>
                      <b>{phoneTypeMap[type]?.name || type}</b>
                      <br />
                      <span>{formatPhoneNumber(phone_number)}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
              {errors.loan && (
                <FormHelperText error>{errors.loan}</FormHelperText>
              )}
            </FormControl>

            {
              (!loanId || !loanIds.includes(loanId))
                ? (
                  <FormControl fullWidth variant={'outlined'} size='small'>
                    <InputLabel id={'collection-call-loan-id-select-label'}>Loan</InputLabel>
                    <Select
                      label={'Loan'}
                      labelId={'collection-call-loan-id-select-input'}
                      value={loan || ''}
                      onChange={handleChangeLoan}
                      error={!!errors.loan}
                    >
                      {loans.map((loan) => (
                        <MenuItem key={loan.id} value={loan.id}>
                          <DateTimeBoxComponent label={`#${loan.id} ${loan.state}, created on`} value={loan?.created_at} inline/>
                          <Typography variant={'subtitle1'}>{`, ${loan.principal}$, ${loan.tenor} days`}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.loan && (
                      <FormHelperText error>{errors.loan}</FormHelperText>
                    )}
                  </FormControl>
                )
                : null
            }

            <CollectionCallFormDialogTypeRecursiveInput
              contactsTree={contactsTree}
              onChange={handleChangeContact}
              onStepChange={handleChangeContactStep}
              error={errors.contact}
              userHasActivePromise={userHasActivePromise}
            />

            {shouldSelectMotivator && (
              <FormControl variant={'outlined'} size={'small'}>
                <InputLabel id={'collection-call-group-motivator-label'}>Motivator</InputLabel>

                <Select
                  id={'collection-call-group-motivator-input'}
                  label={'Motivator'}
                  value={motivator || ''}
                  fullWidth
                  onChange={handleChangeMotivator}
                  error={!!errors.motivator}
                >
                  {renderMotivatorOptions(contact.motivators)}
                </Select>

                {errors.motivator && (
                  <FormHelperText error>{errors.motivator}</FormHelperText>
                )}
              </FormControl>
            )}

            {withPromise && (
              <>
                <div className={classes.formControl}>
                  <TextField
                    type={'number'}
                    variant={'outlined'}
                    label={`Promise amount, ${CURRENCY}`}
                    value={promiseAmount}
                    onChange={handleChangePromiseAmount}
                    error={!!errors.promiseAmount}
                    helperText={errors.promiseAmount}
                    size={'small'}
                    fullWidth
                  />
                </div>

                <div className={classes.formControl}>
                  <DatePicker
                    label={'Promise date'}
                    inputVariant={'outlined'}
                    closeOnSelect
                    disablePast
                    maxDate={maxDate}
                    clearable
                    value={promiseDatetime}
                    onChange={handleChangePromiseDatetime}
                    error={!!errors.promiseDatetime}
                    helperText={errors.promiseDatetime}
                    size={'small'}
                    fullWidth
                  />
                </div>
              </>
            )}

            <TextField
              id={'collection-call-group-comment-input'}
              className={classes.formControl}
              variant={'outlined'}
              label={'Comment'}
              multiline
              value={comment || ''}
              onChange={handleChangeComment}
              error={!!errors.comment}
              helperText={errors.comment}
              size={'small'}
            />
          </Box>
        </DialogContent>

        <DialogActions className={classes.modalHorizontalPadding}>
          <span className={classes.colorTransparent}>{selectedGroup?.code || 'UN'}</span>
          <Button onClick={onClose} color={'primary'}>Cancel</Button>
          <Button type={'submit'} color={'primary'}>Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

CollectionCallFormDialog.propTypes = {
  userId: PropTypes.number.isRequired,
  loanId: PropTypes.number,
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
