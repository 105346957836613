import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import { timezone } from '../../utils';

export const UserChangeMoratoriumTransitionDialog = ({ isOpened, onClose, onSubmit, value }) => {
  const [moratoriumTillDate, setMoratoriumTillDate] = useState(value ?? null);
  const handleMoratoriumSubmit = () => onSubmit(moratoriumTillDate);
  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Change moratorium</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, choose moratorium till date</DialogContentText>
        <DateTimePicker
          fullWidth
          value={moratoriumTillDate ? timezone.shift(new Date(moratoriumTillDate)) : null}
          onChange={value => setMoratoriumTillDate(value ? timezone.unshift(value) : null)}
          clearable
          inputVariant={'outlined'}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleMoratoriumSubmit} color={'primary'}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

UserChangeMoratoriumTransitionDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};
