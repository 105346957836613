import { useState, useEffect } from 'react';
import { useNotify, useDataProvider, usePermissions, useRefresh } from 'react-admin';
import {
  Dialog, DialogTitle, DialogContent, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Popover,
  Button, CircularProgress, Chip, ButtonGroup,
} from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import RefreshIcon from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types';

import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

export const DialerProcessesTableDialog = ({ isOpened, onClose, id = null }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [refreshedAt, setRefreshedAt] = useState();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();
  const [processes, setProcesses] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);
  const [PopoverAnchor, setPopoverAnchor] = useState(null);

  const isRefreshDisabled = permissions.indexOf('CAN_AGREEMENT_EDIT') === -1 || loading;

  const openPopover = (event) => setPopoverAnchor(event.currentTarget);

  const applyTransition = (id, name, params, isForce) => {
    setLoading(true);
    dataProvider
      .query(`dialer_processes/${id}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({
          name,
          params: {
            ...params,
            params: [params],
            skip_id_restore: isForce,
          },
        }),
      })
      .then(() => setRefreshedAt(new Date().valueOf()))
      .catch((error) => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };
  const handlePopoverAnchorClose = () => setPopoverAnchor(null);

  useEffect(() => {
    if (id) {
      dataProvider
        .getList('dialer_processes', {
          filter: { campaign: id },
          pagination: { page, perPage },
          sort: { field: 'id', order: 'DESC' },
        })
        .then((res) => {
          if (res.data) {
            setProcesses(res.data);
          }
          if (res.total) {
            setTotal(res.total);
          }
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, [dataProvider, id, page, perPage, refreshedAt]);

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Dialog open={isOpened} onClose={onClose} fullWidth maxWidth='lg'>
      <DialogTitle>Dialer processes</DialogTitle>
      <DialogContent>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Campaign ID</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Last error</TableCell>
              <TableCell>Scheduled at</TableCell>
              <TableCell>Updated at</TableCell>
              <TableCell>Finished at</TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processes?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.campaign_id}</TableCell>
                <TableCell>
                  <Chip size='small' label={row.state} />
                </TableCell>
                <TableCell>{row.last_error}</TableCell>
                <TableCell><DateTimeBoxComponent inline value={row?.scheduled_at} /></TableCell>
                <TableCell><DateTimeBoxComponent inline value={row?.updated_at} /></TableCell>
                <TableCell><DateTimeBoxComponent inline value={row?.finished_at} /></TableCell>
                <TableCell align='right'>
                  {loading
                    ? <CircularProgress size={20} />
                    : (
                      <ButtonGroup size={'small'} variant={'outlined'} color={'secondary'}>
                        <Button
                          disabled={row.enabled_transitions.indexOf('renew') === -1 || isRefreshDisabled}
                          onClick={openPopover}>
                          <RefreshIcon />
                        </Button>
                        <Button
                          disabled={row.enabled_transitions.indexOf('stop') === -1 || loading}
                          onClick={() => applyTransition(row.id, 'stop')}>
                          <StopIcon />
                        </Button>
                      </ButtonGroup>
                    )}
                  <Popover
                    open={Boolean(PopoverAnchor)}
                    onClose={handlePopoverAnchorClose}
                    anchorEl={PopoverAnchor}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}>
                    <ButtonGroup style={{ margin: '15px' }} variant={'outlined'} size={'small'} color={'secondary'}>
                      <Button
                        onClick={() => {
                          handlePopoverAnchorClose();
                          applyTransition(row.id, 'renew', row?.params?.dialer_id, false);
                        }}>
                        Renew
                      </Button>
                      <Button
                        onClick={() => {
                          handlePopoverAnchorClose();
                          applyTransition(row.id, 'renew', row?.params?.dialer_id, true);
                        }}>
                        Force
                      </Button>
                    </ButtonGroup>
                  </Popover>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component='div'
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onPageChange={(e, page) => setPage(page + 1)}
          onRowsPerPageChange={(e) => {
            setPerPage(parseInt(e.target.value, 10));
            setPage(1);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

DialerProcessesTableDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.number,
};
