import { maxLength, minLength, required, TextInput } from 'react-admin';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { formatDatetime, phoneNumber } from '../../../utils';

export const BlackListForm = ({ record }) => {
  const isFieldDisabled = !!record.user_id;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'subtitle1'}>{Object.keys(record).length > 0 ? 'Edit' : 'Create'} blacklist user {record.user_id}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          fullWidth
          size={'small'}
          variant={'outlined'}
          source={'nida_id'}
          disabled={isFieldDisabled}
          validate={[
            maxLength(23, 'Nida Id length should be 23 symbols'),
            minLength(23, 'Nida Id length should be 23 symbols'),
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput size={'small'} variant={'outlined'} fullWidth source={'first_name'} disabled={isFieldDisabled} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput size={'small'} variant={'outlined'} fullWidth source={'last_name'} disabled={isFieldDisabled} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput fullWidth source={'phone_number'}
          placeholder={phoneNumber.placeholder}
          parse={phoneNumber.parser}
          format={phoneNumber.formatter}
          size={'small'}
          variant={'outlined'}
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput size={'small'} variant={'outlined'} fullWidth source={'email'} disabled={isFieldDisabled} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          disabled={isFieldDisabled}
          fullWidth
          source={'contact_phone_number'}
          parse={phoneNumber.parser}
          format={phoneNumber.formatter}
          size={'small'}
          variant={'outlined'}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          disabled={isFieldDisabled}
          fullWidth
          source={'company_phone_number'}
          parse={phoneNumber.parser}
          format={phoneNumber.formatter}
          size={'small'}
          variant={'outlined'}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput format={v => isNaN(Date.parse(v)) ? null : formatDatetime(v)} size={'small'} variant={'outlined'} fullWidth source={'created_at'} label={'Date'} disabled/>
      </Grid>
      <Grid item xs={12}>
        <TextInput disabled={isFieldDisabled} size={'small'} variant={'outlined'} multiline fullWidth source={'comment'} validate={required('The comment is required')} />
      </Grid>
    </Grid>
  );
};

BlackListForm.propTypes = {
  record: PropTypes.object,
};
