import { Box, Typography } from '@material-ui/core';
import { string, bool, oneOfType, node } from 'prop-types';

import { DATE_FORMAT, TIME_FORMAT_SECONDS } from '../../constants';
import { dateNewFormat, timeFormat } from '../../utils';

export const DateTimeBoxComponent = ({ label, value, inline = false, isShowTime = true }) => {
  return (
    <Box display={'flex'} gridGap={'10px'} alignItems={'center'} justifyContent={(label && inline) ? 'flex-start' : 'space-between'}>
      {label && <Typography variant={'subtitle2'}>{label}</Typography>}
      {value
        ? <Box display={'flex'} flexDirection={inline ? 'row' : 'column'} gridGap={inline ? '5px' : 0}>
          <Box whiteSpace={'nowrap'}>{dateNewFormat(DATE_FORMAT).format(new Date(value))}</Box>
          {isShowTime && <Box whiteSpace={'nowrap'}>{timeFormat(TIME_FORMAT_SECONDS).format(new Date(value))}</Box>}
        </Box>
        : ' '}
    </Box>
  );
};

DateTimeBoxComponent.propTypes = {
  label: oneOfType([string, node]),
  value: string,
  inline: bool,
  isShowTime: bool,
};
