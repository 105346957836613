import React from 'react';
import PropTypes from 'prop-types';
import { Edit, SaveButton, DeleteButton, Toolbar, FormWithRedirect, BooleanInput, TextInput } from 'react-admin';
import { makeStyles, Box, Paper, Grid, Divider, Typography, Tabs, Tab } from '@material-ui/core';

import permissionGroups from './permissionGroups';
import TabPanel from './TabPanel';
import CheckboxListInput from './CheckboxListInput';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  tabLink: {
    minWidth: 100,
    paddingLeft: 12,
    paddingRight: 12,
  },
}));

const FormWrapper = ({ permissions, ...props }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const classes = useStyles();

  const isEditable = permissions.indexOf('CAN_ROLE_EDIT') !== -1;

  const handleChange = (e, value) => setActiveTab(() => value);

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Paper>
                <Box p={ 2 }>
                  <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom={ false }>
                      {`Role code: ${formProps.record.code}`}
                    </Typography>
                    <TextInput source="name" fullWidth={ true } helperText={ false } />
                    <BooleanInput label="Is enabled" source="is_enabled" helperText={ false } />
                    <Typography variant="caption">Set permissions for this role in each tab and save them</Typography>
                  </Grid>
                </Box>
                <Divider />
                <Tabs value={activeTab} variant="scrollable" scrollButtons="auto" onChange={handleChange}>
                  {permissionGroups.map((group) => (
                    <Tab key={group.name} label={group.name} className={classes.tabLink}/>
                  ))}
                </Tabs>
                {permissionGroups.map((group, index) => (
                  <TabPanel key={group.name} value={activeTab} index={index}>
                    <CheckboxListInput source="permissions" choices={group.permissions} />
                  </TabPanel>
                ))}
                <Toolbar {...props} className={classes.toolbar}>
                  <SaveButton
                    disabled={ !isEditable }
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                  <DeleteButton
                    undoable={ false }
                    disabled={ !isEditable }
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )
      }
    />
  );
};

FormWrapper.propTypes = {
  permissions: PropTypes.array,
};

const RoleEdit = ({ permissions = [], ...props }) => (
  <Edit component="div" undoable={ false } {...props}>
    <FormWrapper permissions={ permissions } />
  </Edit>
);

RoleEdit.propTypes = {
  permissions: PropTypes.array,
};

export default RoleEdit;
