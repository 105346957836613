import React from 'react';
import { Edit } from 'react-admin';
import PropTypes from 'prop-types';

import { MassSendingFormWrapper } from './MassSendingFormWrapper';

export const MassSendingCampaignEdit = ({ permissions = [], ...props }) => (
  <Edit component={'div'} mutationMode={'pessimistic'} {...props}>
    <MassSendingFormWrapper permissions={permissions} />
  </Edit>
);

MassSendingCampaignEdit.propTypes = {
  permissions: PropTypes.array,
};
