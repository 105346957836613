import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  TextInput,
  SelectInput,
  SaveButton,
  DeleteButton,
  FormWithRedirect,
  required,
  email,
  maxLength,
} from 'react-admin';
import { Box, Paper, Grid, Divider, Typography } from '@material-ui/core';

import { phoneNumber } from '../../../utils';

const FormWrapper = ({ save, ...props }) => {
  const statusChoices = [
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];

  return (
    <FormWithRedirect
      save={ (data, ...rest) => {
        save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest]);
      } }
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={ false }>Update External collection agency</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput source="name" validate={[maxLength(60), required()]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="status" validate={[required()]} choices={statusChoices} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        source="phone"
                        fullWidth
                        helperText={false}
                        placeholder={phoneNumber.placeholder}
                        parse={phoneNumber.parser}
                        format={phoneNumber.formatter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput label="Email" source="email" type="email" validate={[email('Invalid email')]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInput source="details" validate={[maxLength(255)]} fullWidth />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" justifyContent="space-between" padding={2}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                  <DeleteButton
                    undoable={ false }
                    basePath={ formProps.basePath }
                    record={ formProps.record }
                    resource={ formProps.resource }
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    template_key: PropTypes.string,
  }),
};

const ExternalAgenciesEdit = ({ permissions = [], ...props }) => (
  <Edit component="div" undoable={ false } {...props}>
    <FormWrapper permissions={ permissions } />
  </Edit>
);

ExternalAgenciesEdit.propTypes = {
  permissions: PropTypes.array,
};

export default ExternalAgenciesEdit;
