import React, { useState } from 'react';
import { Edit, FormWithRedirect } from 'react-admin';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { invalidateCache } from '../../../hooks';

import { TemplateForm } from './TemplateForm';

const FormWrapper = ({ save, ...props }) => {
  const [selectedRolesStr, setSelectedRolesStr] = useState([]);

  const handleSave = (data, ...rest) => {
    save(...[{ ...data, manual_sending_roles: selectedRolesStr, _params: { method: 'PATCH' } }, ...rest]);
    invalidateCache('admin/templates');
  };

  return (
    <Grid container spacing={2} alignItems={'center'}>
      <Grid item xs={12}>
        <FormWithRedirect
          style={{ height: '100%', maxHeight: '100%' }}
          toolbar={false}
          variant={'outlined'}
          save={handleSave}
          {...props}
          render={(formProps) => <TemplateForm formProps={formProps} record={props.record} {...props} selectedRolesStr={selectedRolesStr}
            setSelectedRolesStr={setSelectedRolesStr} />}
        />
      </Grid>
    </Grid>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.any,
  record: PropTypes.any,
};

export const TemplateEdit = ({ permissions = [], ...props }) => (
  <Edit style={{ height: '100%', maxHeight: '100%' }} undoable={false} {...props}>
    <FormWrapper permissions={permissions} {...props} />
  </Edit>
);

TemplateEdit.propTypes = {
  permissions: PropTypes.array,
};
