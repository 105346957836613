import { useState } from 'react';
import {
  DialogTitle, DialogContent, DialogContentText, Select, MenuItem, DialogActions, Button, Dialog,
} from '@material-ui/core';
import PropTypes from 'prop-types';

export const StartProcessingTransitionDialog = ({ isOpened, onClose, onSubmit }) => {
  const [decisionEngineId, setDecisionEngineId] = useState();

  const handleSubmit = () => onSubmit(decisionEngineId);

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Start processing</DialogTitle>
      <DialogContent>
        <DialogContentText>Please choose decision engine</DialogContentText>
        <Select
          variant={'outlined'}
          id={'decision-engine-select'}
          value={decisionEngineId || ''}
          onChange={e => setDecisionEngineId(e.target.value)}
          fullWidth
        >
          <MenuItem value={'manual'}>Manual</MenuItem>
          <MenuItem value={'aventus-de'}>Aventus DE</MenuItem>
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleSubmit} color={'primary'} disabled={!decisionEngineId}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

StartProcessingTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
