import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDataProvider, useNotify } from 'react-admin';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Chip, TablePagination } from '@material-ui/core';

import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

export const IdCardMatchesTable = ({ userId, refreshedAt }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    dataProvider.getList('aws_rekognition_id_card_matches',
      {
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
        filter: { user: userId, 'similarity[gte]': 99 },
      })
      .then(({ data, total }) => {
        setMatches(data);
        setLoading(false);
        setTotal(total);
      })
      .catch(error => {
        setLoading(false);
        notify(`Error: ${error.message}`, 'error');
      });
  }, [dataProvider, userId, refreshedAt, page, perPage, notify]);
  if (loading) return <CircularProgress/>;

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>User id</TableCell>
            <TableCell>Similar user id</TableCell>
            <TableCell>similarity</TableCell>
            <TableCell>Created at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {matches.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Link to={`/users/${userId}`}>{userId}</Link>
              </TableCell>
              <TableCell>
                {row.similar_id_card_user_id !== userId
                  ? (<Link to={`/users/${row.similar_id_card_user_id}`}>{row.similar_id_card_user_id}</Link>)
                  : (<Link to={`/users/${row.id_card_user_id}`}>{row.id_card_user_id}</Link>)}
              </TableCell>
              <TableCell><Chip size={'small'} label={row.similarity}/></TableCell>
              <TableCell><DateTimeBoxComponent value={row?.created_at} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
    </>
  );
};

IdCardMatchesTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
