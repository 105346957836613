import React, { useState } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import CallIcon from '@material-ui/icons/Call';
import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ClickToCallDialog } from '../dialogs';

export const Call = ({ children, edge, hidden, userId, userPhone, inactive, variant, color = 'secondary', className }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions = [] } = usePermissions();

  const disabled = !userPhone || inactive;
  const canCall = permissions.includes('CAN_CLICK_TO_CALL_VIEW');

  const applyClickToCall = () => {
    dataProvider.query('click_to_call', {
      method: 'POST',
      body: JSON.stringify({ user_id: userId, phone: userPhone }),
    })
      .then()
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };
  return (
    <Box display={'flex'} whiteSpace={'nowrap'} alignItems={children ? 'center' : 'initial'} gridGap={'20px'}>
      {!hidden && children}
      <Button className={className} variant={variant} size={'small'} color={color} disabled={disabled && !canCall} edge={edge} onClick={applyClickToCall}>
        <CallIcon/>
      </Button>
    </Box>
  );
};

Call.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  edge: PropTypes.oneOf(['start', 'end', false]),
  hidden: PropTypes.bool,
  withIcon: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userPhone: PropTypes.string,
  inactive: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

Call.defaultProps = {
  children: '',
  hidden: false,
  withIcon: true,
  inactive: false,
};
