import { Box, Typography, useTheme } from '@material-ui/core';
import { oneOfType, object, string, any, bool } from 'prop-types';

export const ListItem = ({ label, value, isLast, chipValue, justify = 'space-between' }) => {
  const theme = useTheme();
  return (
    <Box padding={'5px 10px'} borderBottom={`1px solid ${isLast ? 'transparent' : theme.palette.default.light}`} display={'flex'} alignItems={'center'} justifyContent={justify} gridGap={'20px'}>
      <Typography variant={'subtitle2'}>{label}</Typography>
      {typeof value === 'string'
        ? <Typography variant={'subtitle1'}>{value}</Typography>
        : <Box display={'flex'} alignItems={'center'} gridGap={'10px'}>{value}</Box> || null}
    </Box>
  );
};

ListItem.propTypes = {
  label: oneOfType([object, string]),
  value: any,
  isLast: bool,
  chipValue: bool,
  justify: string,
};
