import { useDispatch } from 'react-redux';
import { Button, useListContext, setListSelectedIds } from 'react-admin';

export const MarkAllButton = () => {
  const dispatch = useDispatch();
  const { ids } = useListContext();

  const handleMarkAll = () => dispatch(setListSelectedIds('loans', ids));

  return (
    <Button label={'Mark all'} variant={'contained'} onClick={handleMarkAll} color={'primary'}/>
  );
};
