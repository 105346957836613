import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

export const ConfirmToDeleteDialog = ({ isOpened, onClose, onSubmit, title }) => {
  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Delete {title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this item?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={onSubmit} color={'primary'}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmToDeleteDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};
