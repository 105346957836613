import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';

export const QuickFilterInput = ({ label }) => {
  return <Chip label={label} />;
};

QuickFilterInput.propTypes = {
  label: PropTypes.string,
};
