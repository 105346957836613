import React from 'react';
import { useInput } from 'react-admin';
import { DateTimePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import { timezone } from '../../utils';

export const DateTimePickerInput = (props) => {
  const { input: { value, onChange } } = useInput(props);

  return (
    <DateTimePicker
      className={[props.className || ''].join(' ')}
      clearable
      fullWidth
      label={props.label}
      value={value ? timezone.shift(new Date(value)) : null}
      onChange={(value) => onChange(value ? timezone.unshift(value).toISOString() : null)}
      {...props}
    />
  );
};

DateTimePickerInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};
