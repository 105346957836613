import React from 'react';
import { DeleteButton, Edit, SaveButton, SimpleForm, Toolbar } from 'react-admin';
import PropTypes from 'prop-types';

import { BlackListForm } from './BlackListForm';

const UserEditToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props}>
    <SaveButton />
    {permissions.indexOf('CAN_BLACK_LIST_DELETE') !== -1 && <DeleteButton />}
  </Toolbar>
);

UserEditToolbar.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

const FormWrapper = ({ permissions, save, record, ...props }) => (
  <SimpleForm
    save={(data, ...rest) => save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest])}
    toolbar={<UserEditToolbar permissions={permissions} />}
    {...props}
  >
    <BlackListForm record={record} />
  </SimpleForm>
);

FormWrapper.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  save: PropTypes.func,
  record: PropTypes.shape({
    user_id: PropTypes.number,
  }),
};

export const BlackListEdit = ({ permissions, ...props }) => (
  <Edit undoable={false} {...props}>
    <FormWrapper permissions={permissions} />
  </Edit>
);

BlackListEdit.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};
