import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextFieldMaterial from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React from 'react';

import { formatDatetime } from '../../../../utils';

export const RobocallsListOverviewTable = ({ row }) => {
  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row.engine_data?.task_name || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="Task name"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row.engine_data?.language || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="Language"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row.engine_data?.repeat_interval || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="Repeat interval"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row.engine_data?.repeat_number || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="Repeat number"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row.conditions?.loan_type || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="Loan type"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row.conditions?.max_dpd || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="Max dpd"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row.conditions?.min_dpd || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="Min dpd"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row.conditions?.max_outstanding || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="Max outstanding"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row.conditions?.min_outstanding || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="Min outstanding"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row.conditions?.min_outstanding || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="Min outstanding"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row?.engine || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="Engine"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row?.state || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="State"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row?.created_at && formatDatetime(row?.created_at)}
            variant="filled"
            margin="dense"
            fullWidth
            label="Created at"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row?.finished_at && formatDatetime(row?.finished_at)}
            variant="filled"
            margin="dense"
            fullWidth
            label="Finished at"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row?.scheduled_at && formatDatetime(row?.scheduled_at)}
            variant="filled"
            margin="dense"
            fullWidth
            label="Scheduled at"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row?.updated_at && formatDatetime(row?.updated_at)}
            variant="filled"
            margin="dense"
            fullWidth
            label="Updated at"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row?.updated_at && formatDatetime(row?.updated_at)}
            variant="filled"
            margin="dense"
            fullWidth
            label="Updated at"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row?.id || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="Id"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldMaterial
            value={row.requests?.length || ''}
            variant="filled"
            margin="dense"
            fullWidth
            label="Requests count"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

RobocallsListOverviewTable.propTypes = {
  row: PropTypes.shape({
    engine_data: PropTypes.shape({
      all_active_loan_ids: PropTypes.array,
      language: PropTypes.string,
      repeat_interval: PropTypes.number,
      repeat_number: PropTypes.number,
      schedule_end_time: PropTypes.string,
      schedule_start_time: PropTypes.string,
      task_name: PropTypes.string,
    }),
    conditions: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({
      max_dpd: PropTypes.string,
      min_dpd: PropTypes.string,
      loan_type: PropTypes.array,
      max_outstanding: PropTypes.string,
      min_outstanding: PropTypes.string,
    })]),
    created_at: PropTypes.string,
    engine: PropTypes.string,
    finished_at: PropTypes.string,
    id: PropTypes.number,
    requests: PropTypes.array,
    scheduled_at: PropTypes.string,
    state: PropTypes.string,
    updated_at: PropTypes.string,
  }),
};
