import { useController } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { string, object, array, func } from 'prop-types';

import { formatLabel } from '../../../utils';

export const SelectFieldHookForm = ({ name, label, control, options, onChange, defaultValue }) => {
  const {
    field: { value, onChange: onFieldChange },
  } = useController({
    name,
    control,
    defaultValue: defaultValue ?? '',
  });

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    onFieldChange(selectedValue);
    onChange && onChange(selectedValue);
  };
  return (
    <FormControl size={'small'} fullWidth variant={'outlined'}>
      <InputLabel id={`${name}-select-label`}>{formatLabel(label)}</InputLabel>
      <Select
        label={formatLabel(label)}
        labelId={`${name}-select-label`}
        value={value || defaultValue}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectFieldHookForm.propTypes = {
  name: string,
  label: string,
  control: object,
  options: array,
  onChange: func,
  defaultValue: string,
};
