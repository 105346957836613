import {
  Create,
  FormWithRedirect,
} from 'react-admin';

import { ProlongationForm } from './ProlongationForm';

const FormWrapper = props => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => <ProlongationForm formProps={formProps} {...props} isCreate={true}
      />}
    />
  );
};

export default props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
