import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDataProvider, useNotify } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';

// import { withServer, SelectAutoComplete } from '../../../select';
import { LoanCalculator } from '../../../LoanCalculator';
import { useProducts } from '../../../../hooks';
import { CreateAppCalculation } from '../../../CreateAppCalculation';
import { approveAppFormFields } from '../../../../constants';

// const AutoComplete = withServer(SelectAutoComplete);

export const ApproveTransitionDialog = ({ isOpened, onClose, onSubmit, record }) => {
  const { requested_principal, requested_tenor, promo_code, is_repeat } = record;
  const { principal, tenor, promocode } = approveAppFormFields;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [priceList, setPriceList] = useState();
  const defaultValues = {
    principal: requested_principal ?? '',
    tenor: requested_tenor ?? '',
    promocode: promo_code ?? '',
  };
  const { handleSubmit, control, setValue, setError, watch } = useForm({
    mode: 'all',
    defaultValues,
  });
  const { data: products } = useProducts();
  const activeProducts = products.filter((pr) => !pr.isDisabled);
  const [activeProduct] = activeProducts.filter((pr) => pr.id === record.product_id);

  const [calculation, setCalculation] = useState();
  const tenorFieldVal = watch(tenor)?.value ?? activeProduct?.tenor.first_min;
  const principalFieldVal = watch(principal)?.value ?? activeProduct?.principal.first_min;

  useEffect(() => {
    if (priceList && priceList[tenorFieldVal] && priceList[tenorFieldVal][principalFieldVal]) {
      setCalculation(priceList[tenorFieldVal][principalFieldVal]);
    }
  }, [priceList, tenorFieldVal, principalFieldVal]);
  const onSubmitHandler = handleSubmit((values) => {
    const payload = {
      promo_code: null, // TODO: add promocodes
      approved_principal: values.principal.value ?? requested_principal,
      approved_tenor: values.tenor.value ?? requested_tenor,
    };
    onSubmit(payload, { setError });
    onClose();
  });
  const handleCalculatorValueChange = (name, value) => setValue(name, { ...activeProduct[`${name}`], value });

  useEffect(() => {
    dataProvider.query(
      `p/product-price-list/pdl?${promo_code ? `promo_code=${promo_code}&` : ''}is_repeated_loan=${is_repeat ? '1' : '0'}`, // TODO: pdl to record.product_code
      { method: 'GET' },
    )
      .then((res) => {
        if (res?.data) {
          const { data } = res;
          setPriceList(data);
        }
      })
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, is_repeat, promo_code, notify]);

  useEffect(() => {
    if (activeProduct) {
      const { principal: activePPrincipal, tenor: activePTenor } = activeProduct;
      setValue(principal, {
        step: activePPrincipal.step,
        min: is_repeat ? activePPrincipal.min : activePPrincipal.first_min,
        value: record.requested_principal ? record.requested_principal : is_repeat ? activePPrincipal.min : activePPrincipal.first_min,
        max: is_repeat ? activePPrincipal.max : activePPrincipal.first_max,
      });
      setValue(tenor, {
        step: activePTenor.step,
        min: is_repeat ? activePTenor.min : activePTenor.first_min,
        value: record.requested_tenor ? record.requested_tenor : is_repeat ? activePTenor.min : activePTenor.first_min,
        max: is_repeat ? activePTenor.max : activePTenor.first_max,
      });
    }
    // eslint-disable-next-line
  }, [is_repeat]);

  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Approve</DialogTitle>
      <form onSubmit={onSubmitHandler}>
        <DialogContent>
          <DialogContentText>Please select approved loan principal and loan tenor</DialogContentText>
          {(activeProduct && record)
            ? <LoanCalculator
              control={control}
              principal={{
                step: activeProduct.principal.step,
                min: is_repeat ? activeProduct.principal.min : activeProduct.principal.first_min,
                value: record.requested_principal ? record.requested_principal : is_repeat ? activeProduct.principal.min : activeProduct.principal.first_min,
                max: is_repeat ? activeProduct.principal.max : activeProduct.principal.first_max,
              }}
              tenor={{
                step: activeProduct.tenor.step,
                min: is_repeat ? activeProduct.tenor.min : activeProduct.tenor.first_min,
                value: record.requested_tenor ? record.requested_tenor : is_repeat ? activeProduct.tenor.min : activeProduct.tenor.first_min,
                max: is_repeat ? activeProduct.tenor.max : activeProduct.tenor.first_max,
              }}
              onValueChange={handleCalculatorValueChange}
            />
            : <Skeleton variant={'rect'} />}
          {calculation && <CreateAppCalculation calculation={calculation} />}
          {/* <AutoComplete */}
          {/*  perOnePage={30} */}
          {/*  resource={'promo_codes'} */}
          {/*  label={'Promo codes'} */}
          {/*  filter={{ is_enabled: true }} */}
          {/*  value={promoCode} */}
          {/*  setValue={setPromoCode} */}
          {/*  prop={'code'} */}
          {/* /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color={'primary'}>Cancel</Button>
          <Button type={'submit'} color={'primary'}>Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

ApproveTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  record: PropTypes.shape({
    requested_principal: PropTypes.number,
    requested_tenor: PropTypes.number,
    product_id: PropTypes.number,
    promo_code: PropTypes.string,
    is_repeat: PropTypes.bool,
    number_of_closed_loans: PropTypes.number,
  }),
};
