import { ChipField, Datagrid, FunctionField, List, NumberField, TextField, usePermissions } from 'react-admin';
import { Box, Avatar, Chip, Link, CircularProgress, Backdrop, IconButton } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { formatPhoneNumber, getUserId } from '../../../utils';
import Pagination from '../../Pagination';
import { Call } from '../../Call';
import { useCollectionGroups, useExternalAgencies } from '../../../hooks';
import { CURRENCY } from '../../../constants';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

import { ExternalAgencyLoansFilter } from './ExternalAgencyLoansFilter';
import { ExternalAgencyLoansActions } from './ExternalAgencyLoansActions';

const resolveCollectionGroup = (daysPastDue, collectionGroups) => {
  if (daysPastDue >= 91) {
    return 'G6';
  }
  const group = collectionGroups.find((group) => daysPastDue >= group.dpd_from && daysPastDue <= group.dpd_to);
  return group?.code ?? 'Er';
};

export const ExternalAgencyLoans = (props) => {
  const { data: collectionGroups, isLoadingCollections } = useCollectionGroups();
  const { data: agenciesList, isLoadingAgencies } = useExternalAgencies();
  const userId = getUserId();
  const { permissions = [] } = usePermissions();

  if (isLoadingCollections || isLoadingAgencies) return <Backdrop open={true}><CircularProgress /></Backdrop>;

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ExternalAgencyLoansFilter collectionGroups={collectionGroups} />}
      filter={{ enum_state: 'active,defaulted', 'exists[external_agency]': true }}
      filterDefaultValues={permissions.includes('CAN_VIEW_ALL_COLLECTION_LOANS') ? null : { 'collector.id': userId }}
      actions={<ExternalAgencyLoansActions />}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <FunctionField onClick={(e) => e.stopPropagation()} label="Name" render={({ user_id, user_first_name = '', user_last_name = '', user_phone_number }) => <>
          <Link color={'secondary'} href={`#users/${user_id}`}>{`#${user_id} ${user_first_name} ${user_last_name}`}</Link>
          <br />
          <Call userId={user_id} userPhone={user_phone_number}>{formatPhoneNumber(user_phone_number)}</Call>
        </>} />
        <FunctionField
          label="External agency"
          sortable={false}
          render={({ external_agency_id }) => agenciesList.find(agency => agency.id === external_agency_id).name}
        />
        <NumberField source="accrued_principal" label="Principal" options={{ style: 'currency', currency: CURRENCY }} />
        <NumberField source="tenor" />
        <ChipField size="small" source="state" sortable={false} />
        <FunctionField key={'is_repeat'} label={'Loan type'} source={'is_repeat'} sortable={false}
          render={(record, key) => <Chip size="small" label={record[key] ? 'Repeated' : 'New'} />}
        />
        <FunctionField
          source="created_at"
          render={(record) => <DateTimeBoxComponent value={record.created_at} />}
          label="Created at" />
        <FunctionField
          source="disbursed_at"
          render={(record) => <DateTimeBoxComponent value={record.disbursed_at} />}
          label="Disbursed at" />
        <FunctionField
          source="matured_at"
          render={(record) => <DateTimeBoxComponent value={record.matured_at} />}
          label="Matured at" />
        <FunctionField key={'button_tab'}
          render={(record) => (
            <IconButton color={'secondary'} size={'small'} onClick={(e) => {
              e.stopPropagation();
              window.open(`#loans/${record.id}/show`, '_blank');
            }}>
              <OpenInNewIcon fontSize={'small'} />
            </IconButton>
          )}
        />
      </Datagrid>
    </List>
  );
};
