import React from 'react';
import PropTypes from 'prop-types';
import {
  useInput, Edit, TextInput, BooleanInput, SaveButton, DeleteButton, FormWithRedirect, required, maxLength,
} from 'react-admin';
import {
  makeStyles, Button, FormControl, InputLabel, Select, MenuItem,
  TextField, Grid, Typography, List, ListItem, Box, Paper, Divider, IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { DateTimePickerInput } from '../../input';

const useStyles = makeStyles(() => ({
  titleSwitch: {
    '& label': {
      margin: 0,
    },
  },
  textRight: {
    textAlign: 'right',
  },
}));

const ConditionsInput = props => {
  const classes = useStyles();
  const {
    input: { value, onChange },
  } = useInput(props);

  const updateKey = (key, newKeyName) => {
    if (Object.keys(value || {}).includes(newKeyName)) return;

    const newValue = {};

    for (const p in value) {
      if (p === 'is_repeat_loan') {
        newValue.is_repeat_loan = value[p];
      } else {
        newValue[p === key ? newKeyName : p] = value[p];
      }
    }

    onChange(newValue);
  };

  const updateValue = (key, v) => {
    const newValue = { ...(value || {}) };
    newValue[key] = v;
    onChange(newValue);
  };

  const deleteKey = key => {
    const newValue = { ...(value || {}) };
    delete newValue[key];
    onChange(newValue);
  };

  return (
    <>
      <List>
        { Object.keys(value || {}).map((item, idx) => (
          <ListItem key={ idx } disableGutters>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Condition</InputLabel>
                  <Select
                    value={ item }
                    onChange={ e => updateKey(item, e.target.value) }
                  >
                    <MenuItem value="min_principal">Min principal</MenuItem>
                    <MenuItem value="max_principal">Max principal</MenuItem>
                    <MenuItem value="min_tenor">Min tenor</MenuItem>
                    <MenuItem value="max_tenor">Max tenor</MenuItem>
                    <MenuItem value="is_repeat_loan">Is repeat loan</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {item === 'is_repeat_loan'
                ? (
                  <Grid item xs={5}>
                    <Select fullWidth value={value.is_repeat_loan} onChange={(e) => updateValue(item, e.target.value)}>
                      <MenuItem value={false}>New</MenuItem>
                      <MenuItem value={true}>Repeated</MenuItem>
                    </Select>
                  </Grid>
                )
                : (
                  <Grid item xs={5}>
                    <TextField
                      label='Value'
                      value={value[item] || ''}
                      onChange={(e) => updateValue(item, e.target.value)}
                      fullWidth
                    />
                  </Grid>
                )}
              <Grid item xs={1} className={ classes.textRight }>
                <IconButton disabled={ props.disabled } color="primary" size="small" onClick={ () => deleteKey(item) } >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
      <Button
        disabled={ props.disabled }
        variant="contained"
        color="primary"
        size="small"
        onClick={ () => onChange({ ...(value || {}), '': '' }) }
      >Add</Button>
    </>
  );
};

ConditionsInput.propTypes = {
  disabled: PropTypes.bool,
};

const DiscountsInput = props => {
  const classes = useStyles();
  const {
    input: { value, onChange },
  } = useInput(props);

  const updateItem = (idx, item) => {
    const newValue = [...(value || [])];
    newValue[idx] = item;
    onChange(newValue);
  };

  return (
    <>
      <List>
        { (value || []).map((item, idx) => (
          <ListItem key={ idx } disableGutters>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <InputLabel>Transaction type</InputLabel>
                  <Select
                    value={ item[0] }
                    onChange={ e => updateItem(idx, [e.target.value, item[1], item[2]]) }
                  >
                    <MenuItem value="principal">Principal</MenuItem>
                    <MenuItem value="consulting_fee">Consulting fee</MenuItem>
                    <MenuItem value="service_fee">Service fee</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Discount type</InputLabel>
                  <Select
                    value={ item[1] }
                    onChange={ e => updateItem(idx, [item[0], e.target.value, item[2]]) }
                  >
                    <MenuItem value="fixed">Fixed</MenuItem>
                    <MenuItem value="percent">Percent</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField label="Value" value={ item[2] || '' } onChange={ e => updateItem(idx, [item[0], item[1], e.target.value]) } fullWidth />
              </Grid>
              <Grid item xs={1} className={ classes.textRight }>
                <IconButton disabled={ props.disabled } color="primary" size="small" onClick={ () => onChange((value || []).filter((_item, _idx) => _idx !== idx)) } >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
      <Button
        disabled={ props.disabled }
        variant="contained"
        color="primary"
        size="small"
        onClick={ () => onChange([...(value || []), ['', '', '']]) }
      >Add</Button>
    </>
  );
};

DiscountsInput.propTypes = {
  disabled: PropTypes.bool,
};

const FormWrapper = ({ permissions, save, ...props }) => {
  const classes = useStyles();
  const isEditable = permissions.indexOf('CAN_PROMO_CODE_EDIT') !== -1;

  return (
    <FormWithRedirect
      save={ (data, ...rest) => {
        const conditions = {};
        for (const p in (data.conditions || {})) {
          if (data.conditions[p] !== null) {
            conditions[p] = data.conditions[p];
          }
        }
        return save(...[{ ...data, conditions, _params: { method: 'PATCH' } }, ...rest]);
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={ 2 }>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" gutterBottom={ false }>Main info</Typography>
                    <BooleanInput disabled={ !isEditable } source="is_enabled" helperText={ false } className={ classes.titleSwitch } />
                  </Grid>
                  <Divider />
                </Box>
                <Box p={ 2 }>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput disabled={ !isEditable } source="code" validate={ [required(), maxLength(255)] } fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput disabled={ !isEditable } source="campaign" validate={ maxLength(255) } fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerInput disabled={ !isEditable } label="Valid from" source="valid_from" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerInput disabled={ !isEditable } label="Valid till" source="valid_till" />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={ 2 }>
                  <Typography variant="h6" gutterBottom={ false }>Conditions</Typography>
                  <Divider />
                  <ConditionsInput disabled={ !isEditable } source="conditions" />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={ 2 }>
                  <Typography variant="h6" gutterBottom={ false }>Discounts</Typography>
                  <Divider />
                  <DiscountsInput disabled={ !isEditable } source="discounts" />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <SaveButton
                  disabled={ !isEditable }
                  label="Save"
                  redirect="list"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
                <DeleteButton
                  undoable={ false }
                  disabled={ !isEditable }
                  basePath={ formProps.basePath }
                  record={ formProps.record }
                  resource={ formProps.resource }
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  permissions: PropTypes.array,
  save: PropTypes.func,
};

export const PromoCodeEdit = ({ permissions = [], ...props }) => (
  <Edit component="div" undoable={ false } {...props}>
    <FormWrapper permissions={ permissions } />
  </Edit>
);

PromoCodeEdit.propTypes = {
  permissions: PropTypes.array,
};
