import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  useNotify,
  useDataProvider,
  useRefresh,
} from 'react-admin';
import Switch from '@material-ui/core/Switch';

import Pagination from '../../Pagination';

const ListActions = ({ className, filters, ...rest }) => {
  const {
    hasCreate,
    basePath,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {hasCreate ? (<CreateButton basePath={basePath} />) : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

export default props => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);

  const toggleActivity = (id, active) => {
    setIsLoading(true);
    dataProvider.update('extension_discounts', { id, data: { active } })
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        refresh();
        setIsLoading(false);
      });
  };

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
      {...props}
    >
      <Datagrid rowClick='edit'>
        <TextField source='id' />
        <TextField source='lower_limit' label={'% charges Min'} />
        <TextField source='upper_limit' label={'% charges Max'}/>
        <TextField source='discount' />
        <FunctionField onClick={(e) => e.stopPropagation()} label='Enabled'
          render={({ id, active }) => <Switch color='primary' checked={active} disabled={isLoading}
            onChange={() => toggleActivity(id, !active)} />} />
      </Datagrid>
    </List>
  );
};
