import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  pxy2: {
    padding: theme.spacing(2),
  },
}));

const Details = ({ isEditable = true, affiliateActionId, refreshedAt, onEdit, onApplyTransition }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [affiliateAction, setAffiliateAction] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider.getOne('affiliate_actions', { id: affiliateActionId })
      .then(({ data }) => {
        setAffiliateAction(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, affiliateActionId, refreshedAt]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  const { callback_url: callbackUrl, callback_params: callbackParams } = affiliateAction;

  return (
    <>
      <Typography className={classes.pxy2} variant="subtitle2" gutterBottom={false}>
        <strong>Callback URL:</strong> {callbackUrl}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(callbackParams).map((key, idx) => (
            <TableRow key={idx}>
              <TableCell>{key}</TableCell>
              <TableCell>{callbackParams[key]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <ButtonGroup disabled={!isEditable} variant="contained" color="primary" size="small">
          {(affiliateAction.enabled_transitions || []).indexOf('enable') !== -1 && (
            <Button onClick={() => onApplyTransition(affiliateActionId, 'enable')}>Enable</Button>
          )}

          {(affiliateAction.enabled_transitions || []).indexOf('disable') !== -1 && (
            <Button onClick={() => onApplyTransition(affiliateActionId, 'disable')}>Disable</Button>
          )}

          <Button onClick={() => onEdit(affiliateActionId)}>Edit</Button>
        </ButtonGroup>
      </Box>
    </>
  );
};
Details.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  affiliateActionId: PropTypes.number.isRequired,
  refreshedAt: PropTypes.number,
  onEdit: PropTypes.func.isRequired,
  onApplyTransition: PropTypes.func.isRequired,
};

const Row = ({ row, isEditable = true, refreshedAt, onDelete, onEdit, onApplyTransition }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.trigger_event}</TableCell>
        <TableCell><Chip size="small" label={row.state} /></TableCell>
        <TableCell align="right">
          <IconButton disabled={!isEditable} color="primary" size="small" onClick={() => onDelete(row.id)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow key={`${row.id}-details`}>
        <TableCell style={{ paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0 }} colSpan={4}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Details isEditable={isEditable} affiliateActionId={row.id} refreshedAt={refreshedAt} onEdit={onEdit}
              onApplyTransition={onApplyTransition} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
Row.propTypes = {
  row: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  refreshedAt: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onApplyTransition: PropTypes.func.isRequired,
};
export const AffiliateActionTable = ({ isEditable = true, affiliateId, refreshedAt, onDelete, onEdit, onApplyTransition }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [affiliateActions, setAffiliateActions] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  useEffect(() => {
    dataProvider.getList('affiliate_actions', {
      filter: { 'affiliate.id': affiliateId },
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
    })
      .then(({ data, total }) => {
        setAffiliateActions(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, affiliateId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(affiliateActions)) return null;

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Trigger event</TableCell>
            <TableCell>State</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {affiliateActions.map(row => <Row key={row.id} isEditable={isEditable} row={row} refreshedAt={refreshedAt}
            onDelete={onDelete} onEdit={onEdit}
            onApplyTransition={onApplyTransition} />)}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};
AffiliateActionTable.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  affiliateId: PropTypes.number.isRequired,
  refreshedAt: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onApplyTransition: PropTypes.func.isRequired,
};
