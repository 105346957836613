import AccountTreeIcon from '@material-ui/icons/AccountTree';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import ExtensionIcon from '@material-ui/icons/Extension';
import PublicIcon from '@material-ui/icons/Public';
import Subject from '@material-ui/icons/Subject';
import SettingsInputAntenna from '@material-ui/icons/SettingsInputAntenna';
import DialerSip from '@material-ui/icons/DialerSip';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { Box, FormControl, MenuItem, Select, Switch, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

export const GLOBAL_SETTINGS_OPTIONS_MAP = {
  auto_start_de: {
    title: 'Autostart DE',
    icon: <AccountTreeIcon />,
    options: [
      { id: 1, name: 'Manual' },
      { id: 2, name: 'Aventus DE' },
    ],
  },
  auto_start_juicy_score_config: {
    title: 'Juicy score',
    icon: <FingerprintIcon />,
    options: [
      { id: 1, name: 'only on first clients' },
      { id: 2, name: 'for all clients' },
    ],
  },
  verifier_auto_assignment_config: {
    title: 'Verifier auto assignment',
    icon: <ExtensionIcon />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  geolocation_config: {
    title: 'Geolocation',
    icon: <PublicIcon />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  log_notification: {
    title: 'Log notification',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  regula_required_on_registration: {
    title: 'Is regula response required on registration',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  main_phone_aws_validate_on_registration: {
    title: 'Main user phone AWS validation required on registration',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  contact_phone_aws_validate_on_registration: {
    title: 'Contact user phone AWS validation required on registration',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  allow_send_real_sms: {
    title: 'Allow send real sms',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  promise_complete_amount_part: {
    title: 'Promise complete amount part',
    icon: <Subject />,
    options: [
      { id: 50, name: '50%' },
      { id: 55, name: '55%' },
      { id: 60, name: '60%' },
      { id: 65, name: '65%' },
      { id: 70, name: '70%' },
      { id: 75, name: '75%' },
      { id: 80, name: '80%' },
      { id: 85, name: '85%' },
      { id: 90, name: '90%' },
      { id: 95, name: '95%' },
      { id: 100, name: '100%' },
    ],
  },
  webitel_validate_call_on_registration: {
    title: 'Webitel validate call on registration',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  default_sms_transmitter: {
    title: 'Default SMS transmitter',
    icon: <SettingsInputAntenna />,
    options: [
      { id: 1, name: 'Fake' },
      { id: 2, name: 'Infobip' },
      { id: 4, name: 'Africa\'s Talking' },
    ],
  },
  repeat_sms_transmitter: {
    title: 'SMS transmitter for repeat SMS',
    icon: <SettingsInputAntenna />,
    options: [
      { id: 1, name: 'Fake' },
      { id: 2, name: 'Infobip' },
      { id: 4, name: 'Africa\'s Talking' },
    ],
  },
  default_dialer_transmitter: {
    title: 'Default dialer transmitter',
    icon: <DialerSip />,
    options: [
      { id: 1, name: 'Fake' },
      { id: 2, name: 'Webitel' },
    ],
  },
  regula_similar_check_replace: {
    title: 'Regula name replace if similarity more than',
    icon: <Subject />,
    options: [
      { id: 60, name: '60%' },
      { id: 65, name: '65%' },
      { id: 70, name: '70%' },
      { id: 75, name: '75%' },
      { id: 80, name: '80%' },
      { id: 85, name: '85%' },
      { id: 90, name: '90%' },
      { id: 95, name: '95%' },
    ],
  },
  regula_similar_check_replace_enabled: {
    itle: 'Enable regula name replace',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  create_request_limiter: {
    title: 'Enable limiter for create request',
    icon: <Subject />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
  },
  uma_value: {
    title: 'UMA',
    icon: <Subject />,
  },
  default_auto_money_transfer: {
    title: 'Default auto money transfer',
    icon: <AttachMoney />,
    options: [
      { id: 1, name: 'Fake' },
      { id: 2, name: 'Selcom' },
    ],
  },
  default_charge_provider: {
    title: 'Default charge provider',
    icon: <AttachMoney />,
    options: [
      { id: 1, name: 'Fake' },
      { id: 2, name: 'Selcom' },
    ],
  },
  auto_money_transfer_config: {
    title: 'Auto money transfer',
    icon: <AttachMoney />,
    options: [
      { id: 0, name: 'Off' },
      { id: 1, name: 'On' },
    ],
    nested_settings: {
      auto_money_transfer_auto_approved_new: {
        title: 'Auto-approved new customers',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      auto_money_transfer_auto_approved_repeat: {
        title: 'Auto-approved repeat customers',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      auto_money_transfer_manual_approved_new: {
        title: 'Manually approved new customers',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      auto_money_transfer_manual_approved_repeat: {
        title: 'Manually approved repeat customers',
        options: [
          { id: 0, name: 'Off' },
          { id: 1, name: 'On' },
        ],
      },
      uma_value: {
        title: 'UMA Value',
        icon: <Subject />,
      },
    },
  },
  collection_auto_assign_consider_loans: {
    title: 'Consider loans already assigned to collectors',
    icon: <ExtensionIcon />,
    options: [
      { id: 0, name: 'Not use old loans' },
      { id: 1, name: 'Use principal from old loans' },
      { id: 2, name: 'Use total from old loans' },
    ],
  },
  collection_auto_assign_sort_loans: {
    title: 'Sort loans before auto assign',
    icon: <ExtensionIcon />,
    options: [
      { id: 0, name: 'Sort by dpd and sum' },
      { id: 1, name: 'Sort by sum' },
    ],
  },
};

export const SettingItem = ({ permissions, item: { id, name, value, nested_settings: stored_nested_settings = null }, onChange, onChangeNested }) => {
  if (!GLOBAL_SETTINGS_OPTIONS_MAP[name]) {
    return (
      <Typography variant={'caption'} color={'error'}>
        Undefined setting name: <code>{name}</code>
      </Typography>
    );
  }

  const { title, icon, options, nested_settings } = GLOBAL_SETTINGS_OPTIONS_MAP[name];
  return (
    <>
      <Box display={'flex'} paddingBottom={1} paddingTop={1} gridGap={30} justifyContent={'space-between'}>
        <Box
          flexGrow={1}
          flexBasis={'calc(100% - 300px)'}
          flexShrink={0}
          color={'secondary'}
          display={'flex'}
          alignItems={'center'}
          gridGap={10}
          flexDirection={'row'}>
          {icon}
          <Typography variant={'body1'}>{title}</Typography>
        </Box>
        <FormControl fullWidth size={'small'} variant={'outlined'}>
          {options
            ? <Select
              fullWidth
              size={'small'}
              value={value ?? '-'}
              onChange={(event) => onChange(id, event.target.value?.toString() || null)}
              disabled={permissions.includes('CAN_GLOBAL_SETTINGS_EDIT') === false}>
              <MenuItem value={''}>-</MenuItem>
              {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            : <TextField
              fullWidth
              variant={'outlined'}
              size={'small'}
              disabled={permissions.includes('CAN_GLOBAL_SETTINGS_EDIT') === false}
              value={value ?? ''}
              onChange={(event) => onChange(id, event.target.value || null)}
            />}
        </FormControl>
      </Box>
      {name === 'auto_money_transfer_config' && value === 1 &&
        Object.keys(nested_settings).map((nested_setting_name) =>
          <Box key={nested_settings[nested_setting_name]}>
            <Typography variant={'body1'}>{nested_settings[nested_setting_name].title}</Typography>
            <Switch
              color={'secondary'}
              checked={Array.isArray(stored_nested_settings) && Boolean(stored_nested_settings.find(item => item.name === nested_setting_name)?.value)}
              onChange={(event) => {
                onChangeNested(id, nested_setting_name, event.target.checked ? 1 : 0);
              }}
            />
          </Box>)}
    </>
  );
};

SettingItem.propTypes = {
  permissions: PropTypes.array,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    nested_settings: PropTypes.array,
  }),
  onChange: PropTypes.func,
  onChangeNested: PropTypes.func,
};
