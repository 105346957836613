import { useState } from 'react';
import {
  List, Datagrid, TextField, NumberField, ChipField, FunctionField, Filter, NumberInput, TextInput, useDataProvider,
  useRefresh, useNotify,
} from 'react-admin';
import { Chip, Button, Link, useTheme, Box, Card } from '@material-ui/core';
import PropTypes from 'prop-types';

import Pagination from '../../../Pagination';
import { AsyncSelectInput } from '../../../input';
import { DateFilterInput } from '../../../filters';
import { AVENTUS_DECISION_ENGINE_STATUSES, getUserId, getRequestDuration, formatCurrency } from '../../../../utils';
import { verifierRow } from '../ApplicationShow';
import { ApplicationsBulkActionButtons } from '../ApplicationsBulkActionButtons';
import { ApplicationColorLegend } from '../../../ApplicationColorLegend';
import { ListItem } from '../../../ListItem';
import { DateTimeBoxComponent } from '../../../DateTimeBoxComponent';

import { ManualVerificationListActions } from './ManualVerificationListActions';

const ListFilter = (props) => (
  <Filter {...props} style={{ marginTop: 0, marginBottom: '20px' }} variant={'outlined'}>
    <NumberInput label="Id" source="id" alwaysOn />
    <NumberInput label="User Id" source="user->id" />
    <TextInput label="Document number" source="user->userDocuments->number" />
    <DateFilterInput label="Created before" source="created_at|before" before/>
    <DateFilterInput label="Created after" source="created_at|after" after/>
    <AsyncSelectInput
      label="Affiliate"
      source="affiliate"
      query={{ resource: 'affiliates', payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} } }}
      mapper={ ({ id, name }) => ({ value: id, name }) }
    />
  </Filter>
);

export const ManualVerificationList = ({ filter, listfilters: CustomFilters, permissions, ...props }) => {
  const userId = getUserId();
  const { palette } = useTheme();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [isTransitionInProgress, setIsTransitionInProgress] = useState(false);
  const [isLegendShow, setIsLegendShow] = useState(false);

  const handleLegendToggle = () => setIsLegendShow(!isLegendShow);

  const isRowSelectable = (record) => record?.state === 'pending' || (record?.state === 'processing' && ['manual', null].includes(record?.decision_engine_id));

  const loanRowStyle = (record) => ({ backgroundColor: getRequestDuration(record, palette) });

  const assignVerifier = (applicationId) => {
    setIsTransitionInProgress(true);
    dataProvider.query(`applications/${applicationId}/apply_transition`, {
      method: 'POST',
      body: JSON.stringify({ name: 'assign_verifier', params: { verifier_id: userId } }),
    })
      .then(() => refresh())
      .catch((error) => notify(`Error: ${error.message}`, 'error'))
      .finally(() => setIsTransitionInProgress(false));
  };

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={<ApplicationsBulkActionButtons />}
      sort={{ field: 'id', order: 'DESC' }}
      filters={CustomFilters ? <CustomFilters /> : <ListFilter />}
      actions={<ManualVerificationListActions isLegendShow={isLegendShow} onLegendToggle={handleLegendToggle} />}
      filter={{ ...filter, manual_verification: userId }}
      permissions={permissions}
      {...props}
    >
      <>
        {isLegendShow && <ApplicationColorLegend />}
        <Datagrid
          bulkActionButtons={<ApplicationsBulkActionButtons />}
          rowStyle={loanRowStyle}
          rowClick={(id, path, { verifier_id }) => verifier_id ? 'show' : notify('Verifier not assigned.')}
          isRowSelectable={isRowSelectable}>
          <TextField source="id" />
          <FunctionField onClick={(e) => e.stopPropagation()} label="Name" render={({ user_id, user_first_name = '', user_last_name = '' }) =>
            <Link style={{ whiteSpace: 'nowrap' }} color={'secondary'} href={`#users/${user_id}`}>{`#${user_id} ${user_first_name} ${user_last_name}`}</Link>} />,
          <FunctionField render={({ decision_engine_id, state, verifier_username }) => verifierRow(decision_engine_id, state, verifier_username)} label="Verifier"/>
          <ChipField size="small" source="product_name" />
          <NumberField label="Loan number" source="potential_loan_number" />
          <FunctionField label="Request details" render={ (record) => record.requested_principal && record.requested_tenor && <Card>
            <ListItem label={'Request number:'} value={<b>{record.request_number}</b>} />
            <ListItem label={'Type:'} value={<Chip size={'small'} color={record.is_repeat ? 'default' : 'secondary'} label={<b>{record.is_repeat ? 'Repeat' : 'First'}</b>}/>} />
            <ListItem label={'Principal:'} value={<b>{formatCurrency(record.requested_principal)}</b>} />
            <ListItem label={'Tenor:'} value={<span><b>{record.requested_tenor}</b> days</span>} />
          </Card> } />
          <ChipField size="small" source="state" />
          <FunctionField label="Created at" source="created_at" render={(record) => <DateTimeBoxComponent value={record.created_at} />} />
          <FunctionField label="Affiliate" source="affiliate" render={(record, key) => record[key] ? <Chip size="small" label={record[key]} /> : null} />
          <FunctionField label="ADE status" source="aventus_decision_engine_status" render={(record, key) => record[key] ? <Chip size="small" label={(AVENTUS_DECISION_ENGINE_STATUSES.find((item) => item.id === record[key]) || { name: null }).name} /> : null} />
          <FunctionField
            onClick={(e) => e.stopPropagation()}
            label="Actions"
            render={({ id, verifier_id }) => (
              <Button
                variant={'outlined'}
                size={'small'}
                color={'secondary'}
                disabled={(permissions.indexOf('CAN_APPLICATION_TRANSITION_ASSIGN_VERIFIER') === -1) || !!verifier_id || isTransitionInProgress}
                onClick={() => assignVerifier(id)}>
                {`Assign${verifier_id ? 'ed' : ''}`}
              </Button>
            ) }
          />
        </Datagrid>
      </>
    </List>
  );
};

ManualVerificationList.propTypes = {
  filter: PropTypes.object,
  permissions: PropTypes.arrayOf(PropTypes.string),
  listfilters: PropTypes.func,
};
