import { Card, CardContent, CardHeader, Chip, Divider, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../../utils';
import { FeeAccordion } from '../../../FeeAccordion';
import { ListItem } from '../../../ListItem';
import { CopyToClipboardButton } from '../../../buttons';
import { DateTimeBoxComponent } from '../../../DateTimeBoxComponent';

const rowData = (name, value) => ({ name, value });

export const LoanInfo = ({ record }) => {
  const infoRows = [
    rowData('ID', record.id),
    rowData('Contract number', record.contract_number),
    rowData('Product', record.product_name),
    rowData('Promocode', record.promo_code),
    rowData('Discount details', record.discount_details?.map((discount) => <p style={{ marginTop: 0, marginBottom: '2px' }} key={discount}>{discount}</p>)),
    rowData('Discount amount', formatCurrency(record.discount_amount)),
    rowData('Short link to extension', <CopyToClipboardButton url={record.extension_short_link_uri} />),
    rowData('Full payment short link URL', <CopyToClipboardButton url={record.full_payment_short_link_uri} />),
    rowData('Part payment short link URL', <CopyToClipboardButton url={record.part_payment_short_link_uri} />),
    rowData('Assigned collector name', record.assigned_collector),
    rowData('Collection score priority', record.collection_score_priority),
    rowData('Principal', formatCurrency(record.principal)),
    rowData('Tenor', record.tenor),
    rowData('State', record.state),
    record.state === 'cancelled' ? rowData('Cancellation reason', record.cancel_reason) : null,
    rowData('Created at', <DateTimeBoxComponent inline value={record?.created_at} />),
    rowData('Disbursed at', <DateTimeBoxComponent inline value={record?.disbursed_at} />),
    rowData('Maturity date', <DateTimeBoxComponent inline value={record?.matured_at} />),
    rowData('Closed at', <DateTimeBoxComponent inline value={record?.closed_at} />),
    rowData('Days past due', record.days_past_due),
    rowData('Number of extensions', record.number_of_extensions),
    rowData('Bank name', record.bank_name),
    rowData('Loan type', record.is_repeat ? 'Repeat' : 'New'),
    record.state === 'defaulted'
      ? rowData('Extension min payment', 'Extension is not allowed')
      : rowData('Extension min payment', record.allowed_extension_tenors_price && Object.keys(record.allowed_extension_tenors_price).map((i) => {
        return (<>
          {record.discount_amount_for_extension !== 0 &&
            <span style={{ color: '#e70000', textDecoration: 'line-through' }}>{record.income_amount_for_extension}</span>}
          <Chip
            style={{
              marginLeft: '2px',
              marginTop: '2px',
              backgroundColor:
                record.is_repeat &&
                record.days_past_due >= -3 &&
                record.days_past_due <= 15 &&
                record.state === 'active'
                  ? '#4caf50'
                  : '',
            }}
            key={i}
            variant={'outlined'}
            size={'small'}
            label={`${i} - ${formatCurrency(record.allowed_extension_tenors_price[i])}`}
          />
        </>);
      })),
    rowData('Discount amount for extension', <span style={{ marginLeft: '2px', marginTop: '2px', color: record.is_repeat && record.days_past_due >= 3 && record.days_past_due <= 15 ? '#4caf50' : '' }}>{formatCurrency(record?.discount_amount_for_extension)}</span>),
    rowData('Active promise of payment date', <DateTimeBoxComponent inline value={record?.active_promise_date} />),
    rowData('Active promise of payment amount', formatCurrency(record.active_promise_amount)),
  ];

  const calculationRows = [
    rowData('Principal', formatCurrency(record.accrued_principal)),
    rowData('Commission', formatCurrency(record.accrued_commission)),
    rowData('Interest', formatCurrency(record.accrued_interest)),
    rowData('Past due interest', formatCurrency(record.accrued_past_due_interest)),
    record.accrued_consulting_fee === 0 ? null : rowData('Consulting fee', formatCurrency(record.accrued_consulting_fee)),
    record.accrued_consulting_fee_add === 0 ? null : rowData('Consulting fee (ADD)', formatCurrency(record.accrued_consulting_fee_add)),
    record.accrued_service_fee === 0 ? null : rowData('Service fee', formatCurrency(record.accrued_service_fee)),
    record.accrued_service_fee_add === 0 ? null : rowData('Service fee (ADD)', formatCurrency(record.accrued_service_fee_add)),
    record.accrued_late_payment_fee === 0 ? null : rowData('Late payment fee', formatCurrency(record.accrued_late_payment_fee)),
    record.accrued_penalty === 0 ? null : rowData('Penalty', formatCurrency(record.accrued_penalty)),
    record.accrued_interest_penalty === 0 ? null : rowData('Interest penalty', formatCurrency(record.accrued_interest_penalty)),
    record.accrued_interest_penalty === 0 ? null : rowData('Interest penalty', formatCurrency(record.accrued_interest_penalty)),
    record.accrued_vat === 0 ? null : rowData('VAT', formatCurrency(record.accrued_vat)),
    record.accrued_excise_duty === 0 ? null : rowData('Excise duty', formatCurrency(record.accrued_excise_duty)),
    rowData('Total', formatCurrency(record.accrued_total)),
  ];

  return (
    <Box display={'flex'} flexDirection={'column'} gridGap={'20px'}>
      <Card>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} paddingRight={'16px'}>
          <CardHeader title={'Loan'} />
          {record.is_repeat &&
            record.days_past_due >= -3 &&
            record.days_past_due <= 15 &&
            record.state === 'active' &&
            <Box
              sx={{ backgroundColor: '#4caf50', color: 'white', borderRadius: '4px', padding: '6px' }}
            >
              OFFER EXTENSION
            </Box>
          }
        </Box>
        <Divider />
        <CardContent>
          {infoRows.map((row, key) =>
            row && <ListItem key={key} isLast={key === infoRows.length - 1} label={row.name} value={row.value} />)}
        </CardContent>
      </Card>
      <Card>
        <CardHeader title={'Today\'s total'} />
        <Divider />
        <CardContent>
          {calculationRows.map((row, key) =>
            row && <ListItem key={key} isLast={key === calculationRows.length - 1} label={row.name} value={row.value} />)}
        </CardContent>
        <CardContent>
          <FeeAccordion record={record} />
        </CardContent>
      </Card>
    </Box>
  );
};

LoanInfo.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    contract_number: PropTypes.string,
    product_name: PropTypes.string,
    discount_details: PropTypes.array,
    discount_amount: PropTypes.number,
    discount_amount_for_extension: PropTypes.number,
    assigned_collector: PropTypes.string,
    collection_score_priority: PropTypes.oneOfType([PropTypes.string]),
    state: PropTypes.string,
    cancel_reason: PropTypes.oneOfType([PropTypes.string]),
    created_at: PropTypes.string,
    disbursed_at: PropTypes.string,
    matured_at: PropTypes.string,
    closed_at: PropTypes.string,
    days_past_due: PropTypes.number,
    number_of_extensions: PropTypes.number,
    bank_name: PropTypes.string,
    allowed_extension_tenors_price: PropTypes.object,
    active_promise_date: PropTypes.oneOfType([PropTypes.string]),
    active_promise_amount: PropTypes.oneOfType([PropTypes.number]),
    accrued_principal: PropTypes.number,
    accrued_commission: PropTypes.number,
    accrued_interest: PropTypes.number,
    accrued_past_due_interest: PropTypes.number,
    accrued_consulting_fee: PropTypes.number,
    accrued_consulting_fee_add: PropTypes.number,
    accrued_service_fee: PropTypes.number,
    accrued_service_fee_add: PropTypes.number,
    accrued_late_payment_fee: PropTypes.number,
    accrued_penalty: PropTypes.number,
    accrued_interest_penalty: PropTypes.number,
    accrued_vat: PropTypes.number,
    accrued_excise_duty: PropTypes.number,
    accrued_total: PropTypes.number,
    principal: PropTypes.number,
    tenor: PropTypes.number,
    promo_code: PropTypes.string,
    is_repeat: PropTypes.bool,
    extension_short_link_uri: PropTypes.string,
    full_payment_short_link_uri: PropTypes.string,
    part_payment_short_link_uri: PropTypes.string,
  }),
};
