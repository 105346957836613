import React, { useEffect, useState } from 'react';
import { Loading, useDataProvider, useNotify } from 'react-admin';
import {
  Chip, ButtonGroup, Button, Table, TableBody, TableRow, TableCell, TableHead, Tooltip, TablePagination, makeStyles,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';

import { openIntoNewTab } from '../../utils';
import { EngineDataDialog } from '../engines';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

const useStyles = makeStyles(() => ({
  errorWrapper: {
    fontSize: '10px',
    lineHeight: '12px',
    whiteSpace: 'pre-line',
    maxHeight: 60,
    padding: 0,
    overflow: 'hidden',
  },

  errorWrapperOpened: {
    maxHeight: 'none',
  },
}));

export const UserApiDataTable = ({ userId, refreshedAt }) => {
  const [data, setData] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showedError, setShowedError] = useState(null);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  useEffect(() => {
    dataProvider.getList('user_api_datas', {
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
      filter: { 'user.id': userId },
    }).then(({ data, total }) => {
      setData(data);
      setTotal(total);
      setLoading(false);
    }).catch((error) => {
      notify(`Error: ${error.message || 'User api data not found'}`, 'error');
      setLoading(false);
    });
  }, [notify, userId, refreshedAt, dataProvider, page, perPage]);
  if (loading) return <Loading />;

  const downloadFile = (url, err) => {
    setLoading(true);
    dataProvider
      .downloadFile(url, {})
      .then(({ data: objUrl }) => openIntoNewTab(objUrl))
      .catch((error) => {
        notify(`Error: ${error.message || err}`, 'error');
      })
      .finally(() => {
        setLoading(() => false);
      });
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  const handleDialogClose = () => setDialog((state) => !state);
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            {['ID', 'Engine', 'State', 'Error', 'Created at', 'Actions']
              .map(i => <TableCell key={i}>{i}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(i => (
            <TableRow key={i.id}>
              <TableCell>{i.id}</TableCell>
              <TableCell><Chip size={'small'} label={i.service} /></TableCell>
              <TableCell><Chip size={'small'} label={i.state} /></TableCell>
              <TableCell width={'40%'}>
                <div className={`${classes.errorWrapper} ${showedError === i.id ? classes.errorWrapperOpened : ''}`}>{i.error}</div>

                {i.error && i.error.length > 300 && (
                  <div>
                    <Button
                      color={'primary'}
                      size={'small'}
                      onClick={() => setShowedError(showedError !== i.id ? i.id : null)}
                      endIcon={showedError === i.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    >
                      {showedError === i.id ? 'Hide' : 'Show more'}
                    </Button>
                  </div>
                )}
              </TableCell>
              <TableCell><DateTimeBoxComponent value={i?.created_at}/></TableCell>
              <TableCell>
                <ButtonGroup size={'small'} color={'secondary'}>
                  <Button
                    onClick={
                      () => downloadFile(
                        `user_api_datas/${i.id}/response`,
                        'user api data download error',
                      )
                    }
                    disabled={!i.response}
                  >
                    <Tooltip title="download user api data" arrow>
                      <GetAppIcon />
                    </Tooltip>
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
      <EngineDataDialog
        array={(() => Object.keys(dialog).map((i) => ({ name: i, value: dialog[i] })))()}
        isOpened={!!dialog}
        onClose={handleDialogClose}
      />
    </>
  );
};

UserApiDataTable.propTypes = {
  userId: PropTypes.number.isRequired,
  refreshedAt: PropTypes.number,
};
