import { Filter, NumberInput, SelectInput, TextInput } from 'react-admin';

import { DateFilterInput } from '../../../filters';
import { AsyncSelectInput } from '../../../input';

export const ManualVerificationListRepeatFilter = (props) => (
  <Filter {...props} style={{ marginTop: 0, marginBottom: '20px' }} variant={'outlined'}>
    <NumberInput label="Id" source="id" alwaysOn />
    <NumberInput label="User Id" source="user->id" />
    <TextInput label="Document number" source="user->userDocuments->number" />
    <DateFilterInput label="Created before" source="created_at|before" before />
    <DateFilterInput label="Created after" source="created_at|after" after />
    <AsyncSelectInput
      label="Affiliate"
      source="affiliate"
      query={{ resource: 'affiliates', payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} } }}
      mapper={({ id, name }) => ({ value: id, name })}
    />
    <TextInput label="Verifier" source="verifier->username" />
    <SelectInput label="Status" source="state" choices={[
      { id: 'pending', name: 'Pending' },
      { id: 'processing', name: 'Processing' },
      { id: 'approved', name: 'Approved' },
      { id: 'rejected', name: 'Rejected' },
      { id: 'confirmed', name: 'Confirmed' },
      { id: 'cancelled', name: 'Cancelled' },
      { id: 'error', name: 'Error' },
    ]} />
  </Filter>
);
