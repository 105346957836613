import { List, Datagrid, TextField, NumberField, ChipField, FunctionField } from 'react-admin';
import { Link, Chip, ButtonGroup, useTheme } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';

import Pagination from '../../Pagination';
import { MarkAllButton, TransferMoneyButton } from '../../buttons';
import { CURRENCY } from '../../../constants';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

import { LoanListActions } from './LoanListActions';

const LOANS_LIST_PENDING = [
  <TextField key="id" source="id" />,
  <FunctionField key="name" label="Name" onClick={(e) => e.stopPropagation()}
    render={({ user_id, user_first_name = '', user_last_name = '' }) =>
      <Link style={{ whiteSpace: 'nowrap' }} color={'secondary'} href={`#users/${user_id}`}>{`#${user_id} ${user_first_name} ${user_last_name}`}</Link>} />,
  <NumberField key="principal" source="principal" options={{ style: 'currency', currency: CURRENCY }} />,
  <NumberField key="tenor" source="tenor" />,
  <ChipField key="state" size="small" source="state" sortable={false} />,
  <FunctionField key="is_repeat" sortable={false} label="Loan type" source="is_repeat" render={(record, key) =>
    <Chip size="small" label={record[key] ? 'Repeated' : 'New'} />} />,
  <FunctionField key="created_at" source="created_at" label="Created at" render={(record) =>
    <DateTimeBoxComponent value={record?.created_at} />} />,
  <FunctionField key="disbursed_at" source="disbursed_at" label="Disbursed at" render={(record) =>
    <DateTimeBoxComponent value={record?.disbursed_at} />} />,
  <FunctionField key="matured_at" source="matured_at" label="Matured at" render={(record) =>
    <DateTimeBoxComponent value={record?.matured_at} />} />,
  <TextField key="transaction_status_code" source="last_money_transfer_result_status_code" label="Transaction status code" sortable={false} />,
  <TextField key="account_number" source="account_number" label={'Account number'} sortable={false} />,
  <FunctionField key="button_tab" onClick={(e) => e.stopPropagation()} render={(record) =>
    <Link href={`#loans/${record.id}/show`} underline="none" target="_blank" rel="noreferrer">
      <OpenInNewIcon fontSize="small" />
    </Link>}
  />,
];

const BulkActionButtons = (props) => (
  <ButtonGroup {...props}>
    <MarkAllButton {...props} />
    <TransferMoneyButton {...props} />
  </ButtonGroup>
);

export const LoanListPending = ({ statefilterchoices = [], listfilters: ListFilters, ...props }) => {
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={<BulkActionButtons />}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilters statefilterchoices={statefilterchoices} />}
      actions={<LoanListActions />}
      {...props}
    >
      <Datagrid rowClick="show">
        {LOANS_LIST_PENDING.map((i) => i)}
      </Datagrid>
    </List>
  );
};

LoanListPending.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
};

export const LoanListPendingNotReady = ({ statefilterchoices = [], listfilters: ListFilters, ...props }) => {
  const theme = useTheme();
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilters statefilterchoices={statefilterchoices} />}
      actions={<LoanListActions />}
      {...props}
    >
      <Datagrid rowClick="show" rowStyle={() => ({ backgroundColor: theme.palette.error.light })}>
        {LOANS_LIST_PENDING.map((i) => i)}
      </Datagrid>
    </List>
  );
};

LoanListPendingNotReady.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
};
