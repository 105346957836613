import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  ChipField, CreateButton, Datagrid, Filter, List, NumberInput, sanitizeListRestProps,
  SelectInput, TextField, TextInput, TopToolbar, useListContext,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import { invalidateCache } from '../../../hooks';
import { marginZeroStyles } from '../../../constants';
import { TEMPLATE_CATEGORIES, TEMPLATE_TYPES } from '../../../utils';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput variant={'outlined'} label={'Id'} source={'id'} alwaysOn />
      <TextInput variant={'outlined'} label={'Key'} source={'key'} />
      <SelectInput variant={'outlined'} label={'Category'} source={'category'} choices={TEMPLATE_CATEGORIES} />
      <SelectInput variant={'outlined'} label={'Type'} source={'type'} choices={TEMPLATE_TYPES} />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {hasCreate && <CreateButton basePath={basePath} />}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

export const TemplateList = props => {
  invalidateCache('templates');

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}
    >
      <Datagrid rowClick={'edit'}>
        <TextField source={'id'} />
        <TextField source={'category'} />
        <ChipField size={'small'} source={'type'} />
        <TextField source={'locale'} />
        <TextField source={'key'} />
      </Datagrid>
    </List>
  );
};
