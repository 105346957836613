import { useHandbook } from './useHandbook';

export function useProducts () {
  const { isLoading, data: originalData, reload } = useHandbook('products', { 'order[createdAt]': 'desc' });

  const data = originalData.map((product) => ({
    id: product.id,
    name: product.name,
    code: product.code,
    isDisabled: !product.is_enabled || !product.is_actual,
    principal: {
      min: product.min_principal,
      first_min: product.first_loan_min_principal,
      max: product.max_principal,
      first_max: product.first_loan_max_principal,
      step: product.principal_step,
    },
    tenor: {
      min: product.min_tenor,
      first_min: product.first_loan_min_tenor,
      max: product.max_tenor,
      first_max: product.first_loan_max_tenor,
      step: product.tenor_step,
    },
    createdAt: product.created_at,
  }));

  return {
    isLoading,
    data,
    choices: data,
    reload,
  };
}
