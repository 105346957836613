import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import {
  CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Chip, Button, ButtonGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../utils';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';
import { ActivateExtensionDialog } from '../dialogs';

export const ExtensionTable = ({ loanId, refreshedAt, onTransition }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();
  const [extensions, setExtensions] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isActivateExtensionDialogOpened, setIsActivateExtensionDialogOpened] = useState(false);
  const [rowId, setRowId] = useState();

  useEffect(() => {
    dataProvider.getList('extensions', {
      filter: { 'loan.id': loanId },
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
    })
      .then(({ data, total }) => {
        setExtensions(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, loanId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(extensions)) return null;

  const openActivationDialog = id => {
    setRowId(id);
    setIsActivateExtensionDialogOpened(true);
  };

  const closeActivationDialog = () => {
    setRowId(undefined);
    setIsActivateExtensionDialogOpened(false);
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  const handleSubmit = (extension_date) => {
    onTransition(rowId, 'activate', { extension_date });
    closeActivationDialog();
  };
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Tenor</TableCell>
            <TableCell>Started at</TableCell>
            <TableCell>Requested by</TableCell>
            <TableCell>Ended at</TableCell>
            <TableCell align={'right'}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {extensions.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell><DateTimeBoxComponent value={row?.created_at} /></TableCell>
              <TableCell><Chip size={'small'} label={row.state} /></TableCell>
              <TableCell>{row.price && formatCurrency(row.price)}</TableCell>
              <TableCell>{row.tenor}</TableCell>
              <TableCell><DateTimeBoxComponent value={row?.started_at} /></TableCell>
              <TableCell>{row.created_by_username}</TableCell>
              <TableCell><DateTimeBoxComponent value={row?.ended_at} /></TableCell>
              <TableCell align={'right'}>
                <ButtonGroup size={'small'} variant={'contained'} color={'primary'} disabled={ permissions.indexOf('CAN_EXTENSION_EDIT') === -1 }>
                  {row.enabled_transitions.indexOf('reject') !== -1 && <Button onClick={() => onTransition(row.id, 'reject')}>Reject</Button>}
                  {row.enabled_transitions.indexOf('activate') !== -1 && <Button onClick={() => openActivationDialog(row.id)}>Activate</Button>}
                  {row.enabled_transitions.indexOf('cancel') !== -1 && <Button onClick={() => onTransition(row.id, 'cancel')}>Cancel</Button>}
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
      { permissions.indexOf('CAN_EXTENSION_EDIT') !== -1 &&
        <ActivateExtensionDialog
          isOpened={isActivateExtensionDialogOpened}
          onClose={closeActivationDialog}
          onSubmit={handleSubmit}
        />
      }
    </>
  );
};

ExtensionTable.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onTransition: PropTypes.func,
};
