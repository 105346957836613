import { useState, useEffect } from 'react';
import { useNotify, useDataProvider, usePermissions } from 'react-admin';
import {
  Table, TableHead, TableBody, TableRow, TableCell, TablePagination, CircularProgress, Chip, Menu, MenuItem, ButtonGroup, Button,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import DetailsIcon from '@material-ui/icons/Details';
import PropTypes from 'prop-types';

import { openIntoNewTab } from '../../utils';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

export const MoneyTransferTable = ({ loanId, refreshedAt, onTransition }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();
  const [moneyTransfers, setMoneyTransfers] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [transmissionLogsId, setTransmissionLogsId] = useState(null);
  const [transmissionLogs, setTransmissionLogs] = useState([]);
  const [transmissionLogsMenuAnchorEl, setTransmissionLogsMenuAnchorEl] = useState(null);
  const isTransmissionLogsMenuOpened = Boolean(transmissionLogsMenuAnchorEl);

  useEffect(() => {
    dataProvider.getList('money_transfers', {
      filter: { 'loan.id': loanId },
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
    })
      .then(({ data, total }) => {
        setMoneyTransfers(data);
        setTotal(total);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, loanId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(moneyTransfers)) return null;

  const openTransmissionLogsMenu = (anchorEl, id, logs) => {
    setTransmissionLogsId(id);
    setTransmissionLogs(logs);
    setTransmissionLogsMenuAnchorEl(anchorEl);
  };

  const closeTransmissionLogsMenu = () => {
    setTransmissionLogsMenuAnchorEl(null);
    setTransmissionLogs([]);
    setTransmissionLogsId(null);
  };

  const handleTransmissionLogsMenuClick = (log) => {
    const id = transmissionLogsId;
    closeTransmissionLogsMenu();
    dataProvider.downloadFile(`money_transfers/${id}/transmission_log/${log}`)
      .then(({ data: objUrl }) => openIntoNewTab(objUrl))
      .catch(error => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Gateway</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Status code</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Updated at</TableCell>
            <TableCell>Scheduled at</TableCell>
            <TableCell>Finished at</TableCell>
            <TableCell>Triggered by</TableCell>
            <TableCell align={'right'}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {moneyTransfers.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell><Chip size={'small'} label={row.gateway} /></TableCell>
              <TableCell><Chip size={'small'} label={row.state} /></TableCell>
              <TableCell><Chip size={'small'} label={row.status} /></TableCell>
              <TableCell>{row.transaction_id}</TableCell>
              <TableCell>{row.result_status_code && <Chip size={'small'} label={row.result_status_code} />}</TableCell>
              <TableCell>
                <DateTimeBoxComponent value={row?.created_at} />
              </TableCell>
              <TableCell>
                <DateTimeBoxComponent value={row?.updated_at} />
              </TableCell>
              <TableCell>
                <DateTimeBoxComponent value={row?.scheduled_at} />
              </TableCell>
              <TableCell>
                <DateTimeBoxComponent value={row?.finished_at} />
              </TableCell>
              <TableCell>{
                row.triggered_by_admin_id && row.triggered_by_admin_username
                  ? row.triggered_by_admin_username.concat('#').concat(row.triggered_by_admin_id)
                  : 'system'
              }</TableCell>
              <TableCell align={'right'}>
                <ButtonGroup color={'secondary'} variant={'outlined'} size={'small'}>
                  <Button
                    disabled={row.transmission_logs.length === 0 || permissions.indexOf('CAN_MONEY_TRANSFER_EDIT') === -1}
                    onClick={(e) => openTransmissionLogsMenu(e.currentTarget, row.id, row.transmission_logs)}
                  >
                    <DetailsIcon />
                  </Button>
                  <Button
                    disabled={row.enabled_transitions.indexOf('interrupt') === -1 || permissions.indexOf('CAN_MONEY_TRANSFER_EDIT') === -1}
                    onClick={() => onTransition(row.id, 'interrupt')}
                  >
                    <CancelIcon />
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
      <Menu
        anchorEl={transmissionLogsMenuAnchorEl}
        keepMounted
        open={isTransmissionLogsMenuOpened}
        onClose={closeTransmissionLogsMenu}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
          },
        }}
      >
        {transmissionLogs.map((option, idx) => (
          <MenuItem key={idx} onClick={() => handleTransmissionLogsMenuClick(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

MoneyTransferTable.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onTransition: PropTypes.func,
};
