import React from 'react';
import { BooleanInput, FormWithRedirect, NumberInput, required, SaveButton, TextInput, Toolbar } from 'react-admin';
import { Grid, Paper, Box, Typography, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';

export const RejectionReasonsForm = ({ save, ...props }) => (
  <FormWithRedirect
    save={ (data, ...rest) => save(data, ...rest) }
    {...props}
    render={(formProps) => (
      <form>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={6}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6" gutterBottom={ false }>{props.isEdit ? 'Edit' : 'Create'} rejection reason</Typography>
              </Box>
              <Divider />
              <Box p={2}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextInput variant={'outlined'} fullWidth label="Name" source="name" validate={ [required()] } />
                  </Grid>
                  <Grid item xs={12}>
                    <NumberInput variant={'outlined'} source="moratorium_in_days" validate={ [required()] } fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <BooleanInput variant={'outlined'} label="Enabled" source="is_enabled" />
                  </Grid>
                </Grid>
              </Box>
              <Toolbar>
                <SaveButton
                  label="Save"
                  redirect="list"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              </Toolbar>
            </Paper>
          </Grid>
        </Grid>
      </form>
    )}
  />
);

RejectionReasonsForm.propTypes = {
  save: PropTypes.func,
  isEdit: PropTypes.bool,
};
