export const time = value => {
  if (typeof value !== 'string' || !/^\d{2}:\d{2}:\d{2}$/.test(value)) return 'Invalid time format.';
  let hour = -1;
  let minute = -1;
  let second = -1;
  [hour, minute, second] = value.split(':').map(v => parseInt(v[0] === '0' ? v[1] : v));
  if (hour < 0 || hour > 23) return 'Invalid hour.';
  if (minute < 0 || minute > 59) return 'Invalid minute.';
  if (second < 0 || second > 59) return 'Invalid second.';
};

export const symbolsValidator = (value, min, max) => {
  if (value === undefined) return undefined;
  const regex = new RegExp(`^.{${min},${max}}$`);
  if (!regex.test(value)) {
    return `Min symbols is ${min}, max ${max}`;
  }
};

export const phoneValidator = (number) => {
  if (typeof number !== 'string') {
    return;
  }

  if (/^\d{10,11}$/.test(number) === false) {
    return 'Phone number should consist of 9 or 10 digits';
  }
};
