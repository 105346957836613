import { useDataProvider, useNotify } from 'react-admin';
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import PropTypes from 'prop-types';

import { DateTimeBoxComponent } from '../../../DateTimeBoxComponent';

export const RobocallsListDetailsTable = ({ requestId }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dataProvider
      .query('robocall_task_requests/' + requestId + '/details', { method: 'GET' })
      .then(({ data }) => {
        setDetails(data);
      })
      .catch((error) =>
        notify(`Error: ${error.message || 'not found'}`, 'error'),
      )
      .finally(() => setLoading(false));
  }, [dataProvider, notify, requestId]);

  if (loading) {
    return (
      <Box textAlign="center">
        <Box p={2} width="300px">
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Call Id</TableCell>
            <TableCell>Request id</TableCell>
            <TableCell>Callee name</TableCell>
            <TableCell>Callee number</TableCell>
            <TableCell>Call time</TableCell>
            <TableCell>Result</TableCell>
            <TableCell>Comment</TableCell>
            <TableCell align="center">Additional data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details.map((item, key) => {
            return (
              <TableRow key={key + '_log'}>
                <TableCell>{item.call_id}</TableCell>
                <TableCell>{item.task_request_id}</TableCell>
                <TableCell>{item.callee_name}</TableCell>
                <TableCell>{item.callee_number}</TableCell>
                <TableCell>
                  <DateTimeBoxComponent value={item?.call_time} />
                </TableCell>
                <TableCell>{item.result}</TableCell>
                <TableCell>{item.comment}</TableCell>
                <TableCell>
                  {Object.keys(item.additional_data).map((i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{i}</TableCell>
                        <TableCell>{item.additional_data[i]}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

RobocallsListDetailsTable.propTypes = {
  requestId: PropTypes.string,
};
