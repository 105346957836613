import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Create, SelectInput, SaveButton, FormWithRedirect, FormDataConsumer, useNotify, useDataProvider,
} from 'react-admin';
import { DateTimePickerInput } from './../../input/DateTimePickerInput';
import { FormSpy } from 'react-final-form';
import { makeStyles, Paper, Grid, Divider, Typography, CircularProgress, Box } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
}));

const ParamsInput = ({ importerId }) => {
  switch (importerId) {
    default:
      return null;
  }
};

ParamsInput.propTypes = {
  importerId: PropTypes.string,
};

const FileInput = ({ selectedFile, onFileChange }) => {
  const classes = useStyles();

  return (
    <label htmlFor="upload-button">
      <IconButton color="primary" aria-label="upload file" component="span">
        <CloudUploadIcon />
      </IconButton>
      <Typography variant="caption" display="inline" gutterBottom>
        { selectedFile ? `${selectedFile.name} (${selectedFile.size} bytes)` : 'Upload file' }
      </Typography>
      <input id="upload-button" className={ classes.input } accept="text/csv" type="file" onChange={ e => onFileChange(e.target.files[0]) } />
    </label>
  );
};

FileInput.propTypes = {
  selectedFile: PropTypes.object,
  onFileChange: PropTypes.func,
};

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [importers, setImporters] = useState([]);
  const [transmiters, setTransmiters] = useState([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);

  const onFileChange = file => {
    setIsProcessingFile(true);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setIsProcessingFile(false);
      setSelectedFile(file);
      setFileContent(reader.result);
    });
    reader.readAsText(file);
  };

  useEffect(() => {
    dataProvider.query('import_processes/importer_list', { method: 'GET' })
      .then(({ data }) => {
        setImporters(data.filter(i => i.id !== 'user'));
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  useEffect(() => {
    dataProvider.query('notifications/transmitters', { method: 'GET' })
      .then(({ data }) => {
        setTransmiters(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  if (loading === false && error) {
    notify(`Error: ${error.message}`, 'error');
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom={ false }>
            Create import process
          </Typography>
          <Divider />
          <FormWithRedirect
            save={({ importer_id, transmitter_id, start_date, end_date, ...params }, ...rest) => {
              save(
                ...[
                  {
                    importer_id,
                    data: fileContent,
                    params: {
                      ...params[importer_id],
                      transmitter_id,
                      start_date,
                      end_date,
                    },
                  },
                  ...rest,
                ],
              );
            }}
            {...props}
            render={formProps => (
              <>
                <SelectInput
                  helperText={false}
                  className={classes.fullWidth}
                  onChange={ () => formProps.form.change('transmitter_id', null) }
                  source='importer_id'
                  choices={importers}
                />
                <FormSpy subscription={{ values: {} }}>
                  {({ values: { importer_id: importerId } }) => {
                    return (
                      <>
                        {importerId === 'notification'
                          ? (
                            <FormDataConsumer>
                              {({ formData, ...rest }) => (
                                <SelectInput
                                  source='transmitter_id'
                                  fullWidth
                                  helperText={false}
                                  choices={formData.importer_id === 'notification' ? transmiters : []}
                                  {...rest}
                                />
                              )}
                            </FormDataConsumer>
                          )
                          : (importerId === 'collector_company' || importerId === 'collector_assign') && <FormDataConsumer>
                            {({ formData, ...rest }) => (<Box display="flex" flexDirection="row" alignItems='center' flexWrap='wrap' gridGap={8}>
                              <DateTimePickerInput label='Start date' fullWidth={false} source='start_date' onChange={(value) => formProps.form.change('start_date', value)} maxDate={formData?.end_date} {...rest} />
                              <DateTimePickerInput label='End date' fullWidth={false} source='end_date' onChange={(value) => formProps.form.change('end_date', value)} minDate={formData?.start_date} {...rest}/>
                            </Box>)}
                          </FormDataConsumer> }
                        <ParamsInput importerId={importerId} />
                      </>
                    );
                  } }
                </FormSpy>
                { isProcessingFile ? <CircularProgress /> : <FileInput selectedFile={ selectedFile } onFileChange={ onFileChange } /> }
                <Divider className={ classes.my2 } />
                <SaveButton
                  label="Submit"
                  saving={formProps.saving}
                  disabled={ !fileContent }
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              </>
            )}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

export const ImportProcessCreate = props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
