export const assignEssence = (
  dataProvider,
  notify,
  refresh,
  query,
  body,
) => {
  return dataProvider.query(
    query,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
  ).catch((error) => notify(`Error: ${error.message}`, 'error'),
  ).finally(() => refresh());
};

export const changeStatus = (
  status,
  dataProvider,
  notify,
  setOpenDropdownDialog,
  refresh,
  query,
  body,
) => {
  return dataProvider.query(
    query,
    {
      method: 'POST',
      body: JSON.stringify(body),
    })
    .then(() => {
      notify('Success: status changed to ' + status, 'success');
      setOpenDropdownDialog(state => !state);
    },
    ).catch((error) => notify(`Error: ${error.message || 'change status error'}`, 'error'),
    ).finally(() => refresh());
};
