import React, { cloneElement } from 'react';
import { sanitizeListRestProps, TopToolbar, useListContext } from 'react-admin';
import PropTypes from 'prop-types';

export const UserListActions = ({ className, filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    </TopToolbar>
  );
};

UserListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};
