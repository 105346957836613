import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanField,
  ChipField,
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';

import Pagination from '../../Pagination';
import { WEEK_DAYS } from '../../../constants';

const ListFilter = (props) => <Filter {...props}>
  <NumberInput variant={'outlined'} label={'Id'} source={'id'} alwaysOn />
</Filter>;

const ListActions = ({ className, filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {hasCreate && <CreateButton basePath={basePath} />}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

export const MassSendingCampaignList = (props) => {
  const handleRenderPeriod = (record) => {
    const monthDays = Array.isArray(record?.params?.month_days) ? record.params.month_days : [];
    const weekDays = Array.isArray(record?.params?.week_days) ? record.params.week_days : [];

    monthDays.sort((a, b) => {
      a = a < 0 ? a + 100 : a;
      b = b < 0 ? b + 100 : b;

      return Math.sign(a - b);
    });
    weekDays.sort();

    return (
      <>
        {monthDays.length > 0 && (
          <>
            <div><b>Days of month:</b></div>
            <div>{monthDays.map(day => day === -1 ? 'Last' : day).join(', ')}</div>
          </>
        )}

        {weekDays.length > 0 && (
          <>
            <div><b>Days of week:</b></div>
            <div>{weekDays.map(day => WEEK_DAYS[day - 1] || 'UN').join(', ')}</div>
          </>
        )}
      </>
    );
  };

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}
    >
      <Datagrid rowClick={'edit'}>
        <TextField source={'id'} />
        <TextField source={'name'} />
        <ChipField source={'audience_id'} size={'small'} />
        <ChipField source={'transmitter_id'} size={'small'} />
        <TextField source={'template_key'} />
        <FunctionField label={'Period'} render={handleRenderPeriod} />
        <TextField source="start_time" />
        <BooleanField source="is_enabled" />
      </Datagrid>
    </List>
  );
};
