import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Edit, TextInput, NumberInput, BooleanInput, SelectInput, SaveButton,
  DeleteButton, FormWithRedirect, required, maxLength, minValue, maxValue, FormDataConsumer,
} from 'react-admin';
import { makeStyles, Box, Paper, Grid, Divider, Typography } from '@material-ui/core';

import { withServerAutocomplete, SelectAutoComplete } from '../../select';
import {
  USER_EVENT as userEvents, TRANSMITTERSLIST_DEFAULT as transmitters, ALPHA_NAMES, formatChoices,
} from '../../../utils';

const AutoComplete = withServerAutocomplete(SelectAutoComplete);

const useStyles = makeStyles((theme) => ({
  mt4: {
    marginTop: theme.spacing(4),
  },
}));

const FormWrapper = ({ permissions, save, ...props }) => {
  const classes = useStyles();
  const [valueKey, setValueKey] = useState('');
  const [value, setValue] = useState(props.record.template_key || '');
  const isEditable = permissions.indexOf('CAN_USER_EVENT_NOTIFICATION_EDIT') !== -1;

  return (
    <FormWithRedirect
      save={ (data, ...rest) => {
        save(...[{ ...data, template_key: value, _params: { method: 'PATCH' } }, ...rest]);
      } }
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={ false }>Update user notification</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <BooleanInput label="Enabled" source="is_enabled" disabled={ !isEditable } />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput className={ classes.mt4 } source="name" validate={ [maxLength(255)] } fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="trigger_event" choices={ userEvents } validate={ [required()] } fullWidth disabled={ !isEditable } />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="interrupt_event" choices={ userEvents } allowEmpty fullWidth disabled={ !isEditable } />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="transmitter_id"
                        inputProps={{
                          onChange: () => formProps.form.change('alpha_name', null),
                        }}
                        choices={ transmitters }
                        validate={ [required()] }
                        fullWidth
                        disabled={ !isEditable }
                      />
                    </Grid>
                    <FormDataConsumer key='consumer'>
                      {({ formData, ...rest }) =>
                        (formData.transmitter_id === 'text_ware' || formData.transmitter_id === 'text_ware_promo') && (
                          <Grid item xs={12} sm={6} key='alpha_name'>
                            <SelectInput
                              helperText={false}
                              source='alpha_name'
                              validate={[required()]}
                              choices={formData.transmitter_id ? formatChoices(ALPHA_NAMES[formData.transmitter_id]) : []}
                              {...rest}
                              fullWidth
                              disabled={!isEditable}
                            />
                          </Grid>
                        )}
                    </FormDataConsumer>
                    <Grid item xs={12} sm={6}>
                      <NumberInput source="delay" min={0} max={65535} step={1} validate={ [required(), minValue(0), maxValue(65535)] } fullWidth helperText="In minutes" disabled={ !isEditable } />
                    </Grid>
                    <Grid item xs={12}>
                      <AutoComplete
                        perOnePage={15}
                        resource="admin/templates"
                        label="Template Key"
                        value={value}
                        filter={valueKey ? { key: valueKey } : {}}
                        onChangeInput={val => setValueKey(val)}
                        setValue={setValue}
                        prop={'key'}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display="flex" justifyContent="space-between" padding={2}>
                  <SaveButton
                    disabled={ !isEditable }
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                  <DeleteButton
                    undoable={ false }
                    disabled={ !isEditable }
                    basePath={ formProps.basePath }
                    record={ formProps.record }
                    resource={ formProps.resource }
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  permissions: PropTypes.array,
  save: PropTypes.func,
  record: PropTypes.shape({
    template_key: PropTypes.string,
  }),
};

const UserEventNotificationEdit = ({ permissions = [], ...props }) => (
  <Edit component="div" undoable={ false } {...props}>
    <FormWrapper permissions={ permissions } />
  </Edit>
);

UserEventNotificationEdit.propTypes = {
  permissions: PropTypes.array,
};

export default UserEventNotificationEdit;
