import { useEffect, useRef, useState } from 'react';
import { Box, Card, TablePagination } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { array, func, object, string, number, oneOfType } from 'prop-types';

import { getFormattedDay } from '../../utils';

import { ClickableCell } from './ClickableCell';

const CellBox = ({ value, justify = 'center', align = 'center' }) => {
  return (
    <Box
      flexGrow={1}
      display={'flex'}
      alignItems={align}
      padding={`${justify === 'flex-end' ? '0 10px 0 0' : '0'}`}
      justifyContent={justify}
    >{value ?? <Skeleton style={{ width: '100%', height: '100%' }} />}</Box>
  );
};

CellBox.propTypes = {
  value: oneOfType([object, string, number]),
  justify: string,
  align: string,
};

export const ClickableGrid = ({
  rowsData,
  columnsData,
  markedCells,
  onSelectCell,
  cellSize = '30px',
  year,
  month,
  markTypes,
  page = 1,
  perPage = 10,
  total = 0,
  onSetPage,
  onPerPageChange,
}) => {
  const [randomKey, setRandomKey] = useState(Math.round(Math.random() * 100));
  const wrapRef = useRef();

  useEffect(() => {
    setRandomKey(Math.round(Math.random() * 100));
  }, [markedCells]);
  useEffect(() => {
    const updateGridTemplateColumns = () => {
      if (wrapRef.current) {
        const fixedBarW = document.getElementsByClassName('MuiDrawer-root')[0].clientWidth;
        const parentWidth = document.body.offsetWidth - fixedBarW;
        const numColumns = columnsData.length;
        const columnWidth = parentWidth ? `${(parentWidth - 200) / numColumns}px` : `calc((100% - 200) / ${numColumns})`;
        const gridTemplateColumns = `200px repeat(${numColumns}, ${columnWidth})`;
        wrapRef.current.style.gridTemplateColumns = gridTemplateColumns;
      }
    };

    updateGridTemplateColumns();
    window.addEventListener('resize', updateGridTemplateColumns);
    return () => window.removeEventListener('resize', updateGridTemplateColumns);
  }, [columnsData]);
  return (
    <>
      <Box
        component={Card}
        display={'grid'}
        gridGap={'3px'}
        padding={'20px'}
        ref={wrapRef}
        gridTemplateRows={`repeat(${rowsData.length + 1}, ${cellSize})`}
      >
        <CellBox justify={'flex-end'} value={<b>Admin name</b>} />
        {columnsData.map((day, colKey) => <CellBox key={`column-${colKey}`} value={day} />)}
        {rowsData.map((row, rowKey) => (
          <>
            <CellBox key={`row-${rowKey}`} justify={'flex-end'} value={<b>{row.username}</b>} />
            {columnsData.map((day) => (
              <ClickableCell
                key={`${year}-${month}-${day}-${row.id}-${randomKey}`}
                data={day}
                isMarked={markedCells.find((markedDay) => markedDay.admin === row.id && markedDay.day === getFormattedDay(year, month, day))}
                onSelect={(isMarked) => onSelectCell({ isMarked, day, id: row.id })}
                markTypes={markTypes}
              />
            ))}
          </>
        ))}
      </Box>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => onSetPage(page + 1)}
        onRowsPerPageChange={onPerPageChange}
      />
    </>
  );
};

ClickableGrid.propTypes = {
  rowsData: array,
  columnsData: array,
  markedCells: array,
  onSelectCell: func,
  cellSize: string,
  markTypes: array,
  year: number,
  month: number,
  page: number,
  perPage: number,
  total: number,
  onSetPage: func,
  onPerPageChange: func,
};
