import React from 'react';
import { useNotify } from 'react-admin';
import { Paper, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import { formatPhoneNumber } from '../../utils';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
  },
  card: {
    padding: theme.spacing(3),
    position: 'fixed',
    bottom: theme.spacing(3),
    left: '50%',
    transform: 'translateX(-50%)',
    minWidth: '500px',
  },
  callTitle: {
    marginBottom: theme.spacing(2),
  },
  figure: {
    margin: 0,
  },
  audio: {
    width: '100%',
  },
}));

export const AudioPlayerDialog = ({ onClose, record }) => {
  const classes = useStyles();
  const notify = useNotify();

  React.useLayoutEffect(() => {
    function handleMediaError (e) {
      switch (e.target.error.code) {
        case e.target.error.MEDIA_ERR_ABORTED:
        case e.target.error.MEDIA_ERR_NETWORK:
        case e.target.error.MEDIA_ERR_DECODE:
        case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          onClose();
          notify('Error: The recording could not be played', 'error');
          break;
        default:
          onClose();
          notify('Error: The recording could not be played', 'error');
      }
    }

    document.getElementById('audio-rec').addEventListener('error', handleMediaError);
  }, [notify, onClose]);

  return (
    <Paper elevation={24} onClose={onClose} className={classes.card}>
      <DateTimeBoxComponent inline label={`Call ${formatPhoneNumber(record.phone)} on`} value={record?.call_date}/>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <figure className={classes.figure}>
        <audio
          id={'audio-rec'}
          className={classes.audio}
          controls
          controlsList={'nodownload noplaybackrate'}
          src={record.audio_link}
        />
      </figure>
    </Paper>
  );
};

AudioPlayerDialog.propTypes = {
  onClose: PropTypes.func,
  record: PropTypes.shape({
    call_date: PropTypes.string,
    phone: PropTypes.string,
    audio_link: PropTypes.string,
  }),
};
