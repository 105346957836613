import { MASK_SYMBOL, NIDA_PLACEHOLDER } from '../../constants';

import { validateFieldSync, yup } from './configYup';

const dynamicalMessage = {
  phone: {
    from: 0,
    to: 9,
    transform: { regular: /\D/gi },
    message: 'phone_should_consist_12_digits',
  },
  code: { from: 0, to: 4 },
  nida: {
    from: 23,
    to: 23,
    // message: 'nida_err_validate',
    message: 'Provided value is incorrect',
    transform: { regular: /^[\d-]*$/ },
  },
  employmentCompanyPhone: {
    from: 0,
    to: 10,
    message: 'phone_should_consist_10_digits',
    transform: { regular: /\D/gi },
  },
};

export const validateNIDA = {
  validate: validateFieldSync(yup.string().nullable().minLength(dynamicalMessage.nida)),
  formatter: (value) => {
    if (!value) return '';

    const cleanedValue = value.replace(/[^\d]/g, '');

    let formattedValue = '';
    let valueIndex = 0;

    for (let i = 0; i < NIDA_PLACEHOLDER.length; i++) {
      if (NIDA_PLACEHOLDER[i] === MASK_SYMBOL) {
        if (valueIndex < cleanedValue.length) {
          formattedValue += cleanedValue.charAt(valueIndex);
          valueIndex++;
        } else {
          formattedValue += MASK_SYMBOL;
        }
      } else {
        formattedValue += NIDA_PLACEHOLDER[i];
      }
    }
    return formattedValue;
  },
  parser: (value) => {
    return value.replace(/[^\d]/g, '');
  },
};

export const validateText = {
  validate: validateFieldSync(
    yup.string().nullable().test('not-zero', 'Can\'t be zero', (value) => value !== '0'),
  ),
};

export const validateSelect = {
  validate: validateFieldSync(yup.string().nullable()),
};
