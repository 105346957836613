import { useController } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { string, object, func } from 'prop-types';

import { formatLabel } from '../../../utils';

export const TextFieldHookForm = ({ name, label, control, onChange }) => {
  const { field } = useController({ name, control });

  const handleChange = (event) => {
    field.onChange(event.target.value);
    if (onChange) onChange(name, event.target.value);
  };
  return (
    <TextField
      {...field}
      name={name}
      label={formatLabel(label)}
      type={'text'}
      variant={'outlined'}
      size={'small'}
      fullWidth
      onChange={handleChange}
    />
  );
};

TextFieldHookForm.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  control: object.isRequired,
  onChange: func,
};
