import React from 'react';
import PropTypes from 'prop-types';

import { CollectionActionTreeListContactTreeItem } from './CollectionActionTreeListContactTreeItem';

export const CollectionActionTreeListContactTree = ({ contactsTree, types, typeValues, onClickCreate, onClickEdit, onClickDelete, toggleExpand, expandedContacts }) => {
  const typeValueIds = typeValues.map(({ id }) => id);

  return contactsTree.filter((contact) => typeValueIds.includes(contact.type_value)).map((contact) => (
    <CollectionActionTreeListContactTreeItem
      toggleExpand={toggleExpand}
      expandedContacts={expandedContacts}
      key={contact.id}
      contact={contact}
      types={types}
      typeValues={typeValues}
      onClickCreate={onClickCreate}
      onClickEdit={onClickEdit}
      onClickDelete={onClickDelete}
    />
  ));
};

CollectionActionTreeListContactTree.propTypes = {
  contactsTree: PropTypes.array,
  types: PropTypes.array,
  typeValues: PropTypes.array,
  onClickCreate: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  toggleExpand: PropTypes.func,
  expandedContacts: PropTypes.array,
};
