import { Box, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

const LegendItem = ({ color, label }) => (
  <Box display={'flex'} alignItems={'center'} gridGap={'10px'} padding={'5px'}>
    <Box variant={'square'} style={{ borderRadius: 4, width: 30, height: 30, flexBasis: 30, flexShrink: 0, backgroundColor: color }} />
    <Typography variant={'body2'}>{label}</Typography>
  </Box>
);

LegendItem.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

export const ApplicationColorLegend = () => {
  const theme = useTheme();
  return (
    <Box display={'grid'} gridGap={'5px'} gridTemplateColumns={'repeat(3, 1fr)'} borderBottom={'1px solid #ddd'}>
      <LegendItem color={theme.palette.error.light} label={'ADE finished with error'} />
      <LegendItem color={theme.palette.success.light} label={'Application confirmed and loan is activated'} />
      <LegendItem color={theme.palette.warningAlt.light} label={'Application confirmed and loan is not activated'} />
      <LegendItem color={theme.palette.warning.light} label={'Application is pending, manual verification and verifier is not assigned'} />
      <LegendItem color={theme.palette.primary.light} label={'Application is pending, manual verification and verifier is assigned'} />
      <LegendItem color={theme.palette.secondary.light} label={'Application is approved more than 5 minutes ago, but still not confirmed'} />
    </Box>
  );
};
