import { Filter, TextInput, NumberInput, SelectInput } from 'react-admin';
import PropTypes from 'prop-types';

import { DateFilterInput, QuickFilterInput } from '../../filters';
import { LOAN_TYPES } from '../../../constants';
import { AsyncSelectInput } from '../../input';

export const ListFilters = ({ statefilterchoices, ...props }) => {
  return (
    <Filter {...props} variant={'outlined'} style={{ marginTop: 0, marginBottom: '20px' }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="User name" source="search_by_name"/>
      <TextInput label="Contract number" source="application->contractNumber" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <NumberInput label="Phone number" source="phone_book_number" />
      {statefilterchoices.length ? <SelectInput label="Status" source="state" choices={statefilterchoices} /> : null}
      <AsyncSelectInput
        label="Affiliate"
        source="user->affiliate->id"
        query={{ resource: 'affiliates', payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} } }}
        mapper={({ id, name }) => ({ value: id, name })}
      />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Disbursed before" source="disbursed_at|before" before />
      <DateFilterInput label="Disbursed after" source="disbursed_at|after" after />
      <DateFilterInput label="Matured before" source="matured_at|before" before />
      <DateFilterInput label="Matured after" source="matured_at|after" after />
      <QuickFilterInput source="is_extension_requested" label="Extension requested" defaultValue={true} />
      <QuickFilterInput source="is_extension_possible" label="Extension possible" defaultValue={true} />
      <QuickFilterInput source="count_extension_more_than=1" label="Extensions amount" defaultValue={true} />
      <QuickFilterInput source="is_extended" label="Extended" defaultValue={true} />
      <SelectInput label="Loan type" source="is_repeat" choices={LOAN_TYPES} />
      <TextInput label="Residence municipality" source="user->residence->municipality" />
      <NumberInput label="Max days past due" source="days_past_due|lte" />
      <NumberInput label="Min days past due" source="days_past_due|gte" />
    </Filter>
  );
};

ListFilters.propTypes = {
  statefilterchoices: PropTypes.array,
};
