import { useState } from 'react';
import { useDataProvider, useGetList, useNotify, usePermissions, useShowController } from 'react-admin';
import { Button, ButtonGroup, CircularProgress } from '@material-ui/core';
import { shape, number, array, string, bool, func } from 'prop-types';
import { Alert } from '@material-ui/lab';

import * as Dialogs from '../../../dialogs';
import * as Tables from '../../../tables';
import DebounceButton from '../../../button/DebounceButton';
import { TabPanel } from '../../../TabPanel';

export const LoanShowAdditionalInfo = ({ activeTabIndex, setActiveTabIndex, ...props }) => {
  const notify = useNotify();
  const { permissions = [] } = usePermissions();
  const dataProvider = useDataProvider();
  const { record: loan, loading, loaded, refetch, error } = useShowController(props);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddNotificationDialogOpen, setIsAddNotificationDialogOpen] = useState(false);
  const [isAddIncomeDialogOpen, setIsAddIncomeDialogOpen] = useState(false);
  const [isAddNoteDialogOpen, setIsAddNoteDialogOpen] = useState(false);
  const [isAddExtensionDialogOpen, setIsAddExtensionDialogOpen] = useState(false);
  const [isCollectionCallDialogOpen, setIsCollectionCallDialogOpen] = useState(false);
  const [isUserContactsDialogOpen, setIsUserContactsDialogOpen] = useState(false);

  const isNotificationViewPermitted = permissions.includes('CAN_NOTIFICATION_VIEW');
  const isNotificationEditPermitted = permissions.includes('CAN_NOTIFICATION_EDIT');
  const isPhoneBookViewPermitted = permissions.includes('CAN_PHONE_BOOK_VIEW');
  const isPhoneBookEditPermitted = permissions.includes('CAN_PHONE_BOOK_EDIT');
  const isCollectionCallViewPermitted = permissions.includes('CAN_COLLECTION_CALL_VIEW');
  const isCollectionCallEditPermitted = permissions.includes('CAN_COLLECTION_CALL_EDIT');
  const isNoteViewPermitted = permissions.includes('CAN_NOTE_VIEW');
  const isNoteEditPermitted = permissions.includes('CAN_NOTE_EDIT');
  const isAgreementViewPermitted = permissions.includes('CAN_AGREEMENT_VIEW');
  const isMoneyTransferViewPermitted = permissions.includes('CAN_MONEY_TRANSFER_VIEW');
  const isIncomeViewPermitted = permissions.includes('CAN_INCOME_VIEW');
  const isIncomeEditPermitted = permissions.indexOf('CAN_INCOME_EDIT');
  const isIncomeAddPermitted = permissions.includes('CAN_INCOME_ADD');
  const isExtensionViewPermitted = permissions.includes('CAN_EXTENSION_VIEW');
  const isExtensionAddPermitted = permissions.includes('CAN_EXTENSION_ADD');
  const isLoanViewPermitted = permissions.includes('CAN_LOAN_VIEW');
  const isCollectionScoreViewPermitted = permissions.includes('CAN_COLLECTION_SCORE_VIEW');
  const isPhoneCallViewPermitted = permissions.includes('CAN_PHONE_CALL_VIEW');
  const isSLLogViewPermitted = permissions.includes('CAN_SHORT_LINK_LOG_VIEW');
  const { total: notificationsTotal, error: notificationsError, loaded: notificationsLoaded } = useGetList('notifications', {}, {}, { 'loan.id': loan.id });

  const applyNotificationTransition = (notificationId, name, params = {}) => {
    dataProvider.query(`notifications/${notificationId}/apply_transition`, {
      method: 'POST',
      body: JSON.stringify({ name, params }),
    })
      .then(() => handleRefresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  const applyAgreementTransition = (agreementId, name, params = {}) => {
    dataProvider.query(`agreements/${agreementId}/apply_transition`, {
      method: 'POST',
      body: JSON.stringify({ name, params }),
    })
      .then(() => handleRefresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  const applyMoneyTransferTransition = (moneyTransferId, name, params = {}) => {
    dataProvider.query(`money_transfers/${moneyTransferId}/apply_transition`, {
      method: 'POST',
      body: JSON.stringify({ name, params }),
    })
      .then(() => handleRefresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  const applyIncomeTransition = (incomeId, name, params = {}) => {
    dataProvider.query(`incomes/${incomeId}/apply_transition`, {
      method: 'POST',
      body: JSON.stringify({ name, params }),
    })
      .then(() => handleRefresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  const applyExtensionTransition = (extensionId, name, params = {}) => {
    dataProvider.query(`extensions/${extensionId}/apply_transition`, {
      method: 'POST',
      body: JSON.stringify({ name, params }),
    })
      .then(() => handleRefresh())
      .catch((error) => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  const requestExtension = (days) => {
    setIsLoading(true);
    dataProvider.query(`loans/${loan.id}/extensions`, { method: 'POST', body: JSON.stringify({ tenor: days }) })
      .then(() => handleRefresh())
      .catch((error) => notify(`Error: ${error.message}`, 'error'))
      .finally(() => setIsLoading(false))
    ;
  };

  const handleRefresh = () => {
    refetch();
  };

  const handleAddNotificationDialogOpen = () => setIsAddNotificationDialogOpen(true);
  const handleAddNotificationDialogClose = () => setIsAddNotificationDialogOpen(false);
  const handleAddNotificationSubmit = (transmitterId, destination, template, locale, message, subject) => {
    handleAddNotificationDialogClose();
    dataProvider.query(`loans/${loan.id}/notifications`, {
      body: JSON.stringify({
        transmitter_id: transmitterId,
        destination,
        template,
        locale,
        message,
        subject,
      }),
    })
      .then(() => handleRefresh())
      .catch((error) => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  const handleCollectionCallDialogOpen = () => setIsCollectionCallDialogOpen(true);
  const handleCollectionCallDialogClose = () => setIsCollectionCallDialogOpen(false);
  const handleCollectionCallDialogSubmit = () => {
    handleRefresh();
    handleCollectionCallDialogClose();
  };

  const handleAddNoteDialogOpen = () => setIsAddNoteDialogOpen(true);
  const handleAddNoteDialogClose = () => setIsAddNoteDialogOpen(false);
  const handleAddNoteSubmit = (label, message) => {
    handleAddNoteDialogClose();
    dataProvider.create('notes', {
      data: {
        user_id: loan.user_id,
        application_id: loan.application_id,
        loan_id: loan.id,
        label,
        message,
      },
    })
      .then(() => handleRefresh())
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  };

  const handleSetUserContactsDialogOpen = () => setIsUserContactsDialogOpen(true);
  const handleIsUserContactDialogClose = () => setIsUserContactsDialogOpen(false);
  const handleEditUserContactsSubmit = (phone_number, name, type, note, relation, source, status, validation_state) => {
    handleIsUserContactDialogClose();
    dataProvider.create('phone_books', {
      data: {
        user: loan.user_id,
        phone_number,
        name,
        type,
        note: note?.trim() || null,
        relation,
        source,
        status: status || 'active',
        validation_state,
      },
    })
      .then(() => handleRefresh())
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  };

  const handleAddIncomeDialogOpen = () => setIsAddIncomeDialogOpen(true);
  const handleAddIncomeDialogClose = () => setIsAddIncomeDialogOpen(false);
  const handleAddIncomeSubmit = (amount, receivedAt, paidVia, paymentType) => {
    handleAddIncomeDialogClose();
    dataProvider.query(`loans/${loan.id}/incomes`, {
      body: JSON.stringify({
        amount,
        received_at: receivedAt,
        bank_name: paidVia,
        payment_type: paymentType,
      }),
    })
      .then(() => handleRefresh())
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  };

  const handleAddExtensionDialogOpen = () => setIsAddExtensionDialogOpen(true);
  const handleAddExtensionDialogClose = () => setIsAddExtensionDialogOpen(false);
  const handleAddExtensionSubmit = (days) => {
    handleAddExtensionDialogClose();
    requestExtension(days);
  };

  const isCollectionCallAddable = isCollectionCallEditPermitted && isPhoneBookViewPermitted;
  const isCollectionCallDialogAddable = isCollectionCallViewPermitted && isPhoneBookViewPermitted;

  if (loading) return <CircularProgress />;

  if ((loaded && !loan) || error) return <Alert color={'error'}>{'Can\'t load loan:('}</Alert>;

  const tabs = [
    {
      label: 'Notifications',
      value: (notificationsLoaded && !notificationsError) ? notificationsTotal : null,
      hasPermission: isNotificationViewPermitted,
      component: <>
        <Tables.NotificationsTable loanId={loan.id} onTransition={applyNotificationTransition} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
          {isNotificationEditPermitted && <Button onClick={handleAddNotificationDialogOpen}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Calls',
      value: null,
      hasPermission: isCollectionCallViewPermitted,
      component: <>
        <Tables.CollectionCallTable userId={loan.user_id} loanId={loan.id} permissions={permissions} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
          {isCollectionCallAddable && <Button onClick={handleCollectionCallDialogOpen}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Notes',
      value: null,
      hasPermission: isNoteViewPermitted,
      component: <>
        <Tables.NotesTable loanId={loan.id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
          {isNoteEditPermitted && <Button onClick={handleAddNoteDialogOpen}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Agreements',
      value: null,
      hasPermission: isAgreementViewPermitted,
      component: <>
        <Tables.AgreementsTable loanId={loan.id} onTransition={applyAgreementTransition} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Residences',
      value: null,
      hasPermission: isPhoneBookViewPermitted,
      component: <>
        <Tables.ResidencesTable userId={loan.user_id} setRefreshedAt={refetch} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Contacts',
      value: null,
      hasPermission: isPhoneBookViewPermitted,
      component: <>
        <Tables.UserContactsTable userId={loan.user_id} setRefreshedAt={refetch} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
          {isPhoneBookEditPermitted && <Button onClick={handleSetUserContactsDialogOpen}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Money transfers',
      value: null,
      hasPermission: isMoneyTransferViewPermitted,
      component: <>
        <Tables.MoneyTransferTable loanId={loan.id} onTransition={applyMoneyTransferTransition} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Incomes',
      value: null,
      hasPermission: isIncomeViewPermitted,
      component: <>
        <Tables.IncomesTable
          loanId={loan.id}
          onTransition={applyIncomeTransition}
          isTransitionsDisabled={!isIncomeEditPermitted || !['active', 'defaulted'].includes(loan.state)}
          permissions={permissions}
        />
        <ButtonGroup size={'small'} color={'primary'} disabled={!(['active', 'defaulted'].includes(loan.state))}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
          {isIncomeAddPermitted && <Button onClick={handleAddIncomeDialogOpen}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Extensions',
      value: null,
      hasPermission: isExtensionViewPermitted,
      component: <>
        <Tables.ExtensionTable loanId={loan.id} onTransition={applyExtensionTransition} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
          {isExtensionAddPermitted && <Button disabled={isLoading || !loan.is_extension_request_allowed}
            onClick={handleAddExtensionDialogOpen}>Request extension</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Changes',
      value: null,
      hasPermission: isLoanViewPermitted,
      component: <>
        <Tables.ChangeHistoryTable entityId={loan.id} entityField={'loan'} endpoint={'loan_change_histories'} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Collection Score',
      value: null,
      hasPermission: isCollectionScoreViewPermitted,
      component: <>
        <Tables.CollectionScoreTable loanId={loan.id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Collection Assign Log',
      value: null,
      hasPermission: isPhoneCallViewPermitted,
      component: <>
        <Tables.CollectionAssignTable loanId={loan.id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Short links',
      value: null,
      hasPermission: isSLLogViewPermitted,
      component: <Tables.ShortLinkTable userId={loan.user_id} returnSimilar={false} />,
    },
    {
      label: 'Payment request',
      value: null,
      hasPermission: true,
      component: <>
        <Tables.RequestPaymentTable userId={loan.user_id} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={handleRefresh}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
  ];

  const filteredTabs = tabs.filter((item) => item.hasPermission);

  return (
    <>
      <TabPanel items={filteredTabs} activeTabIndex={activeTabIndex} onTabClick={setActiveTabIndex} />
      {(isNotificationEditPermitted && isAddNotificationDialogOpen) &&
        <Dialogs.AddNotificationDialog
          userId={loan.user_id}
          isOpened={isAddNotificationDialogOpen}
          onClose={handleAddNotificationDialogClose}
          onSubmit={handleAddNotificationSubmit}
          defaultPhoneNumber={loan.user_phone_number}
          defaultEmailAddress={loan.user_email_address}
        />}
      {(isNoteEditPermitted && isAddNoteDialogOpen) &&
        <Dialogs.AddNoteDialog
          isOpened={isAddNoteDialogOpen}
          onClose={handleAddNoteDialogClose}
          onSubmit={handleAddNoteSubmit}
        />}
      {(isIncomeEditPermitted && isAddIncomeDialogOpen) &&
        <Dialogs.AddIncomeDialog
          record={loan}
          isOpened={isAddIncomeDialogOpen}
          onClose={handleAddIncomeDialogClose}
          onSubmit={handleAddIncomeSubmit}
        />}
      {(isExtensionViewPermitted && isAddExtensionDialogOpen) &&
        <Dialogs.AddExtensionDialog
          extensionTenors={loan.allowed_extension_tenors}
          isOpened={isAddExtensionDialogOpen}
          onClose={handleAddExtensionDialogClose}
          onSubmit={handleAddExtensionSubmit}
        />}
      {(isCollectionCallDialogAddable && isCollectionCallDialogOpen) &&
        <Dialogs.CollectionCallFormDialog
          userId={loan.user_id}
          loanId={loan.id}
          isOpened={isCollectionCallDialogOpen}
          onClose={handleCollectionCallDialogClose}
          onSubmit={handleCollectionCallDialogSubmit}
        />}
      {(isPhoneBookEditPermitted && isUserContactsDialogOpen) &&
        <Dialogs.EditUserContactsDialog
          isOpened={isUserContactsDialogOpen}
          permissions={permissions}
          onClose={handleIsUserContactDialogClose}
          onSubmit={handleEditUserContactsSubmit}
        />}
    </>
  );
};

LoanShowAdditionalInfo.propTypes = {
  loan: shape({
    id: number,
    application_id: number,
    allowed_extension_tenors: array,
    state: string,
    is_extension_request_allowed: bool,
    user_id: number,
    user_phone_number: string,
    user_email_address: string,
  }),
  loans: array,
  activeTabIndex: number,
  setActiveTabIndex: func,
};
