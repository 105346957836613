import { useState } from 'react';
import {
  Button,
  useNotify,
  useDataProvider,
  Confirm,
  useRefresh,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

export const ActivateButton = ({ selectedIds }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const activateExtensionRequests = async (params = {}) => {
    await dataProvider.query('extensions/batch_activate', { method: 'POST', body: JSON.stringify(params) })
      .then(() => {
        notify('Request was success, extended loans removed from list', 'info');
        refresh();
      })
      .catch(error => notify(`Error: ${error.message || 'activation error'}`, 'error'));
  };
  const handleConfirmDialogClose = () => setConfirmDialogOpen(false);
  const handleConfirmDialogOpen = () => setConfirmDialogOpen(true);
  const handleConfirm = () => {
    setDisabled((state) => !state);
    activateExtensionRequests({ loans: selectedIds })
      .then(() => setDisabled((state) => !state));
    handleConfirmDialogClose();
  };
  return (
    <Box>
      <Button label={'Activate'} variant={'contained'} onClick={handleConfirmDialogOpen} color={'primary'} disabled={disabled} />
      {confirmDialogOpen && <Confirm
        isOpen={confirmDialogOpen}
        title={'Activate extension requests'}
        content={`Are you sure to activate ${selectedIds.length} extension requests?`}
        onConfirm={handleConfirm}
        onClose={handleConfirmDialogClose}
      />}
    </Box>
  );
};

ActivateButton.propTypes = {
  selectedIds: PropTypes.array.isRequired,
};
