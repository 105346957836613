import React, { cloneElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  useDataProvider,
  useNotify,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Code" source="code" alwaysOn />
  </Filter>
);

const ListActions = ({ className, filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const getAdminsList = (admins, code) => {
  const list = admins.filter((admin) => admin.collection_groups.includes(code)).map((item) => item.username);
  return <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px' }}>{list.map((item) => <Chip key={item} size="small" label={item} />)}</Box>;
};

const CollectionGroupList = props => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [admins, setAdmins] = useState(null);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getList('admins', {
      filter: {},
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'ASC' },
    })
      .then(({ data }) => {
        setAdmins(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  if (loading) return <Backdrop open={true}><CircularProgress /></Backdrop>;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <List
      bulkActionButtons={ false }
      sort={{ field: 'code', order: 'ASC' }}
      filters={ <ListFilter /> }
      actions={ <ListActions /> }
      { ...props }
    >
      <Datagrid>
        <TextField label="Group code" source="code" />
        <TextField label="Days past due from" source="dpd_from" />
        <TextField label="Days past due to" source="dpd_to" />
        <FunctionField
          label="Assigned to admins"
          render={record => getAdminsList(admins, record.code)}
        />
      </Datagrid>
    </List>
  );
};
export default CollectionGroupList;
