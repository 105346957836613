import React, { useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberInput,
  TextInput,
  EmailField,
  FunctionField,
  useDataProvider,
  useNotify,
  SelectInput,
  Filter,
  useRefresh,
} from 'react-admin';
import { Link, IconButton, Chip, Button, Box, makeStyles } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { UserListActions } from '../user';
import { formatChoices, formatPhoneNumber } from '../../../utils';
import { DateFilterInput, QuickFilterInput } from '../../filters';
import Pagination from '../../Pagination';
import { AsyncSelectInput } from '../../input';
import { useHandbook } from '../../../hooks';
import { marginZeroStyles } from '../../../constants';
import { CallCenterLogTable } from '../../tables';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

import { DropdownDialog, LogDialog } from './dialogs';
import { assignEssence, changeStatus } from './actions';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

export const ROWS = [
  <TextField key="id" source="id" />,
  <FunctionField key="phone_number" source="phone_number" render={({ phone_number }) => {
    return phone_number && formatPhoneNumber(phone_number);
  }} />,
  <FunctionField render={({ first_name, last_name, gender }) => {
    return (
      <Box>
        <Box>{first_name}</Box>
        <Box>{last_name}</Box>
        <Box>{gender}</Box>
      </Box>
    );
  }} key="name_gender" label="Name/Gender"/>,
  <EmailField key="email" source="email" />,
];

const USER_STATUS = [
  'Still thinking',
  'Not ready to use',
  'Reject',
  'Not interested',
  'Will use',
  'Not picked',
  'Hang the phone',
  'Unavailable',
];

export const ListFilter = props => {
  const { choices: employmentTypeChoices } = useHandbook('employments');
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="Registration step" source="registration_step" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Assign before" source="promo_code_phone_call_assigned_at|before" before />
      <DateFilterInput label="Assign after" source="promo_code_phone_call_assigned_at|after" after />
      <TextInput label="First name" source="profile->first_name" />
      <TextInput label="Last name" source="profile->last_name" />
      <TextInput label="Personal ID" source="profile->id_number" />
      <NumberInput label="Phone number" source="phone_book_number" />
      <TextInput label="Email" source="profile->email" />
      <SelectInput label="Empl. type" source="employment->type->code" choices={employmentTypeChoices} />
      <QuickFilterInput source="sleeping_clients" label="Sleeping clients" defaultValue={true} />
      <SelectInput label="Last call result" source="phone_call_last_result" choices={formatChoices(USER_STATUS)} />
      <AsyncSelectInput
        label="Affiliate"
        source="affiliate"
        query={{ resource: 'affiliates', payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} } }}
        mapper={({ id, name }) => ({ value: id, name })}
      />
      <AsyncSelectInput
        label="Operator"
        source="phone_call_operator"
        query={{ resource: 'admins?role_permission=CAN_BE_PHONE_CALL_OPERATOR', payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} } }}
        mapper={({ id, username }) => ({ value: id, name: username })}
        type='getList'
      />
      <NumberInput label="Age less" source="age|lt" />
      <NumberInput label="Age greater" source="age|gt" />
      <QuickFilterInput source="is_promo_code_used" label="Is promo code used" defaultValue={true} />
      <AsyncSelectInput
        label="Sent SMS"
        source="last_promo_code_sms_template"
        query={{
          resource: 'admin/templates',
          payload: {
            pagination: { page: 1 },
            sort: { field: 'key', order: 'ASC' },
            filter: { enum_type: 'Marketing', is_promo_code: true },
          },
        }}
        mapper={({ key, locale }) => ({ value: key, name: key + ' (' + locale + ')' })}
        type='getList'
      />
    </Filter>
  );
};

export const ClientsWithPromoCodeList = props => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [refreshedAt, setRefreshedAt] = useState();
  const [openLogDialog, setOpenLogDialog] = useState(false);
  const [openDropdownDialog, setOpenDropdownDialog] = useState(false);
  const [userIdLog, setUserIdLog] = useState();
  const [userIdStatus, setUserIdStatus] = useState();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  return (
    <>
      <LogDialog open={openLogDialog} close={setOpenLogDialog} title='User status log'>
        <CallCenterLogTable
          refreshedAt={refreshedAt}
          filter={{ 'user.id': userIdLog, context: 'user_promo_code_sms' }}
          isDialog
        />
      </LogDialog>
      <DropdownDialog
        title='Change user status'
        isOpened={openDropdownDialog}
        onClose={() => setOpenDropdownDialog(state => !state)}
        submitDisabled={submitDisabled}
        onSubmit={userStatus => {
          setSubmitDisabled(true);
          changeStatus(
            userStatus,
            dataProvider,
            notify,
            setOpenDropdownDialog,
            refresh,
            'phone_calls',
            { user_id: userIdStatus, result: userStatus, context: 'user_promo_code_sms' },
          ).finally(() => setSubmitDisabled(false));
        }}
        selectOptions={USER_STATUS}
      />
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<ListFilter />}
        actions={<UserListActions />}
        filter={{ received_promo_code_sms: true }}
        {...props}
      >
        <Datagrid rowClick="edit">
          {ROWS.map(i => i)}
          <FunctionField
            onClick={e => e.stopPropagation()}
            label="Status"
            render={({ last_phone_calls_by_context, id, has_application_after_sms_promo_code }) => {
              if (last_phone_calls_by_context.user_promo_code_sms?.operator) {
                return (
                  <Box>
                    <Box>
                      <Button
                        size="small"
                        variant='outlined'
                        onClick={() => {
                          setUserIdStatus(id);
                          setOpenDropdownDialog(state => !state);
                        }}
                      >Change</Button>
                    </Box>
                    <Box mt={1}>
                      <Button
                        size="small"
                        variant='outlined'
                        onClick={() => {
                          setUserIdLog(id);
                          setRefreshedAt(new Date().valueOf());
                          setOpenLogDialog(state => !state);
                        }}
                      >log</Button>
                    </Box>
                  </Box>
                );
              } else {
                return (
                  <Button variant="contained" color="primary" onClick={() => {
                    assignEssence(
                      dataProvider,
                      notify,
                      refresh,
                      'phone_call_user_assigns',
                      { user_id: id, context: 'user_promo_code_sms' },
                    );
                  }} disabled={has_application_after_sms_promo_code}>Assign</Button>
                );
              }
            }
            }
          />
          <FunctionField label="Operator" render={({ last_phone_calls_by_context }) => <Chip label={last_phone_calls_by_context.user_promo_code_sms?.operator || 'none'} size="small" />} />
          <FunctionField label="Last call result" render={({ last_phone_calls_by_context, has_application_after_sms_promo_code }) => {
            if (has_application_after_sms_promo_code) {
              return <Chip label="Used promo-code" size="small" />;
            } else {
              return <Chip label={last_phone_calls_by_context.user_promo_code_sms?.result || 'none'} size="small" />;
            }
          }} />
          <FunctionField label="Sent Date" render={(record) => <DateTimeBoxComponent value={record?.last_sms_promo_code_sent_at} />} />
          <FunctionField label="Sented SMS" render={({ last_sms_promo_code_template_key }) => {
            return last_sms_promo_code_template_key;
          }} />
          <FunctionField render={(record) => <Link href={`/admin#/clients_with_promo_code/${record.id}`} underline="none" target="_blank" rel="noreferrer"><IconButton onClick={(e) => e.stopPropagation()}><OpenInNewIcon fontSize="small" /></IconButton></Link>}/>
        </Datagrid>
      </List>
    </>
  );
};
