import React, { useState, useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineTwoTone from '@material-ui/icons/AddCircleOutlineTwoTone';
import RemoveCircleOutlineTwoTone from '@material-ui/icons/RemoveCircleOutlineTwoTone';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { ButtonGroup, InputLabel } from '@material-ui/core';

import { AUDIENCE_FILTER_CONDITION_CHOICES, AUDIENCE_FILTER_INCLUDE_CONDITION_CHOICES } from '../../utils';

export const OperationSignFilter = ({ id, title, filter, form }) => {
  const [inputList, setInputList] = useState(filter?.values ? filter?.values?.map((item, index) => ({ condition: item.operation_sign, include: item.include || 'include', value: item.number, id: index })) : [{ include: 'include', condition: '', value: '', id: 0 }]);

  useEffect(() => {
    if (form) {
      inputList.forEach((item, index) => {
        form.change(`${id}.values[${index}].include`, item.include);
        form.change(`${id}.values[${index}].operation_sign`, item.condition);
        form.change(`${id}.values[${index}].number`, item.value);
      });
    }
  }, [form, inputList, id]);

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    form.change(`${id}.values`, list.map(item => ({ operation_sign: item.condition, include: item.include, number: item.value })));
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { condition: '', value: '', id: inputList.length + 1 }]);
  };

  return (
    <>
      <Divider />
      <FormControlLabel
        control={
          <Checkbox
            color='secondary'
            defaultChecked={filter?.active}
            onChange={(e) => form.change(`${id}.active`, e.target.checked ? [id] : [])}
          />
        }
        label={<b>{title.toUpperCase()}</b>}
      />
      {inputList.map((x, i) => {
        return (
          <Box display={'grid'} gridGap={'20px'} marginBottom={'10px'} gridTemplateColumns={'1fr auto 1fr 85px'} key={i.id}>
            <FormControl variant={'outlined'} size='small'>
              <InputLabel>Include</InputLabel>
              <Select
                onChange={(e) => {
                  const newInputList = inputList.map((item, index) =>
                    i === index ? { ...item, include: e.target.value } : item,
                  );
                  setInputList(newInputList);
                  form.change(`${id}.values[${i}].include`, e.target.value);
                }}
                label='Include'
                value={x.include}>
                {AUDIENCE_FILTER_INCLUDE_CONDITION_CHOICES.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant={'outlined'} style={{ minWidth: '120px' }} size='small'>
              <InputLabel>Condition</InputLabel>
              <Select
                onChange={(e) => {
                  const newInputList = inputList.map((item, index) =>
                    i === index ? { ...item, condition: e.target.value } : item,
                  );
                  setInputList(newInputList);
                  form.change(`${id}.values[${i}].operation_sign`, e.target.value);
                }}
                variant='outlined'
                label='Condition'
                value={x.condition}>
                {AUDIENCE_FILTER_CONDITION_CHOICES.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              onChange={(e) => {
                const newInputList = inputList.map((item, index) =>
                  i === index ? { ...item, value: e.target.value } : item,
                );
                setInputList(newInputList);
                form.change(`${id}.values[${i}].number`, e.target.value);
              }}
              type='number'
              label='Value'
              size='small'
              variant='outlined'
              value={x.value}
              fullWidth
            />
            <ButtonGroup>
              {inputList.length !== 1 && (
                <IconButton color='secondary' onClick={() => handleRemoveClick(i)}>
                  <RemoveCircleOutlineTwoTone />
                </IconButton>
              )}
              {inputList.length - 1 === i && (
                <IconButton color='secondary' onClick={handleAddClick}>
                  <AddCircleOutlineTwoTone />
                </IconButton>
              )}
            </ButtonGroup>
          </Box>
        );
      })}
    </>
  );
};

OperationSignFilter.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  filter: PropTypes.shape({
    active: PropTypes.bool,
    values: PropTypes.array,
  }),
  form: PropTypes.shape({
    change: PropTypes.func,
  }),
};

OperationSignFilter.defaultProps = {
  filter: {},
};
