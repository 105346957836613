import { Filter, NumberInput, SelectInput, TextInput, usePermissions } from 'react-admin';
import PropTypes from 'prop-types';

import { useHandbook } from '../../../hooks';
import { AsyncSelectInput } from '../../input';
import { DateFilterInput, QuickFilterInput } from '../../filters';
import { LOAN_TYPES } from '../../../constants';

export const CollectionLoanListFilter = ({ collectionGroups, filterValues, ...props }) => {
  const { permissions = [] } = usePermissions();
  const { data: typeValues } = useHandbook('collection_contact_type_values', { items_per_page: 1000 });

  const contactTypes = typeValues.filter((item) => item.type_name === 'Contact type').map((item) => ({ id: item.id, name: item.value }));
  const contactResults = typeValues.filter((item) => item.type_name === 'Contact result').map((item) => ({ id: item.id, name: item.value }));
  const promiseTypes = typeValues.filter((item) => item.type_name === 'Promise type').map((item) => ({ id: item.id, name: item.value }));

  const statusChoices = [
    { id: 'active', name: 'Active' },
    { id: 'defaulted', name: 'Defaulted' },
  ];

  return (
    <Filter {...props} variant={'outlined'} style={{ marginTop: '20px', alignItems: 'center' }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <SelectInput label="Collection group" source="collection_group" choices={collectionGroups} alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="User name" source="search_by_name"/>
      {permissions.includes('CAN_VIEW_ALL_COLLECTION_LOANS') && <AsyncSelectInput
        label="Collector Id"
        source="collector->id"
        query={{ resource: 'admins/collection_specialists', payload: { pagination: { }, sort: {}, filter: {} } }}
        mapper={({ id, username }) => ({ value: id, name: username ? id ? `${username}#${id}` : username : null })}
        additionalData={[{ id: null, username: <>&emsp;</> }]}
        alwaysOn
      />}
      <TextInput label="Contract number" source="application->contractNumber" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <NumberInput label="Phone number" source="phone_book_number" />
      <SelectInput label="Status" source="state" choices={statusChoices} />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Closed before" source="closed_at|before" before />
      <DateFilterInput label="Closed after" source="closed_at|after" after />
      <DateFilterInput label="Disbursed before" source="disbursed_at|before" before />
      <DateFilterInput label="Disbursed after" source="disbursed_at|after" after />
      <DateFilterInput label="Matured before" source="matured_at|before" before />
      <DateFilterInput label="Matured after" source="matured_at|after" after />
      <QuickFilterInput source="is_extension_requested" label="Extension requested" defaultValue={true} />
      <QuickFilterInput source="is_extension_possible" label="Extension possible" defaultValue={true} />
      <QuickFilterInput source="is_extended" label="Extended" defaultValue={true} />
      <SelectInput label="Loan type" source="is_repeat" choices={LOAN_TYPES} />
      <TextInput label="Residence municipality" source="user->residence->municipality" />
      <NumberInput label="Max days past due" source="days_past_due|lte" />
      <NumberInput label="Min days past due" source="days_past_due|gte" />
      <DateFilterInput label="Last call before" source="lastPromiseToPay->createdAt|before" before />
      <DateFilterInput label="Last call after" source="lastPromiseToPay->createdAt|after" after />
      <SelectInput
        key={`last-call-contact-type-length-${contactTypes.length}`}
        label="Last call contact type"
        source="last_contact_call->contact_type->id"
        choices={contactTypes}
      />
      <SelectInput
        key={`last-call-contact-results-length-${contactResults.length}`}
        label="Last call contact results"
        source="last_contact_call->contact_result->id"
        choices={contactResults}
      />
      <SelectInput
        key={`last-call-promise-types-length-${promiseTypes.length}`}
        label="Last call promise Types"
        source="last_contact_call->promise_type->id"
        choices={promiseTypes}
      />
      <DateFilterInput label="Last PTP before" source="lastPromiseToPay->promiseDate|before" before />
      <DateFilterInput label="Last PTP after" source="lastPromiseToPay->promiseDate|after" after />
      <DateFilterInput label="Last extension before" source="last_extension_date|before" before />
      <DateFilterInput label="Last extension after" source="last_extension_date|after" after />
      <DateFilterInput label="Last payment before" source="last_payment_date|before" before />
      <DateFilterInput label="Last payment after" source="last_payment_date|after" after />
    </Filter>
  );
};

CollectionLoanListFilter.propTypes = {
  collectionGroups: PropTypes.array,
  filterValues: PropTypes.object,
  onFilterChange: PropTypes.func,
};
