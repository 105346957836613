import { Filter, NumberInput, SelectInput, TextInput } from 'react-admin';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { DateFilterInput } from '../../filters';
import { CALL_CENTER_USER_STATUS_UNSIGNED_REQUESTS, formatChoices } from '../../../utils';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

export const ListFilterUnsigned = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <TextInput label="User ID" source="user->id" />
      <NumberInput label="Request Id" source="id" />
      <DateFilterInput label="Assign before" source="unsigned_request_phone_call_assigned_at|before" before />
      <DateFilterInput label="Assign after" source="unsigned_request_phone_call_assigned_at|after" after />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <SelectInput label="Last call result" source="phone_call_last_result"
        choices={formatChoices(CALL_CENTER_USER_STATUS_UNSIGNED_REQUESTS)} />
    </Filter>
  );
};
