import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

export const ReadyForDisbursementTransitionDialog = ({ isOpened, onClose, onSubmit, record }) => {
  const [isReadyForDisbursement] = useState(record.is_ready_for_disbursement);

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Confirm.</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to {isReadyForDisbursement ? 'cancel' : 'confirm'} readiness for disbursement?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        {isReadyForDisbursement &&
          <Button onClick={() => onSubmit(false)} color="primary">
            Cancel
          </Button>
        }
        {!isReadyForDisbursement &&
          <Button onClick={() => onSubmit(true)} color="primary">
            Confirm
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

ReadyForDisbursementTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  record: PropTypes.shape({
    user_id: PropTypes.number,
    product_id: PropTypes.number,
    principal: PropTypes.number,
    tenor: PropTypes.number,
    promo_code: PropTypes.string,
    is_repeat: PropTypes.bool,
    is_ready_for_disbursement: PropTypes.bool,
  }),
};
