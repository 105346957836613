import { useState } from 'react';
import { Show, useRedirect, useShowController } from 'react-admin';
import { Grid, Button, ButtonGroup, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { arrayOf, number, shape, string, array } from 'prop-types';

import { UserInfoTabCard } from '../../UserInfoTabCard';

import { LoanInfo, ExecutedTransactions, ScheduledTransactions, LoanShowAdditionalInfo } from './loanShowCards';
import { LoanTransitions } from './LoanTransitions';

const Actions = ({ permissions, record: { user_id, application_id } }) => {
  const redirect = useRedirect();

  const canViewUser = permissions.includes('CAN_USER_VIEW') && !!user_id;
  const canViewApplication = permissions.includes('CAN_APPLICATION_VIEW') && !!application_id;
  const handleUserEditNavigate = () => redirect(`/users/${user_id}/edit`);
  const handleAppShowNavigate = () => redirect(`/applications/${application_id}/show`);

  if (!canViewUser && !canViewApplication) return null;

  return (
    <ButtonGroup variant={'contained'} color={'primary'}>
      {canViewUser && <Button onClick={handleUserEditNavigate}>Edit user</Button>}
      {canViewApplication && <Button onClick={handleAppShowNavigate}>Show application</Button>}
    </ButtonGroup>
  );
};

Actions.propTypes = {
  permissions: arrayOf(string).isRequired,
  record: shape({
    user_id: number,
    application_id: number,
  }),
};

export const LoanShow = (props) => {
  const { permissions = [] } = props;
  const { record, error, loading, loaded } = useShowController(props);
  const isLoanEditPermitted = permissions.includes('CAN_LOAN_EDIT');
  const isUserViewPermitted = permissions.includes('CAN_USER_VIEW');
  const isTransactionViewPermitted = permissions.includes('CAN_TRANSACTION_VIEW');
  const isTransactionEditPermitted = permissions.includes('CAN_TRANSACTION_EDIT');
  const transactionEditRecordStates = ['active', 'defaulted'].includes(record?.state);
  const isTransactionViewRecordState = ['active', 'cancelled', 'closed', 'defaulted'].includes(record?.state);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  if (loading) return <CircularProgress />;

  if ((loaded && !record) || error) return <Alert color={'error'}>{'Can\'t load loan:('}</Alert>;
  return (
    <Show component={'div'} {...props}>
      <Grid container spacing={4}>
        <Grid container item xs={12} justifyContent={'space-between'}>
          {isLoanEditPermitted && <LoanTransitions record={record}/>}
          <Actions permissions={permissions} record={record}/>
        </Grid>
        <Grid item xs={12}>
          <LoanShowAdditionalInfo activeTabIndex={activeTabIndex} setActiveTabIndex={setActiveTabIndex} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UserInfoTabCard isPermitted={isUserViewPermitted} user_id={record.user_id}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoanInfo record={record}/>
        </Grid>
        {(isTransactionViewPermitted && isTransactionViewRecordState) &&
          <>
            <Grid item xs={12} sm={6}>
              <ScheduledTransactions loanId={record.id}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ExecutedTransactions loanId={record.id} isActionsDisabled={!isTransactionEditPermitted || !transactionEditRecordStates}
              />
            </Grid>
          </>
        }
      </Grid>
    </Show>
  );
};

LoanShow.propTypes = {
  permissions: array,
};
