import React, { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  FunctionField,
  Filter,
  NumberInput,
  SelectInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  useNotify,
  useDataProvider,
  useRefresh,
  NumberField,
} from 'react-admin';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput variant={'outlined'} label='Id' source='id' alwaysOn />
      <TextInput variant={'outlined'} label='Name' source='name' />
      <SelectInput
        variant={'outlined'}
        label='Enabled'
        source='is_enabled'
        choices={[
          { id: 'false', name: 'No' },
          { id: 'true', name: 'Yes' },
        ]}
      />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

export const RejectionReasonsList = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);

  const handleSwitchToggle = (id, is_enabled) => {
    setIsLoading(true);
    dataProvider
      .update('rejection_reasons', { id, data: { is_enabled } })
      .catch((error) => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        refresh();
        setIsLoading(false);
      });
  };

  const buildHandleClickDelete = (id) => {
    return (e) => {
      e.stopPropagation();

      setIsLoading(true);
      dataProvider
        .delete('rejection_reasons', { id })
        .catch((error) => notify(`Error: ${error.message}`, 'error'))
        .finally(() => {
          refresh();
          setIsLoading(false);
        });
    };
  };

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick='edit'>
        <TextField source='id' />
        <TextField source='name' />
        <NumberField source='moratorium_in_days' />
        <FunctionField
          onClick={(e) => e.stopPropagation()}
          label='Enabled'
          render={({ id, is_enabled }) => (
            <Switch
              color='primary'
              checked={!!is_enabled}
              disabled={isLoading}
              onChange={() => handleSwitchToggle(id, !is_enabled)}
            />
          )}
        />
        <FunctionField
          label='Actions'
          render={({ id }) => (
            <IconButton color='primary' disabled={isLoading} onClick={buildHandleClickDelete(id)}>
              <DeleteIcon />
            </IconButton>
          )}
        />
      </Datagrid>
    </List>
  );
};
