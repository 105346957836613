import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@material-ui/core';

export const AddCancelNoteDialog = ({ isOpened, onClose, onSubmit }) => {
  const [message, setMessage] = useState();

  const handleMessageChange = (e) => setMessage(e.target.value);
  const handleMessageSubmit = () => onSubmit(message);
  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Reason of cancel</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <TextField margin={'normal'} multiline={true} fullWidth label={'Message'} value={message} onChange={handleMessageChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleMessageSubmit} color={'primary'} disabled={!message}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

AddCancelNoteDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
