import { Edit } from 'react-admin';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

import { CollectionContactTypeValueDialog } from '../../dialogs';
import { CollectionContactTypeValuesTable } from '../../collection';

const useStyles = makeStyles(() => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 32,
    padding: '24px 24px 48px',
  },
}));

const CollectionContactTypeFormWrapper = ({ record, onLoadRecord }) => {
  const classes = useStyles();

  useEffect(() => {
    onLoadRecord(record);
  }, [record, onLoadRecord]);

  return (
    <div className={classes.formWrapper}>
      <FormControl>
        <TextField
          value={record.id}
          variant='filled'
          size='small'
          label="ID"
          disabled
        />
      </FormControl>

      <FormControl>
        <TextField
          value={record.name}
          variant='filled'
          size='small'
          label="Name"
          disabled
        />
      </FormControl>
    </div>
  );
};

CollectionContactTypeFormWrapper.propTypes = {
  record: PropTypes.object,
  onLoadRecord: PropTypes.func,
};

export const CollectionContactTypeEdit = ({ ...props }) => {
  const [record, setRecord] = useState(null);
  const [isValueModalOpened, setIsValueModalOpened] = useState(false);
  const [refresh, setRefresh] = useState(Date.now());

  const typeId = parseInt(`${props.id}`);

  const handleClickCreate = () => {
    setIsValueModalOpened(true);
  };

  const handleValueModalClose = () => {
    setIsValueModalOpened(false);
  };

  const handleValueModalSubmit = () => {
    setIsValueModalOpened(false);
    setRefresh(Date.now());
  };

  return (
    <>
      <Edit undoable={false} hasEdit={false} hasCreate={false} {...props}>
        <CollectionContactTypeFormWrapper onLoadRecord={setRecord} />
      </Edit>

      {record && (
        <>
          <CollectionContactTypeValuesTable
            typeId={typeId}
            refresh={refresh}
            onClickCreate={handleClickCreate}
          />

          <CollectionContactTypeValueDialog
            defaultType={typeId}
            isOpened={isValueModalOpened}
            onClose={handleValueModalClose}
            onSubmit={handleValueModalSubmit}
          />
        </>
      )}
    </>
  );
};

CollectionContactTypeEdit.propTypes = {
  id: PropTypes.string,
};
