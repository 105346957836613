import { useEffect, useState } from 'react';

import { useHandbook } from './useHandbook';

export function useExternalAgencies () {
  const { isLoading, data: originalData, reload } = useHandbook('external_agencies');

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(originalData.map((agency) => ({
      id: agency.id,
      name: agency.name,
      status: agency.status,
      email: agency.email,
      phone: agency.phone,
      details: agency.details,
    })));
  }, [originalData]);

  return {
    isLoading,
    data,
    choices: data,
    reload,
  };
}
