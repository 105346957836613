import React, { useState } from 'react';
import { Create, FormWithRedirect } from 'react-admin';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { TemplateForm } from './TemplateForm';

const FormWrapper = ({ save, ...props }) => {
  const [selectedRolesStr, setSelectedRolesStr] = useState([]);
  return (
    <Grid container spacing={2} alignItems={'center'}>
      <Grid item xs={12}>
        <FormWithRedirect
          style={{ height: '100%', maxHeight: '100%' }}
          variant={'outlined'}
          redirect={'list'}
          save={(values) => save({ manual_sending_roles: selectedRolesStr, ...values })}
          {...props}
          render={(formProps) => <TemplateForm formProps={formProps} record={props.record} {...props}
            selectedRolesStr={selectedRolesStr}
            setSelectedRolesStr={setSelectedRolesStr} />}
        />
      </Grid>
    </Grid>
  );
};

FormWrapper.propTypes = {
  record: PropTypes.any,
};

export const TemplateCreate = (props) => <Create {...props}>
  <FormWrapper {...props} />
</Create>;

TemplateCreate.propTypes = {
  record: PropTypes.any,
};
