import { useState, useEffect } from 'react';
import { Create, FormWithRedirect, useDataProvider } from 'react-admin';
import { Grid, Card } from '@material-ui/core';
import PropTypes from 'prop-types';

import { DialerBasesForm } from './DialerBasesForm';

const FormWrapper = ({ save, ...props }) => {
  const dataProvider = useDataProvider();

  const [transmitters, setTransmitters] = useState({});
  const [audiencesList, setAudiencesList] = useState([]);
  const [transmitter, setTransmitter] = useState(null);
  const [weekDays, setWeekDays] = useState([]);
  const [variables, setVariables] = useState([]);
  const [dialerId, setDialerId] = useState(null);
  const [frequencyPeriod, setFrequencyPeriod] = useState(null);
  const [contactSource, setContactSource] = useState(null);
  const [contactType, setContactType] = useState(null);
  const [phoneStatus, setPhoneStatus] = useState(null);
  const [phoneValidation, setPhoneValidation] = useState(null);

  useEffect(() => {
    dataProvider
      .query('mass_sending_audiences?pagination=false', { method: 'GET' })
      .then(({ data }) => setAudiencesList(data));

    dataProvider
      .query('dialer_transmitters', { method: 'GET' })
      .then(({ data }) => {
        let newData = {};

        for (const i of data) {
          let params = {};

          for (const j of i.params_description) {
            params = { ...params, [j.id]: j };
          }

          newData = { ...newData, [i.id]: { ...i, params_description: params } };
        }

        const variables_allowed_values = newData?.webitel?.params_description?.variables?.selected_values;
        const weekDays_allowed_values = newData?.webitel?.params_description?.week_days?.selected_values;

        setTransmitters(newData);
        setVariables(Array.isArray(variables_allowed_values) ? variables_allowed_values : []);
        setWeekDays(Array.isArray(weekDays_allowed_values) ? weekDays_allowed_values : []);
      });
  }, [dataProvider]);

  const getAllowedValues = (id) => {
    const { allowed_values } = transmitters?.[transmitter]?.params_description[id];
    const values = Object.entries(allowed_values).map((item) => ({
      id: item[1],
      [id === 'frequency_period' || id === 'contact_source' || id === 'contact_type' || id === 'phone_status' || id === 'phone_validation' ? 'name' : 'label']: item[0],
    }));

    return values;
  };

  const onChange = (id, paramsId) => {
    const params = paramsId === 'week_days' ? weekDays : variables;
    const setParams = paramsId === 'week_days' ? setWeekDays : setVariables;
    const element = params.includes(id);

    if (element) {
      setParams(params.filter((i) => i !== id));
    } else {
      setParams([...params, id]);
    }
  };

  const getParams = () => {
    const params = {};
    if (contactSource) {
      params.contact_source = contactSource;
    }
    if (contactType) {
      params.contact_type = contactType;
    }
    if (dialerId) {
      params.dialer_id = +dialerId;
    }
    if (frequencyPeriod) {
      params.frequency_period = frequencyPeriod;
    }
    if (phoneStatus) {
      params.phone_status = phoneStatus;
    }
    if (phoneValidation) {
      params.phone_validation = phoneValidation;
    }
    if (variables.length) {
      params.variables = variables;
    }
    if (weekDays.length) {
      params.week_days = weekDays;
    }
    return params;
  };

  const AUDIENCE_CHOICES = audiencesList?.map((item) => ({ id: item.id, name: item.name }));

  return (
    <Grid container justifyContent='center' spacing={4}>
      <Grid item xs={12} sm={8}>
        <Card>
          <FormWithRedirect
            save={({ name, start_time, end_time, mass_sending_audience, is_enabled }, ...rest) => {
              save(
                ...[
                  {
                    name,
                    start_time,
                    end_time,
                    mass_sending_audience,
                    is_enabled,
                    transmitter_id: transmitter,
                    params: getParams(),
                  },
                  ...rest,
                ],
              );
            }}
            {...props}
            render={(formProps) => <DialerBasesForm
              transmitters={transmitters}
              transmitter={{ transmitter, setTransmitter }}
              dialerId={{ dialerId, setDialerId }}
              getAllowedValues={getAllowedValues}
              frequencyPeriod={{ frequencyPeriod, setFrequencyPeriod }}
              phoneStatus={{ phoneStatus, setPhoneStatus }}
              contactType={{ contactType, setContactType }}
              contactSource={{ contactSource, setContactSource }}
              phoneValidation={{ phoneValidation, setPhoneValidation }}
              onChange={onChange}
              weekDays={weekDays}
              variables={variables}
              audienceChoices={AUDIENCE_CHOICES}
              formProps={formProps} />}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

export const DialerBasesCreate = (props) => (
  <Create component='div' {...props}>
    <FormWrapper redirect="list" />
  </Create>
);
