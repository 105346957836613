import { Box } from '@material-ui/core';
import { number, shape, object, func } from 'prop-types';

import { SliderFieldHookForm } from '../formFields';
import { CURRENCY, createAppFormFields } from '../../constants';

export const LoanCalculator = ({ onValueChange, principal, tenor, control }) => {
  const { tenor: tenorLbl, principal: principalLbl } = createAppFormFields;
  return (
    <Box style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}>
      <SliderFieldHookForm
        name={principalLbl}
        label={principalLbl}
        value={principal.value}
        min={principal.min}
        max={principal.max}
        step={principal.step}
        control={control}
        onChange={onValueChange}
        unit={CURRENCY}
      />
      <SliderFieldHookForm
        onChange={onValueChange}
        name={tenorLbl}
        label={tenorLbl}
        value={tenor.value}
        min={tenor.min}
        max={tenor.max}
        step={tenor.step}
        control={control}
        unit={'days'}
      />
    </Box>
  );
};

LoanCalculator.propTypes = {
  principal: shape({
    value: number,
    min: number,
    step: number,
    max: number,
  }),
  tenor: shape({
    value: number,
    min: number,
    step: number,
    max: number,
  }),
  control: object.isRequired,
  onValueChange: func,
};
