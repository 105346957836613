import { useState } from 'react';
import { Show, useRedirect, useRefresh } from 'react-admin';
import { Card, CardHeader, Grid, Divider, Table, TableBody, TableRow, TableCell, Button, ButtonGroup } from '@material-ui/core';
import PropTypes from 'prop-types';

import { UserInfoTabCard } from '../../UserInfoTabCard';
import { formatCurrency } from '../../../utils';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

import { ApplicationTransitions } from './ApplicationTransitions';
import { ApplicationAdditionalInfo } from './ApplicationAdditionalInfo';

const rowData = (name, value) => ({ name, value });

export const verifierRow = (row, state, name) => {
  if (row === 'aventus-de' && (state === 'approved' || state === 'confirmed')) {
    return 'Auto Disbursement';
  } else if (row === 'aventus-de' && state === 'rejected') {
    return 'Auto reject';
  } else {
    return name;
  }
};

const ApplicationInfo = ({ record }) => {
  const rows = [
    rowData('ID', record.id),
    rowData('Contract number', record.contract_number),
    rowData('Product', record.product_name),
    rowData('Promocode', record.promo_code),
    rowData('Initial requested principal', formatCurrency(record.initial_requested_principal)),
    rowData('Initial requested tenor', record.initial_requested_tenor),
    rowData('Requested principal', formatCurrency(record.requested_principal)),
    rowData('Requested tenor', record.requested_tenor),
    rowData('Approved principal', record.approved_principal && formatCurrency(record.approved_principal)),
    rowData('Approved tenor', record.approved_tenor),
    rowData('State', record.state),
    rowData('Referrer', record.referrer),
    rowData('Verifier', verifierRow(record.decision_engine_id, record.state, record.verifier_username)),
    rowData('Created at', <DateTimeBoxComponent value={record?.created_at} />),
    rowData('Resolved at', record.resolved_at && <DateTimeBoxComponent value={record?.resolved_at} />),
    rowData('Resolved by', record.resolved_by_id),
    rowData('Confirmation code', record.confirmation_code),
    rowData('Confirmed at', record.confirmed_at && <DateTimeBoxComponent value={record?.confirmed_at} />),
    rowData('Bank name', record.bank_name),
    rowData('Bank branch', record.branch_name),
    rowData('Bank account', record.account_number),
    rowData('Request type', record.is_repeat ? 'Repeat' : 'First'),
    rowData('Affiliate', record.affiliate),
    rowData('Postponed until', record.postponed_until && <DateTimeBoxComponent value={record?.postponed_until} />),
  ];

  return (
    <Card>
      <CardHeader title={'Application'} />
      <Divider />
      <Table size={'small'}>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell align={'left'}>{row.name}</TableCell>
              <TableCell align={'right'}>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

ApplicationInfo.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    contract_number: PropTypes.string,
    product_name: PropTypes.string,
    promo_code: PropTypes.string,
    requested_principal: PropTypes.number,
    requested_tenor: PropTypes.number,
    approved_principal: PropTypes.number,
    approved_tenor: PropTypes.number,
    state: PropTypes.string,
    referrer: PropTypes.string,
    decision_engine_id: PropTypes.string,
    verifier_username: PropTypes.string,
    created_at: PropTypes.string,
    resolved_at: PropTypes.string,
    resolved_by_id: PropTypes.number,
    confirmation_code: PropTypes.string,
    confirmed_at: PropTypes.string,
    bank_name: PropTypes.string,
    branch_name: PropTypes.string,
    account_number: PropTypes.string,
    is_repeat: PropTypes.bool,
    affiliate: PropTypes.string,
    postponed_until: PropTypes.string,
    initial_requested_principal: PropTypes.number,
    initial_requested_tenor: PropTypes.number,
  }),
};

const Actions = ({ permissions, record: { user_id, loan_id } }) => {
  const redirect = useRedirect();

  const allowSeeClientPage = user_id && permissions.includes('CAN_USER_VIEW');
  const allowSeeLoanPage = loan_id && permissions.includes('CAN_LOAN_VIEW');
  const handleRedirectUserEdit = () => redirect(`/users/${user_id}/edit`);
  const handleRedirectUserShow = () => redirect(`/loans/${loan_id}/show`);

  if (!allowSeeClientPage && !allowSeeLoanPage) {
    return null;
  }

  return (
    <ButtonGroup variant={'contained'} color={'primary'}>
      {allowSeeClientPage && <Button onClick={handleRedirectUserEdit}>Edit user</Button>}
      {allowSeeLoanPage && <Button onClick={handleRedirectUserShow}>Show loan</Button>}
    </ButtonGroup>
  );
};

Actions.propTypes = {
  record: PropTypes.shape({
    user_id: PropTypes.number,
    loan_id: PropTypes.number,
  }),
  permissions: PropTypes.array,
};

const Layout = ({ permissions, record, ...props }) => {
  const onRefresh = useRefresh();
  const [refreshedAt, setRefreshedAt] = useState(null);

  const isAppEditable = permissions.includes('CAN_APPLICATION_EDIT');
  const isUserView = permissions.includes('CAN_USER_VIEW');
  const refresh = () => {
    setRefreshedAt(Date.now());
    onRefresh();
  };
  return (
    <Grid container spacing={4}>
      <Grid container item xs={12} justifyContent={'space-between'}>
        {isAppEditable &&
          <ApplicationTransitions permissions={permissions} record={record} refreshedAt={refreshedAt} refresh={refresh} />}
        <Actions record={record} permissions={permissions} />
      </Grid>
      <Grid item xs={12}>
        <ApplicationAdditionalInfo permissions={permissions} record={record} refreshedAt={refreshedAt} {...props} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <UserInfoTabCard isPermitted={isUserView} user_id={record.user_id} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ApplicationInfo record={record} />
      </Grid>
    </Grid>
  );
};

Layout.propTypes = {
  permissions: PropTypes.array,
  record: PropTypes.shape({
    user_id: PropTypes.number,
  }),
};

export const ApplicationShow = ({ permissions = [], ...props }) => (
  <Show component={'div'} {...props}>
    <Layout permissions={permissions} {...props} />
  </Show>
);

ApplicationShow.propTypes = {
  permissions: PropTypes.array,
};
