import React from 'react';
import { Create } from 'react-admin';

import { RejectionReasonsForm } from './RejectionReasonsForm';

export const RejectionReasonsCreate = (props) => (
  <Create component="div" {...props}>
    <RejectionReasonsForm />
  </Create>
);
