import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, FormControl, Select, MenuItem, DialogActions, Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';

export const DropdownDialog = ({
  isOpened,
  onClose,
  onSubmit,
  selectOptions,
  title,
  submitDisabled,
}) => {
  const [userStatus, setUserStatus] = useState();
  const handleStatusChange = (e) => setUserStatus(() => e.target.value);
  const handleSubmit = () => onSubmit(userStatus);
  return (
    <Dialog open={isOpened} onClose={onClose}>
      <DialogTitle id={'form-dialog-title'}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Status</DialogContentText>
        <FormControl fullWidth>
          <Select variant={'outlined'} value={userStatus} onChange={handleStatusChange}>
            {selectOptions.map((i) => <MenuItem key={i} value={i}>{i}</MenuItem>)}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color={'primary'} onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color={'primary'} disabled={submitDisabled}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

DropdownDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  selectOptions: PropTypes.array,
  title: PropTypes.string,
  submitDisabled: PropTypes.bool,
};
