import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, FormControl,
  TextField, InputLabel, Select, MenuItem, Grid,
} from '@material-ui/core';

export const AddNoteDialog = ({ isOpened, onClose, onSubmit }) => {
  const [label, setLabel] = useState('');
  const [message, setMessage] = useState('');

  const handleLabelChange = (e) => setLabel(e.target.value);
  const handleMessageChange = (e) => setMessage(e.target.value);
  const handleSubmit = () => onSubmit(label, message);
  return (
    <Dialog open={ isOpened } onClose={ onClose } aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Add user note</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant={'outlined'} fullWidth>
              <InputLabel id={'label-select-label'}>Label</InputLabel>
              <Select label={'Label'} labelId={'label-select-label'} value={label} onChange={handleLabelChange}>
                <MenuItem value={'info'}>Info</MenuItem>
                <MenuItem value={'verification'}>Verification</MenuItem>
                <MenuItem value={'collection'}>Collection</MenuItem>
                <MenuItem value={'call_center'}>Call center</MenuItem>
                <MenuItem value={'telesales'}>Telesales</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField variant={'outlined'} multiline fullWidth label={'Message'} value={message} onChange={handleMessageChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={ onClose } color={'primary'}>Cancel</Button>
        <Button onClick={handleSubmit} color={'primary'} disabled={!label || !message}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

AddNoteDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
