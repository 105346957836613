import { Admin, defaultTheme, Resource } from 'react-admin';
import UserIcon from '@material-ui/icons/Group';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import PublicIcon from '@material-ui/icons/Public';
import WeekendIcon from '@material-ui/icons/Weekend';
import AssessmentIcon from '@material-ui/icons/Assessment';
import UpdateIcon from '@material-ui/icons/Update';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MailIcon from '@material-ui/icons/Mail';
import BlockIcon from '@material-ui/icons/Block';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import { createTheme } from '@material-ui/core/styles';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import LinkIcon from '@material-ui/icons/Link';
import BuildIcon from '@material-ui/icons/Build';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AndroidIcon from '@material-ui/icons/Android';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SearchIcon from '@material-ui/icons/Search';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import {
  green,
  orange,
  red,
  deepPurple,
  yellow,
  blue,
  lightGreen,
  grey,
} from '@material-ui/core/colors';

import AppResource from './components/Resource';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import customReducers from './redux/reducers';
import initialState from './redux/initialState';
import { AppLayout } from './components/Layout';
import { Dashboard } from './components/pages';
import { UserList, UserEdit } from './components/pages/user';
import AdminList from './components/pages/admin/AdminList';
import AdminEdit from './components/pages/admin/AdminEdit';
import AdminCreate from './components/pages/admin/AdminCreate';
import * as Pages from './components/pages';
import { AffiliateCreate, AffiliateList, AffiliateEdit } from './components/pages/affiliate';
import { ReportCreate, ReportList } from './components/pages/report';
import { ImportProcessCreate, ImportProcessList } from './components/pages/import_process';
import { PromoCodeList, PromoCodeCreate, PromoCodeEdit } from './components/pages/promo_code';
import ShortLinkCreate from './components/pages/short_links/ShortLinkCreate';
import ShortLinkEdit from './components/pages/short_links/ShortLinkEdit';
import ShortLinksList from './components/pages/short_links/ShortLinksList';
import UserEventNotificationList from './components/pages/user_event_notification/UserEventNotificationList';
import UserEventNotificationCreate from './components/pages/user_event_notification/UserEventNotificationCreate';
import UserEventNotificationEdit from './components/pages/user_event_notification/UserEventNotificationEdit';
import { MassSendingProcessList, MassSendingProcessCreate } from './components/pages/mass_sending_process';
import { MassSendingCampaignList, MassSendingCampaignCreate, MassSendingCampaignEdit } from './components/pages/mass_sending_campaign';
import AutoAssignmentList from './components/pages/auto_assignment/AutoAssignmentList';
import AutoAssignmentCreate from './components/pages/auto_assignment/AutoAssignmentCreate';
import {
  CallCenterList,
  CallCenterListClosedLoans,
  CallCenterListUnsignedRequests,
  ListFilterUnsigned,
  ClientsWithPromoCodeList,
  DuplicatesList,
  ArchiveClientsList,
} from './components/pages/callcenter';
import { RobocallsCreate, RobocallsList } from './components/pages/robocalls';
import { RejectionReasonsList, RejectionReasonsCreate, RejectionReasonsEdit } from './components/pages/rejection_reasons';
import { CollectionMotivatorList, CollectionMotivatorEdit } from './components/pages/collection_motivators';
import { CollectionContactTypeList, CollectionContactTypeEdit } from './components/pages/collection_contact_types';
import CollectionContactTypeValueEdit
  from './components/pages/collection_contact_type_values/CollectionContactTypeValueEdit';
import { CollectionActionTreeList } from './components/pages/collection_action_tree';
import HolidayList from './components/pages/holiday/HolidayList';
import HolidayEdit from './components/pages/holiday/HolidayEdit';
import HolidayCreate from './components/pages/holiday/HolidayCreate';
import RoleList from './components/pages/role/RoleList';
import RoleEdit from './components/pages/role/RoleEdit';
import RoleCreate from './components/pages/role/RoleCreate';
import CollectionGroupList from './components/pages/collection_groups/CollectionGroupList';
import { CollectionIncomesList } from './components/pages/collection_incomes';
import { NotificationList } from './components/pages/notification';
import PhoneBook from './components/pages/phonebook/PhoneBook';
import ExternalAgenciesList from './components/pages/collection_external_agencies/ExternalAgenciesList';
import ExternalAgenciesCreate from './components/pages/collection_external_agencies/ExternalAgenciesCreate';
import ExternalAgenciesEdit from './components/pages/collection_external_agencies/ExternalAgenciesEdit';
import { TemplateVariablesTable } from './components/tables';
import ProlongationsList from './components/pages/Prolongations/ProlongationsList';
import ProlongationsCreate from './components/pages/Prolongations/ProlongationsCreate';
import { ProlongationEdit } from './components/pages/Prolongations/ProlongationEdit';
import AudienceList from './components/pages/audience/AudienceList';
import AudienceEdit from './components/pages/audience/AudienceEdit';
import AudienceCreate from './components/pages/audience/AudienceCreate';

const theme = createTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      light: deepPurple[200],
      main: '#3f50b5',
      dark: deepPurple[700],
      contrastText: '#fff',
    },
    secondary: {
      light: blue[200],
      main: blue[500],
      dark: '#01579b',
      contrastText: '#fff',
    },
    success: {
      light: lightGreen[200],
      main: green[500],
      dark: '#ba000d',
      contrastText: '#fff',
    },
    error: {
      light: red[200],
      main: red[500],
      dark: '#ba000d',
      contrastText: '#fff',
    },
    warning: {
      light: yellow[200],
      main: yellow[500],
      dark: '#ba000d',
      contrastText: '#000',
    },
    warningAlt: {
      light: orange[200],
      main: orange[500],
      dark: '#ba000d',
      contrastText: '#000',
    },
    default: {
      light: grey[200],
      main: '#999',
      dark: '#777',
      contrastText: '#000',
    },

    purple: {
      light: deepPurple[200],
      main: '#3f50b5',
      dark: deepPurple[700],
      contrastText: '#fff',
    },
    blue: {
      light: blue[200],
      main: blue[500],
      dark: '#01579b',
      contrastText: '#fff',
    },
    // info: {
    //   main: '',
    // },
    // grey: {
    //   main: '',
    // },
    // text: {
    //   main: '',
    // },
    // divider: {
    //   main: '',
    // },
    // background: {
    //   main: '',
    // },
    // getContrastText: {
    //   main: '',
    // },
  },
  sidebar: {
    width: 280,
    closedWidth: 55,
  },
  overrides: {
    RaRadioButtonGroupInput: {
      root: {
        color: 'red',
        // Add your custom styles here
      },
      radioButton: {
        backgroundColor: 'red',
        // Add your custom styles for radio buttons here
      },
    },
    MuiButtonGroup: {
      root: {},
    },
    // MuiFormControl: {
    //   root: {
    //     minWidth: '240px',
    //   },
    // },
    MuiPaper: {
      root: {
        boxShadow: 'none',
        border: '1px solid rgba(0, 0, 0, 0.12)',
      },
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiSelect: {
      root: {
        whiteSpace: 'break-spaces !important',
        gap: '2px',
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
    MuiSkeleton: {
      rect: {
        marginTop: 8,
        marginBottom: 5,
        height: 43.98,
        width: '100%',
      },
    },
    MuiTab: {
      root: {
        minWidth: 'auto !important',
        fontSize: '12px',
      },
    },
    MuiTableRow: {},
    MuiTabPanel: {
      root: {
        // border: '1px solid red',
      },
    },
    MuiTabs: {
      flexContainer: {
        maxWidth: '100%',
      },
    },
    // MuiTextField: {
    //   root: {
    //     minWidth: '240px !important',
    //   },
    // },
    MuiToolbar: {
      root: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
      },
    },
  },
});
const ListPendingNotReady = Pages.LoanListFilter(Pages.LoanListPendingNotReady, {
  state: 'pending',
  is_ready_for_disbursement: false,
}, [], Pages.ListFilters);
const ListPendingReady = Pages.LoanListFilter(Pages.LoanListPending, {
  state: 'pending',
  is_ready_for_disbursement: true,
  is_money_transferred: false,
}, [], Pages.ListFilters);
const ListPendingFailure = Pages.LoanListFilter(Pages.LoanListPending, {
  state: 'pending',
  money_transfer_status: 'failure',
}, [], Pages.ListFilters);
const ListCancelled = Pages.LoanListFilter(Pages.LoanList, {
  state: 'cancelled',
}, [], Pages.ListFilters);
const ListFirstManual = Pages.LoanListFilter(Pages.ManualVerificationList, { is_repeat: false });
const ListRepeatManual = Pages.LoanListFilter(Pages.ManualVerificationList, { is_repeat: true }, [], Pages.ManualVerificationListRepeatFilter);
const ManualVerified = Pages.LoanListFilter(Pages.ManualVerificationAll, { decision_engine_id: 'manual' });
const ListAll = Pages.LoanListFilter(Pages.LoanList, { _state: ['active', 'closed', 'defaulted', 'inactive', 'pending'] }, [
  { id: 'active', name: 'Active' },
  { id: 'closed', name: 'Closed' },
  { id: 'defaulted', name: 'Defaulted' },
  { id: 'inactive', name: 'Inactive' },
  { id: 'pending', name: 'Pending' },
], Pages.ListFilters);
const ListUncompleted = Pages.LoanListFilter(CallCenterList, { has_application: false });
const ListClosed = Pages.LoanListFilter(CallCenterListClosedLoans, { closedLoans: true }, [], Pages.ListFilters);
const ListUnsignedRequests = Pages.LoanListFilter(CallCenterListUnsignedRequests, { state: 'approved' }, [], ListFilterUnsigned);
const ListExtensionRequest = Pages.LoanListFilter(Pages.LoanListExtensionRequest, { enum_state: 'active', count_extension_more_than: 1 }, []);

const App = () => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Admin
      theme={theme}
      layout={AppLayout}
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={dataProvider}
      customReducers={customReducers}
      initialState={initialState}
      disableTelemetry
    >
      {(permissions, key) => [
        permissions.includes('CAN_USER_VIEW') && (
          <Resource
            key={key}
            name={'users'}
            icon={UserIcon}
            list={UserList}
            edit={UserEdit}
            options={{ label: 'Clients', menuGroup: 'users' }}
          />
        ),
        permissions.includes('CAN_USER_VIEW') && permissions.includes('CAN_CALL_CENTER_PAGES_VIEW') && (
          <AppResource
            key={key}
            name={'uncompleted_registration'}
            source={'users'}
            icon={UserIcon}
            list={ListUncompleted}
            edit={UserEdit}
            options={{ label: 'Uncompleted registration', menuGroup: 'call_centers' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && permissions.includes('CAN_CALL_CENTER_PAGES_VIEW') && (
          <AppResource
            key={key}
            name="closed_loans"
            source="loans"
            icon={AssignmentIcon}
            list={ListClosed}
            show={Pages.LoanShow}
            options={{ label: 'Closed loans', menuGroup: 'call_centers' }}
          />
        ),
        permissions.includes('CAN_APPLICATION_VIEW') && permissions.includes('CAN_CALL_CENTER_PAGES_VIEW') && (
          <AppResource
            key={key}
            name="unsigned_requests"
            source="applications"
            icon={AssignmentIcon}
            list={ListUnsignedRequests}
            show={Pages.ApplicationShow}
            options={{ label: 'Unsigned requests', menuGroup: 'call_centers' }}
          />
        ),
        permissions.includes('CAN_USER_VIEW') && permissions.includes('CAN_CALL_CENTER_PAGES_VIEW') && (
          <AppResource
            key={key}
            name="clients_with_promo_code"
            source="users"
            icon={UserIcon}
            list={ClientsWithPromoCodeList}
            edit={UserEdit}
            create={false}
            options={{ label: 'Clients with promo code', menuGroup: 'call_centers' }}
          />
        ),
        permissions.includes('CAN_DUPLICATES_VIEW') && permissions.includes('CAN_CALL_CENTER_PAGES_VIEW') && (
          <AppResource
            key={key}
            name="duplicates"
            source="duplicates"
            icon={UserIcon}
            list={DuplicatesList}
            edit={false}
            create={false}
            options={{ label: 'Duplicates', menuGroup: 'call_centers' }}
          />
        ),
        permissions.includes('CAN_DUPLICATES_VIEW') && permissions.includes('CAN_CALL_CENTER_PAGES_VIEW') && (
          <AppResource
            key={key}
            name="duplicate_registrations"
            source="duplicate_registrations"
            icon={UserIcon}
            list={ArchiveClientsList}
            edit={false}
            create={false}
            options={{ label: 'Archive clients', menuGroup: 'call_centers' }}
          />
        ),
        permissions.includes('CAN_APPLICATION_VIEW') && (
          <Resource
            key={key}
            name="applications"
            icon={AssignmentIcon}
            list={Pages.ApplicationList}
            show={Pages.ApplicationShow}
            options={{
              label: 'Applications',
              menuGroup: 'requests',
            }}
          />
        ),
        permissions.includes('CAN_MANUAL_VERIFICATIONS_VIEW') && (
          <AppResource
            key={key}
            name="manual_verification_all"
            source="applications"
            icon={AssignmentIcon}
            list={ManualVerified}
            show={Pages.ApplicationShow}
            options={{
              label: 'Manual verification',
              menuGroup: 'requests',
            }}
          />
        ),
        permissions.includes('CAN_APPLICATION_VIEW') && (
          <AppResource
            key={key}
            name="manual_verification"
            source="applications"
            icon={AssignmentIcon}
            list={ListFirstManual}
            show={Pages.ApplicationShow}
            options={{
              label: 'First manual verif',
              menuGroup: 'requests',
            }}
          />
        ),
        permissions.includes('CAN_APPLICATION_VIEW') && (
          <AppResource
            key={key}
            name="repeat_manual_verification"
            source="applications"
            icon={AssignmentIcon}
            list={ListRepeatManual}
            show={Pages.ApplicationShow}
            options={{
              label: 'Repeat manual verif',
              menuGroup: 'requests',
            }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <Resource
            key={key}
            name="loans"
            icon={AssignmentTurnedInIcon}
            list={ListAll}
            show={Pages.LoanShow}
            options={{ label: 'Loans', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="in_progress"
            source="loans"
            icon={AssignmentTurnedInIcon}
            list={ListPendingNotReady}
            show={Pages.LoanShow}
            options={{ label: 'In progress', menuGroup: 'pending' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && permissions.includes('CAN_LOAN_TRANSITION_TRANSFER_MONEY') && (
          <AppResource
            key={key}
            name="ready_for_disbursement"
            source="loans"
            icon={AssignmentTurnedInIcon}
            list={ListPendingReady}
            show={Pages.LoanShow}
            options={{ label: 'Ready for disbursement', menuGroup: 'pending' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="failure"
            source="loans"
            icon={SmsFailedIcon}
            list={ListPendingFailure}
            show={Pages.LoanShow}
            options={{ label: 'Failure', menuGroup: 'pending' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="cancelled"
            source="loans"
            icon={AssignmentTurnedInIcon}
            list={ListCancelled}
            show={Pages.LoanShow}
            options={{ label: 'Cancelled', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="collection"
            source="loans"
            icon={AssignmentLateIcon}
            list={Pages.CollectionLoanList}
            show={Pages.LoanShow}
            options={{ label: 'Collection', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="external_agency_loans"
            source="loans"
            icon={AssignmentReturnIcon}
            list={Pages.ExternalAgencyLoans}
            show={Pages.LoanShow}
            options={{ label: 'External agency', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <AppResource
            key={key}
            name="extension_request"
            source="loans"
            icon={UpdateIcon}
            list={ListExtensionRequest}
            show={Pages.LoanShow}
            options={{ label: 'Extensions', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_LOAN_VIEW') && (
          <Resource
            key={key}
            icon={SearchIcon}
            name="phone_books"
            source="phone_books"
            list={PhoneBook}
            options={{ label: 'Phone books', menuGroup: 'loans' }}
          />
        ),
        permissions.includes('CAN_REJECTION_REASONS_EDIT') && (
          <AppResource
            key={key}
            name="rejection_reasons"
            source="rejection_reasons"
            list={RejectionReasonsList}
            create={RejectionReasonsCreate}
            edit={RejectionReasonsEdit}
            options={{ label: 'Rejection reasons', menuGroup: 'handbook' }}
          />
        ),
        permissions.includes('CAN_COLLECTION_CALL_TREE_EDIT') && (
          <AppResource
            key={key}
            name="collection_action_tree"
            source="collection_action_tree"
            list={CollectionActionTreeList}
            edit={false}
            options={{ label: 'Action tree', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_COLLECTION_CALL_TREE_VIEW') && (
          <AppResource
            key={key}
            name="collection_motivators"
            source="collection_motivators"
            list={CollectionMotivatorList}
            edit={permissions.includes('CAN_COLLECTION_CALL_TREE_EDIT') && CollectionMotivatorEdit}
            options={{ label: 'Motivators', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_COLLECTION_CALL_TREE_VIEW') && (
          <AppResource
            key={key}
            name="collection_contact_types"
            source="collection_contact_types"
            list={CollectionContactTypeList}
            edit={permissions.includes('CAN_COLLECTION_CALL_TREE_EDIT') && CollectionContactTypeEdit}
            options={{ label: 'Contact types', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_COLLECTION_GROUP_VIEW') &&
        (
          <AppResource
            key={key}
            name="collection_groups"
            source="collection_groups"
            list={CollectionGroupList}
            options={{ label: 'Collection groups', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_VIEW_INCOME_PAYMENTS') &&
        (
          <AppResource
            key={key}
            name="incomes"
            source="incomes"
            icon={AttachMoneyIcon}
            list={CollectionIncomesList}
            options={{ label: 'Incomes', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_EXTERNAL_AGENCY_VIEW') &&
        (
          <AppResource
            key={key}
            name="external_agencies"
            source="external_agencies"
            icon={AccountBalanceIcon}
            list={ExternalAgenciesList}
            create={permissions.includes('CAN_EXTERNAL_AGENCY_EDIT') && ExternalAgenciesCreate}
            edit={permissions.includes('CAN_EXTERNAL_AGENCY_EDIT') && ExternalAgenciesEdit}
            options={{ label: 'External agencies', menuGroup: 'collection' }}
          />
        ),
        permissions.includes('CAN_COLLECTION_CALL_TREE_EDIT') &&
        (
          <AppResource
            key={key}
            name="collection_contact_type_values"
            source="collection_contact_type_values"
            list={false}
            edit={CollectionContactTypeValueEdit}
            options={{ menuGroup: 'hidden' }}
          />
        ),
        permissions.includes('CAN_NOTIFICATION_ALL_VIEW') &&
        (
          <Resource
            key={key}
            icon={MailIcon}
            name="notifications"
            source="notifications"
            list={NotificationList}
            edit={false}
            options={{ label: 'Notifications' }}
          />
        ),
        permissions.includes('CAN_IMPORT_PROCESS_VIEW')
          ? (
            <Resource
              name="import_processes"
              icon={CloudUploadIcon}
              list={ImportProcessList}
              create={permissions.includes('CAN_IMPORT_PROCESS_EDIT') ? ImportProcessCreate : null}
              options={{ label: 'Imports', menuGroup: 'management' }}
            />
          )
          : null,
        permissions.includes('CAN_REPORT_VIEW')
          ? (
            <Resource
              name="reports"
              icon={AssessmentIcon}
              list={ReportList}
              create={permissions.includes('CAN_REPORT_EDIT') ? ReportCreate : null}
              options={{ label: 'Reports', menuGroup: 'management' }}
            />
          )
          : null,
        permissions.includes('CAN_ADMIN_VIEW')
          ? (
            <Resource
              name="admins"
              icon={SupervisorAccountIcon}
              list={AdminList}
              edit={AdminEdit}
              create={permissions.includes('CAN_ADMIN_EDIT') ? AdminCreate : null}
              options={{ label: 'Admins', menuGroup: 'management' }}
            />
          )
          : null,
        permissions.includes('CAN_ROLE_EDIT')
          ? (
            <Resource
              name="admin_auth_roles"
              icon={SupervisorAccountIcon}
              list={RoleList}
              edit={RoleEdit}
              create={permissions.includes('CAN_ROLE_EDIT') ? RoleCreate : null}
              options={{ label: 'Roles', menuGroup: 'management' }}
            />
          )
          : null,
        permissions.includes('CAN_ROBOCALL_VIEW')
          ? (
            <Resource
              name="robocall_tasks"
              icon={AndroidIcon}
              list={RobocallsList}
              edit={false}
              create={permissions.includes('CAN_ROBOCALL_EDIT') ? RobocallsCreate : null}
              options={{ label: 'Robocalls', menuGroup: 'management' }}
            />
          )
          : null,
        permissions.includes('CAN_TEMPLATE_VIEW')
          ? (
            <Resource
              name="templates"
              icon={TextFormatIcon}
              list={Pages.TemplateList}
              edit={Pages.TemplateEdit}
              create={permissions.includes('CAN_TEMPLATE_EDIT') ? Pages.TemplateCreate : null}
              options={{ label: 'Templates', menuGroup: 'settings' }}
            />
          )
          : null,
        permissions.includes('CAN_TEMPLATE_VIEW')
          ? (
            <Resource
              name={'template_placeholders'}
              icon={TextFormatIcon}
              list={TemplateVariablesTable}
              options={{ label: 'Template variables', menuGroup: 'settings' }}
            />
          )
          : null,
        permissions.includes('CAN_AFFILIATE_VIEW')
          ? (
            <Resource
              name="affiliates"
              icon={PublicIcon}
              list={AffiliateList}
              edit={AffiliateEdit}
              create={permissions.includes('CAN_AFFILIATE_EDIT') ? AffiliateCreate : null}
              options={{ label: 'Affiliates', menuGroup: 'settings' }}
            />
          )
          : null,
        permissions.includes('CAN_MASS_SENDING_VIEW')
          ? (
            <Resource
              name="mass_sending_campaigns"
              icon={MailIcon}
              list={MassSendingCampaignList}
              edit={MassSendingCampaignEdit}
              create={permissions.includes('CAN_MASS_SENDING_EDIT') ? MassSendingCampaignCreate : null}
              options={{ label: 'Mass sending', menuGroup: 'settings' }}
            />
          )
          : null,
        permissions.includes('CAN_EDIT_EXTENSION_DISCOUNT')
          ? (
            <Resource
              name="auto_assignments"
              icon={DirectionsRunIcon}
              list={AutoAssignmentList}
              create={permissions.includes('CAN_AUTO_ASSIGNMENT_EDIT') ? AutoAssignmentCreate : null}
              options={{ label: 'Auto assignment', menuGroup: 'settings' }}
            />
          )
          : null,
        permissions.includes('CAN_VIEW_EXTENSION_DISCOUNT')
          ? (
            <Resource
              name="extension_discounts"
              icon={LocalOfferIcon}
              list={ProlongationsList}
              edit={ProlongationEdit}
              create={permissions.includes('CAN_EDIT_EXTENSION_DISCOUNT') ? ProlongationsCreate : null}
              options={{ label: 'Prolongations', menuGroup: 'settings' }}
            />
          )
          : null,
        permissions.includes('CAN_HOLIDAY_VIEW')
          ? (
            <Resource
              name="holidays"
              icon={WeekendIcon}
              list={HolidayList}
              edit={HolidayEdit}
              create={permissions.includes('CAN_HOLIDAY_EDIT') ? HolidayCreate : null}
              options={{ label: 'Holidays', menuGroup: 'settings' }}
            />
          )
          : null,
        permissions.includes('CAN_DIALER_VIEW')
          ? (
            <Resource
              name="dialer_campaigns"
              icon={BuildIcon}
              list={Pages.DialerBasesList}
              create={permissions.includes('CAN_DIALER_EDIT') ? Pages.DialerBasesCreate : null}
              edit={permissions.includes('CAN_DIALER_EDIT') ? Pages.DialerBasesEdit : null}
              options={{ label: 'Dialer bases', menuGroup: 'settings' }}
            />
          )
          : null,
        permissions.includes('CAN_PROMO_CODE_VIEW')
          ? (
            <Resource
              name="promo_codes"
              icon={LocalOfferIcon}
              list={PromoCodeList}
              edit={PromoCodeEdit}
              create={permissions.includes('CAN_PROMO_CODE_EDIT') ? PromoCodeCreate : null}
              options={{ label: 'Promo codes', menuGroup: 'marketing' }}
            />
          )
          : null,
        permissions.includes('CAN_USER_EVENT_NOTIFICATION_VIEW')
          ? (
            <Resource
              name="user_event_notifications"
              icon={NotificationsIcon}
              list={UserEventNotificationList}
              edit={UserEventNotificationEdit}
              create={permissions.includes('CAN_USER_EVENT_NOTIFICATION_EDIT') ? UserEventNotificationCreate : null}
              options={{ label: 'User notifications', menuGroup: 'marketing' }}
            />
          )
          : null,
        permissions.includes('CAN_MASS_SENDING_VIEW')
          ? (
            <Resource
              name="mass_sending_processes"
              icon={MailIcon}
              list={MassSendingProcessList}
              create={permissions.includes('CAN_MASS_SENDING_EDIT') ? MassSendingProcessCreate : null}
              options={{ label: 'Mass sending', menuGroup: 'marketing' }}
            />
          )
          : null,
        permissions.includes('CAN_SHORT_LINK_VIEW')
          ? (
            <Resource
              name="short_links"
              icon={LinkIcon}
              list={ShortLinksList}
              edit={ShortLinkEdit}
              create={permissions.includes('CAN_SHORT_LINK_EDIT') ? ShortLinkCreate : null}
              options={{ label: 'Short links', menuGroup: 'marketing' }}
            />
          )
          : null,
        (permissions.includes('CAN_MASS_SENDING_AUDIENCE_VIEW') || permissions.includes('CAN_MASS_SENDING_AUDIENCE_EDIT'))
          ? (
            <Resource
              name="mass_sending_audiences"
              icon={AssignmentIcon}
              list={AudienceList}
              edit={AudienceEdit}
              create={permissions.includes('CAN_MASS_SENDING_AUDIENCE_EDIT') ? AudienceCreate : null}
              options={{ label: 'Audiences', menuGroup: 'marketing' }}
            />
          )
          : null,
        permissions.includes('CAN_BLACK_LIST_EDIT')
          ? (
            <Resource
              name={'black_lists'}
              icon={BlockIcon}
              list={Pages.BlackList}
              edit={Pages.BlackListEdit}
              create={Pages.BlackListCreate}
              options={{ label: 'Blacklist' }}
            />
          )
          : null,
        <Resource
          key={key}
          name="manual_verification_steps"
          options={{ menuGroup: 'hidden' }}
        />,
        <Resource
          key={key}
          icon={BuildIcon}
          name="settings"
          list={Pages.Settings}
          edit={false}
          create={false}
          options={{ label: 'Settings', menuGroup: 'settings' }}
        />,
        permissions.includes('CAN_WORK_SCHEDULE_VIEW')
          ? (
            <Resource
              key={key}
              icon={ScheduleIcon}
              name={'work_schedule'}
              list={Pages.WorkSchedule}
              edit={false}
              create={false}
              options={{ label: 'Work schedule', menuGroup: 'settings' }}
            />
          )
          : null,
      ]}
    </Admin>
  </MuiPickersUtilsProvider>
);
export default App;
