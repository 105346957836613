import PropTypes from 'prop-types';
import React from 'react';

import { TitleCloseDialog } from '../../../dialogs';

export const LogDialog = ({ open, close, children, title }) => (
  <TitleCloseDialog
    open={open || false}
    close={close}
    title={title}
    maxWidth={'md'}
  >
    {children}
  </TitleCloseDialog>
);

LogDialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  children: PropTypes.element,
  title: PropTypes.string,
};
