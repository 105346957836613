import React, { useEffect, useState } from 'react';
import { BooleanInput, SaveButton, SelectInput, TextInput, useRedirect } from 'react-admin';
import {
  Box,
  Button,
  ButtonGroup,
  CardContent, Chip,
  Dialog,
  DialogContent,
  DialogTitle, FormControl,
  Grid,
  IconButton,
  InputLabel, MenuItem,
  Select,
  Toolbar, useTheme,
} from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import PropTypes from 'prop-types';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import CloseIcon from '@material-ui/icons/Close';

import { EN_LANGUAGE, TEMPLATE_CATEGORIES, TEMPLATE_TYPES } from '../../../utils';
import { TemplateVariablesTable } from '../../tables';
import { useHandbook } from '../../../hooks';

export const TemplateForm = ({ permissions, save, record, formProps, selectedRolesStr, setSelectedRolesStr, ...props }) => {
  const redirect = useRedirect();
  const theme = useTheme();
  const [isEmailCategory, setIsEmailCategory] = useState(record.category);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isEditable = permissions?.indexOf('CAN_TEMPLATE_EDIT') !== -1;
  const { data: roles } = useHandbook('admin_auth_roles');

  useEffect(() => {
    if (record && record.manual_sending_roles?.length > 0) {
      setSelectedRolesStr(formProps.record.manual_sending_roles);
    }
  }, [record, setSelectedRolesStr]);
  const handleGoToList = () => redirect('/templates');
  const handleModalClose = () => setIsModalOpen(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleChangeCategory = (e) => setIsEmailCategory(e.target.value);
  const handleChangeRoles = (event) => {
    const targetValue = event.target.value;
    const lastAddedVal = targetValue[targetValue.length - 1].code;
    if (selectedRolesStr.includes(lastAddedVal)) {
      setSelectedRolesStr((prevStrs) => prevStrs.filter((role) => role !== lastAddedVal));
    } else {
      setSelectedRolesStr((prevStrs) => [...prevStrs, lastAddedVal]);
    }
  };

  return (
    <CardContent>
      <Grid container spacing={2} style={{ minWidth: '100%', width: 'auto' }}>
        <Grid item xs={12} md={6}>
          <SelectInput
            disabled={!isEditable}
            fullWidth
            variant={'outlined'}
            size={'small'}
            source={'category'}
            choices={TEMPLATE_CATEGORIES}
            onChange={handleChangeCategory}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            fullWidth
            variant={'outlined'}
            disabled
            defaultValue={EN_LANGUAGE[0].id}
            source={'locale'}
            choices={EN_LANGUAGE}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput size={'small'} disabled={!isEditable} variant={'outlined'} fullWidth source={'key'} />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            fullWidth
            size={'small'}
            variant={'outlined'}
            source={'type'}
            initialValue={'System'}
            choices={TEMPLATE_TYPES}
          />
        </Grid>
      </Grid>
      {isEmailCategory === 'Email' && (
        <TextInput fullWidth size={'small'} variant={'outlined'} source={'subject'} />
      )}
      <BooleanInput disabled={!isEditable} helperText={false} label={'Is promo code'} source={'is_promo_code'} />
      <TextInput disabled={!isEditable} multiline fullWidth size={'small'} variant={'outlined'} source={'template'} />
      {isEmailCategory === 'SMS' && (
        <FormControl variant={'outlined'} size={'small'} fullWidth>
          <InputLabel>Access to manual sending</InputLabel>
          <Select
            label={'Access to manual sending'}
            multiple
            value={selectedRolesStr}
            onChange={handleChangeRoles}
            disabled={!isEditable}
            renderValue={() => selectedRolesStr.map((code, index) =>
              <Chip key={index} label={code} />)}>
            {roles.map((role) =>
              <MenuItem
                style={{ backgroundColor: selectedRolesStr.includes(role.code) ? theme.palette.primary.light : '' }}
                key={role.id}
                value={role}>
                {role.code}
              </MenuItem>,
            )}
          </Select>
        </FormControl>)}
      <Toolbar variant={'regular'} style={{ padding: 0 }} {...props}>
        <ButtonGroup variant={'contained'} color={'primary'} {...props}>
          <SaveButton redirect={'list'} saving={formProps.saving} handleSubmitWithRedirect={formProps.handleSubmitWithRedirect} label={'Save'} />
          <Button startIcon={<ListIcon />} onClick={handleGoToList}>Back to list</Button>
          <Button startIcon={<TextFormatIcon />} variant={'outlined'} onClick={handleModalOpen}>Template Variables list</Button>
        </ButtonGroup>
        {/* {!isEmailCategory && <SmsCount />} */}
      </Toolbar>
      {isModalOpen && <Dialog maxWidth={'md'} open={isModalOpen} onClose={handleModalClose}>
        <Box padding={'10px'} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
          <DialogTitle variant={'subtitle1'}>Print variables list</DialogTitle>
          <IconButton onClick={handleModalClose}><CloseIcon /></IconButton>
        </Box>
        <DialogContent>
          <TemplateVariablesTable />
        </DialogContent>
      </Dialog>}
    </CardContent>
  );
};

TemplateForm.propTypes = {
  permissions: PropTypes.array,
  save: PropTypes.func,
  selectedRolesStr: PropTypes.array,
  setSelectedRolesStr: PropTypes.func,
  record: PropTypes.shape({
    category: PropTypes.string,
    id: PropTypes.number,
    is_promo_code: PropTypes.bool,
    key: PropTypes.string,
    locale: PropTypes.string,
    subject: PropTypes.oneOfType([PropTypes.string]),
    template: PropTypes.string,
    manual_sending_roles: PropTypes.array,
    type: PropTypes.string,
  }),
  formProps: PropTypes.shape({
    saving: PropTypes.any,
    handleSubmitWithRedirect: PropTypes.any,
  }),
};
