export const validators = {
  affiliate_credits: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  summary_report: ({ start_date, end_date, calculation_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!calculation_date) {
      errors.calculation_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  income_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  loan_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  collection_loan_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  application_report: ({ start_date, end_date, timezone, date_format, filter_by_date }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!filter_by_date) {
      errors.filter_by_date = 'Required';
    }

    return errors;
  },
  cashflow_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  security_report: ({ start_date, end_date, calculation_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!calculation_date) {
      errors.calculation_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  transaction_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  extension_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  notification_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  local_notification_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  security_application_report: ({ start_date, end_date, timezone, date_format, filter_by_date }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!filter_by_date) {
      errors.filter_by_date = 'Required';
    }

    return errors;
  },
  promo_codes_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  unfinished_registration_report: ({ start_date, end_date }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },
  affiliate_event_report: ({ start_date, end_date }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },
  closed_loan_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },
  verification_staff_statistics_report: ({ start_date, end_date }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },
  promo_code_sms_report: ({ start_date, end_date, timezone, date_format }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },

  collection_email_report: ({ timezone, date_format }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }

    return errors;
  },

  collection_assign_report: ({ timezone, date_format, start_date, end_date, min_dpd, max_dpd }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }
    if (min_dpd === undefined) {
      errors.min_dpd = 'Required';
    }
    if (max_dpd === undefined) {
      errors.max_dpd = 'Required';
    }

    return errors;
  },

  collection_call_report: ({ timezone, date_format, start_date, end_date }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },

  accounting_loan_report: ({ timezone, date_format, start_date, end_date }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },

  accounting_paid_report: ({ timezone, date_format, start_date, end_date }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },

  accounting_unpaid_report: ({ timezone, date_format, end_date }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },

  accounting_total_report: ({ timezone, date_format, start_date, end_date }) => {
    const errors = {};

    if (!timezone) {
      errors.timezone = 'Required';
    }
    if (!date_format) {
      errors.date_format = 'Required';
    }
    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },
  functional_link_log_report: ({ start_date, end_date }) => {
    const errors = {};

    if (!start_date) {
      errors.start_date = 'Required';
    }
    if (!end_date) {
      errors.end_date = 'Required';
    }

    return errors;
  },
};
