import React, { useState } from 'react';
import { Datagrid, FunctionField, List, TextField, useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Box, Button, ButtonGroup, Chip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { CALL_CENTER_USER_STATUS_CLOSED_LOANS } from '../../../utils';
import Pagination from '../../Pagination';
import { UserListActions } from '../user';
import { LOAN_LIST_ROWS } from '../loan';
import { CallCenterLogTable } from '../../tables';
import { DATE_FORMAT, TIME_FORMAT_SECONDS } from '../../../constants';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

import { assignEssence, changeStatus } from './actions';
import { DropdownDialog, LogDialog } from './dialogs';

export const CallCenterListClosedLoans = ({ statefilterchoices = [], listfilters: ListFilters, permissions, ...props }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [refreshedAt, setRefreshedAt] = useState();
  const [openLogDialog, setOpenLogDialog] = useState(false);
  const [openDropdownDialog, setOpenDropdownDialog] = useState(false);
  const [loanIdLog, setLoanIdLog] = useState();
  const [loanIdStatus, setLoanIdStatus] = useState();

  const handleStatusSubmit = (userStatus) =>
    changeStatus(
      userStatus,
      dataProvider,
      notify,
      setOpenDropdownDialog,
      refresh,
      'phone_calls',
      {
        loan_id: loanIdStatus,
        result: userStatus,
        context: 'loan_closed_loans',
      },
    );
  const handleChangeStatusDialogOpen = () => setOpenDropdownDialog((state) => !state);
  const handleChangeStatus = (id) => {
    setLoanIdStatus(id);
    setOpenDropdownDialog(state => !state);
  };
  const handleShowLogDialog = (id) => {
    setLoanIdLog(id);
    setRefreshedAt(new Date().valueOf());
    setOpenLogDialog(state => !state);
  };
  const handleAssign = (id) => {
    assignEssence(
      dataProvider,
      notify,
      refresh,
      'phone_call_loan_assigns',
      { loan_id: id, context: 'loan_closed_loans' },
    );
  };
  return (
    <>
      <LogDialog open={openLogDialog} close={setOpenLogDialog} title={'Loan status log'}>
        <CallCenterLogTable
          refreshedAt={refreshedAt}
          filter={{ 'loan.id': loanIdLog, context: 'loan_closed_loans' }}
          isDialog
        />
      </LogDialog>
      <DropdownDialog
        title={'Change loan status'}
        isOpened={openDropdownDialog}
        onClose={handleChangeStatusDialogOpen}
        onSubmit={handleStatusSubmit}
        selectOptions={CALL_CENTER_USER_STATUS_CLOSED_LOANS}
      />
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<ListFilters statefilterchoices={statefilterchoices} />}
        actions={<UserListActions />}
        permissions={permissions}
        {...props}
      >
        <Datagrid rowClick={'show'}>
          {LOAN_LIST_ROWS.slice(0, 9).map((i) => i)}
          <FunctionField label={'Closing date'} render={(record) => <DateTimeBoxComponent value={record?.closed_at} />} />
          <FunctionField
            onClick={(e) => e.stopPropagation()}
            label={'Status actions'}
            render={({ last_phone_calls_by_context, id }) => {
              if (last_phone_calls_by_context?.loan_closed_loans?.operator) {
                return (
                  <ButtonGroup color={'secondary'}>
                    <Button size={'small'} onClick={() => handleChangeStatus(id)} disabled={permissions.indexOf('CAN_PHONE_CALL_EDIT') === -1}>
                      <RefreshIcon />
                    </Button>
                    <Button
                      size={'small'}
                      onClick={handleShowLogDialog}
                      disabled={permissions.indexOf('CAN_PHONE_CALL_VIEW') === -1}
                    >
                      Log
                    </Button>
                  </ButtonGroup>
                );
              } else {
                return (
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => handleAssign(id)}
                    disabled={permissions.indexOf('CAN_PHONE_CALL_EDIT') === -1}
                  >Assign</Button>
                );
              }
            }
            }
          />
          <TextField label={'Operator'} source={'last_phone_calls_by_context.loan_closed_loans.operator'} />
          <FunctionField label={'Last call result'} render={({ last_phone_calls_by_context }) => last_phone_calls_by_context?.loan_closed_loans?.result && <Chip
            label={last_phone_calls_by_context?.loan_closed_loans?.result} size={'small'} />} />
          <FunctionField label={'Last activity'}
            render={
              (record) => record.last_phone_calls_by_context?.user_uncompleted_registrations?.called_at &&
                 <Box>
                   <div>{format(new Date(record.last_phone_calls_by_context?.user_uncompleted_registrations?.called_at), DATE_FORMAT)}</div>
                   <div>{format(new Date(record.last_phone_calls_by_context?.user_uncompleted_registrations?.called_at), TIME_FORMAT_SECONDS)}</div>
                 </Box>} />
          {LOAN_LIST_ROWS.slice(9).map((i) => i)}
        </Datagrid>
      </List>
    </>
  );
};

CallCenterListClosedLoans.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
};
