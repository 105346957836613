import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import {
  Typography, Box, Card, Chip, CircularProgress, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select,
  CardContent, Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const TemplateVariableRow = ({ item, category }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={7}><Chip style={{ fontWeight: 'bold' }} size={'small'} label={`{{ ${item[0]} }}`}/></Grid>
        <Grid item xs={12} md={5}>
          <Box alignItems={'center'} justifyContent={'space-between'} display={'flex'} gridGap={10}>
            <Typography color={'textSecondary'} variant={'body2'}>{'Value'}</Typography>
            <Typography variant={'body1'}>{`${item[1].example}`}</Typography>
          </Box>
          <Box alignItems={'center'} justifyContent={'space-between'} display={'flex'} gridGap={10}>
            <Typography color={'textSecondary'} variant={'body2'}>{'Description'}</Typography>
            <Typography variant={'body1'}>{`${item[1].description}`}</Typography>
          </Box>
          <Box alignItems={'center'} justifyContent={'space-between'} display={'flex'} gridGap={10}>
            <Typography color={'textSecondary'} variant={'body2'}>{'Category'}</Typography>
            <Typography color={'secondary'}>{category}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
    </>
  );
};

TemplateVariableRow.propTypes = {
  isShow: PropTypes.bool,
  item: PropTypes.shape({
    example: PropTypes.string,
    description: PropTypes.string,
  }),
  category: PropTypes.string,
};

export const TemplateVariablesTable = () => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [templateVarsList, setTemplateVarsList] = useState(null);
  const [error, setError] = useState();
  const [templateVarsCategories, setTemplateVarsCategories] = useState([]);

  const fetchVars = () => {
    return (
      dataProvider.query('template_placeholders', {})
        .then((res) => {
          if (res.data) setTemplateVarsList(res.data);
        })
        .catch((error) => setError(error))
    );
  };

  useEffect(() => {
    fetchVars();
  }, [dataProvider]);

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!templateVarsList) return <CircularProgress />;
  const handleChange = (event) => {
    const { target: { value } } = event;
    setTemplateVarsCategories(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <FormControl size={'small'} variant={'outlined'} fullWidth>
          <InputLabel id={'category-lbl'}>{'Categories'}</InputLabel>
          <Select
            labelId={'category-lbl'}
            multiple
            value={templateVarsCategories}
            onChange={handleChange}
            input={<OutlinedInput id={'select-multiple-chip'} label={'Categories'} />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => <Chip key={value} label={value} />)}
              </Box>
            )}
          >
            {Object.keys(templateVarsList).map((name) => <MenuItem key={name} value={name}>{name}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent style={{ maxHeight: '100%', overflowY: 'auto' }}>
            {Object.entries(templateVarsList).map((item, index) =>
              (Object.entries(item[1]).map((item2, key) => templateVarsCategories.includes(item[0]) || templateVarsCategories.length === 0
                ? <TemplateVariableRow category={item[0]} item={item2} key={key}/>
                : null)))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
