import React from 'react';
import { Button } from 'react-admin';
import { Tabs, Tab, Box, Badge, Typography, Card, CardContent, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';

export const TabView = ({ children, value, index, ...other }) => (
  <div
    role={'tabpanel'}
    hidden={value !== index}
    id={`scrollable-auto-tabpanel-${index}`}
    aria-labelledby={`scrollable-auto-tab-${index}`}
    {...other}
  >{value === index ? children : null}</div>
);

TabView.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps (index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export const TabPanel = ({ label, items = [], isClosable = true, activeTabIndex, onTabClick }) => {
  const [isTabBodyShow, setIsTabBodyShow] = React.useState(true);
  const [activeTabIndexDefault, setActiveTabIndexDefault] = React.useState(0);

  const handleChange = (event, newValue) => {
    setIsTabBodyShow(true);
    if (onTabClick) {
      onTabClick(newValue);
      return;
    }
    setActiveTabIndexDefault(newValue);
  };

  const handleHideTabBody = () => setIsTabBodyShow(false);

  return (
    <Card variant={'outlined'}>
      {label && (
        <Typography variant={'h6'} component={'div'} >
          {label}:
        </Typography>
      )}
      <Box display={'flex'} alignItems={'center'}>
        {isClosable && <Button color={'secondary'} label={'✖'} onClick={handleHideTabBody} />}
        <Tabs value={activeTabIndex || activeTabIndexDefault} onChange={handleChange} variant={'scrollable'}>
          {items.length > 0 && items.map(({ label, value }, idx) =>
            <Tab
              key={`tab-${idx}`}
              label={<Badge badgeContent={value} color={'error'}>{label}</Badge>}
              {...a11yProps(idx)}
            />)}
        </Tabs>
      </Box>
      <Divider />
      <CardContent hidden={!isTabBodyShow}>
        {items.map(({ component }, idx) => <TabView key={`panel-${idx}`} value={activeTabIndex || activeTabIndexDefault} index={idx}>{component}</TabView>)}
      </CardContent>
    </Card>
  );
};

TabPanel.propTypes = {
  label: PropTypes.string,
  labels: PropTypes.array,
  items: PropTypes.array,
  isClosable: PropTypes.bool,
  activeTabIndex: PropTypes.number,
  onTabClick: PropTypes.func,
};
