import { getHours, getMinutes, getSeconds } from 'date-fns';

import { COUNTRY_CODE, PHONE_NUMBER_PLACEHOLDER } from '../constants';

import { getAdminTimezoneOffset } from './getAdminTimezoneOffset';

export const phoneNumber = {
  placeholder: `${COUNTRY_CODE} ${PHONE_NUMBER_PLACEHOLDER}`,
  parser: value => value && value.replace(/\D/g, '').slice(COUNTRY_CODE.length - 1, 12),
  formatter: value => {
    if (!value) {
      return COUNTRY_CODE;
    }

    if (value.length >= 9) {
      return `${COUNTRY_CODE} ${value.slice(0, 3)} ${value.slice(3, 6)} ${value.slice(6)}`;
    } else if (value.length >= 7) {
      return `${COUNTRY_CODE} ${value.slice(0, 3)} ${value.slice(3, 6)} ${value.slice(6)}`;
    } else if (value.length >= 3) {
      return `${COUNTRY_CODE} ${value.slice(0, 2)} ${value.slice(2)}`;
    } else {
      return `${COUNTRY_CODE} ${value}`;
    }
  },
  validator: value => value && [9].includes(value.replace(/\D/g, '').length),
};

const getTimezoneShiftMilliseconds = () => 60000 * (getAdminTimezoneOffset() + new Date().getTimezoneOffset());

export const timezoneOffsetLabel = (offset) => {
  const sign = (offset < 0 ? '-' : '+');
  const minuteOffset = Math.abs(offset) % 60;
  const minuteOffsetLabel = minuteOffset < 10 ? `0${minuteOffset}` : `${minuteOffset}`;
  const hoursOffset = Math.floor(Math.abs(offset / 60));
  const hoursOffsetLabel = hoursOffset < 10 ? `0${hoursOffset}` : `${hoursOffset}`;

  return `${sign}${hoursOffsetLabel}:${minuteOffsetLabel}`;
};

export const timezone = {
  shift: date => new Date(date.valueOf() + getTimezoneShiftMilliseconds()),
  unshift: date => new Date(date.valueOf() - getTimezoneShiftMilliseconds()),
  setTime: (date, h, m, s) => {
    const v = new Date(date);
    const hours = h !== 'undefined' ? h : getHours(v);
    const minutes = m !== 'undefined' ? m : getMinutes(v);
    const seconds = s !== 'undefined' ? s : getSeconds(v);

    v.setHours(hours, minutes, seconds, 0);

    return new Date(v.valueOf() - getTimezoneShiftMilliseconds());
  },
  setTimeWithoutOffset: (date, h = 0, m = 0, s = 0, ms = 0) => {
    const v = new Date(date);
    v.setHours(0, 0, 0, 0);
    return new Date(v.valueOf() + ((((h * 60) + m) * 60 + s) * 1000) + ms);
  },
};
