import React, { useState } from 'react';
import { TableCell, TableSortLabel } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

export const TableCellSort = ({ children, changeArray, filter, activeFilter, setActiveFilter }) => {
  const [desc, setDesc] = useState(false);
  const handleChange = (changeArray, filter) => {
    setDesc(() => !desc);
    filterArray(changeArray, filter);
    setActiveFilter(filter);
  };
  const filterArray = (changeArray, filter) => {
    changeArray((state) => state.slice().sort((a, b) => {
      if (desc) {
        return b[filter] - a[filter];
      } else {
        return a[filter] - b[filter];
      }
    }));
  };
  return (
    <TableCell>
      <Tooltip title={'Sort'}>
        <TableSortLabel
          active={activeFilter === filter && true}
          direction={desc ? 'asc' : 'desc'}
          hideSortIcon={false}
          onClick={() => handleChange(changeArray, filter)}
        >
          {children}
        </TableSortLabel>
      </Tooltip>
    </TableCell>
  );
};

TableCellSort.propTypes = {
  children: PropTypes.node,
  changeArray: PropTypes.func,
  filter: PropTypes.any,
  activeFilter: PropTypes.any,
  setActiveFilter: PropTypes.func,
};
