import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TablePagination } from '@material-ui/core';

import { formatPhoneNumber } from '../../utils';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

export const DuplicateRegistrationTable = ({ userId = null, refreshedAt }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [duplicateRegistrations, setDuplicateRegistrations] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    dataProvider.getList('duplicate_registrations', {
      filter: { 'user.id': userId },
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
    })
      .then(({ data, total }) => {
        setDuplicateRegistrations(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  if (!Array.isArray(duplicateRegistrations)) return null;

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Phone number</TableCell>
            <TableCell align={'right'}>Created at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {duplicateRegistrations.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.phone_number && formatPhoneNumber(row.phone_number)}</TableCell>
              <TableCell align="right"><DateTimeBoxComponent value={row?.created_at} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
    </>
  );
};

DuplicateRegistrationTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
