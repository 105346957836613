import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Edit, BooleanInput, SaveButton, Toolbar, FormWithRedirect, useDataProvider, useNotify, TextInput,
} from 'react-admin';
import { makeStyles, Box, Paper, Grid, Divider, Typography, Tabs, Tab, CircularProgress, Backdrop } from '@material-ui/core';

import { CheckboxListInput } from '../../input';
import { useHandbook } from '../../../hooks';
import { phoneNumber, snakeToHuman } from '../../../utils';
import { COUNTRY_CODE } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  titleSwitch: {
    '& label': {
      margin: 0,
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div {...other}>
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.element,
  value: PropTypes.number,
  index: PropTypes.number,
};

const FormWrapper = ({ permissions, ...props }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [roles, setRoles] = useState(null);
  const [phone, setPhone] = useState(null);
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const isEditable = permissions.indexOf('CAN_ADMIN_EDIT') !== -1;

  const handleChange = (e, value) => setActiveTab(() => value);

  const { data: collectionGroupsResponse } = useHandbook('collection_groups', { items_per_page: 30 });

  const collectionGroups = collectionGroupsResponse.map((group) => ({
    id: group.code,
    name: group.code,
  }));

  useEffect(() => {
    props.record.phone && setPhone(props.record.phone);
  }, [props]);

  useEffect(() => {
    if (phone?.includes(COUNTRY_CODE)) {
      setIsPhoneValid(phoneNumber.validator(phoneNumber.parser(phone)));
    } else {
      setIsPhoneValid(phoneNumber.validator(phone));
    }
  }, [phone]);

  useEffect(() => {
    const firstRoles = [
      'ROLE_SUPER_ADMIN',
      'ROLE_CEO',
      'ROLE_BASIC_VIEW',
      'ROLE_EXTENDED_VIEW',
    ];

    dataProvider.getList('admin_auth_roles', {
      filter: {},
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
    })
      .then(({ data }) => {
        const roles = data.map((role) => ({
          id: role.code,
          name: role.code === 'ROLE_SUPER_ADMIN' ? 'Root' : snakeToHuman(role.code.replace('ROLE_', '').toLowerCase()),
        }));

        roles.sort(function (a, b) {
          const aIndex = firstRoles.indexOf(a.id);
          const bIndex = firstRoles.indexOf(b.id);

          if (aIndex === -1 && bIndex === -1) {
            return 0;
          }

          if (aIndex === -1) {
            return 1;
          }

          if (bIndex === -1) {
            return -1;
          }

          return Math.sign(aIndex - bIndex);
        });

        setRoles(roles);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  if (loading) return <Backdrop open={true}><CircularProgress /></Backdrop>;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={ 2 }>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" gutterBottom={ false }>
                      { formProps.record.username }
                    </Typography>
                    <BooleanInput disabled={ !isEditable } label="Blocked" source="is_blocked" helperText={ false } className={ classes.titleSwitch } />
                  </Grid>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <TextInput
                      source="phone"
                      helperText="Optional field"
                      placeholder={phoneNumber.placeholder}
                      parse={phoneNumber.parser}
                      format={phoneNumber.formatter}
                      onChange={(event) => setPhone(event.target.value)}
                    />
                    <BooleanInput
                      disabled={!isPhoneValid}
                      label="Send SMS about loans"
                      source="is_send_sms_about_loans"
                      helperText={false}
                      className={classes.titleSwitch}
                    />
                  </Grid>
                  <Divider />
                </Box>
                <Tabs value={activeTab} onChange={handleChange}>
                  <Tab label="Admin roles"/>
                  <Tab label="Collection groups"/>
                </Tabs>
                <TabPanel value={activeTab} index={0}>
                  <CheckboxListInput isEditable={isEditable} source="roles" choices={roles} />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                  <CheckboxListInput isEditable={isEditable} source="collection_groups" choices={collectionGroups} />
                </TabPanel>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    disabled={ !isEditable }
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  record: PropTypes.shape({
    phone: PropTypes.string,
  }),
};

const AdminEdit = ({ permissions = [], ...props }) => (
  <Edit component="div" undoable={ false } {...props}>
    <FormWrapper permissions={ permissions } />
  </Edit>
);

AdminEdit.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default AdminEdit;
