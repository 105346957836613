import { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, InputLabel, Select, MenuItem,
  Grid, FormControl,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import { timezone } from '../../utils';

const alpabeticArraySort = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const optionData = (value) => ({ value, name: `${value.charAt(0).toUpperCase()}${value.slice(1, value.length)}`.replaceAll('_', ' ') });

const transactionTypes = [
  optionData('excise_duty'),
  optionData('excise_duty_discount'),
  optionData('excise_duty_revert'),
  optionData('interest'),
  optionData('interest_discount'),
  optionData('interest_revert'),
  optionData('interest_penalty'),
  optionData('interest_penalty_discount'),
  optionData('interest_penalty_revert'),
  optionData('late_payment_fee'),
  optionData('late_payment_fee_discount'),
  optionData('late_payment_fee_revert'),
  optionData('penalty'),
  optionData('penalty_discount'),
  optionData('penalty_revert'),
  optionData('principal'),
  optionData('principal_discount'),
  optionData('principal_revert'),
  optionData('service_fee'),
  optionData('service_fee_discount'),
  optionData('service_fee_revert'),
  optionData('vat'),
  optionData('vat_discount'),
  optionData('vat_revert'),
];

export const AddExecutedTransactionDialog = ({ isOpened, onClose, onSubmit }) => {
  const [type, setType] = useState();
  const [direction, setDirection] = useState();
  const [amount, setAmount] = useState();
  const [executedAt, setExecutedAt] = useState(new Date());
  const handleChangeType = (e) => setType(e.target.value);
  const handleDirectionChange = (e) => setDirection(e.target.value);
  const handleAmountChange = (e) => setAmount(parseFloat(e.target.value));
  const handleExecutedAtChange = (value) => setExecutedAt(timezone.unshift(value));
  const handleExecutedTransactionSubmit = () => onSubmit(type, direction, amount, executedAt);
  const isSubmitDisabled = !type || !direction || !amount || !executedAt;
  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Add executed transaction</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControl size={'small'} variant={'outlined'} fullWidth>
              <InputLabel id={'type-select-label'}>Type</InputLabel>
              <Select label={'Type'} labelId={'type-select-label'} value={type || ''} onChange={handleChangeType}>
                {transactionTypes.sort(alpabeticArraySort).map((option) => (
                  <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl size={'small'} variant={'outlined'} fullWidth>
              <InputLabel id={'direction-select-label'}>Direction</InputLabel>
              <Select
                label={'Direction'}
                labelId={'direction-select-label'}
                value={direction || ''}
                onChange={handleDirectionChange}
              >
                <MenuItem value={'in'}>In</MenuItem>
                <MenuItem value={'out'}>Out</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant={'outlined'}
              type={'number'}
              margin={'none'}
              fullWidth
              size={'small'}
              label={'Amount'}
              value={amount || ''}
              placeholder={'0.00'}
              onChange={handleAmountChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              size={'small'}
              inputVariant={'outlined'}
              label={'Executed at'}
              fullWidth
              value={timezone.shift(executedAt)}
              onChange={handleExecutedAtChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleExecutedTransactionSubmit} color={'primary'} disabled={isSubmitDisabled}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

AddExecutedTransactionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
