import React from 'react';
import { useField } from 'react-final-form';
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core';

import {
  TimezoneInput,
  DateFormatInput,
  LastCallDateInput,
  CalculationDateInput,
  LastCallResultInput,
  DaysWithoutLoanInput,
  FilterByDateInput,
  UMACountInput,
  LastCallDateTimeInput,
  StartDateTimeInput,
  EndDateTimeInput,
  CallAssignedBeforeDateInput,
  CallAssignedAfterDateInput,
  RegistrationStepInput,
  DPDInput,
  EndDateInput,
  StartDateInput,
} from './inputs';
import { ReportDate } from './ReportDate';

const fieldNamesObject = {
  collection_email_report: ['timezone', 'dateformat'],
  creditinfo_report: ['reportdate'],
  promo_code_sms_report: ['reportdate', 'timezone', 'dateformat'],
  affiliate_credits: ['reportdate', 'timezone', 'dateformat'],
  summary_report: ['reportdate', 'calculationdate', 'timezoneWithReport', 'dateformat'],
  income_report: ['reportdate', 'timezone', 'dateformat'],
  loan_report: ['reportdate', 'timezone', 'dateformat'],
  closed_loan_report: ['reportdate', 'timezone', 'dateformat', 'lastcallresult', 'dayswithoutloan', 'lastcalldate'],
  collection_loan_report: ['reportdate', 'timezone', 'dateformat'],
  compliance_report: ['reportdate', 'umacount', 'timezone', 'dateformat'],
  application_report: ['reportdate', 'timezone', 'dateformat', 'filterbydate'],
  cashflow_report: ['reportdate', 'timezone', 'dateformat'],
  security_report: ['reportdate', 'calculationdate', 'timezone', 'dateformat'],
  client_report: ['reportdate', 'timezone'],
  transaction_report: ['reportdate', 'timezone', 'dateformat'],
  extension_report: ['reportdate', 'timezone', 'dateformat'],
  notification_report: ['reportdate', 'timezone', 'dateformat'],
  local_notification_report: ['reportdate', 'timezone', 'dateformat'],
  security_application_report: ['startdatetime', 'enddatetime', 'timezone', 'dateformat', 'filterbydate'],
  promo_codes_report: ['reportdate', 'timezone', 'dateformat', 'filterbydate'],
  unfinished_registration_report: ['reportdate', 'callassignedbefore', 'callassignedafter', 'timezone', 'dateformat', 'lastcallresult', 'lastcalldatetime', 'registrationstep'],
  affiliate_event_report: ['reportdate', 'timezone', 'dateformat'],
  verification_staff_statistics_report: ['reportdate'],
  collection_assign_report: ['reportdate', 'dpd', 'timezone', 'dateformat'],
  collection_call_report: ['reportdate', 'timezone', 'dateformat'],
  accounting_loan_report: ['reportdate', 'timezone', 'dateformat'],
  accounting_paid_report: ['reportdate', 'timezone', 'dateformat'],
  accounting_unpaid_report: ['enddate', 'timezone', 'dateformat'],
  credit_bureau_report: ['reportdate'],
  accounting_total_report: ['reportdate', 'timezone', 'dateformat'],
  functional_link_log_report: ['startdate', 'enddate'],
};

export const ReportInput = ({ fieldName }) => {
  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useField(fieldName);

  const inputInputProps = {
    value,
    onChange,
  };

  const inputProps = {
    value,
    onChange,
    touched,
    error,
  };

  const inputsObject = {
    timezone: <TimezoneInput {...inputProps} />,
    dateformat: <DateFormatInput {...inputProps} />,
    lastcalldate: <LastCallDateInput {...inputProps} />,
    lastcalldatetime: <LastCallDateTimeInput {...inputProps} />,
    reportdate: <ReportDate {...inputProps} />,
    timezoneWithReport: <>
      <TimezoneInput {...inputProps} />
      <FormControlLabel
        label={'Short report'}
        control={<Checkbox checked={!!value.short_report} onChange={ e => onChange({ ...(value || {}), short_report: e.target.checked }) } />}
      />
    </>,
    lastcallresult: <LastCallResultInput {...inputProps} />,
    dayswithoutloan: <DaysWithoutLoanInput {...inputProps} />,
    filterbydate: <FilterByDateInput {...inputProps} />,
    umacount: <UMACountInput {...inputInputProps} />,
    calculationdate: <CalculationDateInput {...inputProps} />,
    startdatetime: <StartDateTimeInput {...inputProps} />,
    enddatetime: <EndDateTimeInput {...inputProps} />,
    callassignedbefore: <CallAssignedBeforeDateInput {...inputProps} />,
    callassignedafter: <CallAssignedAfterDateInput {...inputProps} />,
    registrationstep: <RegistrationStepInput {...inputInputProps} />,
    dpd: <DPDInput {...inputProps} />,
    enddate: <EndDateInput {...inputProps} />,
    startdate: <StartDateInput {...inputProps} />,
  };

  return (
    <Grid container spacing={4} pt={1} >
      {fieldNamesObject[fieldName].map((el, idx) =>
        el === 'reportdate'
          ? <React.Fragment key={idx}>{inputsObject[el]}</React.Fragment>
          : <Grid item xs={12} sm={6} key={idx}>
            {inputsObject[el]}
          </Grid>,
      )}
    </Grid>
  );
};
