import React, { useEffect, useState, useCallback } from 'react';
import { Box } from '@material-ui/core';
import { Confirm, Title, useDataProvider } from 'react-admin';

import { useCollectionGroups, useHandbook } from '../../../hooks';
import { convertIdentifiersToNumbers } from '../../../utils';
import { CollectionContactFormDialog } from '../../dialogs';
import { CollectionActionTreeListContactTree } from '../../collection';

import { CollectionActionTreeListGroupsToolbar } from './CollectionActionTreeListGroupsToolbar';
import { CollectionActionTreeListRootContactsToolbar } from './CollectionActionTreeListRootContactsToolbar';

const findParent = (array, id) => {
  for (const item of array) {
    if (item.id === id) {
      return item;
    } else {
      if (item.children?.length > 0) {
        const res = findParent(item.children, id);
        if (res) {
          return res;
        }
      }
    }
  }
};

export const CollectionActionTreeList = () => {
  const dataProvider = useDataProvider();

  const [expandedContacts, setExpandedContacts] = useState([]);

  const toggleExpand = (id) => {
    if (expandedContacts.includes(id)) {
      setExpandedContacts(
        expandedContacts.filter((expandedId) => id !== expandedId),
      );
    } else {
      setExpandedContacts([...expandedContacts, id]);
    }
  };

  const { data: groups } = useCollectionGroups();

  const { isLoading: isTypesLoading, data: types } = useHandbook(
    'collection_contact_types',
    { items_per_page: 1000 },
  );

  const { isLoading: isTypeValuesLoading, data: typeValues } = useHandbook(
    'collection_contact_type_values',
    { items_per_page: 1000 },
  );

  const [rootContacts, setRootContacts] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedRootContactId, setSelectedRootContactId] = useState(null);
  const [contactsTree, setContactsTree] = useState([]);

  const [formDialogParent, setFormDialogParent] = useState(null);
  const [formDialogEditId, setFormDialogEditId] = useState(null);
  const [isFormDialogOpened, setIsFormDialogOpened] = useState(false);

  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
  const [deletingContactId, setDeletingContactId] = useState(null);
  const [currentLevelTree, setCurrentLevelTree] = useState([]);

  const fetchRootContacts = useCallback(() => {
    setRootContacts([]);

    if (selectedGroupId) {
      dataProvider
        .getList('collection_contacts', {
          filter: { collectionGroup: selectedGroupId, 'exists[parent]': false },
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'id', order: 'ASC' },
        })
        .then(({ data }) => {
          setRootContacts(convertIdentifiersToNumbers(data));
        });
    }
  }, [selectedGroupId, dataProvider]);

  const fetchContactsTree = useCallback(() => {
    if (selectedRootContactId) {
      dataProvider
        .query(`collection_contacts/${selectedRootContactId}/tree`, {
          method: 'GET',
        })
        .then(({ data }) => {
          setContactsTree(data.children);
          setCurrentLevelTree(data.children);
        });
    }
  }, [selectedRootContactId, dataProvider]);

  useEffect(() => {
    setSelectedGroupId(groups[0]?.id || null);
  }, [groups]);

  useEffect(() => {
    fetchRootContacts();
  }, [selectedGroupId, fetchRootContacts]);

  useEffect(() => {
    fetchContactsTree();
  }, [selectedRootContactId, fetchContactsTree]);

  useEffect(() => {
    setSelectedRootContactId(rootContacts[0]?.id);
  }, [rootContacts]);

  const handleSelectGroup = (groupId) => {
    setSelectedGroupId(groupId);
    setExpandedContacts([]);
  };

  const handleRootContact = (contactId) => {
    setSelectedRootContactId(contactId);
    setExpandedContacts([]);
  };

  const handleFormDialogClose = () => {
    setFormDialogParent(null);
    setFormDialogEditId(null);
    setIsFormDialogOpened(false);
    setCurrentLevelTree(contactsTree);
  };

  const handleFormDialogSubmit = () => {
    setFormDialogParent(null);
    setFormDialogEditId(null);
    setIsFormDialogOpened(false);
    setCurrentLevelTree(contactsTree);
    fetchRootContacts();
    fetchContactsTree();
  };

  const handleFormDialogCreateOpen = (parentId, tree) => {
    setFormDialogParent(parentId);
    setFormDialogEditId(null);
    setIsFormDialogOpened(true);
    setCurrentLevelTree(tree || contactsTree);
  };

  const handleFormDialogEditOpen = (id, parentId) => {
    setFormDialogParent(null);
    setFormDialogEditId(id);
    setIsFormDialogOpened(true);
    const parent = findParent(contactsTree, parentId);
    setCurrentLevelTree(parent?.children || contactsTree);
  };

  const handleDelete = (id) => {
    setIsDeleteDialogOpened(true);
    setDeletingContactId(id);
  };

  const handleDeleteConfirm = () => {
    dataProvider
      .delete('collection_contacts', { id: deletingContactId })
      .then(() => {
        setIsDeleteDialogOpened(false);
        fetchRootContacts();
        fetchContactsTree();
      });
  };

  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpened(false);
  };

  return (
    <>
      <Title title="Action tree" />

      <Box>
        <CollectionActionTreeListGroupsToolbar
          selectedGroupId={selectedGroupId}
          onSelectGroup={handleSelectGroup}
          groups={groups}
        />

        {!!selectedGroupId && (
          <CollectionActionTreeListRootContactsToolbar
            selectedRootContactId={selectedRootContactId}
            onSelectRootContact={handleRootContact}
            typeValues={typeValues}
            rootContacts={rootContacts}
            onClickCreate={handleFormDialogCreateOpen}
          />
        )}

        {!!selectedRootContactId && !isTypesLoading && !isTypeValuesLoading && (
          <CollectionActionTreeListContactTree
            contactsTree={contactsTree}
            types={types}
            typeValues={typeValues}
            onClickCreate={handleFormDialogCreateOpen}
            onClickEdit={handleFormDialogEditOpen}
            onClickDelete={handleDelete}
            toggleExpand={toggleExpand}
            expandedContacts={expandedContacts}
          />
        )}
      </Box>

      <CollectionContactFormDialog
        editId={formDialogEditId}
        parentId={formDialogParent}
        isOpened={isFormDialogOpened}
        collectionGroupId={selectedGroupId}
        contactsTree={currentLevelTree}
        onClose={handleFormDialogClose}
        onSubmit={handleFormDialogSubmit}
      />

      <Confirm
        title="Delete of contact"
        content="Are you sure? This action can not be undone."
        isOpen={isDeleteDialogOpened}
        onClose={handleDeleteDialogClose}
        onConfirm={handleDeleteConfirm}
      />
    </>
  );
};
