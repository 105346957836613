import { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { Grid, ButtonGroup, Button, useTheme, Box } from '@material-ui/core';
import formatISO from 'date-fns/formatISO';
import PropTypes from 'prop-types';

import * as Dialogs from '../../dialogs';

export const UserEditTransitions = (props) => {
  const theme = useTheme();
  const { record: { id, enabled_transitions, moratorium_till }, refresh } = props;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [disabled, setDisabled] = useState(false);
  const [isConfirmTransitionDialogOpened, setIsConfirmTransitionDialogOpened] = useState(false);
  const [isChangeMoratoriumTransitionDialogOpened, setIsChangeMoratoriumTransitionDialogOpened] = useState(false);
  const [addToBlockListDialogOpened, setAddToBlockListDialogOpened] = useState(false);
  if (!enabled_transitions) return null;

  const applyTransition = (name, params = {}) => {
    setDisabled((value) => !value);
    dataProvider.query(`users/${id}/apply_transition`, { method: 'POST', body: JSON.stringify({ name, params }) })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .then(() => setDisabled((value) => !value));
  };

  return (
    <>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
          {enabled_transitions.indexOf('confirm') !== -1 &&
              <Button onClick={() => setIsConfirmTransitionDialogOpened(true)} disabled={disabled}>Confirm</Button>}
          {enabled_transitions.indexOf('block') !== -1 &&
              <Button onClick={() => applyTransition('block')} disabled={disabled}>Block</Button>}
          {enabled_transitions.indexOf('activate') !== -1 &&
              <Button onClick={() => applyTransition('activate')} disabled={disabled}>Activate</Button>}
          {enabled_transitions.indexOf('change_moratorium') !== -1 &&
              <Button onClick={() => setIsChangeMoratoriumTransitionDialogOpened(true)} disabled={disabled}>Change
                Moratorium</Button>}
          {enabled_transitions.indexOf('refresh_selfie_matches') !== -1 &&
              <Button onClick={() => applyTransition('refresh_selfie_matches')} disabled={disabled}>Refresh selfie
                matches</Button>}
        </ButtonGroup>
        <ButtonGroup variant={'contained'} color={'primary'}>
          {enabled_transitions.indexOf('otp_unblock') !== -1 && (
            <Button
              style={{ backgroundColor: theme.palette.error.main }}
              onClick={() => applyTransition('otp_unblock')}
              disabled={disabled}
            >
                Unblock OTP
            </Button>
          )}
          {enabled_transitions.indexOf('add_black_list') !== -1 && (
            <Button
              style={{ backgroundColor: theme.palette.error.main }}
              onClick={() => setAddToBlockListDialogOpened(true)}
              disabled={disabled}
            >
                Add to black list
            </Button>
          )}
          {enabled_transitions.indexOf('remove_black_list') !== -1 && (
            <Button
              style={{ backgroundColor: theme.palette.success.main }}
              onClick={() => applyTransition('remove_black_list')}
              disabled={disabled}>
                Remove from black list
            </Button>
          )}
        </ButtonGroup>
      </Box>
      {isConfirmTransitionDialogOpened &&
        <Dialogs.UserConfirmTransitionDialog
          isOpened={isConfirmTransitionDialogOpened}
          onClose={() => setIsConfirmTransitionDialogOpened(false)}
          onSubmit={(confirmationCode) => {
            setIsConfirmTransitionDialogOpened(false);
            applyTransition('confirm', { confirmation_code: confirmationCode });
          }}
        />
      }
      {addToBlockListDialogOpened &&
        <Dialogs.UserAddToBlockListDialog
          user={props}
          isOpened={addToBlockListDialogOpened}
          onClose={() => setAddToBlockListDialogOpened(false)}
          applyTransition={applyTransition}
        />
      }
      {isChangeMoratoriumTransitionDialogOpened &&
        <Dialogs.UserChangeMoratoriumTransitionDialog
          isOpened={isChangeMoratoriumTransitionDialogOpened}
          onClose={() => setIsChangeMoratoriumTransitionDialogOpened(false)}
          onSubmit={moratoriumTillDate => {
            setIsChangeMoratoriumTransitionDialogOpened(false);
            applyTransition('change_moratorium', { moratorium_till: moratoriumTillDate ? formatISO(moratoriumTillDate) : null });
          }}
          value={moratorium_till}
        />
      }
    </>
  );
};

UserEditTransitions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number.isRequired,
    enabled_transitions: PropTypes.array.isRequired,
    moratorium_till: PropTypes.string,
  }),
  refresh: PropTypes.func.isRequired,
};
