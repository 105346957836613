import React, { useState } from 'react';
import {
  Create, TextInput, NumberInput, BooleanInput, SelectInput, SaveButton, Toolbar,
  FormWithRedirect, required, maxLength, minValue, maxValue, FormDataConsumer,
} from 'react-admin';
import { makeStyles, Box, Paper, Grid, Divider, Typography } from '@material-ui/core';

import { withServerAutocomplete, SelectAutoComplete } from '../../select';
import {
  USER_EVENT as userEvents, TRANSMITTERSLIST_DEFAULT as transmitters, ALPHA_NAMES, formatChoices,
} from '../../../utils';

const AutoComplete = withServerAutocomplete(SelectAutoComplete);

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
}));

const FormWrapper = props => {
  const classes = useStyles();
  const [valueKey, setValueKey] = useState('');
  const [value, setValue] = useState('');

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={ false }>Create user notification</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <BooleanInput label="Enabled" source="is_enabled" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput className={ classes.mt4 } source="name" validate={ [maxLength(255)] } fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="trigger_event" choices={ userEvents } validate={ [required()] } fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="interrupt_event" choices={ userEvents } allowEmpty fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="transmitter_id" choices={ transmitters } validate={ [required()] } fullWidth />
                    </Grid>
                    <FormDataConsumer key='consumer'>
                      {({ formData, ...rest }) =>
                        (formData.transmitter_id === 'text_ware' || formData.transmitter_id === 'text_ware_promo') && (
                          <Grid item xs={12} sm={6} key='alpha_name'>
                            <SelectInput
                              helperText={false}
                              source='alpha_name'
                              validate={[required()]}
                              choices={formData.transmitter_id ? formatChoices(ALPHA_NAMES[formData.transmitter_id]) : []}
                              {...rest}
                              fullWidth
                            />
                          </Grid>
                        )}
                    </FormDataConsumer>
                    <Grid item xs={12} sm={6}>
                      <NumberInput source="delay" min={0} max={65535} step={1} validate={ [required(), minValue(0), maxValue(65535)] } fullWidth helperText="In minutes" />
                    </Grid>
                    <Grid item xs={12}>
                      <AutoComplete
                        perOnePage={15}
                        resource="admin/templates"
                        label="Template Key"
                        value={value}
                        filter={valueKey ? { key: valueKey } : {}}
                        onChangeInput={val => setValueKey(val)}
                        setValue={setValue}
                        prop={'key'}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={ classes.toolbar }>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    transform={data => ({
                      ...data,
                      template_key: value,
                    })}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
