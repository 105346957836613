import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Card, CardContent } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BlockIcon from '@material-ui/icons/Block';
import PropTypes from 'prop-types';

import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

export const BlackListComponent = ({ createdBy, createdAt, comment, reason }) => {
  const theme = useTheme();
  return (
    <Accordion>
      <AccordionSummary
        style={{ backgroundColor: theme.palette.error.main }}
        expandIcon={<ExpandMoreIcon style={{ color: theme.palette.error.contrastText }} />}
        aria-controls="black-list-panel-content"
        id="black-list-panel-header"
      >
        <BlockIcon style={{ color: theme.palette.error.contrastText, paddingRight: '0.5rem' }} />
        <Typography style={{ color: theme.palette.error.contrastText }}>User in blacklist</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Card>
          <CardContent>
            <Typography variant='body2'><b>Comment: </b>{comment}</Typography>
            <DateTimeBoxComponent inline value={createdAt} label={<b>Created at: </b>}/>
            <Typography variant='body2'><b>Created by: </b>{createdBy}</Typography>
            <Typography variant='body2'><b>Reason: </b>{reason}</Typography>
          </CardContent>
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

BlackListComponent.propTypes = {
  createdBy: PropTypes.string,
  createdAt: PropTypes.string,
  comment: PropTypes.string,
  reason: PropTypes.string,
};

BlackListComponent.defaultProps = {
  createdBy: '',
  createdAt: '',
  comment: '',
  reason: '',
};
