import { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import {
  Card, CardHeader, CircularProgress, Divider, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { formatCurrency, snakeToHuman } from '../../../../utils';
import { TransactionType } from '../../../field';
import { DateTimeBoxComponent } from '../../../DateTimeBoxComponent';

export const ScheduledTransactions = ({ loanId }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider.query(`loans/${loanId}/scheduled_transactions`, { method: 'GET' })
      .then(({ data }) => {
        setTransactions(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, loanId]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Card>
      <CardHeader title={'Scheduled transactions'} />
      <Divider />
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((row, key) => (
            <TableRow key={key}>
              <TableCell>#{row.id}</TableCell>
              <TableCell><DateTimeBoxComponent value={row?.scheduled_at} /></TableCell>
              <TableCell>{snakeToHuman(row.state)}</TableCell>
              <TableCell>{<TransactionType type={row.type} />}</TableCell>
              <TableCell>{formatCurrency(row.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

ScheduledTransactions.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
