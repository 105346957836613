import { Box, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

export const CollectionActionTreeListGroupsToolbar = ({
  selectedGroupId,
  onSelectGroup,
  groups,
}) => {
  return (
    <Toolbar variant="dense" disableGutters>
      <Typography
        variant="subtitle1"
        component="div"
        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
      >
        Groups:
      </Typography>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
          marginLeft: 12,
        }}
      >
        {groups.map((item) => (
          <Button
            key={item.id}
            sx={{ color: '#fff' }}
            variant={selectedGroupId === item.id ? 'contained' : 'text'}
            color="secondary"
            onClick={() => onSelectGroup(item.id)}
          >
            {item.code}
          </Button>
        ))}
      </Box>
    </Toolbar>
  );
};

CollectionActionTreeListGroupsToolbar.propTypes = {
  selectedGroupId: PropTypes.number,
  onSelectGroup: PropTypes.func,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
    }),
  ),
};
