import { Filter, NumberInput, SelectInput, TextInput } from 'react-admin';

import { DateFilterInput, QuickFilterInput } from '../../../filters';
import { AsyncSelectInput } from '../../../input';
import { timezone } from '../../../../utils';

export const ManualVerificationAllFilters = (props) => {
  return (
    <Filter {...props} style={{ marginTop: 0, marginBottom: '20px' }} variant={'outlined'}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="Contract number" source="contractNumber" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <TextInput label="Verifier" source="verifier->username" />
      <SelectInput label="Status" source="state" choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'processing', name: 'Processing' },
        { id: 'approved', name: 'Approved' },
        { id: 'rejected', name: 'Rejected' },
        { id: 'confirmed', name: 'Confirmed' },
        { id: 'cancelled', name: 'Cancelled' },
        { id: 'error', name: 'Error' },
      ]} />
      <DateFilterInput label="Created before" source="created_at|before" before/>
      <DateFilterInput label="Created after" source="created_at|after" after/>
      <DateFilterInput label="Assigned before" source="verifier_assigned_at|before" before/>
      <DateFilterInput label="Assigned after" source="verifier_assigned_at|after" after/>
      <SelectInput label="Request type" source="is_repeat" choices={[
        { id: 'false', name: 'First' },
        { id: 'true', name: 'Repeated' },
      ]} />
      <AsyncSelectInput
        label="Affiliate"
        source="affiliate"
        query={{ resource: 'affiliates', payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} } }}
        mapper={ ({ id, name }) => ({ value: id, name }) }
      />
      <QuickFilterInput source="postponed_until|after" label="Postponed all" defaultValue={ timezone.shift(new Date())} />
      <QuickFilterInput source="postponed_until|before" label="Postponed in work" defaultValue={ timezone.shift(new Date())} />
    </Filter>
  );
};
