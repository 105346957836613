import { useState } from 'react';
import { useController } from 'react-hook-form';
import { Box, Chip, Slider, Typography } from '@material-ui/core';
import { func, number, string, object, shape } from 'prop-types';

import { formatCurrency } from '../../../utils';
import { CURRENCY } from '../../../constants';

export const SliderFieldHookForm = ({ name, label, min, max, step, onChange, control, unit, value }) => {
  const defaultValue = value;
  const { field } = useController({ name, control, defaultValue });
  const [sliderValue, setSliderValue] = useState(value ?? field.value.value);
  const handleSliderChange = (e, newValue) => {
    field.onChange(newValue);
    setSliderValue(newValue);
    if (onChange) onChange(name, newValue);
  };

  return (
    <Box>
      <Box display={'grid'} justifyContent={'space-around'} gridTemplateColumns={'1fr auto 1fr'}>
        <Typography variant={'subtitle1'}><b>{label}</b></Typography>
        <Chip size={'small'} label={unit === CURRENCY ? formatCurrency(field.value.value ?? value) : `${field.value.value ?? value} ${unit}`} />
      </Box>
      <Slider
        {...field}
        color={'secondary'}
        value={sliderValue}
        aria-labelledby={`${name}-slider`}
        step={step}
        min={min}
        max={max}
        onChangeCommitted={handleSliderChange}
        onChange={handleSliderChange}
      />
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant={'caption'} gutterBottom={false}>{unit === CURRENCY ? formatCurrency(min) : `${min} ${unit}`}</Typography>
        <Typography variant={'caption'} gutterBottom={false}>{unit === CURRENCY ? formatCurrency(max) : `${max} ${unit}`}</Typography>
      </Box>
    </Box>
  );
};

SliderFieldHookForm.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  value: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
    value: number.isRequired,
  }),
  min: number.isRequired,
  max: number.isRequired,
  step: number.isRequired,
  onChange: func,
  control: object,
  unit: string,
};
