import React from 'react';
import {
  Select, FormControl, FormHelperText, InputLabel, MenuItem, TextField, Grid,
} from '@material-ui/core';
import { DateTimePicker, DatePicker } from '@material-ui/pickers';

import {
  timezone,
  timezoneOffsetLabel,
  CALL_CENTER_USER_STATUS_CLOSED_LOANS,
} from '../../../utils';

export const DateFormatInput = ({ touched, error, value, onChange }) => (
  <FormControl size={'small'} variant={'outlined'} fullWidth error={!!(touched && error && error.date_format)}>
    <InputLabel id={'date-format-select-label'}>Date format</InputLabel>
    <Select
      label={'Date format'}
      labelId={'date-format-select-label'}
      value={(value && value.date_format) ? value.date_format : ''}
      onChange={e => onChange({ ...(value || {}), date_format: e.target.value })}
    >
      <MenuItem value="Y-m-d\TH:i:sP">RFC3339 (Y-m-d\TH:i:sP)</MenuItem>
      <MenuItem value="Y-m-d H:i:s">Y-m-d H:i:s</MenuItem>
    </Select>
    {touched && error && error.date_format && <FormHelperText>{error.date_format}</FormHelperText>}
  </FormControl>
);

const TIMEZONES_SELECT_OPTIONS = [
  { value: 'Africa/Dar_es_Salaam', label: 'East Africa/Tanzania (UTC+03:00)' },
  {
    value: 'Local',
    label: (() => {
      const offset = new Date().getTimezoneOffset() * -1;

      return `Local (UTC${timezoneOffsetLabel(offset)})`;
    })(),
  },
  { value: 'UTC', label: 'UTC' },
];

export const TimezoneInput = ({ touched, error, value, onChange }) => (
  <FormControl size={'small'} variant={'outlined'} fullWidth error={!!(touched && error && error.timezone)}>
    <InputLabel id={'timezone-select-label'}>Timezone</InputLabel>
    <Select
      label={'Timezone'}
      labelId={'timezone-select-label'}
      value={(value && value.timezone) ? value.timezone : ''}
      onChange={e => onChange({ ...(value || {}), timezone: e.target.value })}
    >
      {TIMEZONES_SELECT_OPTIONS.map(({ value, label }) => (
        <MenuItem key={value} value={value}>{label}</MenuItem>
      ))}
    </Select>
    {touched && error && error.timezone && <FormHelperText>{error.timezone}</FormHelperText>}
  </FormControl>
);

export const LastCallDateInput = ({ touched, error, value, onChange }) => {
  return (
    <DatePicker
      inputVariant={'outlined'}
      size={'small'}
      fullWidth
      label={'Last call date'}
      value={(value && value.last_call_date) ? timezone.shift(value.last_call_date) : null}
      onChange={v => onChange({ ...(value || {}), last_call_date: timezone.setTime(timezone.unshift(v), 0, 0, 0) })}
      error={!!(touched && error && error.last_call_date)}
      helperText={touched && error && error.last_call_date}
    />
  );
};

export const LastCallDateTimeInput = ({ touched, error, value, onChange }) => {
  return (
    <DateTimePicker
      label={'Last call date'}
      fullWidth
      size={'small'}
      inputVariant={'outlined'}
      value={ (value && value.date_last_call_result) ? timezone.shift(value.date_last_call_result) : null }
      onChange={ v => onChange({ ...(value || {}), date_last_call_result: timezone.unshift(v) })}
      error={ !!(touched && error && error.date_last_call_result) }
      helperText={ touched && error && error.date_last_call_result }
    />
  );
};

export const CalculationDateInput = ({ touched, error, value, onChange }) => {
  return (
    <DateTimePicker
      inputVariant={'outlined'}
      label={'Calculation date'}
      fullWidth
      size={'small'}
      value={ (value && value.calculation_date) ? timezone.shift(value.calculation_date) : null }
      onChange={ v => onChange({ ...(value || {}), calculation_date: timezone.unshift(v) })}
      required={ true }
      error={ !!(touched && error && error.calculation_date) }
      helperText={ touched && error && error.calculation_date }
    />
  );
};

export const LastCallResultInput = ({ touched, error, value, onChange }) => {
  return (
    <FormControl fullWidth variant={'outlined'} size={'small'} error={!!(touched && error && error.last_call_result)}>
      <InputLabel id={'last_call_result'}>Last call result</InputLabel>
      <Select
        label={'Last call result'}
        labelId={'last_call_result'}
        value={(value && value.last_call_result) ? value.last_call_result : ''}
        onChange={e => onChange({ ...(value || {}), last_call_result: e.target.value })}
      >
        {CALL_CENTER_USER_STATUS_CLOSED_LOANS.map(i => <MenuItem value={i} key={i}>{i}</MenuItem>)}
      </Select>
      {touched && error && error.last_call_result && <FormHelperText>{error.last_call_result}</FormHelperText>}
    </FormControl>);
};

export const DaysWithoutLoanInput = ({ touched, error, value, onChange }) => {
  return (<FormControl fullWidth variant={'outlined'} size={'small'} error={!!(touched && error && error.day_without_loan)}>
    <InputLabel id={'day_without_loan'}>Day without loan</InputLabel>
    <Select
      label={'Day without loan'}
      labelId={'day_without_loan'}
      value={(value && value.day_without_loan) ? value.day_without_loan : ''}
      onChange={e => onChange({ ...(value || {}), day_without_loan: e.target.value })}
    >
      {Array.from(new Array(60).keys()).map(i => <MenuItem value={i + 1} key={i + '_day_without_loan'}>{i + 1}</MenuItem>)}
    </Select>
    {touched && error && error.day_without_loan && <FormHelperText>{error.day_without_loan}</FormHelperText>}
  </FormControl>);
};

export const FilterByDateInput = ({ touched, error, value, onChange }) => {
  return (
    <FormControl size={'small'} variant={'outlined'} fullWidth error={ !!(touched && error && error.filter_by_date) }>
      <InputLabel id={'sorton-select-label'}>Filter by date</InputLabel>
      <Select
        label={'Filter by date'}
        labelId={'sorton-select-label'}
        value={ (value && value.filter_by_date) ? value.filter_by_date : '' }
        onChange={ e => onChange({ ...(value || {}), filter_by_date: e.target.value }) }
      >
        <MenuItem value={'create'}>Create</MenuItem>
        <MenuItem value={'decision'}>Decision</MenuItem>
      </Select>
      { touched && error && error.filter_by_date && <FormHelperText>{ error.filter_by_date }</FormHelperText> }
    </FormControl>
  );
};

export const UMACountInput = ({ value, onChange }) => {
  return (
    <TextField
      variant={'outlined'}
      size={'small'}
      id={'uma_count'}
      label={'Count UMA'}
      type={'number'}
      onChange={ e => onChange({ ...(value || {}), uma_count: e.target.value })}
      fullWidth
    />
  );
};

export const StartDateTimeInput = ({ touched, error, value, onChange }) => {
  return (<DateTimePicker
    label={'Start date'}
    fullWidth
    value={ (value && value.start_date) ? timezone.shift(value.start_date) : null }
    onChange={ v => onChange({ ...(value || {}), start_date: timezone.unshift(v) })}
    required
    size={'small'}
    inputVariant={'outlined'}
    error={ !!(touched && error && error.start_date) }
    helperText={ touched && error && error.start_date }
  />);
};

export const StartDateInput = ({ touched, error, value, onChange }) => {
  return (
    <DatePicker
      label={'Start date'}
      value={(value && value.start_date) ? timezone.shift(value.start_date) : null}
      onChange={v => onChange({ ...(value || {}), start_date: timezone.setTime(timezone.unshift(v), 23, 59, 59) })}
      required
      size={'small'}
      fullWidth
      inputVariant={'outlined'}
      error={!!(touched && error && error.start_date)}
      helperText={touched && error && error.start_date}
    />
  );
};

export const EndDateTimeInput = ({ touched, error, value, onChange }) => {
  return (<DateTimePicker
    label={'End date'}
    fullWidth
    size={'small'}
    inputVariant={'outlined'}
    value={ (value && value.end_date) ? timezone.shift(value.end_date) : null }
    onChange={ v => onChange({ ...(value || {}), end_date: timezone.unshift(v) })}
    required
    error={ !!(touched && error && error.end_date) }
    helperText={ touched && error && error.end_date }
  />);
};

export const EndDateInput = ({ touched, error, value, onChange }) => {
  return (
    <DatePicker
      label={'End date'}
      value={(value && value.end_date) ? timezone.shift(value.end_date) : null}
      onChange={v => onChange({ ...(value || {}), end_date: timezone.setTime(timezone.unshift(v), 23, 59, 59) })}
      required
      size={'small'}
      fullWidth
      inputVariant={'outlined'}
      error={!!(touched && error && error.end_date)}
      helperText={touched && error && error.end_date}
    />
  );
};

export const CallAssignedBeforeDateInput = ({ touched, error, value, onChange }) => {
  return (<DatePicker
    inputVariant={'outlined'}
    size={'small'}
    fullWidth
    label={'Call assigned before'}
    value={(value && value.phone_call_assigned_before) ? timezone.shift(value.phone_call_assigned_before) : null}
    onChange={v => onChange({ ...(value || {}), phone_call_assigned_before: timezone.setTime(timezone.unshift(v), 0, 0, 0) })}
    error={!!(touched && error && error.phone_call_assigned_before)}
    helperText={touched && error && error.phone_call_assigned_before}
  />);
};

export const CallAssignedAfterDateInput = ({ touched, error, value, onChange }) => {
  return (<DatePicker
    inputVariant={'outlined'}
    size={'small'}
    fullWidth
    label={'Call assigned after'}
    value={(value && value.phone_call_assigned_after) ? timezone.shift(value.phone_call_assigned_after) : null}
    onChange={v => onChange({ ...(value || {}), phone_call_assigned_after: timezone.setTime(timezone.unshift(v), 0, 0, 0) })}
    error={!!(touched && error && error.phone_call_assigned_after)}
    helperText={touched && error && error.phone_call_assigned_after}
  />);
};

export const RegistrationStepInput = ({ value, onChange }) => {
  return (
    <TextField
      id={'registration-step-number'}
      label={'Registration step'}
      type={'number'}
      onChange={ e => onChange({ ...(value || {}), registration_step: e.target.value })}
      fullWidth
      size={'small'}
      variant={'outlined'}
    />
  );
};

export const DPDInput = ({ touched, error, value, onChange }) => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          variant={'outlined'}
          size={'small'}
          id={'min-dpd-number'}
          label={'Min days past due'}
          type={'number'}
          onChange={ e => onChange({ ...(value || {}), min_dpd: +e.target.value })}
          fullWidth
          error={!!(touched && error && error.min_dpd)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant={'outlined'}
          size={'small'}
          id={'max-dpd-number'}
          label={'Max days past due'}
          type={'number'}
          onChange={ e => onChange({ ...(value || {}), max_dpd: +e.target.value })}
          fullWidth
          error={!!(touched && error && error.max_dpd)}
        />
      </Grid>
    </>
  );
};
