import { getAdminTimezone } from './getAdminTimezone';
import { TIMEZONE_OFFSET } from './dictionary';

export const getAdminTimezoneOffset = () => {
  getAdminTimezone();

  const savedTimezone = localStorage.getItem('admin_timezone');

  return TIMEZONE_OFFSET[savedTimezone] ? TIMEZONE_OFFSET[savedTimezone]() : 0;
};
