import React from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { green, grey } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import { formatPhoneNumber } from '../../../utils';
import { useCollectionGroups } from '../../../hooks';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    cursor: 'pointer',
  },
  noLoanRow: {
    cursor: 'default',
  },
  noLoanCell: {
    backgroundColor: grey[300],
    cursor: 'default',
  },
  closedLoanCell: {
    backgroundColor: green[300],
  },
}));

const resolveCollectionGroup = (daysPastDue, collectionGroups) => {
  if (daysPastDue >= 91) {
    return 'G6';
  }
  const group = collectionGroups.find(group => daysPastDue >= group.dpd_from && daysPastDue <= group.dpd_to);
  return group?.code ?? 'Er';
};

const DaysPastDueField = ({ dpd, collectionGroups }) => <Chip avatar={<Avatar>{resolveCollectionGroup(dpd, collectionGroups)}</Avatar>} label={dpd} />;

DaysPastDueField.propTypes = {
  dpd: PropTypes.number,
  collectionGroups: PropTypes.array,
};

const PhoneBook = () => {
  const [data, setData] = React.useState([]);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const { data: collectionGroups, isLoading } = useCollectionGroups();

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  React.useEffect(() => {
    if (phoneNumber && phoneNumber.length > 8) {
      setLoading(true);
      dataProvider
        .getList('phone_books', {
          filter: {
            page: 1,
            items_per_page: 20,
            phone_number: phoneNumber,
          },
          pagination: {},
          sort: {},
        })
        .then(({ data }) => {
          setData(data);
        })
        .catch((error) => {
          notify(`Error: ${error.message}`, 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    } else if ((phoneNumber && phoneNumber.length === 0) || !phoneNumber) {
      setData([]);
    }
  }, [phoneNumber, dataProvider, notify]);

  const handleChange = (e) => {
    if (e.target.value.toString().length <= 11) {
      setPhoneNumber(e.target.value);
    }
  };

  const openLoan = (loanId) => {
    window.open(`#loans/${loanId}/show`, '_blank');
  };

  const getCollector = (name, id) => {
    return name && id ? `${name} #${id}` : null;
  };

  const getLoanCellColor = (record) => {
    const cellClasses = [];
    !record.last_loan_id && cellClasses.push(classes.noLoanCell);
    record.last_loan_state === 'closed' && cellClasses.push(classes.closedLoanCell);
    if (cellClasses.length > 0) {
      return cellClasses.join(' ');
    } else {
      return '';
    }
  };

  if (isLoading) return <Backdrop open={true}><CircularProgress /></Backdrop>;

  return (
    <Grid container spacing={3} justifyContent='center'>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item sm={12}>
        <Box className={classes.flexRowCenterToolbar}>
          <TextField
            label="Phone number"
            type="number"
            inputProps={{ maxLength: 11 }}
            value={phoneNumber}
            onChange={handleChange}
          />
        </Box>
        {data.length
          ? (
            <TableContainer component={Paper} className={classes.mt4}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Client ID</TableCell>
                    <TableCell>Client name</TableCell>
                    <TableCell>Loan ID</TableCell>
                    <TableCell>Loan state</TableCell>
                    <TableCell>Maturity date</TableCell>
                    <TableCell>DPD</TableCell>
                    <TableCell>Collector</TableCell>
                    <TableCell>Phone number</TableCell>
                    <TableCell>Phone state</TableCell>
                    <TableCell>Phone type</TableCell>
                    <TableCell>Phone name</TableCell>
                    <TableCell>Phone source</TableCell>
                    <TableCell>Phone note</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((record) => (
                    <TableRow
                      key={record.id}
                      className={[classes.tableRow, !record.last_loan_id && classes.noLoanRow].join(' ')}
                      onClick={() => record.last_loan_id ? openLoan(record.last_loan_id) : null}
                    >
                      <TableCell component="th" scope="row">{record.user}</TableCell>
                      <TableCell component="th" scope="row">
                        {`${record.user_first_name ?? ''} ${record.user_middle_name ?? ''} ${record.user_last_name ?? ''}`}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={getLoanCellColor(record)}
                      >
                        {record.last_loan_id ?? '---'}
                      </TableCell>
                      <TableCell component="th" scope="row" className={getLoanCellColor(record)}>
                        {record.last_loan_state ? <Chip size="small" label={record.last_loan_state} /> : '---'}
                      </TableCell>
                      <TableCell component="th" scope="row" className={getLoanCellColor(record)}>
                        <DateTimeBoxComponent value={record?.last_loan_maturity_at} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <DaysPastDueField dpd={record.last_loan_dpd} collectionGroups={collectionGroups} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {getCollector(record.last_loan_collector_username, record.last_loan_collector_id)}
                      </TableCell>
                      <TableCell component="th" scope="row">{formatPhoneNumber(record.phone_number)}</TableCell>
                      <TableCell component="th" scope="row">{record.validation_state ?? null}</TableCell>
                      <TableCell component="th" scope="row">{record.type ?? null}</TableCell>
                      <TableCell component="th" scope="row">{record.name ?? null}</TableCell>
                      <TableCell component="th" scope="row">{record.source ?? null}</TableCell>
                      <TableCell component="th" scope="row">{record.note ?? null}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
          : (
            <Typography className={classes.mt4}>Nothing found :(</Typography>
          )
        }
      </Grid>
    </Grid>
  );
};

export default PhoneBook;
