import { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';

// import { SelectAutoComplete, withServer } from '../select';
import { useProducts } from '../../hooks';
import { createAppFormFields } from '../../constants';
import { LoanCalculator } from '../LoanCalculator';
import { validateSelect } from '../../utils';
import { SelectFieldHookForm, TextFieldHookForm } from '../formFields';
import { CreateAppCalculation } from '../CreateAppCalculation';

// const AutoComplete = withServer(SelectAutoCompleteHookForm);

export const CreateApplicationDialog = ({ isOpened, onClose, record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { data: products } = useProducts();
  const { tenor, principal, product_code, target_url, promo_code } = createAppFormFields;
  const activeProducts = products.filter((pr) => !pr.isDisabled);
  const fActiveProduct = activeProducts ? [...activeProducts][0] : null;
  const defaultValues = {
    [tenor]: record.tenor ? record.tenor : 0,
    [principal]: record.principal ? record.principal : 0,
    [product_code]: fActiveProduct?.code ?? '',
    [target_url]: '',
    [promo_code]: null,
  };
  const { handleSubmit, control, setValue, watch } = useForm({
    mode: 'all',
    defaultValues,
  });

  const activeProductOptions = activeProducts.map((item) => ({ label: item.name, value: item.code }));
  const isRepeatedLoan = record?.number_of_closed_loans > 0;
  const productCode = fActiveProduct?.code ?? watch(product_code);
  const promoCode = watch(promo_code);
  const [activeProduct] = activeProducts.filter((pr) => pr.code === productCode) ?? fActiveProduct;
  const tenorFieldVal = watch(tenor)?.value ?? fActiveProduct?.tenor.value;
  const principalFieldVal = watch(principal)?.value ?? fActiveProduct?.principal.value;
  const onSubmitHandler = handleSubmit((values) => {
    const payload = {
      ...values,
      product_code: productCode,
      tenor: values.tenor.value,
      principal: values.principal.value,
      promo_code: null,
    };
    dataProvider.query(`users/${record.id}/application`, { body: JSON.stringify(payload) })
      .then((res) => {
        onClose();
        notify(`Application ${res.id ? `#${res.id}` : ''} created successfully`, 'success');
      })
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  });

  const [priceList, setPriceList] = useState(null);
  const calculation = activeProduct ? priceList && priceList[tenorFieldVal] && priceList[tenorFieldVal][principalFieldVal] : null;

  const handleCalculatorValueChange = (name, value) => setValue(name, { ...activeProduct[`${name}`], value });

  useEffect(() => {
    if (productCode.length > 0) {
      dataProvider
        .query(`p/product-price-list/${productCode}?is_repeated_loan=${Number(isRepeatedLoan)}${promoCode ? '&promo_code=' + encodeURIComponent(promoCode) : ''}`, { method: 'GET' })
        .then((res) => {
          if (res?.data) {
            const { data } = res;
            setPriceList(data);
          }
        })
        .catch((error) => notify(`Error: ${error.message}`, 'error'));
    }
  }, [dataProvider, isRepeatedLoan, productCode, promoCode, notify]);

  useEffect(() => {
    if (productCode && fActiveProduct) {
      const { principal: fActivePPrincipal, tenor: fActivePTenor } = fActiveProduct;
      setValue(principal, {
        step: fActivePPrincipal.step,
        min: isRepeatedLoan ? fActivePPrincipal.min : fActivePPrincipal.first_min,
        value: isRepeatedLoan ? fActivePPrincipal.min : fActivePPrincipal.first_min,
        max: isRepeatedLoan ? fActivePPrincipal.max : fActivePPrincipal.first_max,
      });
      setValue(tenor, {
        step: fActivePTenor.step,
        min: isRepeatedLoan ? fActivePTenor.min : fActivePTenor.first_min,
        value: isRepeatedLoan ? fActivePTenor.min : fActivePTenor.first_min,
        max: isRepeatedLoan ? fActivePTenor.max : fActivePTenor.first_max,
      });
    }
    // eslint-disable-next-line
  }, [productCode, isRepeatedLoan]);

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Create loan application</DialogTitle>
      <form onSubmit={onSubmitHandler}>
        <DialogContent style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}>
          <DialogContentText>To create loan application, please fill following fields</DialogContentText>
          {activeProductOptions && activeProductOptions?.length > 0
            ? <SelectFieldHookForm
              defaultValue={activeProduct?.code}
              control={control}
              label={product_code}
              name={product_code}
              options={activeProductOptions}
              rules={validateSelect}
            />
            : <Skeleton variant={'rect'} />}
          {activeProduct
            ? <LoanCalculator
              control={control}
              principal={{
                step: activeProduct.principal.step,
                min: isRepeatedLoan ? activeProduct.principal.min : activeProduct.principal.first_min,
                value: isRepeatedLoan ? activeProduct.principal.min : activeProduct.principal.first_min,
                max: isRepeatedLoan ? activeProduct.principal.max : activeProduct.principal.first_max,
              }}
              tenor={{
                step: activeProduct.tenor.step,
                min: isRepeatedLoan ? activeProduct.tenor.min : activeProduct.tenor.first_min,
                value: isRepeatedLoan ? activeProduct.tenor.min : activeProduct.tenor.first_min,
                max: isRepeatedLoan ? activeProduct.tenor.max : activeProduct.tenor.first_max,
              }}
              onValueChange={handleCalculatorValueChange}
            />
            : <Skeleton variant={'rect'} />}
          <TextFieldHookForm name={target_url} label={target_url} control={control} />
          {/* TODO: ADD SELECTAUTOCOMPLETE-HOOK-FORM */}
          {/* <AutoComplete */}
          {/*  perOnePage={30} */}
          {/*  resource={'promo_codes'} */}
          {/*  name={promo_code} */}
          {/*  label={formatLabel(promo_code)} */}
          {/*  filter={{ is_enabled: true }} */}
          {/*  control={control} */}
          {/*  prop={'code'} */}
          {/*  setValue={setValue} */}
          {/* /> */}
          {calculation && <CreateAppCalculation calculation={calculation} />}
        </DialogContent>
        <DialogActions>
          <Button color={'primary'} onClick={onClose}>Cancel</Button>
          <Button color={'primary'} type={'submit'}>Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

CreateApplicationDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  record: PropTypes.object,
};
