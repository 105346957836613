import React from 'react';
import { Create, TextInput, SaveButton, Toolbar, FormWithRedirect, required, maxLength } from 'react-admin';
import { makeStyles, Box, Paper, Grid, Divider, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
}));

const FormWrapper = props => {
  const classes = useStyles();

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={ 2 }>
                  <Typography variant="h6" gutterBottom={ false }>Create promo code</Typography>
                  <Divider />
                  <TextInput className={ classes.mt4 } source="code" validate={ [required(), maxLength(255)] } fullWidth />
                  <TextInput source="campaign" fullWidth validate={ maxLength(255) } />
                </Box>
                <Toolbar className={ classes.toolbar }>
                  <SaveButton
                    label="Save"
                    redirect="edit"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export const PromoCodeCreate = props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
