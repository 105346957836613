import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';

import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

export const CollectionAssignTable = ({ loanId, refreshedAt }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [assignLog, setAssignLog] = useState();
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const tableRows = [
    'Assigned collector name',
    'Assigned_start',
    'Assignment_type',
    'Assigned_by',
    'Assigned_stop',
  ];

  useEffect(() => {
    setLoading(true);
    dataProvider.getList('phone_call_loan_assigns', {
      filter: { 'loan.id': loanId },
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
    })
      .then(({ data, total }) => {
        setAssignLog(data);
        setLoading(false);
        setTotal(total);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, loanId, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  const handlePerPageChange = (e) => {
    setPerPage(+(e.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            {tableRows.map((row) => <TableCell key={row}>{row}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {assignLog.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.admin_username}</TableCell>
              <TableCell><DateTimeBoxComponent value={row?.created_at} /></TableCell>
              <TableCell>{row.created_in}</TableCell>
              <TableCell>{row.created_by}</TableCell>
              <TableCell><DateTimeBoxComponent value={row?.stopped_at} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
    </>
  );
};

CollectionAssignTable.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
