import React from 'react';
import { Create } from 'react-admin';

import { MassSendingFormWrapper } from './MassSendingFormWrapper';

export const MassSendingCampaignCreate = (props) => (
  <Create component={'div'} {...props}>
    <MassSendingFormWrapper />
  </Create>
);
