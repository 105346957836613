import React, { useEffect, useState } from 'react';
import {
  BooleanInput, DeleteButton, FormDataConsumer, FormWithRedirect, maxLength,
  required, SaveButton, SelectInput, TextInput, useDataProvider,
} from 'react-admin';
import {
  Box, Button, Checkbox, Divider, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Typography, makeStyles,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { DAYS_MODE_DOM, DAYS_MODE_DOW, MONTH_DAYS, WEEK_DAYS } from '../../../constants';
import { useHandbook } from '../../../hooks';
import { ALPHA_NAMES, formatChoices, time as timeValidator, TRANSMITTERSLIST_DEFAULT } from '../../../utils';
import { withServerAutocomplete, SelectAutoComplete } from '../../select';

const AutoComplete = withServerAutocomplete(SelectAutoComplete);

const useStyles = makeStyles((theme) => ({
  flexACenteredJBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const MassSendingFormWrapper = ({ permissions, save, ...props }) => {
  const classes = useStyles();
  const isEditable = permissions?.indexOf('CAN_MASS_SENDING_EDIT') !== -1;
  const [options, setOptions] = useState({
    audience: null,
  });
  const [usePhoneBook, setUsePhoneBook] = useState(props.record?.params?.process?.use_phone_book);
  const [params, setParams] = useState(props.record?.params?.process?.phone_book_filters);
  const [weekDays, setWeekDays] = useState(props.record?.params?.week_days || []);
  const [monthDays, setMonthDays] = useState(props.record?.params?.month_days || []);
  const [audiencesList, setAudiencesList] = useState([]);
  const [valueKey, setValueKey] = useState('');
  const [value, setValue] = useState(props.record.template_key || '');
  const [daysMode, setDaysMode] = useState(
    Array.isArray(props.record?.params?.month_days) && props.record.params.month_days.length > 0
      ? DAYS_MODE_DOM
      : DAYS_MODE_DOW,
  );
  const dataProvider = useDataProvider();

  const { choices: contactRelationshipChoices } = useHandbook('phone_relations');
  const { choices: phoneTypeChoices } = useHandbook('phone_types');
  const { choices: phoneSourceChoices } = useHandbook('phone_sources');

  useEffect(() => {
    dataProvider.query('mass_sending_audiences?pagination=false', { method: 'GET' })
      .then(({ data }) => setAudiencesList(data));
  }, [dataProvider]);

  const AUDIENCE_CHOICES = [
    ...audiencesList?.map(item => ({ id: item.id, name: item.name })),
  ];

  const showOptions = (value) => {
    setUsePhoneBook(value);
    setParams([]);
  };

  const addItem = () => setParams([...params, {
    id: Date.now(),
    type: null,
    relation: null,
    source: null,
  }]);

  const setSelectValue = (id, type, value) => {
    setParams([...params.map(item => {
      if (item.id === id && type === 'type' && value !== 'contact_person') {
        item[type] = value;
        item.relation = null;
      } else if (item.id === id) {
        item[type] = value;
      }
      return item;
    })]);
  };

  const removeItem = (id) => setParams([...params.filter(item => item.id !== id)]);

  const onChangeWeekDay = (id) => {
    const element = weekDays.includes(id);
    if (element) {
      setWeekDays(weekDays.filter(i => i !== id));
    } else {
      setWeekDays([...weekDays, id]);
    }
  };

  const onChangeMonthDay = (id) => {
    const element = monthDays.includes(id);
    if (element) {
      setMonthDays(monthDays.filter(i => i !== id));
    } else {
      setMonthDays([...monthDays, id]);
    }
  };

  return (
    <FormWithRedirect
      save={(data, ...rest) => {
        save(...[{
          ...data,
          template_key: value,
          params: {
            ...data.params,
            week_days: daysMode === DAYS_MODE_DOW ? weekDays : [],
            month_days: daysMode === DAYS_MODE_DOM ? monthDays : [],
            process: {
              ...data?.params?.process,
              phone_book_filters: params,
              use_phone_book: usePhoneBook,
            },
          },
          _params: { method: 'PATCH' },
        }, ...rest]);
      }}
      {...props}
      render={(formProps) => (
        <form>
          <Grid container justifyContent={'center'}>
            <Grid item xs={12} sm={10}>
              <Paper>
                <Box p={2} className={classes.flexACenteredJBetween}>
                  <Typography variant={'h6'} gutterBottom={false}>Update mass sending campaign</Typography>
                  <BooleanInput helperText={false} label={'Enabled'} source={'is_enabled'} />
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        source={'start_time'} // Set the source to 'start_time'
                        size={'small'}
                        variant={'outlined'}
                        label={'Start time'}
                        type={'time'}
                        helperText={'Format: hh:mm:ss'}
                        fullWidth
                        validate={[required(), (value) => timeValidator(value)]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 1, // 5 min
                        }}
                      />
                      {/* <TextInput source={'start_time'} /> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput onChange={(e) => {
                        setOptions({
                          ...options,
                          audience: e.target.value,
                        });
                      }} variant={'outlined'} source={'audience_id'} choices={AUDIENCE_CHOICES} validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        variant={'outlined'}
                        source={'transmitter_id'}
                        inputProps={{
                          onChange: () => formProps.form.change('alpha_name', null),
                        }}
                        choices={TRANSMITTERSLIST_DEFAULT}
                        validate={[required()]}
                        fullWidth
                      />
                    </Grid>
                    <FormDataConsumer key={'consumer'}>
                      {({ formData, ...rest }) =>
                        (formData.transmitter_id === 'text_ware' || formData.transmitter_id === 'text_ware_promo') && (
                          <Grid item xs={12} sm={6} key={'alpha_name'}>
                            <SelectInput
                              variant={'outlined'}
                              helperText={false}
                              source={'alpha_name'}
                              defaultValue={props.record?.params?.process?.alpha_name}
                              validate={[required()]}
                              choices={formData.transmitter_id ? formatChoices(ALPHA_NAMES[formData.transmitter_id]) : []}
                              {...rest}
                              fullWidth
                            />
                          </Grid>
                        )}
                    </FormDataConsumer>
                    <Grid item xs={12} sm={6}>
                      <AutoComplete
                        size={'small'}
                        perOnePage={15}
                        resource={'admin/templates'}
                        label={'Template Key'}
                        value={value}
                        filter={valueKey ? { key: valueKey } : {}}
                        onChangeInput={(val) => setValueKey(val)}
                        setValue={setValue}
                        prop={'key'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput variant={'outlined'} source={'name'} validate={[required(), maxLength(255)]} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup row>
                        <FormControlLabel
                          value={DAYS_MODE_DOW}
                          control={<Radio />}
                          label={'Days of week'}
                          checked={daysMode === DAYS_MODE_DOW}
                          onChange={() => setDaysMode(DAYS_MODE_DOW)}
                        />

                        <FormControlLabel
                          value={DAYS_MODE_DOM}
                          control={<Radio />}
                          label={'Days of month'}
                          checked={daysMode === DAYS_MODE_DOM}
                          onChange={() => setDaysMode(DAYS_MODE_DOM)}
                        />
                      </RadioGroup>

                      {daysMode === DAYS_MODE_DOW && (
                        WEEK_DAYS.map((day, idx) => (
                          <FormControlLabel
                            key={day}
                            control={<Checkbox onChange={() => onChangeWeekDay(idx + 1)} checked={weekDays.includes(idx + 1)} />}
                            label={day}
                          />
                        ))
                      )}

                      {daysMode === DAYS_MODE_DOM && (
                        MONTH_DAYS.map(({ value, label }) => (
                          <FormControlLabel
                            key={value}
                            control={
                              <Checkbox
                                onChange={() => onChangeMonthDay(value)}
                                checked={monthDays.includes(value)}
                              />
                            }
                            label={label}
                          />
                        ))
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box padding={2} className={classes.flexACenteredJBetween}>
                  <FormControlLabel
                    control={<Checkbox checked={usePhoneBook} onChange={() => showOptions(!usePhoneBook)} />}
                    label={'Use alternative contacts'}
                  />

                  {usePhoneBook && <Button onClick={addItem} color={'primary'}>add</Button>}
                </Box>
                <Divider />
                {usePhoneBook &&
                  <Box padding={2}>
                    {params.map(item => <Grid container alignItems={'center'} spacing={2} key={item.id}>
                      <Grid item xs={12} sm={4}>
                        <SelectInput
                          variant={'outlined'}
                          key={`contact-sources-input-${phoneSourceChoices.length}`}
                          helperText={false}
                          label={'Contact source'}
                          source={`contact_source->${item.id}`}
                          validate={[required()]}
                          choices={phoneSourceChoices}
                          onChange={(e) => setSelectValue(item.id, 'source', e.target.value)}
                          defaultValue={item.source}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <SelectInput
                          variant={'outlined'}
                          key={`contact-types-input-${phoneTypeChoices.length}`}
                          helperText={false}
                          label={'Contact type'}
                          source={`contact_type->${item.id}`}
                          validate={[required()]}
                          choices={phoneTypeChoices}
                          onChange={(e) => setSelectValue(item.id, 'type', e.target.value)}
                          defaultValue={item.type}
                          fullWidth
                        />
                      </Grid>
                      {item.type === 'contact_person' &&
                        <Grid item xs={12} sm={3}>
                          <SelectInput
                            variant={'outlined'}
                            key={`contact-relationship-input-${contactRelationshipChoices.length}`}
                            emptyValue={null}
                            helperText={false}
                            validate={[required()]}
                            label={'Contact relation'}
                            source={`contact_relation->${item.id}`}
                            choices={contactRelationshipChoices}
                            defaultValue={item.relation}
                            onChange={(e) => setSelectValue(item.id, 'relation', e.target.value)}
                            fullWidth
                          />
                        </Grid>}
                      <Grid item xs={12} sm={2}>
                        <IconButton onClick={() => removeItem(item.id)} color={'primary'} variant={'contained'}>
                          <Delete />
                        </IconButton>
                      </Grid>
                      <Divider className={classes.my2} />
                    </Grid>)}
                  </Box>
                }
                <Box display={'flex'} justifyContent={'space-between'} padding={2}>
                  <SaveButton
                    disabled={!isEditable}
                    label={'Save'}
                    redirect={'list'}
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    transform={data => ({
                      ...data,
                      audience: data.audience_id,
                      params: {
                        ...(data.params ?? {}),
                        process: {
                          ...(data?.params?.process ?? {}),
                          phone_book_filters: params,
                          alpha_name: data.alpha_name,
                          use_phone_book: usePhoneBook,
                        },
                      },
                    })}
                  />
                  <DeleteButton
                    disabled={!isEditable}
                    basePath={formProps.basePath}
                    record={formProps.record}
                    resource={formProps.resource}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

MassSendingFormWrapper.propTypes = {
  permissions: PropTypes.array,
  save: PropTypes.func,
  record: PropTypes.shape({
    params: PropTypes.shape({
      process: PropTypes.shape({
        use_phone_book: PropTypes.bool,
        phone_book_filters: PropTypes.array,
        alpha_name: PropTypes.string,
      }),
      week_days: PropTypes.array,
      month_days: PropTypes.array,
    }),
    template_key: PropTypes.string,
  }),
};
