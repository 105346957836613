import { useEffect, useState } from 'react';
import { Loading, useDataProvider, useNotify } from 'react-admin';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Table, TableBody, TableCell, TableRow, Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../utils';
import { ListItem } from '../ListItem';

export const FeeAccordion = ({ record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [discountCalculations, setDiscountCalculations] = useState([]);
  const recordId = record.id;

  useEffect(() => {
    dataProvider.query(`loans/${recordId}/discount_calculations`, { method: 'GET' })
      .then(({ data }) => setDiscountCalculations(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, recordId]);

  if (!loading && error) {
    notify(`Error: ${error.message}`, 'error');
  }

  if (loading) return <Loading />;

  return (
    discountCalculations.map((calc) => <Accordion key={calc.title}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={'panel1a-content'} id={'panel1a-header'}>
        <Typography variant={'subtitle1'}>{calc.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box style={{ width: '100%' }}>
          {calc.items.map((item, key) =>
            <ListItem key={key} label={`${item.title} - ${item.percentage}%`} value={formatCurrency(item.amount)} />)}
        </Box>
      </AccordionDetails>
    </Accordion>));
};

FeeAccordion.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.number,
    product_id: PropTypes.number,
    principal: PropTypes.number,
    tenor: PropTypes.number,
    promo_code: PropTypes.string,
    is_repeat: PropTypes.bool,
    is_ready_for_disbursement: PropTypes.bool,
  }),
};
