import { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import {
  DialogContent, Dialog, DialogContentText, DialogActions, Button, DialogTitle, InputLabel, Select, MenuItem, FormControl,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';

export const AddUserApiDataDialog = ({ userId, applicationId, isOpened, onClose, onSubmit, providers }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [isLocked, setIsLocked] = useState(false);
  const [service, setService] = useState('');

  const isSubmitDisabled = isLocked || !(service !== '');

  const handleClose = (e) => {
    if (isLocked && ['click', 'mouseup'].includes(e.type) && e.target?.matches('.MuiDialog-container')) {
      return;
    }
    onClose();
  };
  const handleSubmit = () => {
    const options = {};
    setIsLocked(true);
    dataProvider.query('user_api_datas', {
      method: 'POST',
      body: JSON.stringify({
        user_id: userId,
        application_id: applicationId,
        service,
        options,
      }),
    })
      .then(() => {
        setTimeout(() => {
          setIsLocked(false);
          onSubmit();
        }, 1000);
      })
      .catch((error) => {
        setIsLocked(false);
        notify(`Error: ${error.message}`, 'error');
      });
  };
  const handleProviderChange = (e) => setService(e.target.value);

  useEffect(() => {
    setIsLocked(false);
  }, [userId, applicationId, isOpened]);

  return (
    <Dialog open={isOpened} onClose={handleClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Add user api data request</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        {providers
          ? <FormControl variant={'outlined'} fullWidth>
            <InputLabel id={'providers-select-label'}>Providers</InputLabel>
            <Select
              label={'Providers'}
              labelId={'providers-select-label'}
              value={service}
              onChange={handleProviderChange}>
              {providers.map((option, key) => <MenuItem key={key} value={option.name}>{option.name.replaceAll('_', ' ')}</MenuItem>)}
            </Select>
          </FormControl>
          : <Skeleton variant={'rect'} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'} disabled={isLocked}>Cancel</Button>
        <Button onClick={handleSubmit} color={'primary'} disabled={isSubmitDisabled}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

AddUserApiDataDialog.propTypes = {
  userId: PropTypes.number.isRequired,
  applicationId: PropTypes.number,
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  providers: PropTypes.array,
};
