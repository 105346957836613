import { useEffect, useState } from 'react';
import { useDataProvider, useNotify, useRefresh, useShowController } from 'react-admin';
import { ButtonGroup, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import { TRANSMITTERSLIST_DEFAULT } from '../../../utils';
import * as Tables from '../../tables';
import DebounceButton from '../../button/DebounceButton';
import { TabPanel } from '../../TabPanel';
import * as Dialogs from '../../dialogs';

export const ApplicationAdditionalInfo = ({ permissions, refreshedAt, ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { record, refetch } = useShowController(props);
  const [isAddNotificationDialogOpened, setIsAddNotificationDialogOpened] = useState(false);
  const [isAddNoteDialogOpened, setIsAddNoteDialogOpened] = useState(false);
  const [isUserApiDataDialogOpened, setIsUserApiDataDialogOpened] = useState(false);

  const refreshHandler = () => {
    refetch();
  };

  const [matches, setMatches] = useState(0);
  const [selfieMatches, setSelfieMatches] = useState(0);
  const [idCardMatches, setIdCardMatches] = useState(0);
  const [notifications, setNotifications] = useState(0);
  const [applications, setApplications] = useState(0);
  const [providersList, setProvidersList] = useState(null);
  const [transmittersList, setTransmittersList] = useState(TRANSMITTERSLIST_DEFAULT);

  useEffect(() => {
    dataProvider.query(`applications/${record.id}/user-field-matches`, {})
      .then(({ data }) => {
        setMatches(data.length);
      })
      .catch((error) => {
        notify(`Error: ${error.message}`, 'error');
      });

    dataProvider.query('notifications/transmitters', { method: 'GET' })
      .then(({ data }) => setTransmittersList(data)).catch(() => {
        setTransmittersList(TRANSMITTERSLIST_DEFAULT);
      });

    if (permissions.includes('CAN_USER_VIEW')) {
      dataProvider.getList('aws_rekognition_matches',
        {
          pagination: {},
          sort: {},
          filter: { user: record.user_id, 'similarity[gte]': 99 },
        })
        .then(({ total }) => {
          setSelfieMatches(total);
        })
        .catch(error => {
          notify(`Error: ${error.message}`, 'error');
        });
    }

    if (permissions.includes('CAN_USER_VIEW')) {
      dataProvider.getList('aws_rekognition_id_card_matches',
        {
          pagination: {},
          sort: {},
          filter: { user: record.user_id, 'similarity[gte]': 99 },
        })
        .then(({ total }) => {
          setIdCardMatches(total);
        })
        .catch(error => {
          notify(`Error: ${error.message}`, 'error');
        });
    }

    if (permissions.includes('CAN_NOTIFICATION_VIEW')) {
      dataProvider.getList('notifications', {
        filter: { 'application.id': record.id },
        pagination: {},
        sort: {},
      })
        .then(({ total }) => {
          setNotifications(total);
        })
        .catch((error) => {
          notify(`Error: ${error.message}`, 'error');
        });
    }

    dataProvider.getList('applications', {
      filter: { 'user.id': record.user_id },
      pagination: {},
      sort: {},
    })
      .then(({ total }) => {
        setApplications(total);
      })
      .catch((error) => {
        notify(`Error: ${error.message}`, 'error');
      });

    if (permissions.includes('CAN_USER_API_DATA_EDIT')) {
      dataProvider.query('user_api_datas/providers', { method: 'GET' })
        .then(({ data }) => setProvidersList(data))
        .catch((error) => notify(`Error: ${error.message}`, 'error'));
    }
  }, [permissions, dataProvider, record, notify]);

  const applyNotificationTransition = (notificationId, name, params = {}) => {
    dataProvider.query(`notifications/${notificationId}/apply_transition`, {
      method: 'POST',
      body: JSON.stringify({ name, params }),
    })
      .then(() => refreshHandler())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  const applyAgreementTransition = (agreementId, name, params = {}) => {
    dataProvider.query(`agreements/${agreementId}/apply_transition`, {
      method: 'POST',
      body: JSON.stringify({ name, params }),
    })
      .then(() => refreshHandler())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
    ;
  };

  const handleAddNotificationDialogOpen = () => setIsAddNotificationDialogOpened(true);
  const handleAddNotificationDialogClose = () => setIsAddNotificationDialogOpened(false);

  const handleAddNoteDialogOpen = () => setIsAddNoteDialogOpened(true);
  const handleAddNoteDialogClose = () => setIsAddNoteDialogOpened(false);

  const handleUserApiDataDialogOpen = () => setIsUserApiDataDialogOpened(true);
  const handleUserApiDataDialogClose = () => setIsUserApiDataDialogOpened(false);

  const handleCreateKYCRequest = () => {
    dataProvider.create('kyc_requests', {
      data: {
        user: `/api/users/${record.user_id}`,
        engine: 'seon_io',
      },
    })
      .then(() => refreshHandler())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const tabs = [
    {
      label: 'Notifications',
      value: notifications,
      hasPermission: permissions.includes('CAN_NOTIFICATION_VIEW'),
      component: <>
        <Tables.NotificationsTable applicationId={record.id} refreshedAt={refreshedAt} onTransition={applyNotificationTransition} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
          {permissions.includes('CAN_NOTIFICATION_EDIT') && <Button onClick={handleAddNotificationDialogOpen}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Notes',
      value: null,
      hasPermission: permissions.includes('CAN_NOTE_VIEW'),
      component: <>
        <Tables.NotesTable applicationId={record.id} refreshedAt={refreshedAt} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
          {permissions.includes('CAN_NOTE_EDIT') && <Button onClick={handleAddNoteDialogOpen}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Agreements',
      value: null,
      hasPermission: permissions.includes('CAN_AGREEMENT_VIEW'),
      component: <>
        <Tables.AgreementsTable applicationId={record.id} refreshedAt={refreshedAt} onTransition={applyAgreementTransition} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Changes',
      value: null,
      hasPermission: permissions.includes('CAN_APPLICATION_VIEW'),
      component: <>
        <Tables.ChangeHistoryTable entityId={record.id} entityField={'application'} refreshedAt={refreshedAt} endpoint={'application_change_histories'} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Applications',
      value: applications,
      hasPermission: permissions.includes('CAN_APPLICATION_VIEW'),
      component: <>
        <Tables.ApplicationsTable userId={record.user_id} refreshedAt={refreshedAt} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Matches',
      value: matches,
      hasPermission: permissions.includes('CAN_APPLICATION_VIEW'),
      component: <>
        <Tables.MatchesTable permissions={permissions} recordId={record.id} refreshedAt={refreshedAt} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'ADE Data',
      value: null,
      hasPermission: permissions.includes('CAN_APPLICATION_VIEW'),
      component: <>
        <Tables.AventusDecisionEngineDataTable recordId={record.id} refreshedAt={refreshedAt} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Sessions',
      value: null,
      hasPermission: permissions.includes('CAN_USER_VIEW'),
      component: <>
        <Tables.SessionsTable userId={record.user_id} refreshedAt={refreshedAt} returnSimilar={false} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Juicy Score',
      value: null,
      hasPermission: permissions.includes('CAN_APPLICATION_VIEW'),
      component: <>
        <Tables.JuicyScoreTable applicationId={record.id} refreshedAt={refreshedAt} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Selfie matches',
      value: selfieMatches,
      hasPermission: permissions.includes('CAN_USER_VIEW'),
      component: <>
        <Tables.SelfieMatchesTable userId={record.user_id} refreshedAt={refreshedAt} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Id card matches',
      value: idCardMatches,
      hasPermission: permissions.includes('CAN_USER_VIEW'),
      component: <>
        <Tables.IdCardMatchesTable userId={record.user_id} refreshedAt={refreshedAt} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'KYC',
      value: null,
      hasPermission: permissions.includes('CAN_KYC_REQUEST_VIEW'),
      component: <>
        <Tables.KycTable userId={record.user_id} refreshedAt={refreshedAt} />
        <ButtonGroup size="small" color="primary">
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
          {permissions.includes('CAN_KYC_REQUEST_EDIT') && <Button onClick={handleCreateKYCRequest}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Api data',
      value: null,
      hasPermission: permissions.includes('CAN_USER_API_DATA_VIEW'),
      component: <>
        <Tables.UserApiDataTable userId={record.user_id} refreshedAt={refreshedAt} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
          {permissions.includes('CAN_USER_API_DATA_EDIT') && <Button onClick={handleUserApiDataDialogOpen}>Add</Button>}
        </ButtonGroup>
      </>,
    },
    {
      label: 'Webitel calls',
      value: null,
      hasPermission: permissions.includes('CAN_VERIFICATION_CALLS_VIEW'),
      component: <>
        <Tables.CallsTable applicationId={record.id} refreshedAt={refreshedAt} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
    {
      label: 'Credit bureau',
      value: null,
      hasPermission: permissions.includes('CAN_CREDIT_BUREAU_REPORT_VIEW'),
      component: <>
        <Tables.ReportTable userId={record.user_id} refreshedAt={refreshedAt} />
        <ButtonGroup size={'small'} color={'primary'}>
          <DebounceButton onClick={refreshHandler}>Refresh</DebounceButton>
        </ButtonGroup>
      </>,
    },
  ];

  const filteredTabs = tabs.filter((tab) => tab.hasPermission);

  return (
    <>
      <TabPanel items={filteredTabs} activeTabIndex={+window.location.href.split('activeTab=')[1]} />
      {permissions.includes('CAN_NOTIFICATION_EDIT') && isAddNotificationDialogOpened &&
        <Dialogs.AddNotificationDialog
          userId={record}
          transmittersList={transmittersList}
          isOpened={isAddNotificationDialogOpened}
          onClose={handleAddNotificationDialogClose}
          onSubmit={(transmitterId, destination, template, locale, message, subject) => {
            handleAddNotificationDialogClose();
            dataProvider.query(`applications/${record.id}/notifications`, {
              body: JSON.stringify({
                transmitter_id: transmitterId,
                destination,
                template,
                locale,
                message,
                subject,
              }),
            })
              .then(() => refreshHandler())
              .catch(error => notify(`Error: ${error.message}`, 'error'))
            ;
          }}
          defaultPhoneNumber={record.user_phone_number}
          defaultEmailAddress={record.user_email_address}
        />}
      {permissions.includes('CAN_NOTE_EDIT') &&
        <Dialogs.AddNoteDialog
          isOpened={isAddNoteDialogOpened}
          onClose={handleAddNoteDialogClose}
          onSubmit={(label, message) => {
            setIsAddNoteDialogOpened(false);
            dataProvider.create('notes', {
              data: {
                user_id: record.user_id,
                application_id: record.id,
                label,
                message,
              },
            })
              .then(() => refreshHandler())
              .catch(error => notify(`Error: ${error.message}`, 'error'))
            ;
          }}
        />}
      {permissions.includes('CAN_USER_API_DATA_EDIT') && isUserApiDataDialogOpened &&
        <Dialogs.AddUserApiDataDialog
          providers={providersList}
          userId={record.user_id}
          applicationId={record.id}
          isOpened={isUserApiDataDialogOpened}
          onClose={handleUserApiDataDialogClose}
          onSubmit={() => {
            handleUserApiDataDialogClose();
            refreshHandler();
          }}
        />}
    </>
  );
};

ApplicationAdditionalInfo.propTypes = {
  permissions: PropTypes.array,
  record: PropTypes.shape({
    user_id: PropTypes.number,
    id: PropTypes.number,
    user_phone_number: PropTypes.string,
    user_email_address: PropTypes.string,
  }),
  refreshedAt: PropTypes.number,
};
