import Dialog from '@material-ui/core/Dialog';
import { Field, Form as FinalForm } from 'react-final-form';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { required, useDataProvider } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';

import { invalidateCache, useHandbook } from '../../hooks';

import { useDialogStyles } from './useDialogStyle';

export const CollectionContactTypeValueDialog = ({ defaultType, isOpened, onClose, onSubmit }) => {
  const classes = useDialogStyles();
  const dataProvider = useDataProvider();

  const { data: contactTypes, reload } = useHandbook('collection_contact_types');

  const handleSubmit = (values) => {
    const data = {
      type: values.type,
      value: values.value,
      enabled: true,
    };

    dataProvider
      .fetch('collection_contact_type_values', {
        body: JSON.stringify(data),
        method: 'POST',
      })
      .then(() => {
        invalidateCache('collection_contact_type_values');
        reload();
        onSubmit();
      });
    onSubmit();
  };

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <FinalForm
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <DialogTitle>Create new contact value</DialogTitle>
            <DialogContent>
              <Field
                name="type"
                defaultValue={defaultType}
                validate={required('Can not be empty.')}
              >
                {({ input, meta }) => (
                  <FormControl fullWidth variant='filled'>
                    <InputLabel id="collection-contact-type-label">Contact type</InputLabel>

                    {contactTypes.length > 0 && (
                      <Select
                        id="collection-contact-type-input"
                        margin='dense'
                        value={input.value}
                        error={meta.touched && meta.error}
                        onChange={(e) => {
                          input.onChange(e.target.value ? parseInt(e.target.value) : null);
                        }}
                        fullWidth
                      >
                        {contactTypes.map((type) => (
                          <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                        ))}
                      </Select>
                    )}

                    {meta.touched && meta.error && (
                      <FormHelperText error={meta.touched && meta.error}>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </Field>
              <Field
                name="value"
                validate={required('Can not be empty.')}
              >
                {({ input, meta }) => (
                  <TextField
                    type="text"
                    error={meta.touched && meta.error}
                    helperText={meta.touched && meta.error}
                    id="contact-type-value-name-input"
                    label="Name"
                    variant='filled'
                    margin='dense'
                    value={input.value}
                    onChange={input.onChange}
                    fullWidth
                  />
                )}
              </Field>
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button type='submit' color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

CollectionContactTypeValueDialog.propTypes = {
  defaultType: PropTypes.number,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
