import { useState } from 'react';
import { ChipField, Datagrid, FunctionField, List, TextField } from 'react-admin';
import { Link, useTheme, Card } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';

import { formatCurrency, getRequestDuration } from '../../../../utils';
import Pagination from '../../../Pagination';
import { ListItem } from '../../../ListItem';
import { ApplicationColorLegend } from '../../../ApplicationColorLegend';
import { ApplicationsBulkActionButtons } from '../ApplicationsBulkActionButtons';
import { DateTimeBoxComponent } from '../../../DateTimeBoxComponent';

import { ManualVerificationAllFilters } from './ManualVerificationAllFilters';
import { ManualVerificationListActions } from './ManualVerificationListActions';

export const ManualVerificationAll = ({ filter, permissions, ...props }) => {
  const { palette } = useTheme();
  const [isLegendShow, setIsLegendShow] = useState(false);

  const handleLegendToggle = () => setIsLegendShow(!isLegendShow);

  const isRowSelectable = (record) => record?.state === 'pending' || (record?.state === 'processing' && ['manual', null].includes(record?.decision_engine_id));

  const loanRowStyle = (record) => ({ backgroundColor: getRequestDuration(record, palette) });

  return (
    <List
      pagination={ <Pagination /> }
      bulkActionButtons={<ApplicationsBulkActionButtons />}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ManualVerificationAllFilters />}
      actions={<ManualVerificationListActions onLegendToggle={handleLegendToggle} isLegendShow={isLegendShow} />}
      filter={{ ...filter }}
      permissions={permissions}
      {...props}
    >
      <>
        {isLegendShow && <ApplicationColorLegend />}
        <Datagrid
          bulkActionButtons={<ApplicationsBulkActionButtons />}
          rowStyle={loanRowStyle}
          rowClick="show"
          isRowSelectable={isRowSelectable}>
          <TextField source="id" />
          <FunctionField label="Verifier" source="verifier_id" render={({ verifier_id, verifier_username = '' }) =>
            `#${verifier_id} ${verifier_username}`} />
          <ChipField size="small" source="state" />
          <FunctionField label="Request details" render={ (record) => record.requested_principal && record.requested_tenor && <Card>
            <ListItem label={'Principal:'} value={<b>{formatCurrency(record.requested_principal)}</b>} />
            <ListItem label={'Tenor:'} value={<span><b>{record.requested_tenor}</b> days</span>} />
          </Card>} />
          <FunctionField label="Created at" source="created_at" render={(record) =>
            <DateTimeBoxComponent value={record?.created_at} />} />
          <FunctionField label="Assigned at" source="verifier_assigned_at" render={(record) =>
            <DateTimeBoxComponent value={record?.verifier_assigned_at} />} />
          <FunctionField label="Resolved at" source="resolved_at" render={(record) =>
            <DateTimeBoxComponent value={record?.resolved_at} />} />
          <FunctionField label="Processing time" render={({ resolved_at, verifier_assigned_at }) => {
            if (verifier_assigned_at && resolved_at) {
              const diff = new Date(resolved_at) - new Date(verifier_assigned_at);
              const minutes = Math.floor((diff / (1000 * 60)) % 60);
              const seconds = Math.floor((diff / 1000) % 60);
              return `${minutes}:${seconds}`;
            } else {
              return null;
            }
          }} />
          <FunctionField onClick={(e) => e.stopPropagation()} render={(record) => <Link color={'secondary'} href={ `#applications/${record.id}/show` } underline="none" target="_blank" rel="noreferrer"><OpenInNewIcon fontSize="small"/></Link>}/>
        </Datagrid>
      </>
    </List>
  );
};

ManualVerificationAll.propTypes = {
  filter: PropTypes.object,
  permissions: PropTypes.arrayOf(PropTypes.string),
};
