import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  Filter,
  NumberInput,
  useDataProvider,
  useNotify,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  FunctionField,
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import { LinearProgressField } from '../../field';
import { openIntoNewTab } from '../../../utils';
import { marginZeroStyles } from '../../../constants';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      { hasCreate ? (<CreateButton basePath={ basePath } />) : null }
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const DownloadButtonField = ({ record = {} }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);

  const downloadHandler = reportId => {
    setIsLoading(true);
    dataProvider.downloadFile(`reports/${reportId}/download`)
      .then(({ data: objUrl }) => openIntoNewTab(objUrl))
      .catch(error => {
        notify(`Error: ${error.message}`, 'error');
      })
      .finally(() => setIsLoading(false))
    ;
  };

  return (
    <IconButton color="primary" disabled={ record.state !== 'processed' || isLoading } onClick={ () => downloadHandler(record.id) }>
      <CloudDownloadIcon />
    </IconButton>
  );
};

DownloadButtonField.propTypes = {
  record: PropTypes.shape({
    state: PropTypes.string,
    id: PropTypes.number,
  }),
};

export const ReportList = (props) => (
  <List
    pagination={ <Pagination /> }
    bulkActionButtons={ false }
    sort={{ field: 'id', order: 'DESC' }}
    filters={ <ListFilter /> }
    actions={ <ListActions /> }
    { ...props }
  >
    <Datagrid>
      <TextField source="id" />
      <ChipField size="small" source="state" />
      <ChipField size="small" source="builder_id" />
      <LinearProgressField source="progress" />
      <FunctionField render={(record) => <DateTimeBoxComponent value={record?.created_at} />} label="Created at"/>
      <FunctionField label="Created by" render={ ({ created_by_id, created_by_name = '' }) => created_by_id ? (created_by_name + '#' + created_by_id) : '' } />
      <FunctionField render={(record) => <DateTimeBoxComponent value={record?.finished_at} />} label="Finished at"/>
      <DownloadButtonField />
    </Datagrid>
  </List>
);
