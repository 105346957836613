import { useState } from 'react';
import { useGetList, useNotify } from 'react-admin';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, List, ListItem, ListItemText,
  ListItemSecondaryAction, Select, MenuItem, DialogActions, Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';

export const ManualVerificationDialog = ({ applicationId, isOpened, onClose, onSubmit }) => {
  const notify = useNotify();
  const [results, setResults] = useState({});

  const {
    data,
    loading,
    error,
  } = useGetList('manual_verification_steps', {}, {}, { 'application->id': applicationId });

  if (loading === false && error) {
    notify(`Error: ${error.message}`, 'error');
  }

  const onResultChange = (id, result) => {
    setResults({ ...results, ...({ [id]: result }) });
  };

  const handleSubmit = () => onSubmit(results);

  const dictionary = {
    client: {
      title: 'Client verification.',
      description: 'Verify client.',
    },
    contact_person: {
      title: 'Contact person verification.',
      description: 'Verify contact person.',
    },
    employer: {
      title: 'Employer verification.',
      description: 'Verify employer.',
    },
  };

  const getTitle = key => dictionary[key] && dictionary[key].title ? dictionary[key].title : 'Unknown';
  const getDescription = key => dictionary[key] && dictionary[key].description ? dictionary[key].description : 'Unknown';

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'} fullWidth>
      <DialogTitle id={'form-dialog-title'}>Manual verification</DialogTitle>
      <DialogContent>
        <DialogContentText>Verification steps</DialogContentText>
        {loading
          ? <CircularProgress />
          : <List dense={false}>
            {Object.keys(data).map(idx => (
              <ListItem key={idx} disableGutters>
                <ListItemText primary={getTitle(data[idx].key)} secondary={getDescription(data[idx].key)} />
                <ListItemSecondaryAction>
                  <Select
                    variant={'outlined'}
                    value={results[data[idx].id] || data[idx].result || ''}
                    onChange={e => onResultChange(data[idx].id, e.target.value)}
                    fullWidth
                  >
                    <MenuItem value={'success'}>Confirmed</MenuItem>
                    <MenuItem value={'failure'}>Declined</MenuItem>
                    <MenuItem value={'error'}>{'Doesn\'t respond'}</MenuItem>
                  </Select>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleSubmit} color={'primary'}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

ManualVerificationDialog.propTypes = {
  applicationId: PropTypes.number,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
