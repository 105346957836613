import * as yup from 'yup';
import { isValid, parse } from 'date-fns';

import { onlyNumbers } from '../parsers';
import { FIELD_MIN_LENGTH, NIDA_PLACEHOLDER } from '../../constants';
import { applyMask } from '../applyMask';

function validateMinLength (data) {
  const { transform, message, to, from } = data;
  // const errorMessage = {
  //   message: message || FIELD_MIN_LENGTH,
  //   data: {
  //     from,
  //     to,
  //   },
  // };
  const errorMessage = message || FIELD_MIN_LENGTH;

  return this.test({
    name: 'validateMinLength',
    message: errorMessage,
    test: (value) => {
      if (!value) return true;

      let curVal = value;
      if (transform) {
        const { excludeLength = from, regular } = transform;
        // curVal = regular ? curVal.replace(regular, '') : curVal;
        curVal = applyMask(curVal, NIDA_PLACEHOLDER);
        if (curVal.length === excludeLength) return true;
      }
      // errorMessage.data.from = value.length;
      return curVal.length === to;
    },
  });
}

function correctDate (data) {
  return this.test({
    name: 'isCorrectDate',
    message: data?.message || 'INCORRECT_DATE',
    test: (value) => {
      if (!value) return true;
      const date = data?.format ? parse(`${value}`, data.format, new Date()) : value;
      return isValid(new Date(date));
    },
  });
}

function isNumbers (message) {
  return this.matches(/^\d+$/, message || 'ONLY_NUMBERS');
}

function validateCodePhone (codes, message = 'incorrect_phone_code') {
  return this.test({
    name: 'validateCodePhone',
    message,
    test: (value) => {
      if (!value) return true;
      const codeLength = `${codes[0]}`.length;
      const val = onlyNumbers(value);
      const searchStr = val.length < codeLength ? val : val.slice(0, codeLength);
      return codes.some((code) => `${code}`.slice(0, searchStr.length).includes(searchStr));
    },
  });
}

export const validateFieldSync = (schema) => (value) => {
  try {
    schema.validateSync(value);
    return undefined;
  } catch (e) {
    return JSON.stringify(e.errors[0]);
  }
};

yup.addMethod(yup.string, 'minLength', validateMinLength);
yup.addMethod(yup.string, 'phoneNumberCode', validateCodePhone);
yup.addMethod(yup.string, 'isNumbers', isNumbers);
yup.addMethod(yup.mixed, 'isCorrectDate', correctDate);

export { yup };
