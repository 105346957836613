import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps, SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
} from 'react-admin';
import { Link } from 'react-router-dom';

import Pagination from '../../Pagination';
import { DateFilterInput } from '../../filters';
import { formatPhoneNumber } from '../../../utils';
import CollapsedText from '../../collapsed_text/CollapsedText';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

export const NotificationListFilter = (props) => {
  return (
    <Filter {...props} variant={'outlined'} size={'small'} style={{ marginTop: 0, marginBottom: '20px' }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <DateFilterInput label="Sent before" source="sent_at|before" before />
      <DateFilterInput label="Sent after" source="sent_at|after" after />
      <TextInput source="template" label="Template" />
      <SelectInput source="state" choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'sent', name: 'Sent' },
        { id: 'error', name: 'Error' },
      ]}/>
      <SelectInput source="delivery_status" choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'cannot_be_known', name: 'Cannot be known' },
        { id: 'not_delivered', name: 'Not delivered' },
        { id: 'delivery_failed', name: 'Delivery failed' },
        { id: 'delivered', name: 'Delivered' },
        { id: 'viewed', name: 'Viewed' },
      ]}/>
      {/* TODO: get vals from be */}
      <SelectInput source="transmitter_id" choices={[
        { id: 'fake', name: 'Fake' },
        { id: 'infobip', name: 'Infobip' },
        { id: 'africas_talking', name: 'Africa\'s Talking' },
        { id: 'mailer', name: 'Mailer' },
      ]}/>
    </Filter>
  );
};

export const NotificationListActions = ({ filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' })}
      {hasCreate && <CreateButton basePath={basePath} />}
    </TopToolbar>
  );
};

NotificationListActions.propTypes = {
  filters: PropTypes.element,
};

export const NotificationList = (props) => {
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<NotificationListFilter />}
      actions={<NotificationListActions />}
      {...props}
    >
      <Datagrid>
        <TextField source="id" />
        <FunctionField
          label="Created / Sent"
          source="createdAt"
          render={
            (row) => (
              <div style={{ whiteSpace: 'nowrap' }}>
                <div><b>Created:</b> <DateTimeBoxComponent inline value={row?.created_at} /></div>
                <div><b>Sent at:</b> <DateTimeBoxComponent inline value={row?.sent_at} /></div>
              </div>
            )
          }
        />
        <FunctionField
          render={
            (row) => (
              <>
                <div>
                  <b>User:&nbsp;</b>
                  {row.user_id
                    ? (<Link to={`/users/${row.user_id}`}>#{row.user_id}</Link>)
                    : (<>---</>)
                  }
                </div>
                <div>
                  <b>Loan:&nbsp;</b>
                  {row.loan_id
                    ? (<Link to={`/loans/${row.loan_id}/show`}>#{row.loan_id}</Link>)
                    : (<>---</>)
                  }
                </div>
                <div>
                  <b>Appl:&nbsp;</b>
                  {row.application_id
                    ? (<Link to={`/applications/${row.application_id}/show`}>#{row.application_id}</Link>)
                    : (<>---</>)
                  }
                </div>
              </>
            )
          }
        />
        <FunctionField
          label="Statuses"
          render={
            (row) => (
              <div style={{ whiteSpace: 'nowrap' }}>
                <div><b>Status:</b> {row.state}</div>
                <div><b>Delivery status:</b> {row.delivery_status}</div>
              </div>
            )
          }
        />
        <TextField source="transmitter_id" />
        <TextField source="template" />
        <FunctionField
          label="Destination"
          render={
            (row) => (
              <div>{/^\d{10,11}$/.test(row.destination) ? formatPhoneNumber(row.destination) : row.destination}</div>
            )
          }
        />
        <FunctionField
          label="Content"
          render={
            (row) => (
              <CollapsedText text={row.message} />
            )
          }
        />
      </Datagrid>
    </List>
  );
};
