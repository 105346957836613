import { forwardRef, useState } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

import ListboxComponent from './ListBox';

export const SelectAutoComplete = ({
  result,
  loading,
  maxPage,
  setPage,
  page,
  variant = 'outlined',
  label,
  value,
  setValue,
  onChangeInput,
  prop,
}) => {
  const [openSelect, setOpenSelect] = useState(false);
  const openClose = () => setOpenSelect((state) => !state);
  const Wrapper = (WrappedComponent, page, setPage, maxPage) => {
    return forwardRef((props, ref) => {
      return (
        <WrappedComponent
          {...props}
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          ref={ref}
        />
      );
    });
  };
  const ListBox = Wrapper(ListboxComponent, page, setPage, maxPage);
  return (
    <Autocomplete
      value={typeof value !== 'undefined' ? { [prop]: value } : undefined}
      ListboxComponent={ListBox}
      id={`select-auto-complite${label}`}
      defaultValue={value}
      open={openSelect}
      onChange={(event, newValue, reason) => setValue(reason === 'clear' ? undefined : newValue[prop])}
      onOpen={() => openClose()}
      onClose={() => openClose()}
      getOptionSelected={(option, value) => typeof value !== 'undefined' && option[prop] === value[prop]}
      getOptionLabel={(option) => option[prop] ?? ''}
      options={result.options}
      loading={loading}
      renderInput={(params) => {
        let options = [];
        if (!loading) {
          options = params.InputProps;
        }
        if (value && !params.inputProps.value) {
          params.inputProps.value = value;
        }

        return <TextField
          {...params}
          role={'select-auto-complete-component'}
          label={label}
          size={'small'}
          variant={variant}
          onChange={(e) => onChangeInput?.(e.target.value)}
          InputProps={{
            ...options,
            endAdornment: loading ? <CircularProgress /> : params.InputProps.endAdornment || null,
          }}
        />;
      }}
    />
  );
};
SelectAutoComplete.propTypes = {
  result: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  maxPage: PropTypes.number,
  setPage: PropTypes.func,
  page: PropTypes.number,
  variant: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  prop: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func,
};
