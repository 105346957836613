import { TIMEZONES } from './dictionary';

/**
 * @param {boolean} replaceLocalTimezone
 * @returns {string}
 */
export const getAdminTimezone = (replaceLocalTimezone = true) => {
  let savedTimezone = localStorage.getItem('admin_timezone');

  if (TIMEZONES.includes(savedTimezone) === false) {
    savedTimezone = TIMEZONES[0];

    localStorage.setItem('admin_timezone', savedTimezone);
  }

  if (replaceLocalTimezone && savedTimezone === 'Local') {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  return savedTimezone;
};
