import { Box, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';

export const CollectionActionTreeListRootContactsToolbar = ({
  typeValues,
  selectedRootContactId,
  onSelectRootContact,
  rootContacts,
  onClickCreate,
}) => {
  const handleClickCreateRoot = () => {
    onClickCreate();
  };

  const buildHandleClickCreateUnderRoot = (parentId) => {
    return () => {
      onClickCreate(parentId);
    };
  };

  return (
    <Toolbar variant="dense" disableGutters>
      <Typography
        variant="subtitle1"
        component="div"
        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
      >
        Call types:
      </Typography>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
          marginLeft: 12,
        }}
      >
        {rootContacts.map((item) => (
          <ButtonGroup
            key={item.id}
            color="secondary"
            size="small"
            variant="contained"
          >
            <Button
              sx={{ color: '#fff' }}
              variant={
                selectedRootContactId === item.id ? 'contained' : 'outlined'
              }
              onClick={() => onSelectRootContact(item.id)}
            >
              {typeValues.find(({ id }) => id === item.type_value)?.value}
            </Button>
            <Button
              variant="text"
              style={{ padding: 0, minWidth: 32 }}
              onClick={buildHandleClickCreateUnderRoot(item.id)}
              disabled={selectedRootContactId !== item.id}
            >
              <AddIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        ))}

        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={handleClickCreateRoot}
        >
          Add new call type
        </Button>
      </Box>
    </Toolbar>
  );
};

CollectionActionTreeListRootContactsToolbar.propTypes = {
  selectedRootContactId: PropTypes.number,
  onSelectRootContact: PropTypes.func,
  onClickCreate: PropTypes.func,
  typeValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    }),
  ),
  rootContacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type_value: PropTypes.number,
    }),
  ),
};
