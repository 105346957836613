import { required } from 'react-admin';
import { Field, Form as FinalForm } from 'react-final-form';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

import { phoneNumber } from '../../utils';

export const ChangePhoneNumberDialog = ({ fieldName, isOpened, onClose, onSubmit }) => (
  <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
    <FinalForm onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <DialogTitle id={'form-dialog-title'}>Change phone number</DialogTitle>
          <DialogContent>
            <DialogContentText>Please set new user phone number</DialogContentText>
            <Field name={fieldName} parse={phoneNumber.parser} format={phoneNumber.formatter} validate={required("Can't be empty")}>
              {({ input, meta }) => (
                <TextField
                  error={meta.touched && meta.error}
                  helperText={meta.touched && meta.error}
                  size={'small'}
                  label={'Phone number'}
                  variant={'outlined'}
                  value={input.value}
                  onChange={input.onChange}
                  fullWidth
                />
              )}
            </Field>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color={'primary'}>Cancel</Button>
            <Button type={'submit'} color={'primary'}>Submit</Button>
          </DialogActions>
        </form>
      )}
    />
  </Dialog>
);

ChangePhoneNumberDialog.propTypes = {
  fieldName: PropTypes.string,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
