import { useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Box, Button, Card, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { string, object, bool } from 'prop-types';

import { findImage, ImageZoom } from '../ImageZoom';
import { formatLabel } from '../../utils';

export const DocPhotoCard = ({ title, record, isEditable, isEditMode = true }) => {
  const dataProvider = useDataProvider();
  const [lastUploadTime, setLastUploadTime] = useState(null);

  const handlePhotoSubmit = (e) => dataProvider.postImage(`users/${record.id}/${title}`, e.target.files[0]).then(() => setLastUploadTime(Date.now()));
  return (
    <Card>
      <Box padding={'10px 20px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography variant={'h5'} gutterBottom={false}>{formatLabel(title)}</Typography>
          {title === 'selfie' && (record.similarity_card_and_selfie
            ? <Typography variant={'body1'}>{`Similarity ${record.similarity_card_and_selfie}%`}</Typography>
            : findImage(record.uploaded_files, 'id_card') && findImage(record.uploaded_files, 'selfie') &&
            <Typography variant={'caption'} color={'error'}>{'Face is not recognizable'}</Typography>)}
        </Box>
        {isEditMode && <label htmlFor={`${title}_upload`}>
          <input
            disabled={!isEditable}
            hidden
            type={'file'}
            id={`${title}_upload`}
            onChange={handlePhotoSubmit}
          />
          <Button component={'span'} disabled={!isEditable} color={'secondary'} size={'small'} variant={'outlined'} aria-label={'upload picture'}>
            <CloudUploadIcon />
          </Button>
        </label>}
      </Box>
      <ImageZoom lastUploadTime={lastUploadTime} userId={record.id} isExist={findImage(record.uploaded_files, title)} label={title} />
    </Card>
  );
};

DocPhotoCard.propTypes = {
  title: string,
  record: object,
  isEditable: bool,
  isEditMode: bool,
};
