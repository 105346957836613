import { useState, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, Select, MenuItem, DialogActions, Button,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';

export const AssignCollectorTransitionDialog = ({ isOpened, onClose, onSubmit, collectorId = '' }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [admins, setAdmins] = useState([]);
  const [newCollectorId, setNewCollectorId] = useState(collectorId);
  const isSubmitDisabled = newCollectorId.length === 0;

  useEffect(() => {
    dataProvider.query('admins/collection_specialists', { method: 'GET' })
      .then((res) => {
        if (res.data) {
          const { data } = res;
          setAdmins(data);
        }
      })
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  }, [dataProvider, notify]);

  useEffect(() => {
    if (collectorId && admins.length > 0) {
      const [existingCollectorIdFromList] = admins.filter((admin) => admin.id.toString() === collectorId);
      setNewCollectorId(existingCollectorIdFromList.username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollectorIdChange = (e) => setNewCollectorId(e.target.value);
  const handleCollectorIdSubmit = () => onSubmit(parseFloat(newCollectorId));

  return (
    <Dialog fullWidth maxWidth={'xs'} open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Assign collector</DialogTitle>
      <DialogContent>
        <DialogContentText>Please select collector</DialogContentText>
        {admins.length > 0
          ? <Select
            size={'small'}
            variant={'outlined'}
            id={'assign-collector-select'}
            value={newCollectorId}
            onChange={handleCollectorIdChange}
            fullWidth
          >
            {admins.map((admin, key) =>
              <MenuItem key={key} value={admin.id}>{admin.username}</MenuItem>)}
          </Select>
          : <Skeleton variant={'rect'} />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleCollectorIdSubmit} color={'primary'} disabled={isSubmitDisabled}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

AssignCollectorTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  collectorId: PropTypes.string,
};
