import { Edit, FormWithRedirect } from 'react-admin';
import PropTypes from 'prop-types';

import { ProlongationForm } from './ProlongationForm';

const FormWrapper = ({ save, ...props }) => {
  return (
    <FormWithRedirect
      save={({ upper_limit, lower_limit, discount, active }, ...rest) => {
        save(
          ...[
            {
              upper_limit,
              lower_limit,
              discount,
              active,
              _params: { method: 'PATCH' },
            },
            ...rest,
          ],
        );
      }}
      {...props}
      render={(formProps) => <ProlongationForm formProps={formProps} isCreate={false} {...props}
      />}
    />
  );
};

export const ProlongationEdit = ({ permissions = [], ...props }) => (
  <Edit undoable={false} {...props}>
    <FormWrapper permissions={permissions} {...props} />
  </Edit>
);

ProlongationEdit.propTypes = {
  permissions: PropTypes.array,
};
