import React from 'react';
import PropTypes from 'prop-types';
import {
  useInput, Create, TextInput, SelectInput, SaveButton, FormWithRedirect, required, maxLength, regex, number,
} from 'react-admin';
import {
  makeStyles, Box, Paper, Grid, Divider, Typography, Button, FormControl, InputLabel,
  Select, MenuItem, List, ListItem, IconButton, Checkbox, Input, ListItemText, TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Field } from 'react-final-form';

import { DateTimePickerInput } from '../../input';
import { ROBOCALL_PROVIDERS, ROBOCALL_LANGUAGE } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const loanTypeOptions = ['new', 'repeated'];

const MultipleSelect = ({ title, handleChange, value, options }) => {
  return (
    <div>
      <InputLabel id="demo-mutiple-checkbox-label">{title}</InputLabel>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        fullWidth
        value={value}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => selected && selected.join(', ')}
      >
        {options.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={value.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

MultipleSelect.propTypes = {
  title: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.array,
};

const FormWrapper = ({ save }, props) => {
  return (
    <FormWithRedirect
      {...props}
      save={(data, ...rest) => {
        const options = {
          engine: data.engine,
          engine_data: {
            task_name: data.task_name,
            schedule_start_time: data.schedule_start_time,
            schedule_end_time: data.schedule_end_time,
            language: data.language,
            repeat_interval: Number(data.repeat_interval),
            repeat_number: Number(data.repeat_number),
          },
          conditions: {
            ...data.conditions,
          },
        };
        save(...[{ ...options, _params: { method: 'POST' } }, ...rest]);
      }}
      render={(formProps) => (
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6">Main info</Typography>
                  <Divider />
                  <Box pt={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          source="engine"
                          choices={ROBOCALL_PROVIDERS}
                          validate={[required()]}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          source="task_name"
                          margin="dense"
                          validate={[maxLength(17)]}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box pb={2}>
                          <DateTimePickerInput
                            source="schedule_start_time"
                            inputVariant="filled"
                            margin="dense"
                            label="Schedule start time"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box pb={2}>
                          <DateTimePickerInput
                            source="schedule_end_time"
                            inputVariant="filled"
                            margin="dense"
                            label="Schedule end time"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          source="language"
                          choices={ROBOCALL_LANGUAGE}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          source="repeat_interval"
                          validate={[
                            regex(/^[0-9]+$/, 'Must be only numbers'),
                          ]}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          source="repeat_number"
                          validate={[
                            regex(/^[0-9]+$/, 'Must be only numbers'),
                          ]}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Conditions
                  </Typography>
                  <Divider />
                  <ConditionsInput source="conditions" />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Box pt={1}>
                <SaveButton
                  label="Save"
                  redirect="list"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const ConditionsInput = (props) => {
  const classes = useStyles();
  const {
    input: { value, onChange },
  } = useInput(props);

  const updateKey = (key, newKeyName) => {
    const newValue = {};
    for (const p in value) {
      newValue[p === key ? newKeyName : p] = value[p];
    }
    onChange(newValue);
  };

  const updateValue = (key, v) => {
    const newValue = { ...(value || {}) };
    newValue[key] = v;
    onChange(newValue);
  };

  const deleteKey = (key) => {
    const newValue = { ...(value || {}) };
    delete newValue[key];
    onChange(newValue);
  };

  const composeValidators = (...validators) => (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );
  return (
    <>
      <List>
        {Object.keys(value || {}).map((item, idx) => {
          const options = {
            loan_type: [
              <Grid item xs={5} key={idx}>
                <MultipleSelect
                  value={value[item] || []}
                  handleChange={(e) => updateValue(item, e.target.value)}
                  title={'Value'}
                  options={loanTypeOptions}
                />
              </Grid>,
            ],
            max_dpd: [
              <Grid item xs={5} key={idx}>
                <Field
                  name={'max_dpd'}
                  validate={composeValidators(
                    number('Must be a number'),
                  )}
                >
                  {({ input, meta }) => (
                    <TextField
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      id="filled-line-static"
                      label="Max Dpd"
                      value={input.value || ''}
                      onChange={(e) => {
                        updateValue(item, Number(e.target.value));
                        input.onChange(e.target.value);
                      }}
                      fullWidth
                    />
                  )}
                </Field>
              </Grid>,
            ],
            max_outstanding: [
              <Grid item xs={5} key={idx}>
                <Field
                  name={'max_outstanding'}
                  validate={composeValidators(
                    number('Must be a number'),
                  )}
                >
                  {({ input, meta }) => (
                    <TextField
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      id="filled-line-static"
                      label="Max outstanding"
                      value={input.value || ''}
                      onChange={(e) => {
                        updateValue(item, Number(e.target.value));
                        input.onChange(e.target.value);
                      }}
                      fullWidth
                    />
                  )}
                </Field>
              </Grid>,
            ],
            min_dpd: [
              <Grid item xs={5} key={idx}>
                <Field
                  name={'min_dpd'}
                  validate={composeValidators(
                    number('Must be a number'),
                  )}
                >
                  {({ input, meta }) => (
                    <TextField
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      id="filled-line-static"
                      label="Min Dpd"
                      value={input.value || ''}
                      onChange={(e) => {
                        updateValue(item, Number(e.target.value));
                        input.onChange(e.target.value);
                      }}
                      fullWidth
                    />
                  )}
                </Field>
              </Grid>,
            ],
            min_outstanding: [
              <Grid item xs={5} key={idx}>
                <Field
                  name={'min_outstanding'}
                  validate={composeValidators(
                    number('Must be a number'),
                  )}
                >
                  {({ input, meta }) => (
                    <TextField
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      id="filled-line-static"
                      label="Min outstanding"
                      value={input.value || ''}
                      onChange={(e) => {
                        input.onChange(e.target.value);
                        updateValue(item, Number(e.target.value));
                      }}
                      fullWidth
                    />
                  )}
                </Field>
              </Grid>,
            ],
          };
          return (
            <ListItem key={idx} disableGutters>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Condition</InputLabel>
                    <Select
                      value={item}
                      onChange={(e) => updateKey(item, e.target.value)}
                    >
                      <MenuItem value="loan_type">Loan type</MenuItem>
                      <MenuItem value="max_dpd">Max dpd</MenuItem>
                      <MenuItem value="max_outstanding">
                        Max outstanding
                      </MenuItem>
                      <MenuItem value="min_dpd">Min dpd</MenuItem>
                      <MenuItem value="min_outstanding">
                        Min outstanding
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {options[item]}
                <Grid item xs={1} className={classes.textRight}>
                  <IconButton
                    disabled={props.disabled}
                    color="primary"
                    size="small"
                    onClick={() => deleteKey(item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
      <Button
        disabled={props.disabled}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => onChange({ ...(value || {}), '': '' })}
      >
        Add
      </Button>
    </>
  );
};

ConditionsInput.propTypes = {
  disabled: PropTypes.bool,
};

export const RobocallsCreate = (props) => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
