import { useEffect, useMemo, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import {
  TableSortLabel, TablePagination, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell, Typography, Link,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Error } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { formatCurrency, formatPhoneNumber } from '../../utils';
import { useHandbook } from '../../hooks';
import { Call } from '../Call';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';
import { ListItem } from '../ListItem';

const getContactChainValue = (contactChain, typeName, defaultValue = '---') => {
  const contact = contactChain.find(({ type }) => type === typeName);

  return contact ? contact.value : defaultValue;
};

export const CollectionCallTable = ({ loanId, userId, refreshedAt, permissions }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const { data: phoneTypes } = useHandbook('phone_types');
  const [user, setUser] = useState(null);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('id');
  const [sortOrder, setSortOrder] = useState('DESC');

  const isPromiseOfPayment = !!user?.active_promise_amount && !!user?.active_promise_date;
  const filter = useMemo(() => loanId ? { 'loan.id': loanId } : { 'loan.user.id': userId }, [loanId, userId]);
  useEffect(() => {
    dataProvider
      .getList('collection_contact_calls', {
        filter,
        pagination: { page, perPage },
        sort: { field: sortBy, order: sortOrder },
      })
      .then(({ data, total }) => {
        setData(data);
        setTotal(total);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));

    dataProvider
      .getOne('users', { id: userId })
      .then(({ data }) => setUser(data));
  }, [dataProvider, loanId, userId, filter, page, perPage, sortBy, sortOrder, refreshedAt]);

  if (loading) return <CircularProgress />;

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  const buildHandleClickSort = (attribute) => () => {
    setSortOrder(attribute === sortBy && sortOrder === 'DESC' ? 'ASC' : 'DESC');
    setSortBy(attribute);
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };

  return (
    <>
      {isPromiseOfPayment && <Alert icon={<Error />} color={'error'}>
        <DateTimeBoxComponent label={`Client has active promise of payment: ${formatCurrency(user.active_promise_amount)} on`} inline value={user?.active_promise_date} />
      </Alert>}

      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortBy === 'id'}
                direction={sortOrder.toLowerCase()}
                onClick={buildHandleClickSort('id')}
              >
                ID
              </TableSortLabel>
            </TableCell>
            <TableCell>Collector</TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === 'createdAt'}
                direction={sortOrder.toLowerCase()}
                onClick={buildHandleClickSort('createdAt')}>
                Created at
              </TableSortLabel>
            </TableCell>
            <TableCell>Call</TableCell>
            <TableCell>Contact</TableCell>
            <TableCell>Promise details</TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === 'promiseDate'}
                direction={sortOrder.toLowerCase()}
                onClick={buildHandleClickSort('promiseDate')}>
                Promise to pay
              </TableSortLabel>
            </TableCell>
            <TableCell>Comment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>
                <Link color={'secondary'} disabled={!permissions.includes('CAN_ADMIN_EDIT')} href={`#/admins/${row.collector}`}>{row.collector_username}</Link>
              </TableCell>
              <TableCell>
                <DateTimeBoxComponent value={row?.created_at} />
              </TableCell>
              <TableCell>
                <ListItem justify={'flex-start'} isLast label={'Type:'} value={<b>{getContactChainValue(row.contact_chain, 'Call type')}</b>} />
                <ListItem justify={'flex-start'} isLast label={'Phone:'} value={<b>{phoneTypes.find((phoneType) => phoneType.code === row.phone_type)?.name || 'Unknown type'}</b>} />
                <Call userId={userId} userPhone={row.phone_number}>{formatPhoneNumber(row.phone_number)}</Call>
              </TableCell>
              <TableCell>
                {row.contact_chain.filter((contact) => contact.type !== 'Call type').map((contact) =>
                  <ListItem justify={'flex-start'} key={contact.id} isLast label={<span style={{ whiteSpace: 'nowrap' }}>{`${contact.type}:`}</span>} value={<span style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{`#${contact.id} ${contact.value}`}</span>} />)}
                {row.motivator && <ListItem justify={'flex-start'} isLast label={'Motivator:'} value={<span style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{`#${row.motivator} ${row.motivator_name}`}</span>} />}
              </TableCell>
              <TableCell>
                {!row.promise_date
                  ? <>-</>
                  : <>
                    <ListItem justify={'flex-start'} isLast label={'Status:'} value={<b>{row.promise_status}</b>} />
                    <ListItem justify={'flex-start'} isLast label={'Payment:'} value={<b>{row.promise_payment_status}</b>} />
                    <ListItem justify={'flex-start'} isLast label={'Paid amount:'} value={<b>{formatCurrency(row.promise_paid_amount || 0)}</b>} />
                    <ListItem justify={'flex-start'} isLast label={'Completed at:'} value={<DateTimeBoxComponent inline value={row?.promise_completed_at} />} />
                  </>}
              </TableCell>
              <TableCell>
                {
                  (!row.promise_date && !row.promise_amount)
                    ? <>-</>
                    : <>
                      <DateTimeBoxComponent inline value={row?.promise_date} isShowTime={false} />
                      <Typography variant={'subtitle2'}>{formatCurrency(row.promise_amount)}</Typography>
                    </>}
              </TableCell>
              <TableCell>{row.comment || '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
    </>
  );
};

CollectionCallTable.propTypes = {
  userId: PropTypes.number,
  loanId: PropTypes.number,
  refreshedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  permissions: PropTypes.arrayOf(PropTypes.string),
};
