import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DateTimePicker } from '@material-ui/pickers';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import { timezone } from '../../../../utils';

export const ActivateTransitionDialog = ({ isOpened, onClose, onSubmit }) => {
  const [disbursementDate, setDisbursementDate] = useState(new Date());

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Activate loan.</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, choose disbursement date.</DialogContentText>
        <DateTimePicker fullWidth value={timezone.shift(disbursementDate)}
          onChange={value => setDisbursementDate(timezone.unshift(value))} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={() => onSubmit(disbursementDate)} color="primary" disabled={!disbursementDate}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

ActivateTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
