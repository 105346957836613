export const openIntoNewTab = (url) => {
  const link = document.createElement('a');

  link.href = url;
  link.target = '_blank';

  link.click();

  setTimeout(() => link.remove(), 500);
};
