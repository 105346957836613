import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(() => ({
  text: {
    fontSize: '10px',
    lineHeight: '12px',
    whiteSpace: 'pre-line',
    padding: 0,
  },

  textCollapsed: {
    maxHeight: 60,
    overflow: 'hidden',
  },

  empty: {
    color: '#ccc',
    fontStyle: 'italic',
  },
}));
const CollapsedText = ({ text, placeholder, collapseOnLength }) => {
  const classes = useStyles();

  const [collapsed, setCollapsed] = useState(false);

  if (!text) {
    return <div className={classes.empty}>{placeholder}</div>;
  }

  let textClassList = `${classes.text}`;

  if (text.length > collapseOnLength && !collapsed) {
    textClassList += ` ${classes.textCollapsed}`;
  }

  return (
    <div>
      <div className={textClassList}>{text}</div>

      {text.length > collapseOnLength && (
        <div>
          <Button
            color="primary"
            size='small'
            onClick={() => setCollapsed(!collapsed)}
            endIcon={collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          >
            {collapsed ? 'Hide' : 'Show more'}
          </Button>
        </div>
      )}
    </div>
  );
};

CollapsedText.propTypes = {
  text: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  collapseOnLength: PropTypes.number,
};

CollapsedText.defaultProps = {
  placeholder: '---',
  collapseOnLength: 300,
};

export default CollapsedText;
