import { differenceInMinutes } from 'date-fns';

export const getRequestDuration = (record, palette) => {
  const approvedInMinutes = differenceInMinutes(new Date(), record.resolved_at ? new Date(record.resolved_at) : new Date());

  switch (record?.aventus_decision_engine_state) {
    case 'error':
      return palette.error.light;
    default:
      break;
  }

  switch (record?.state) {
    case 'confirmed':
      if (record?.loan_id && record?.loan_state === 'active') {
        return palette.success.light;
      }
      if (record?.loan_id && record?.loan_state === 'pending') {
        return palette.warningAlt.light;
      }
      break;
    case 'pending':
    case 'processing':
      if (record?.decision_engine_id === 'manual') {
        return record?.verifier_id ? palette.warning.light : palette.warningAlt.light;
      }
      break;
    default:
      break;
  }

  if (record?.state === 'approved' && approvedInMinutes > 5 && !record?.confirmed_at) {
    return palette.error.light;
  }

  return '#fff';
};
