import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  FunctionField,
  ChipField,
  Filter,
  NumberInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      { hasCreate ? (<CreateButton basePath={ basePath } />) : null }
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const UserEventNotificationList = props => (
  <List
    pagination={ <Pagination /> }
    bulkActionButtons={ false }
    sort={{ field: 'id', order: 'DESC' }}
    filters={ <ListFilter /> }
    actions={ <ListActions /> }
    { ...props }
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ChipField source="trigger_event" size="small" />
      <FunctionField source="interrupt_event" render={ (record, key) => record[key] ? <Chip size="small" label={ record[key] } /> : null } />
      <ChipField source="transmitter_id" size="small" />
      <TextField source="template_key" />
      <TextField source="delay" />
      <BooleanField source="is_enabled" />
    </Datagrid>
  </List>
);

export default UserEventNotificationList;
