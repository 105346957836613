import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import {
  CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, IconButton,
} from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import { DateTimeBoxComponent } from '../DateTimeBoxComponent';
import { formatPhoneNumber } from '../../utils';
import { AudioPlayerDialog } from '../dialogs';

export const CallsTable = ({ applicationId, refreshedAt }) => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [record, setRecord] = useState(null);
  const [openPlayer, setOpenPlayer] = useState(false);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getList('verification_calls',
      {
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
        filter: { application: applicationId },
      })
      .then(({ data, total }) => {
        setMatches(data);
        setLoading(false);
        setTotal(total);
      })
      .catch(error => {
        setLoading(false);
        notify(`Error: ${error.message}`, 'error');
      });
  }, [dataProvider, applicationId, refreshedAt, page, perPage, notify]);

  const handleOpenPlayer = (record) => {
    setRecord(record);
    setOpenPlayer(true);
  };

  const handleClosePlayer = () => {
    setOpenPlayer(false);
    setRecord(null);
  };

  if (loading) return <CircularProgress/>;
  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Phone number</TableCell>
            <TableCell>Application Id</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Result</TableCell>
            <TableCell>Audio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {matches.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{formatPhoneNumber(row.phone)}</TableCell>
              <TableCell>{row.application_id}</TableCell>
              <TableCell><DateTimeBoxComponent value={row?.call_date} /></TableCell>
              <TableCell>{row.result}</TableCell>
              <TableCell>
                <IconButton disabled={!row.audio_link} onClick={() => handleOpenPlayer(row)} color={'secondary'}>
                  <PlayCircleOutlineIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handlePerPageChange}
      />
      {openPlayer && <AudioPlayerDialog onClose={handleClosePlayer} record={record} />}
    </>
  );
};

CallsTable.propTypes = {
  applicationId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
