import React, { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  Filter,
  NumberInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  FunctionField,
  useDataProvider,
  useNotify,
  Confirm,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';
import { TitleCloseDialog } from '../../dialogs';
import Pagination from '../../Pagination';
import { marginZeroStyles } from '../../../constants';

import { RobocallsListDetailsTable, RobocallsListOverviewTable, RobocallsListRequestsTable } from './robocallsTables';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const Dialog = ({ open, close, children, title, ...props }) => (
  <TitleCloseDialog
    open={open || false}
    close={close}
    title={title}
    maxWidth={'md'}
    {...props}
  >
    {children}
  </TitleCloseDialog>
);

Dialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  children: PropTypes.element,
  title: PropTypes.string,
};

export const RobocallsList = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions } = props;

  const [overviewDialogOpen, setOverviewDialogOpen] = useState(false);
  const [requestDialogOpen, setRequestDialogOpen] = useState(false);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [stopConfirmDialogOpen, setStopConfirmDialogOpen] = useState(false);
  const [requestId, setRequestId] = useState();

  const [row, setRow] = useState({});

  const stopTheTask = (taskId) => {
    dataProvider
      .query(`robocall_tasks/${taskId}/stop`, {
        method: 'POST',
        body: JSON.stringify({}),
      })
      .then(() => notify('Success: task stopped', 'success'))
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  };

  return (
    <>
      <Confirm
        isOpen={stopConfirmDialogOpen}
        title="Stop the action"
        content={'Are you sure to stop the task?'}
        onConfirm={() => {
          stopTheTask(row?.id);
          setStopConfirmDialogOpen(false);
        }}
        onClose={() => setStopConfirmDialogOpen(false)}
      />
      <Dialog
        open={overviewDialogOpen}
        close={setOverviewDialogOpen}
        title="Overview"
      >
        <RobocallsListOverviewTable row={row} />
      </Dialog>
      <Dialog
        open={requestDialogOpen}
        close={setRequestDialogOpen}
        title="Requests"
        maxWidth={'lg'}
      >
        <RobocallsListRequestsTable
          permissions={permissions}
          row={row}
          setDetailsDialogOpen={setDetailsDialogOpen}
          setRequestDialogOpen={setRequestDialogOpen}
          setRequestId={setRequestId}
        />
      </Dialog>
      <Dialog
        open={detailsDialogOpen}
        close={setDetailsDialogOpen}
        title="Details"
        maxWidth={'lg'}
      >
        <RobocallsListDetailsTable requestId={requestId} />
      </Dialog>
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<ListFilter />}
        actions={<ListActions />}
        {...props}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="engine" />
          <FunctionField render={(record) => <DateTimeBoxComponent value={record?.created_at} />} label="Created at" />
          <FunctionField render={(record) => <DateTimeBoxComponent value={record?.finished_at} />} label="Finished at" />
          <FunctionField render={(record) => <DateTimeBoxComponent value={record?.scheduled_at} />} label="Scheduled at" />
          <TextField source="engine_data.repeat_interval" label="Repeat interval" />
          <TextField source="engine_data.repeat_number" label="Repeat number" />
          <TextField source="engine_data.task_name" label="Task name" />
          <ChipField source="state" size="small" />
          <FunctionField
            render={(row) => {
              return (
                <Box>
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setRow(row);
                        setRequestDialogOpen(true);
                      }}
                    >
                      Requests
                    </Button>
                  </Box>
                  <Box pt={1}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setRow(row);
                        setOverviewDialogOpen(true);
                      }}
                    >
                      Overview
                    </Button>
                  </Box>
                  <Box pt={1}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setRow(row);
                        setStopConfirmDialogOpen(true);
                      }}
                      disabled={(() => {
                        if (permissions.indexOf('CAN_ROBOCALL_EDIT') === -1) {
                          return true;
                        }
                        return !(row?.state === 'pending' || row?.state === 'processing');
                      })()}
                    >
                      Stop
                    </Button>
                  </Box>
                </Box>
              );
            }}
            label="Actions"
          />
        </Datagrid>
      </List>
    </>
  );
};

RobocallsList.propTypes = {
  permissions: PropTypes.array,
};
