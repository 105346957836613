import { Datagrid, FunctionField, List, TextField, useDataProvider, useNotify, useRefresh } from 'react-admin';
import React, { useState } from 'react';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

import { Call } from '../../Call';
import { UserListActions } from '../user';
import Pagination from '../../Pagination';
import { CALL_CENTER_USER_STATUS_UNSIGNED_REQUESTS, formatPhoneNumber } from '../../../utils';
import { CallCenterLogTable } from '../../tables';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

import { assignEssence, changeStatus } from './actions';
import { DropdownDialog, LogDialog } from './dialogs';

export const CallCenterListUnsignedRequests = ({ statefilterchoices = [], listfilters: ListFilters, permissions, ...props }) => {
  const theme = useTheme();
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [refreshedAt, setRefreshedAt] = useState();
  const [openLogDialog, setOpenLogDialog] = useState(false);
  const [openDropdownDialog, setOpenDropdownDialog] = useState(false);
  const [loanIdLog, setLoanIdLog] = useState();
  const [loanIdStatus, setLoanIdStatus] = useState();
  return (
    <>
      <LogDialog open={openLogDialog} close={setOpenLogDialog} title='Request status log'>
        <CallCenterLogTable
          refreshedAt={refreshedAt}
          filter={{ 'application.id': loanIdLog, context: 'application_unsigned_applications' }}
          isDialog
        />
      </LogDialog>
      <DropdownDialog
        title='Change request status'
        isOpened={openDropdownDialog}
        onClose={() => setOpenDropdownDialog(state => !state)}
        onSubmit={userStatus =>
          changeStatus(
            userStatus,
            dataProvider,
            notify,
            setOpenDropdownDialog,
            refresh,
            'phone_calls',
            {
              application_id: loanIdStatus,
              result: userStatus,
              context: 'application_unsigned_applications',
            },
          )}
        selectOptions={CALL_CENTER_USER_STATUS_UNSIGNED_REQUESTS}
      />
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        actions={<UserListActions />}
        filters={<ListFilters statefilterchoices={statefilterchoices} />}
        permissions={permissions}
        {...props}
      >
        <Datagrid rowClick="show" rowStyle={(i) => {
          let background = theme.palette.background.paper;
          const smsTime = new Date(i.resolved_at);
          const nowTime = new Date();
          const operator = i.last_phone_calls_by_context?.application_unsigned_applications?.operator;
          const result = i.last_phone_calls_by_context?.application_unsigned_applications?.result;
          const min = Math.floor((nowTime - smsTime) / 60000);
          if (min > 15 && operator === null) {
            background = red[300];
          }
          if (min > 60 && (result === 'Is thinking' || result === 'Not answered' || result === 'Going to sign')) {
            background = red[300];
          }
          if (result === 'Is not interested') {
            background = orange[300];
          }
          if (min < 60 && result === 'Is thinking') {
            background = yellow[300];
          }
          if (min < 60 && result === 'Going to sign') {
            background = green[300];
          }
          return {
            backgroundColor: background,
          };
        }}>
          <FunctionField label='Request id / Created at'
            render={(record) => <DateTimeBoxComponent label={record?.id} inline value={record?.created_at} />} />
          <FunctionField label="Name" render={({ user_id, user_first_name = '', user_last_name = '' }) => {
            return <Link onClick={e => e.stopPropagation()}
              href={'#users/' + user_id}>{`${user_first_name} ${user_last_name} #${user_id}`}</Link>;
          }} />,
          <FunctionField label="Phone number"
            render={({ user_phone_number, user_id }) => <Call userId={user_id} userPhone={user_phone_number}>{formatPhoneNumber(user_phone_number)} </Call>} />
          <TextField source="user_id" label='User id' />
          <FunctionField
            onClick={e => e.stopPropagation()}
            label="Status"
            render={({ last_phone_calls_by_context, id }) => {
              if (last_phone_calls_by_context?.application_unsigned_applications?.operator) {
                return (
                  <Box>
                    <Box>
                      <Button
                        size="small"
                        variant='outlined'
                        onClick={() => {
                          setLoanIdStatus(id);
                          setOpenDropdownDialog(state => !state);
                        }}
                        disabled={permissions.indexOf('CAN_PHONE_CALL_EDIT') === -1}
                      >Change</Button>
                    </Box>
                    <Box mt={1}>
                      <Button
                        size="small"
                        variant='outlined'
                        onClick={() => {
                          setLoanIdLog(id);
                          setRefreshedAt(new Date().valueOf());
                          setOpenLogDialog(state => !state);
                        }}
                        disabled={permissions.indexOf('CAN_PHONE_CALL_VIEW') === -1}
                      >log</Button>
                    </Box>
                  </Box>
                );
              } else {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      assignEssence(
                        dataProvider,
                        notify,
                        refresh,
                        'phone_call_application_assigns',
                        { application_id: id, context: 'application_unsigned_applications' },
                      );
                    }}
                    disabled={permissions.indexOf('CAN_PHONE_CALL_EDIT') === -1}
                  >
                    Assign
                  </Button>
                );
              }
            }
            }
          />
          <FunctionField label='Operator / assigning time' render={(record) =>
            <DateTimeBoxComponent
              label={record?.last_phone_calls_by_context?.application_unsigned_applications?.operator || 'not selected'}
              value={record?.last_phone_calls_by_context?.application_unsigned_applications?.called_at}
            />} />
          <FunctionField label='Min PALC' render={({ last_phone_calls_by_context }) => {
            const calledAt = last_phone_calls_by_context?.application_unsigned_applications?.called_at;
            const changeTime = new Date(calledAt);
            const nowTime = new Date();
            if (calledAt) {
              return Math.floor((nowTime - changeTime) / 60000) + ' min';
            } else {
              return 0;
            }
          }}
          />
          <FunctionField label='Min paseed' render={({ resolved_at }) => {
            const smsTime = new Date(resolved_at);
            const nowTime = new Date();
            const min = Math.floor((nowTime - smsTime) / 60000);
            return min + ' min';
          }}
          />
          <FunctionField label='Last call result'
            render={({ last_phone_calls_by_context }) => last_phone_calls_by_context?.application_unsigned_applications?.result} />
          <FunctionField label='Loan sum requested / approved' render={({
            requested_principal,
            approved_principal,
          }) => `${requested_principal} / ${approved_principal}`} />
          <FunctionField label='Loan term requested / approved'
            render={({ requested_tenor, approved_tenor }) => `${requested_tenor} / ${approved_tenor}`} />
          <FunctionField render={(record) => <Link href={`admin#/unsigned_requests/${record.id}/show`} underline="none"
            target="_blank" rel="noreferrer"><IconButton
              onClick={(e) => e.stopPropagation()}><OpenInNewIcon fontSize="small" /></IconButton></Link>}
          key='button_tab' />
        </Datagrid>
      </List>
    </>
  );
};

CallCenterListUnsignedRequests.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
};
