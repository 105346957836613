import { cloneElement } from 'react';
import { sanitizeListRestProps, TopToolbar, useListContext } from 'react-admin';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

export const ManualVerificationListActions = ({ exporter, filters, maxResults, onLegendToggle, isLegendShow, ...rest }) => {
  const { resource, displayedFilters, filterValues, showFilter } = useListContext();
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <Button color={'primary'} onClick={onLegendToggle}>{isLegendShow ? 'Hide legend' : 'Show legend'}</Button>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    </TopToolbar>
  );
};

ManualVerificationListActions.propTypes = {
  onLegendToggle: PropTypes.func,
  isLegendShow: PropTypes.bool,
  filters: PropTypes.element,
  exporter: PropTypes.any,
  maxResults: PropTypes.any,
};
