import { makeStyles } from '@material-ui/core/styles';

export const useDialogStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  pl02: {
    paddingLeft: theme.spacing(0.2),
  },
  modalMove: {
    cursor: 'move',
  },
  w100: {
    width: '100%',
  },
  formControl: {
    width: '100%',
    margin: '0 0 8px',
  },

  modalTitle: {
    cursor: 'move',
  },

  modalHorizontalPadding: {
    paddingLeft: 12,
    paddingRight: 12,
  },

  colorTransparent: {
    color: 'transparent',
  },

  nonClickableBackdrop: {
    pointerEvents: 'none',

    '& .MuiPaper-root': {
      pointerEvents: 'auto',
    },
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: 400,
  },
  title: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
}));
