import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';

export const AssignVerifierTransitionDialog = ({ isOpened, onClose, onSubmit }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [admins, setAdmins] = useState();
  const [verifierId, setVerifierId] = useState();

  useEffect(() => {
    isOpened && dataProvider.query('admins/verifiers?role_permission=CAN_BE_APPLICATION_VERIFIER', { method: 'GET' })
      .then(({ data }) => {
        setAdmins(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, isOpened]);

  if (loading === false && error) {
    notify(`Error: ${error.message}`, 'error');
  }

  const handleSubmit = () => onSubmit(verifierId);

  return (
    <Dialog fullWidth open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Assign verifier</DialogTitle>
      <DialogContent>
        <DialogContentText>Please select verifier</DialogContentText>
        {loading
          ? <CircularProgress />
          : <Select
            variant={'outlined'}
            id={'assign-verifier-select'}
            value={verifierId || ''}
            onChange={e => setVerifierId(e.target.value)}
            fullWidth
          >
            {admins.map(admin => <MenuItem key={admin.id} value={admin.id}>{admin.username}</MenuItem>)}
          </Select>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleSubmit} color={'primary'} disabled={!verifierId}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

AssignVerifierTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
