import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl,
  Grid, InputLabel, MenuItem, Select, TextField,
} from '@material-ui/core';

import {
  symbolsValidator,
  phoneNumber as phoneNumberValidator,
  CONTACT_STATUS_LIST,
  CONTACT_VALIDATION_STATE_LIST,
} from '../../utils';
import { useHandbook } from '../../hooks';

export const EditUserContactsDialog = ({ isOpened, onClose, onSubmit, permissions, data = {} }) => {
  const [phoneNumber, setPhoneNumber] = useState(phoneNumberValidator.formatter(data.phone_number));
  const [name, setName] = useState(data.name);
  const [phoneType, setPhoneType] = useState(data.type);
  const [phoneRelation, setPhoneRelation] = useState(data.relation);
  const [phoneSource, setPhoneSource] = useState(data.source);
  const [status, setStatus] = useState(data.status);
  const [validationState, setValidationState] = useState(data.validation_state);
  const [note, setNote] = useState(data.note);
  const [errors, setErrors] = useState({});

  const { data: contactRelationships } = useHandbook('phone_relations');
  const { data: phoneTypes } = useHandbook('phone_types');
  const { data: phoneSources } = useHandbook('phone_sources');

  const isSubmitDisabled = !phoneNumber || !name || !phoneType || !phoneSource || errors.name || errors.phone || errors.status || errors.validation_state;
  const validate = useCallback(
    (type, value) => {
      if (type === 'phone' && !phoneNumberValidator.validator(phoneNumberValidator.parser(value))) {
        setErrors((prevState) => {
          return {
            ...prevState,
            [type]: 'Invalid phone number',
          };
        });
      } else if (type === 'name' && symbolsValidator(value, 2, 255) !== undefined) {
        setErrors((prevState) => {
          return {
            ...prevState,
            [type]: symbolsValidator(value, 2, 255),
          };
        });
      } else {
        setErrors((prevState) => {
          return {
            ...prevState,
            [type]: undefined,
          };
        });
      }
    },
    [],
  );

  const handlePhoneNumberChange = (e) => setPhoneNumber(phoneNumberValidator.formatter(phoneNumberValidator.parser(e.target.value)));
  const handlePhoneNumberBlur = (e) => validate('phone', e.target.value);
  const handleNameChange = (e) => setName(e.target.value);
  const handleNameValidate = (e) => validate('name', e.target.value);
  const handlePhoneTypeChange = (e) => {
    setPhoneType(e.target.value);
    if (e.target.value !== 'contact_person') {
      setPhoneRelation(null);
    }
  };
  const handlePhoneSourceChange = (e) => setPhoneSource(e.target.value);
  const handleContactRelationChange = (e) => setPhoneRelation(e.target.value);
  const handleStatusChange = (e) => setStatus(e.target.value);
  const handleValidationChange = (e) => setValidationState(e.target.value);
  const handleRenderValidation = (value) => CONTACT_VALIDATION_STATE_LIST.find(({ code }) => code === value)?.name || 'None';
  const handleNoteChange = (e) => setNote(e.target.value);
  const handleSubmit = () => onSubmit(phoneNumberValidator.parser(phoneNumber), name, phoneType, note, phoneRelation, phoneSource, status, validationState);

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>{Object.keys(data).length > 0 ? 'Edit' : 'Add'} user contacts</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant={'outlined'}
              fullWidth
              label={'Phone number'}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              autoComplete={'false'}
              onBlur={handlePhoneNumberBlur}
              name={'phone'}
              error={errors.phone !== undefined}
              helperText={errors.phone}
              disabled={Object.keys(data).length > 0}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant={'outlined'}
              name={'name'}
              error={errors.name !== undefined}
              helperText={errors.name}
              fullWidth
              label={'Name'}
              value={name}
              onChange={handleNameChange}
              onBlur={handleNameValidate} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth variant={'outlined'}>
              <InputLabel id={'locale-select-label'}>Contact Phone Type</InputLabel>
              <Select
                label={'Contact Phone Type'}
                variant={'outlined'}
                labelId={'locale-select-label'}
                value={phoneType}
                disabled={Object.keys(data).length > 0}
                onChange={handlePhoneTypeChange}
              >
                {phoneTypes.map((i) => <MenuItem key={i.code} value={i.code}>{i.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl variant={'outlined'} fullWidth>
              <InputLabel id={'locale-select-label'}>Contact Phone Source</InputLabel>
              <Select
                variant={'outlined'}
                label={'Contact Phone Source'}
                labelId={'locale-select-label'}
                value={phoneSource}
                disabled={Object.keys(data).length > 0}
                onChange={handlePhoneSourceChange}
              >
                {phoneSources.map((i) => <MenuItem key={i.code} value={i.code}>{i.name}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          {phoneType === 'contact_person' &&
            <Grid item xs={12} sm={12}>
              <FormControl variant={'outlined'} fullWidth>
                <InputLabel id={'locale-select-label'}>Contact Phone Relation</InputLabel>
                <Select
                  label={'Contact Phone Relation'}
                  labelId={'locale-select-label'}
                  value={phoneRelation}
                  onChange={handleContactRelationChange}
                >
                  {contactRelationships.map((i) => <MenuItem key={i.code} value={i.code}>{i.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>}
          {permissions.indexOf('CAN_PHONE_BOOK_EDIT_STATUS') !== -1 && (
            <Grid item xs={12} sm={12}>
              <FormControl variant={'outlined'} fullWidth>
                <InputLabel id={'add-user-contact-status-label'}>Status</InputLabel>
                <Select
                  label={'Status'}
                  labelId={'add-user-contact-status-label'}
                  value={status}
                  onChange={handleStatusChange}
                >
                  {CONTACT_STATUS_LIST.map(({ code, name }) => (
                    <MenuItem key={code} value={code}>{name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {permissions.indexOf('CAN_PHONE_BOOK_EDIT_STATUS') !== -1 && (
            <Grid item xs={12} sm={12}>
              <FormControl variant={'outlined'} fullWidth>
                <InputLabel id={'add-user-contact-validation-state-label'}>Validation</InputLabel>
                <Select
                  label={'Validation'}
                  labelId={'add-user-contact-validation-state-label'}
                  value={validationState}
                  onChange={handleValidationChange}
                  renderValue={handleRenderValidation}
                >
                  {CONTACT_VALIDATION_STATE_LIST.map(({ code, name }) => (
                    <MenuItem key={code} value={code}>{name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <TextField
              variant={'outlined'}
              multiline
              fullWidth
              label={'Note'}
              value={note}
              onChange={handleNoteChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={isSubmitDisabled}
        >Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

EditUserContactsDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  permissions: PropTypes.array,
  isEditMode: PropTypes.bool,
  data: PropTypes.shape({
    phone_number: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    relation: PropTypes.string,
    source: PropTypes.string,
    status: PropTypes.string,
    validation_state: PropTypes.string,
    note: PropTypes.string,
  }),
};
