import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import {
  Filter,
  NumberInput,
  SelectInput,
  TopToolbar,
  useListContext,
  List,
  FunctionField,
  ChipField,
  TextField,
  NumberField,
  Datagrid,
  sanitizeListRestProps,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import { snakeToHuman } from '../../../utils';
import { DateFilterInput } from '../../filters';
import { marginZeroStyles } from '../../../constants';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();

  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User ID" source="user->id" />
      <NumberInput label="Loan Id" source="loan->id" />
      <NumberInput label="Amount (greater than)" source="amount|gte" />
      <NumberInput label="Amount (less than)" source="amount|lte" />
      <SelectInput label="Status" source="state" choices={[
        { id: 'processed', name: 'Processed' },
        { id: 'pending', name: 'Pending' },
        { id: 'error', name: 'Error' },
        { id: 'cancelled', name: 'Cancelled' },
      ]} />
      <SelectInput label="Payment type" source="payment_type" choices={[
        { id: 'full_repayment', name: 'Full repayment' },
        { id: 'partial_repayment', name: 'Partial repayment' },
        { id: 'repayment_for_extension', name: 'Repayment for extension' },
      ]} />
      <SelectInput label="Provider" source="gateway" choices={[
        // TODO: get vals from be
        { id: 'manual', name: 'Manual' },
      ]} />
      <DateFilterInput label="Received after" source="received_at|after" after/>
      <DateFilterInput label="Received before" source="received_at|before" before/>
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

export const CollectionIncomesList = props => {
  return (
    <List
      pagination={ <Pagination /> }
      bulkActionButtons={ false }
      sort={{ field: 'id', order: 'DESC' }}
      filters={ <ListFilter /> }
      actions={<ListActions />}
      {...props}
    >
      <Datagrid>
        <TextField source="id" />
        <ChipField size="small" source="state" />
        <FunctionField label="Client" render={({ user_id }) => {
          return <Link onClick={e => e.stopPropagation()} href={'#users/' + user_id}>{`#${user_id}`}</Link>;
        }} />,
        <FunctionField label="Loan" render={({ loan_id }) => {
          return <Link onClick={e => e.stopPropagation()} href={`#loans/${loan_id}/show`}>{`#${loan_id}`}</Link>;
        }} />,
        <NumberField source="amount" options={{ style: 'currency', currency: 'TZS' }} key='amount' />,
        <FunctionField label="Payment type" source="payment_type" render={ (record, key) => record[key] ? snakeToHuman(record[key]) : null } />
        <ChipField size="small" source="gateway" label="Provider" />
        <FunctionField label="Received at" source="received_at" render={(record) => <DateTimeBoxComponent value={record?.received_at} />} />
      </Datagrid>
    </List>
  );
};
