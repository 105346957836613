import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider } from 'react-admin';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  CircularProgress,
  Chip,
  Link,
} from '@material-ui/core';

import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

export const RequestPaymentTable = ({ userId = null, refreshedAt }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [requests, setRequests] = useState([]);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    dataProvider
      .getList('functional_link_logs', {
        filter: {
          ...(userId ? { 'user.id': userId } : {}),
        },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setRequests(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, page, perPage]);

  const handleRowsPerPage = e => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };

  if (loading) return <CircularProgress />;

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  const getLinkWithUTM = (slug, utm_source) => [process.env.REACT_APP_FRONT_HOST, slug, `?utm_source=${utm_source}`].join('/');

  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Link type</TableCell>
            <TableCell>UTM source</TableCell>
            <TableCell>Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requests.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>
                <DateTimeBoxComponent value={row?.redirected_at} />
              </TableCell>
              <TableCell>
                <Chip size={'small'} label={row?.functional_link?.type} />
              </TableCell>
              <TableCell>
                <Chip size={'small'} label={row?.utm_source} />
              </TableCell>
              <TableCell>
                <Link href={getLinkWithUTM(row?.slug, row?.utm_source)}>
                  {getLinkWithUTM(row?.slug, row?.utm_source)}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handleRowsPerPage}
      />
    </>
  );
};

RequestPaymentTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
};
