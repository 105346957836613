import { Filter, NumberInput, SelectInput, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { useHandbook } from '../../../hooks';
import { DateFilterInput, QuickFilterInput } from '../../filters';
import { CALL_CENTER_USER_STATUS_UNCOMPLETED_REG, formatChoices } from '../../../utils';
import { AsyncSelectInput } from '../../input';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const CallCenterListFilter = (props) => {
  const { choices: employmentTypeChoices } = useHandbook('employments');
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput variant={'outlined'} label={'Id'} source={'id'} alwaysOn />
      <NumberInput variant={'outlined'} label={'Registration step'} source={'registration_step'} />
      <DateFilterInput variant={'outlined'} label={'Created before'} source={'created_at|before'} before />
      <DateFilterInput variant={'outlined'} label={'Created after'} source={'created_at|after'} after />
      <TextInput variant={'outlined'} label={'First name'} source={'profile->first_name'} />
      <TextInput variant={'outlined'} label={'Last name'} source={'profile->last_name'} />
      <TextInput variant={'outlined'} label={'Document number'} source={'userDocuments->number'} />
      <NumberInput variant={'outlined'} label={'Phone number'} source={'phone_book_number'} />
      <TextInput variant={'outlined'} label={'Email'} source={'profile->email'} />
      <TextInput variant={'outlined'} label={'Account number'} source={'bankAccount->account_number'} />
      <SelectInput variant={'outlined'} label={'Empl. type'} source={'employment->type->code'} choices={employmentTypeChoices} />
      <QuickFilterInput variant={'outlined'} source={'sleeping_clients'} label={'Sleeping clients'} defaultValue={true} />
      <SelectInput variant={'outlined'} label={'Last call result'} source={'phone_call_last_result'}
        choices={formatChoices(CALL_CENTER_USER_STATUS_UNCOMPLETED_REG)} />
      <AsyncSelectInput
        label={'Affiliate'}
        source={'affiliate'}
        query={{
          resource: 'affiliates',
          payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} },
        }}
        mapper={({ id, name }) => ({ value: id, name })}
      />
      <AsyncSelectInput
        label={'Operator'}
        source={'phone_call_operator'}
        query={{
          resource: 'admins/phone_call_operators',
          payload: { pagination: {}, sort: {}, filter: {} },
        }}
        mapper={({ id, username }) => ({ value: id, name: username })}
        type={'getList'}
        additionalData={[{ id: 'null', username: 'Not assign' }]}
      />
      <NumberInput variant={'outlined'} label={'Age less'} source={'age|lt'} />
      <NumberInput variant={'outlined'} label={'Age greater'} source={'age|gt'} />
      <DateFilterInput variant={'outlined'} label={'Assigned before'} source={'phone_call_assigned_at|before'} before />
      <DateFilterInput variant={'outlined'} label={'Assigned after'} source={'phone_call_assigned_at|after'} after />
    </Filter>
  );
};
