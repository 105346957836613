import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DeleteButton, Edit, FormWithRedirect, maxLength, SaveButton, SelectInput, TextInput, useDataProvider, useNotify, Loading,
} from 'react-admin';
import {
  makeStyles,
  Box,
  Grid,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Button,
  ButtonGroup, Card, CardHeader, CardContent, useTheme,
} from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';

import { BaseAudienceFilterPreviewDialog } from '../../dialogs';
import { AUDIENCE_FILTERS_IDS, CHOICES, formatChoices, formatFilter } from '../../../utils';

import { Filter } from './Filter';

const useStyles = makeStyles(() => ({
  btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 15,
  },
  w100: {
    width: '100%',
  },
  chip: {
    fontSize: '14px',
    backgroundColor: '#c2c2c1',
  },
  selectedMenuItem: {
    backgroundColor: '#c2c2c1',
  },
}));

const FormWrapper = ({ permissions, filters, save, record = {}, ...props }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const isEditable = permissions.indexOf('CAN_MASS_SENDING_AUDIENCE_EDIT') !== -1;
  const [activeType, setActiveType] = useState(record.type || '');
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [sortedFilters, setSortedFilters] = useState(null);
  const [sortedFilterChoices, setSortedFilterChoices] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFiltersStr, setSelectedFiltersStr] = useState([]);
  const theme = useTheme();

  const formatCapitalizeIdToTitle = (str) => {
    const modedStr = str.charAt(0).toUpperCase() + str.slice(1);
    return modedStr.replaceAll('_', ' ');
  };

  const handleChange = (event) => {
    const targetValue = event.target.value;
    const lastAddedVal = targetValue[targetValue.length - 1].id;
    if (selectedFiltersStr.includes(lastAddedVal)) {
      setSelectedFiltersStr((prevStrs) => prevStrs.filter((filter) => filter !== lastAddedVal));
      setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter.id !== lastAddedVal));
    } else {
      setSelectedFiltersStr((prevStrs) => [...prevStrs, lastAddedVal]);
      const newValue = sortedFilters.filter((filter) => lastAddedVal === filter.id);
      setSelectedFilters((prevFilters) => [...prevFilters, ...newValue]);
    }
  };

  const matchFilterType = (filter, form, filterIndex, ...rest) => {
    const matchFilter = sortedFilterChoices.filter((filterItem) => filter.id === filterItem.id);
    return <Filter
      form={form}
      filterIndex={filterIndex}
      type={matchFilter[0]?.type}
      key={filter.id}
      filter={filter}
      id={filter.id}
      choice={formatChoices(filters.find((i) => i.id === filter.id)?.description?.allowed_values || [])}
      {...rest}
    />;
  };

  const getTransformFilters = (filters) => {
    let filtersIds = {};

    AUDIENCE_FILTERS_IDS.forEach(({ id, type }) => {
      filtersIds = { ...filtersIds, [id]: formatFilter(type, id, filters[id]) };
    });

    const newFilters = [];
    selectedFiltersStr.forEach((filter) => newFilters.push(filtersIds[filter]));
    return newFilters;
  };

  const getFormDate = (currentDate) => {
    const year = currentDate.getFullYear();
    const mounth = currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth() + 1;
    const day = currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate();

    return `${year}-${mounth}-${day}T03:30`;
  };

  const handlePreviewDialogClose = () => setIsPreviewDialogOpen(false);

  useEffect(() => {
    setSortedFilters(filters.filter(({ supported_audience_types }) => supported_audience_types.includes(activeType)));
  }, [filters, activeType]);

  useEffect(() => {
    if (record.filters.length > 0) {
      setSelectedFilters(record.filters);
      setSelectedFiltersStr(record.filters?.map((j) => j.id));
    }
  }, [record.filters]);

  useEffect(() => {
    const sortedFilterChoicesStorage = [];
    sortedFilters?.forEach((filterItem) => sortedFilterChoicesStorage.push({ name: formatCapitalizeIdToTitle(filterItem.id), id: filterItem.id, type: filterItem.description.type }));
    setSortedFilterChoices(sortedFilterChoicesStorage);
  }, [sortedFilters]);

  if (!sortedFilters) return <Loading />;

  return (
    <FormWithRedirect
      save={(
        {
          registration_date,
          name,
          type,
          user_is_blocked,
          last_application_rejection_reason,
          last_application_state,
          last_call_result_promo,
          last_call_result_unfinished_registration,
          last_call_result_unsigned_request,
          last_loan_state,
          registration_step,
          closed_loan_number,
          days_since_last_rejected_application,
          days_since_last_user_unlock,
          extension_number,
          last_loan_max_dpd,
          user_days_before_birthday,
          days_without_loan,
          last_moratorium_days,
          user_in_blacklist,
          last_sent_sms_template,
          days_since_last_sms_sent,
          last_user_activity,
          last_loan_has_active_promo_code,
          last_loan_sequence_number,
          last_loan_term,
          last_loan_type,
          user_has_do_not_call_marker,
          registration_date_from_today,
          last_loan_collection_score_group,
          last_loan_collection_score_priority,
          dpd,
          collection_group,
          last_loan_tenor,
          collector,
          days_from_the_last_call_client,
          days_from_the_last_call_tpc,
          days_from_the_last_call,
          days_from_last_ptp_date,
          ptp_stage,
          total,
          principal,
          last_contact_type_client_phone,
          last_contact_type_client_promise_phone,
          days_from_last_payment_date,
          last_logged_into_personal_account_general,
          last_logged_into_personal_account,
          last_logged_into_short_personal_account,
          last_logged_payment_total_link_personal_account,
          last_logged_payment_prolong_link_personal_account,
          external_agency,
          days_from_the_last_pay,
          income_for_loan,
          user_age,
          amount_loan,
          closed_loan,
          days_before_due_date,
          days_after_PTP_date,
          days_before_PTP_date,
          user_gender,
          count_of_broken_ptp,
          collection_result,
          days_after_last_contact_adding,
          max_dpd_ever,
          rpc_ever,
        },
        ...rest
      ) => {
        const currentDate = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        const newRegistrationDate = registration_date?.values
          ? registration_date
          : {
            ...registration_date,
            values: {
              from: getFormDate(yesterday),
              to: getFormDate(currentDate),
            },
          };

        const params = {
          name,
          type,
          filters: getTransformFilters({
            registration_date: newRegistrationDate,
            user_is_blocked,
            last_application_rejection_reason,
            last_application_state,
            last_call_result_promo,
            last_call_result_unfinished_registration,
            last_call_result_unsigned_request,
            last_loan_state,
            registration_step,
            closed_loan_number,
            days_since_last_rejected_application,
            days_since_last_user_unlock,
            extension_number,
            last_loan_max_dpd,
            user_days_before_birthday,
            days_without_loan,
            last_moratorium_days,
            user_in_blacklist,
            last_sent_sms_template,
            days_since_last_sms_sent,
            last_user_activity,
            last_loan_has_active_promo_code,
            last_loan_sequence_number,
            last_loan_term,
            last_loan_type,
            user_has_do_not_call_marker,
            registration_date_from_today,
            last_loan_collection_score_group,
            last_loan_collection_score_priority,
            dpd,
            collection_group,
            last_loan_tenor,
            collector,
            days_from_the_last_call_client,
            days_from_the_last_call_tpc,
            days_from_the_last_call,
            days_from_last_ptp_date,
            ptp_stage,
            total,
            principal,
            last_contact_type_client_phone,
            last_contact_type_client_promise_phone,
            days_from_last_payment_date,
            last_logged_into_personal_account_general,
            last_logged_into_personal_account,
            last_logged_into_short_personal_account,
            last_logged_payment_total_link_personal_account,
            last_logged_payment_prolong_link_personal_account,
            external_agency,
            days_from_the_last_pay,
            income_for_loan,
            user_age,
            amount_loan,
            closed_loan,
            days_before_due_date,
            days_after_PTP_date,
            days_before_PTP_date,
            user_gender,
            count_of_broken_ptp,
            collection_result,
            days_after_last_contact_adding,
            max_dpd_ever,
            rpc_ever,
          }),
        };
        params.filters.some(filter => filter.active)
          ? save(...[{ ...params, _params: { method: 'PATCH' } }, ...rest])
          : notify(
            'Error:no filters are selected for this audience', 'error',
          );
      }}
      {...props}
      render={(formProps) => {
        return (
          <Grid container justifyContent={'center'} spacing={4}>
            <Grid item xs={12} sm={10}>
              <Card>
                <CardHeader title={'Update audience'} />
                <Divider />
                <Card>
                  <CardContent p={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextInput variant={'outlined'} className={classes.mt4} source='name' validate={[maxLength(255)]} fullWidth />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          variant={'outlined'}
                          defaultValue={props.record?.type}
                          source='type'
                          choices={CHOICES}
                          onChange={(e) => setActiveType(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <InputLabel>Filters</InputLabel>
                        <Select
                          variant={'outlined'}
                          className={classes.w100}
                          multiple
                          value={selectedFiltersStr}
                          onChange={handleChange}
                          disabled={!activeType}
                          renderValue={() => selectedFiltersStr.map((id) =>
                            <Chip key={id} className={classes.chip} label={formatCapitalizeIdToTitle(id)} />)}>
                          {sortedFilterChoices.map((filter, index) =>
                            <MenuItem style={{ backgroundColor: selectedFiltersStr.includes(filter.id) ? theme.palette.primary.light : '' }} key={filter.id} value={filter}>
                              {filter.name}
                            </MenuItem>,
                          )}
                        </Select>
                        {selectedFilters.length > 0 &&
                        selectedFilters.map((filter, index) =>
                          <Box key={index} p={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                            {matchFilterType(filter, formProps.form, index)}
                          </Box>)}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <CardContent>
                  <ButtonGroup>
                    <SaveButton
                      icon={<ListIcon />}
                      label='Save and back to list'
                      variant={'contained'}
                      color={'primary'}
                      saving={formProps.saving}
                      handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    />
                    <Button
                      className={classes.btn}
                      variant='outlined'
                      color='primary'
                      onClick={() => {
                        const {
                          registration_date,
                          user_is_blocked,
                          last_application_rejection_reason,
                          last_application_state,
                          last_call_result_promo,
                          last_call_result_unfinished_registration,
                          last_call_result_unsigned_request,
                          last_loan_state,
                          registration_step,
                          closed_loan_number,
                          days_since_last_rejected_application,
                          days_since_last_user_unlock,
                          extension_number,
                          last_loan_max_dpd,
                          user_days_before_birthday,
                          days_without_loan,
                          last_moratorium_days,
                          user_in_blacklist,
                          last_sent_sms_template,
                          days_since_last_sms_sent,
                          last_user_activity,
                          last_loan_has_active_promo_code,
                          last_loan_sequence_number,
                          last_loan_term,
                          last_loan_type,
                          user_has_do_not_call_marker,
                          registration_date_from_today,
                          last_loan_collection_score_group,
                          last_loan_collection_score_priority,
                          dpd,
                          collection_group,
                          last_loan_tenor,
                          collector,
                          days_from_the_last_call_client,
                          days_from_the_last_call_tpc,
                          days_from_the_last_call,
                          days_from_last_ptp_date,
                          ptp_stage,
                          total,
                          principal,
                          last_contact_type_client_phone,
                          last_contact_type_client_promise_phone,
                          days_from_last_payment_date,
                          last_logged_into_personal_account_general,
                          last_logged_into_personal_account,
                          last_logged_into_short_personal_account,
                          last_logged_payment_total_link_personal_account,
                          last_logged_payment_prolong_link_personal_account,
                          external_agency,
                          days_from_the_last_pay,
                          income_for_loan,
                          user_age,
                          amount_loan,
                          closed_loan,
                          days_before_due_date,
                          days_after_PTP_date,
                          days_before_PTP_date,
                          user_gender,
                          count_of_broken_ptp,
                          collection_result,
                          days_after_last_contact_adding,
                          max_dpd_ever,
                          rpc_ever,
                        } = formProps.form.getState().values;
                        const filters = getTransformFilters({
                          registration_date,
                          user_is_blocked,
                          last_application_rejection_reason,
                          last_application_state,
                          last_call_result_promo,
                          last_call_result_unfinished_registration,
                          last_call_result_unsigned_request,
                          last_loan_state,
                          registration_step,
                          closed_loan_number,
                          days_since_last_rejected_application,
                          days_since_last_user_unlock,
                          extension_number,
                          last_loan_max_dpd,
                          user_days_before_birthday,
                          days_without_loan,
                          last_moratorium_days,
                          user_in_blacklist,
                          last_sent_sms_template,
                          days_since_last_sms_sent,
                          last_user_activity,
                          last_loan_has_active_promo_code,
                          last_loan_sequence_number,
                          last_loan_term,
                          last_loan_type,
                          user_has_do_not_call_marker,
                          registration_date_from_today,
                          last_loan_collection_score_group,
                          last_loan_collection_score_priority,
                          dpd,
                          collection_group,
                          last_loan_tenor,
                          collector,
                          days_from_the_last_call_client,
                          days_from_the_last_call_tpc,
                          days_from_the_last_call,
                          days_from_last_ptp_date,
                          ptp_stage,
                          total,
                          principal,
                          last_contact_type_client_phone,
                          last_contact_type_client_promise_phone,
                          days_from_last_payment_date,
                          last_logged_into_personal_account_general,
                          last_logged_into_personal_account,
                          last_logged_into_short_personal_account,
                          last_logged_payment_total_link_personal_account,
                          last_logged_payment_prolong_link_personal_account,
                          external_agency,
                          days_from_the_last_pay,
                          income_for_loan,
                          user_age,
                          amount_loan,
                          closed_loan,
                          days_before_due_date,
                          days_after_PTP_date,
                          days_before_PTP_date,
                          user_gender,
                          count_of_broken_ptp,
                          collection_result,
                          days_after_last_contact_adding,
                          max_dpd_ever,
                          rpc_ever,
                        });
                        dataProvider.query(
                          'mass_sending_audiences/preview',
                          {
                            method: 'POST',
                            body: JSON.stringify({
                              filters,
                            }),
                          },
                        )
                          .then(({ data }) => {
                            setPreviewData(data);
                            setIsPreviewDialogOpen(true);
                          });
                      }}>
                      Base preview
                    </Button>
                    <BaseAudienceFilterPreviewDialog
                      id={record.id}
                      previewList={previewData}
                      isOpened={isPreviewDialogOpen}
                      onClose={handlePreviewDialogClose}
                    />
                    <DeleteButton
                      className={classes.btn}
                      disabled={!isEditable}
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                    />
                  </ButtonGroup>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

FormWrapper.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  filters: PropTypes.array,
  save: PropTypes.func,
  record: PropTypes.shape({
    type: PropTypes.string,
    filters: PropTypes.any,
  }),
};

const AudienceEdit = ({ permissions = [], ...props }) => {
  const notify = useNotify();
  const [filters, setFilters] = useState([]);
  const [error, setError] = useState();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .query('mass_sending_audiences/filters', { method: 'GET' })
      .then(({ data }) => setFilters(data))
      .catch((error) => setError(error));

    return () => {
      setFilters([]);
    };
  }, [dataProvider]);

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <Edit component='div' {...props}>
      <FormWrapper permissions={permissions} filters={filters} />
    </Edit>
  );
};

AudienceEdit.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default AudienceEdit;
