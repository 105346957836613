import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BooleanField, Datagrid, List, sanitizeListRestProps, TextField, TopToolbar, useRefresh } from 'react-admin';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';

import { CollectionMotivatorFormDialog } from '../../dialogs';

const CollectionMotivatorActions = ({ className, onOpenCreateModal, ...rest }) => {
  const handleClick = (e) => {
    e.preventDefault();

    onOpenCreateModal();
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <Button
        color="primary"
        size="small"
        type="button"
        onClick={handleClick}
        startIcon={<IconAdd />}
      >
        Create
      </Button>
    </TopToolbar>
  );
};

CollectionMotivatorActions.propTypes = {
  className: PropTypes.string,
  onOpenCreateModal: PropTypes.func,
};

export const CollectionMotivatorList = (props) => {
  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
  const refresh = useRefresh();

  const handleOpenCreateModal = () => {
    setIsCreateModalOpened(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpened(false);
  };

  const handleSubmitCreateModal = () => {
    refresh();
    setIsCreateModalOpened(false);
  };

  return (
    <>
      <List
        bulkActionButtons={false}
        filter={{ 'exists[parent]': 'false' }}
        actions={<CollectionMotivatorActions onOpenCreateModal={handleOpenCreateModal} />}
        {...props}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <BooleanField sortable={false} source="enabled" />
          <TextField sortable={false} source="name" />
        </Datagrid>
      </List>

      <CollectionMotivatorFormDialog
        isOpened={isCreateModalOpened}
        onClose={handleCloseCreateModal}
        onSubmit={handleSubmitCreateModal}
      />
    </>
  );
};
