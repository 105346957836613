import { cloneElement, useState } from 'react';
import {
  List, Datagrid, TextField, ChipField, FunctionField, Filter, NumberInput, SelectInput, TextInput, TopToolbar,
  useListContext, sanitizeListRestProps,
} from 'react-admin';
import { Link, Chip, Card, useTheme, Button } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';

import { getRequestDuration, timezone, AVENTUS_DECISION_ENGINE_STATUSES, formatCurrency } from '../../../utils';
import Pagination from '../../Pagination';
import { DateFilterInput, QuickFilterInput } from '../../filters';
import { AsyncSelectInput } from '../../input';
import { ApplicationColorLegend } from '../../ApplicationColorLegend';
import { ListItem } from '../../ListItem';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

import { verifierRow } from './ApplicationShow';
import { ApplicationsBulkActionButtons } from './ApplicationsBulkActionButtons';

const ListFilter = (props) => {
  return (
    <Filter {...props} variant={'outlined'} style={{ marginTop: 0, marginBottom: '20px' }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="Contract number" source="contractNumber" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <TextInput label="Verifier" source="verifier->username" />
      <SelectInput label="Status" source="state" choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'processing', name: 'Processing' },
        { id: 'approved', name: 'Approved' },
        { id: 'rejected', name: 'Rejected' },
        { id: 'confirmed', name: 'Confirmed' },
        { id: 'cancelled', name: 'Cancelled' },
        { id: 'error', name: 'Error' },
      ]} />
      <DateFilterInput label="Created before" source="created_at|before" before/>
      <DateFilterInput label="Created after" source="created_at|after" after/>
      <SelectInput label="Request type" source="is_repeat" choices={[
        { id: 'false', name: 'First' },
        { id: 'true', name: 'Repeated' },
      ]} />
      <AsyncSelectInput
        label="Affiliate"
        source="affiliate"
        query={{ resource: 'affiliates', payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} } }}
        mapper={ ({ id, name }) => ({ value: id, name }) }
      />
      <QuickFilterInput source="postponed_until|after" label="Postponed all" defaultValue={timezone.shift(new Date())} />
      <QuickFilterInput source="postponed_until|before" label="Postponed in work" defaultValue={timezone.shift(new Date())} />
    </Filter>
  );
};

const ListActions = ({ filters, onLegendToggle, isLegendShow, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <Button color={'primary'} onClick={onLegendToggle}>{isLegendShow ? 'Hide legend' : 'Show legend'}</Button>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  filters: PropTypes.element,
  onLegendToggle: PropTypes.func,
  isLegendShow: PropTypes.bool,
};

export const ApplicationList = (props) => {
  const { palette } = useTheme();
  const [isLegendShow, setIsLegendShow] = useState(false);

  const isRowSelectable = (record) => record?.state === 'pending' || (record?.state === 'processing' && ['manual', null].includes(record?.decision_engine_id));
  const loanRowStyle = (record) => ({ backgroundColor: getRequestDuration(record, palette) });
  const handleLegendToggle = () => setIsLegendShow(!isLegendShow);
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={<ApplicationsBulkActionButtons />}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions onLegendToggle={handleLegendToggle} isLegendShow={isLegendShow} />}
      {...props}
    >
      <>
        {isLegendShow && <ApplicationColorLegend />}
        <Datagrid bulkActionButtons={<ApplicationsBulkActionButtons />} rowStyle={loanRowStyle} rowClick="show" isRowSelectable={isRowSelectable}>
          <TextField source="id" />
          <FunctionField label="Name" render={({ user_id, user_first_name = '', user_last_name = '' }) =>
            <Link color={'secondary'} onClick={(e) => e.stopPropagation()} href={`#users/${user_id}`}>{`#${user_id} ${user_first_name} ${user_last_name}`}</Link>} />,
          <FunctionField style={{ whiteSpace: 'nowrap' }} render={({ decision_engine_id, state, verifier_username }) => verifierRow(decision_engine_id, state, verifier_username)} label="Verifier"/>
          <ChipField size="small" source="product_name" />
          <ChipField size={'small'} label="Loan number" source="potential_loan_number" />
          <FunctionField label="Request details" render={(record) => <Card>
            <ListItem label={'Request number:'} value={<b>{record.request_number}</b>} />
            <ListItem label={'Type:'} value={<Chip size={'small'} color={record.is_repeat ? 'default' : 'secondary'} label={<b>{record.is_repeat ? 'Repeat' : 'First'}</b>}/>}/>
            <ListItem label={'Principal:'} value={<b>{formatCurrency(record.requested_principal)}</b>} />
            <ListItem isLast label={'Tenor:'} value={<span><b>{record.requested_tenor}</b> days</span>}/>
          </Card>} />
          <ChipField size="small" source="state" />
          <FunctionField label="Created at" source="created_at" render={(record) => <DateTimeBoxComponent value={record.created_at} />} />
          <FunctionField label="Affiliate" source="affiliate" render={(record, key) => record[key] ? <Chip size="small" label={record[key]} /> : null} />
          <FunctionField label="ADE status" source="aventus_decision_engine_status" render={ (record, key) => record[key] ? <Chip size="small" label={(AVENTUS_DECISION_ENGINE_STATUSES.find((item) => item.id === record[key]) || { name: null }).name} /> : null} />
          <FunctionField onClick={(e) => e.stopPropagation()} render={(record) => <Link color={'secondary'} href={`#applications/${record.id}/show`} underline={'none'} target={'_blank'} rel={'noreferrer'}><OpenInNewIcon /></Link>}/>
        </Datagrid>
      </>
    </List>
  );
};
