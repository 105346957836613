import { useDataProvider, useNotify } from 'react-admin';
import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';

import { openIntoNewTab } from '../../../../utils';
import { DateTimeBoxComponent } from '../../../DateTimeBoxComponent';

export const RobocallsListRequestsTable = ({
  permissions,
  row,
  setDetailsDialogOpen,
  setRequestDialogOpen,
  setRequestId,
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadReport = requestId => {
    setIsDownloading(true);
    dataProvider.downloadFile(`robocall_task_requests/${requestId}/download`)
      .then(({ data: objUrl }) => openIntoNewTab(objUrl))
      .catch(error => {
        notify(`Error: ${error.message}`, 'error');
      })
      .finally(() => setIsDownloading(false));
  };
  const stopRequest = (requestId) => {
    dataProvider
      .query(`robocall_task_requests/${requestId}/stop`, {
        method: 'POST',
        body: JSON.stringify({}),
      })
      .then(() => notify('Success: request stopped', 'success'))
      .catch((error) => notify(`Error: ${error.message}`, 'error'));
  };
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Task id</TableCell>
            <TableCell>Provider id</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Provider status</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Updated at</TableCell>
            <TableCell>Finished at</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row.requests?.map((item, key) => {
            return (
              <TableRow key={key + '_log'}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.task_id}</TableCell>
                <TableCell>{item.provider_id}</TableCell>
                <TableCell>{item.state}</TableCell>
                <TableCell>{item.provider_status}</TableCell>
                <TableCell><DateTimeBoxComponent value={item?.created_at}/></TableCell>
                <TableCell><DateTimeBoxComponent value={item?.updated_at}/></TableCell>
                <TableCell><DateTimeBoxComponent value={item?.finished_at}/></TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Box pr={1}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setRequestId(item.id);
                          setDetailsDialogOpen(true);
                          setRequestDialogOpen(false);
                        }}
                      >
                        Details
                      </Button>
                    </Box>
                    <Box pr={1}>
                      <Button
                        disabled={(() => {
                          if (permissions.indexOf('CAN_ROBOCALL_EDIT') === -1) {
                            return true;
                          }
                          return !(item.state === 'pending' || item.state === 'processing');
                        })()}
                        variant="contained"
                        size="small"
                        onClick={() => {
                          stopRequest(item.id);
                        }}
                      >
                        Stop
                      </Button>
                    </Box>
                    <Box>
                      <IconButton onClick={() => downloadReport(item.id)} disabled={isDownloading}>
                        <GetAppIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

RobocallsListRequestsTable.propTypes = {
  permissions: PropTypes.array,
  row: PropTypes.shape({
    requests: PropTypes.array,
  }),
  setDetailsDialogOpen: PropTypes.func,
  setRequestDialogOpen: PropTypes.func,
  setRequestId: PropTypes.func,
};
