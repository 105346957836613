import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider, usePermissions } from 'react-admin';
import {
  Table, TableHead, TableBody, TableRow, TableCell, TablePagination,
  Button, ButtonGroup, Tooltip, CircularProgress, Chip,
} from '@material-ui/core';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import { formatPhoneNumber } from '../../utils';
import { ServiceInfoDialog } from '../dialogs';
import { DateTimeBoxComponent } from '../DateTimeBoxComponent';

export const NotificationsTable = ({ userId = null, applicationId = null, loanId = null, refreshedAt, onTransition }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();
  const [notifications, setNotifications] = useState([]);
  const [total, setTotal] = useState();
  const [isDialog, setIsDialog] = useState(false);
  const [serviceInfo, setServiceInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    dataProvider.getList('notifications', {
      filter: {
        ...(userId ? { 'user.id': userId } : {}),
        ...(applicationId ? { 'application.id': applicationId } : {}),
        ...(loanId ? { 'loan.id': loanId } : {}),
      },
      pagination: { page, perPage },
      sort: { field: 'id', order: 'DESC' },
    })
      .then(({ data, total }) => {
        setNotifications(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, applicationId, loanId, refreshedAt, page, perPage]);

  const getServiceInfo = (url) => {
    dataProvider.query(url, { method: 'GET' })
      .then(({ data }) => {
        setServiceInfo(data);
        setIsDialog(true);
      })
      .catch((error) => {
        notify(`Error: ${error.message}`, 'error');
      });
  };

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(notifications)) return null;

  const handleClose = () => setIsDialog((state) => !state);
  const handleRowsPerPage = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Delivery</TableCell>
            <TableCell>Transmitter</TableCell>
            <TableCell>Destination</TableCell>
            <TableCell>Locale</TableCell>
            <TableCell>Template</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Sent by</TableCell>
            <TableCell>Sent at</TableCell>
            <TableCell align={'right'}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell><Chip size={'small'} label={row.state} /></TableCell>
              <TableCell><Chip size={'small'} label={row.delivery_status} /></TableCell>
              <TableCell>{row.transmitter_id}</TableCell>
              <TableCell>{formatPhoneNumber(row.destination)}</TableCell>
              <TableCell>{row.locale}</TableCell>
              <TableCell>{row.template}</TableCell>
              <TableCell>{row.message}</TableCell>
              <TableCell>{row.sent_by}</TableCell>
              <TableCell><DateTimeBoxComponent value={row?.sent_at} /></TableCell>
              <TableCell align={'right'}>
                <ButtonGroup size={'small'} color={'secondary'} disabled={ permissions.indexOf('CAN_NOTIFICATION_EDIT') === -1 }>
                  {row.enabled_transitions.indexOf('resend') !== -1 && <Button onClick={ () => onTransition(row.id, 'resend') }>Resend</Button>}
                  <Button edge={'end'} onClick={() => getServiceInfo(`notifications/${row.id}/service_info`)}>
                    <Tooltip title={'Get service info'} arrow>
                      <OpenInBrowserIcon />
                    </Tooltip>
                  </Button>
                </ButtonGroup>
                <ServiceInfoDialog
                  array={Object.keys(serviceInfo).map((i) => ({ name: i, value: serviceInfo[i] }))}
                  isOpened={isDialog}
                  onClose={handleClose}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component={'div'}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={handleRowsPerPage}
      />
    </>
  );
};

NotificationsTable.propTypes = {
  userId: PropTypes.number,
  applicationId: PropTypes.number,
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onTransition: PropTypes.func,
};
