import React, { useState } from 'react';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { DateTimePicker, DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import { timezone } from '../../../utils';

export const ReportDate = ({ value, touched, error, onChange }) => {
  const [time, setTime] = useState(false);
  return (
    <>
      <Grid item xs={12} sm={6}>
        {!time
          ? <DatePicker
            label={'Start date'}
            fullWidth
            value={value?.start_date ?? null}
            onChange={(v) => {
              onChange({ ...(value || {}), start_date: timezone.setTimeWithoutOffset(v, 0, 0, 0) });
            }}
            required={true}
            error={!!(touched && error && error.start_date)}
            helperText={touched && error && error.start_date}
            inputVariant={'outlined'}
            size={'small'}
          />
          : <DateTimePicker
            label={'Start date'}
            fullWidth
            value={value?.start_date ?? null}
            onChange={(v) => {
              onChange({ ...(value || {}), start_date: v });
            }}
            required={true}
            error={!!(touched && error && error.start_date)}
            helperText={touched && error && error.start_date}
            inputVariant={'outlined'}
            size={'small'}
          />
        }
      </Grid>
      <Grid item xs={12} sm={6}>
        {!time
          ? <DatePicker
            label={'End date'}
            fullWidth
            value={value?.end_date ?? null}
            onChange={(v) => {
              onChange({ ...(value || {}), end_date: timezone.setTimeWithoutOffset(v, 23, 59, 59) });
            }}
            required={true}
            error={!!(touched && error && error.end_date)}
            helperText={touched && error && error.end_date}
            inputVariant={'outlined'}
            size={'small'}
          />
          : <DateTimePicker
            label={'End date'}
            fullWidth
            value={value?.end_date ?? null}
            onChange={(v) => {
              onChange({ ...(value || {}), end_date: v });
            }}
            required={true}
            error={!!(touched && error && error.end_date)}
            helperText={touched && error && error.end_date}
            inputVariant={'outlined'}
            size={'small'}
          />
        }
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          label={'Change report time'}
          control={
            <Checkbox
              checked={time}
              onChange={() => setTime((state) => !state)}
            />}
        />
      </Grid>
    </>
  );
};

ReportDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  touched: PropTypes.bool,
  error: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
