import React, { useState, useEffect } from 'react';
import {
  Create, BooleanInput, SelectInput, SaveButton, Toolbar, FormWithRedirect, required, useNotify, useDataProvider,
} from 'react-admin';
import { makeStyles, Box, Paper, Grid, Divider, Typography } from '@material-ui/core';

import { AUTO_ASSIGNMENT_CONTEXT as autoAssignmentContexts } from '../../../utils/dictionary';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const FormWrapper = props => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [verifiers, setVerifiers] = useState();

  useEffect(() => {
    dataProvider.query('admins?role_permission=CAN_BE_APPLICATION_VERIFIER', { method: 'GET' })
      .then(({ data }) => {
        setVerifiers(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  if (loading === false && error) {
    notify(`Error: ${error.message}`, 'error');
  }

  const relatedAdminChoices = (Array.isArray(verifiers) ? verifiers : []).map(({ id, username: name }) => ({ id: `/api/admins/${id}`, name }));

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={ false }>Create auto assignment.</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <BooleanInput label="Enabled" source="is_enabled" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="context" choices={ autoAssignmentContexts } validate={ [required()] } fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="related_admin" choices={ relatedAdminChoices } validate={ [required()] } fullWidth />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={ classes.toolbar }>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
