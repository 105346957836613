import { Datagrid, FunctionField, List, TextField, useDataProvider, useNotify, usePermissions } from 'react-admin';
import { Chip, Box, IconButton, Link } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Pagination from '../../Pagination';
import { formatPhoneNumber } from '../../../utils';
import { Call } from '../../Call';
import { DateTimeBoxComponent } from '../../DateTimeBoxComponent';

import { UserListActions } from './UserListActions';
import { UserListFilter } from './UserListFilters';

export const UserList = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions = [] } = usePermissions();
  const handleImpersonate = (id, registration_step) => {
    dataProvider.query(`users/${id}/token`, { method: 'GET' })
      .then(({ data }) => window.open(`${process.env.REACT_APP_FRONT_HOST}/user/${registration_step > 9 ? 'account' : `registration/step${registration_step}`}?impersonate=${encodeURIComponent(JSON.stringify(data))}`))
      .catch((error) => notify(`Error: ${error.message || 'token not found'}`, 'error'));
  };
  return (
    <List
      elevation={0}
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<UserListFilter />}
      actions={<UserListActions />}
      {...props}
    >
      <Datagrid rowClick={'edit'}>
        <TextField source={'id'} />
        <FunctionField
          source={'created_at'}
          label={'Registered at'}
          sortBy={'created_at'}
          render={(record) => <DateTimeBoxComponent value={record?.created_at} />}
        />
        <FunctionField
          source={'phone_number'}
          sortBy={'profile.phone_number'}
          render={(record, key) =>
            record[key] && <Call userId={record.id} userPhone={record.username}>{formatPhoneNumber(record[key])}</Call>}
        />
        <FunctionField
          label={'Name / E-mail'}
          render={({ first_name, last_name, email }) => (
            <>
              {first_name && last_name && <Box>{`${first_name} ${last_name}`}</Box>}
              {email && <Link color={'secondary'} href={`mailto: ${email}`}>{email}</Link>}
            </>
          )}
        />
        <FunctionField label="Gender" source="gender" render={({ gender }) => gender && <Chip size="small" label={gender} />} />
        <TextField source="employment_type" label="Empl. type" sortBy='employment.type' />
        <FunctionField label="Step" source="registration_step" render={(record, key) => record[key] ? <Chip size="small" label={record[key]} /> : null} />
        <FunctionField label="Affiliate" source="affiliate" render={(record, key) => record[key] ? <Chip size="small" label={record[key]} /> : null} sortBy='affiliate.name' />
        {permissions.indexOf('CAN_IMPERSONATION') !== -1 &&
          <FunctionField label="Impers." render={({ id, registration_step }) => <IconButton color={'secondary'} size={'small'}
            onClick={() => handleImpersonate(id, registration_step)}><OpenInNewIcon /></IconButton>}
          />
        }
      </Datagrid>
    </List>
  );
};
