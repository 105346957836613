import { makeStyles } from '@material-ui/core/styles';

export const useCollectionStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: 6,
    transition: 'background-color 0.2s',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.075)',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
    padding: '6px',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 6,
    flexGrow: 1,
  },
  tableWrapper: {
    width: '100%',
    marginTop: 32,
  },
  clickableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  fullWidth: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));
