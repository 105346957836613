import React from 'react';
import { BooleanField, Datagrid, List, TextField } from 'react-admin';

export const CollectionContactTypeList = (props) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <BooleanField sortable={false} source="enabled" />
        <TextField sortable={false} source="name" />
      </Datagrid>
    </List>
  );
};
