import { useDataProvider } from 'react-admin';
import { useEffect, useState } from 'react';
import qs from 'qs';

/** @type Map<string, Promise> */
const promiseStorage = new Map();

function buildUrl (source, query = {}) {
  const queryStr = qs.stringify({ items_per_page: 100, ...query, page: 1 });
  const querySign = source.includes('?') ? '&' : '?';

  return `${source}${querySign}${queryStr}`;
}

export function invalidateCache (source) {
  promiseStorage.delete(source);

  for (const key of promiseStorage.keys()) {
    if (key.startsWith(`${source}/`)) {
      promiseStorage.delete(key);
    }

    if (key.startsWith(`${source}?`)) {
      promiseStorage.delete(key);
    }
  }
}

export function useHandbook (source, query = {}) {
  const dataProvider = useDataProvider();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const url = buildUrl(source, query);

  useEffect(() => {
    function collect () {
      if (promiseStorage.has(url)) {
        return promiseStorage.get(url);
      }

      const promise = new Promise((resolve) => {
        dataProvider
          .fetch(url, { method: 'GET' })
          .then((response) => {
            resolve(response.data);
          });
      });

      promiseStorage.set(url, promise);

      return promise;
    }

    function load () {
      setIsLoading(true);

      return collect()
        .then((rows) => {
          setIsLoading(false);
          setData(rows);
        });
    }

    load();
  }, [dataProvider, url]);

  let choices;
  const isIterable = (obj) => obj !== null && typeof obj[Symbol.iterator] === 'function';

  if (isIterable(data)) {
    choices = data?.map((item) => {
      if (typeof item === 'string') {
        return { id: item, name: item };
      } else {
        return { id: item.code || item.name, name: item.name };
      }
    });
  } else {
    choices = Object.entries(data).map((item) => ({ id: item[0], name: item[1] || item[0] }));
  }

  return {
    isLoading,
    data,
    choices,
    reload: () => invalidateCache(source),
  };
}
