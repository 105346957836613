import React from 'react';
import {
  Create, TextInput, SelectInput, SaveButton, Toolbar, FormWithRedirect, required, email, maxLength,
} from 'react-admin';
import { makeStyles, Box, Paper, Grid, Divider, Typography } from '@material-ui/core';

import { phoneNumber } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
}));

const FormWrapper = props => {
  const classes = useStyles();

  const statusChoices = [
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ];

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={ false }>Create new External collection agency</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput source="name" validate={[maxLength(60), required()]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput source="status" validate={[required()]} choices={statusChoices} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        source="phone"
                        fullWidth
                        helperText={false}
                        placeholder={phoneNumber.placeholder}
                        parse={phoneNumber.parser}
                        format={phoneNumber.formatter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput label="Email" source="email" type="email" validate={[email('Invalid email')]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInput source="details" validate={[maxLength(255)]} fullWidth />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={ classes.toolbar }>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    transform={data => ({ ...data })}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
