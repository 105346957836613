import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  BooleanFilter,
  DateRangeFilter,
  ListFilter,
  OperationSignFilter,
  OperationEqualFilter,
} from '../../audienceFilters';

export const Filter = (props) => {
  const { type, filter, id } = props;
  useEffect(() => {
    if (props.form) {
      props.form.change(`${props.id}.active`, props?.filter?.active ? [props.id] : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.filter?.active]);

  const title = id.toString().replaceAll('_', ' ');

  const components = {
    boolean: <BooleanFilter title={title} {...props} />,
    date_range: <DateRangeFilter title={title} {...props} />,
    list: <ListFilter title={title} {...props} />,
    sign: <OperationSignFilter title={title} {...props} />,
    number_with_operation_sign: <OperationSignFilter title={title} {...props} />,
    equal: <OperationEqualFilter title={title} {...props} />,
  };

  return components[type] || null;
};

Filter.propTypes = {
  type: PropTypes.string,
  filter: PropTypes.shape({
    id: PropTypes.string,
  }),
};
