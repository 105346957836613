import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

export const UserConfirmTransitionDialog = ({ isOpened, onClose, onSubmit }) => {
  const [confirmationCode, setConfirmationCode] = useState();
  const handleConfCodeChange = (e) => setConfirmationCode(e.target.value);
  const handleConfCodeSubmit = () => onSubmit(confirmationCode);
  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Confirm user phone</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, enter confirmation code</DialogContentText>
        <TextField
          variant={'outlined'}
          id={'confirmation-code-field'}
          label={'Code'}
          onChange={handleConfCodeChange}
          autoFocus={true}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={'primary'}>Cancel</Button>
        <Button onClick={handleConfCodeSubmit} color={'primary'} disabled={!confirmationCode}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

UserConfirmTransitionDialog.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
