import { useState, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import PropTypes from 'prop-types';

export const withServerAutocomplete = (WrappedComponent) => {
  const AutoCompleteServer = ({ perOnePage, resource, filter, ...props }) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [result, setResult] = useState({
      options: [],
      total: 0,
    });
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(perOnePage);
    const maxPage = Math.ceil(result.total / perPage);

    useEffect(() => {
      setLoading((state) => !state);
      dataProvider.getList(resource, {
        pagination: {},
        sort: { field: 'id', order: 'DESC' },
        filter: { ...filter, items_per_page: perPage },
      }).then(({ data, total }) => {
        setResult({ options: [...data], total });
        setLoading(false);
      })
        .catch(({ message }) => notify(`Error: ${message}`, 'error'));

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return (
      <WrappedComponent
        result={result}
        loading={loading}
        page={page}
        setPage={() => {
          const newPerPage = perPage + 10;
          setPerPage(prev => prev + 10);

          dataProvider.getList(resource, {
            pagination: {},
            sort: { field: 'id', order: 'DESC' },
            filter: { ...filter, items_per_page: newPerPage },
          }).then(({ data, total }) => {
            setResult({ options: [...data], total });
            setPerPage(newPerPage);
            setLoading(false);
          })
            .catch(({ message }) => notify(`Error: ${message}`, 'error'));
          return setPage;
        }}
        maxPage={maxPage}
        {...props}
      />
    );
  };

  AutoCompleteServer.propTypes = {
    perOnePage: PropTypes.number,
    resource: PropTypes.string,
    filter: PropTypes.object,
  };

  return AutoCompleteServer;
};
