import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import {
  CircularProgress, TableRow, TableBody, Table, TableCell, Chip, Link, Grid, Typography, Box,
} from '@material-ui/core';

import { formatDatetime } from '../../utils';

const SocialMedia = ({ userId }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState();

  useEffect(() => {
    dataProvider
      .query(`users/${userId}/seon_report`, { method: 'GET' })
      .then(({ data }) => setReport(data))
      .catch((error) =>
        notify(`Error: ${error.message || 'social media not found'}`, 'error'),
      )
      .finally(() => setLoading(false));
  }, [dataProvider, notify, userId]);

  if (loading) return <CircularProgress />;

  return (
    <Table size="small">
      <TableBody>
        {report && Object.keys(report).map((name, index) => {
          return (
            <TableRow key={index}>
              {name !== 'images' && <TableCell>{name.slice(0, 1).toUpperCase() + name.slice(1).split('_').join(' ')}</TableCell>}
              {name !== 'images' && (
                <TableCell align="right">
                  {(() => {
                    if (typeof report[name] === 'boolean') {
                      return <Chip label={String(report[name])} />;
                    }
                    if (typeof report[name] === 'string') {
                      if (/^(http)/.test(report[name])) {
                        return (
                          <Link href={report[name]} target="_blank">
                            Open in the new tab
                          </Link>
                        );
                      } else {
                        return report[name];
                      }
                    }
                    if (typeof report[name] === 'number') {
                      if (/(last_seen)/.test(name)) {
                        return formatDatetime(report[name] * 1000);
                      } else {
                        return report[name];
                      }
                    }
                  })()}
                </TableCell>
              )}
            </TableRow>
          );
        })}
        <Box p={2}>
          <Grid container spacing={2}>
            {report && report.images && report.images.map((item, index) => {
              return (
                <Grid item xs={12} md={12} key={index}>
                  <Typography
                    variant="subtitle1"
                    paragraph
                    color="textSecondary"
                  >
                    {item.source}
                  </Typography>
                  <img
                    src={(() => {
                      if (/^(http)/.test(item.value)) {
                        return item.value;
                      } else {
                        return 'data:image/jpeg;base64,' + item.value;
                      }
                    })()}
                    style={{ width: '100%' }}
                    alt={item.source}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </TableBody>
    </Table>
  );
};

SocialMedia.propTypes = {
  userId: PropTypes.number,
};

export default SocialMedia;
