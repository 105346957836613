import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  CreateButton,
  Datagrid,
  List,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
  Filter,
  NumberInput,
  TextInput,
  SelectInput,
  BooleanField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <TextInput label="Role code" source="code" />
      <SelectInput label="Status" source="is_enabled" choices={[
        { id: 'true', name: 'Enabled' },
        { id: 'false', name: 'Not enabled' },
      ]} />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {hasCreate ? (<CreateButton basePath={basePath} />) : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const RoleList = (props) => {
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField label="Role name" source="name" />
        <TextField label="Role code" source="code" />
        <BooleanField source="is_enabled" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default RoleList;
