import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { bool, shape, string, number, func } from 'prop-types';

import { useProducts } from '../../../../hooks';
import { createAppFormFields } from '../../../../constants';
// import { withServer, SelectAutoComplete } from '../../../select';
import { SelectFieldHookForm } from '../../../formFields';
import { validateSelect } from '../../../../utils';
import { LoanCalculator } from '../../../LoanCalculator';
// const AutoComplete = withServer(SelectAutoComplete);

export const ChangeTermsTransitionDialog = ({ isOpened, onClose, onSubmit, record }) => {
  const { data: products } = useProducts();
  const { tenor, principal, product_code, promo_code } = createAppFormFields;
  const activeProducts = products.filter((pr) => !pr.isDisabled);
  const fActiveProduct = activeProducts ? [...activeProducts][0] : null;
  const defaultValues = {
    tenor: record?.tenor || 0,
    principal: record?.principal || 0,
    product_code: fActiveProduct?.code ?? '',
    target_url: '',
    promo_code: '',
  };

  const { handleSubmit, control, setValue, watch } = useForm({
    mode: 'all',
    defaultValues,
  });

  const activeProductOptions = activeProducts.map((item) => ({ label: item.name, value: item.code }));
  const productCode = fActiveProduct?.code ?? watch(product_code);
  const [activeProduct] = activeProducts.filter((pr) => pr.code === productCode) ?? fActiveProduct;
  const productId = watch(tenor)?.value ?? fActiveProduct?.tenor.value;
  const onSubmitHandler = handleSubmit((values) => {
    const payload = {
      product_id: productId,
      tenor: values.tenor.value ?? defaultValues.tenor,
      principal: values.principal.value ?? defaultValues.principal,
    };
    onSubmit(payload);
  });

  const handleCalculatorValueChange = (name, value) => setValue(name, { ...activeProduct[`${name}`], value });

  useEffect(() => {
    if (productCode && fActiveProduct) {
      const { principal: fActivePPrincipal, tenor: fActivePTenor } = fActiveProduct;
      setValue(principal, {
        step: fActivePPrincipal.step,
        min: record?.is_repeat ? fActivePPrincipal.min : fActivePPrincipal.first_min,
        value: record?.principal || (record?.is_repeat ? fActivePPrincipal.min : fActivePPrincipal.first_min),
        max: record?.is_repeat ? fActivePPrincipal.max : fActivePPrincipal.first_max,
      });
      setValue(tenor, {
        step: fActivePTenor.step,
        min: record?.is_repeat ? fActivePTenor.min : fActivePTenor.first_min,
        value: record?.tenor || (record?.is_repeat ? fActivePTenor.min : fActivePTenor.first_min),
        max: record?.is_repeat ? fActivePTenor.max : fActivePTenor.first_max,
      });
    }
    // eslint-disable-next-line
  }, [productCode, record?.is_repeat]);

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby={'form-dialog-title'}>
      <DialogTitle id={'form-dialog-title'}>Change loan terms</DialogTitle>
      <form onSubmit={onSubmitHandler}>
        <DialogContent style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}>
          <DialogContentText>To change loan terms, please fill following fields</DialogContentText>
          {activeProductOptions && activeProductOptions?.length > 0
            ? <SelectFieldHookForm
              defaultValue={activeProduct?.code}
              control={control}
              label={product_code}
              name={product_code}
              options={activeProductOptions}
              rules={validateSelect}
            />
            : <Skeleton variant={'rect'} />}
          {activeProduct
            ? <LoanCalculator
              control={control}
              principal={{
                step: activeProduct.principal.step,
                min: record?.is_repeat ? activeProduct.principal.min : activeProduct.principal.first_min,
                value: record?.principal || (record?.is_repeat ? activeProduct.principal.min : activeProduct.principal.first_min),
                max: record?.is_repeat ? activeProduct.principal.max : activeProduct.principal.first_max,
              }}
              tenor={{
                step: activeProduct.tenor.step,
                min: record?.is_repeat ? activeProduct.tenor.min : activeProduct.tenor.first_min,
                value: record?.tenor || (record?.is_repeat ? activeProduct.tenor.min : activeProduct.tenor.first_min),
                max: record?.is_repeat ? activeProduct.tenor.max : activeProduct.tenor.first_max,
              }}
              onValueChange={handleCalculatorValueChange}
            />
            : <Skeleton variant={'rect'} />}
          {/* <AutoComplete */}
          {/*  perOnePage={30} */}
          {/*  resource="promo_codes" */}
          {/*  label="Promo codes" */}
          {/*  filter={{ is_enabled: true }} */}
          {/*  value={promoCode} */}
          {/*  setValue={setPromoCode} */}
          {/*  prop={'code'} */}
          {/* /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color={'primary'}>Cancel</Button>
          <Button type={'submit'} color={'primary'} disabled={!(productId && record?.principal && record?.tenor)}>Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

ChangeTermsTransitionDialog.propTypes = {
  isOpened: bool,
  onClose: func,
  onSubmit: func,
  record: shape({
    user_id: number,
    product_id: number,
    principal: number,
    tenor: number,
    promo_code: string,
    is_repeat: bool,
    is_ready_for_disbursement: bool,
    number_of_closed_loans: number,
  }),
};

