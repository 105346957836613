export default {
  login: ({ username, password }) => {
    const request = new Request(process.env.REACT_APP_API_ENTRYPOINT + '/login', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-AUTH-CTX': 'admin',
      }),
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          return response.json().then(({ message }) => { throw new Error(message || response.statusText); });
        }
        return response.json();
      })
      .then(({ user, token, expire_at }) => {
        localStorage.setItem('admin_fields', JSON.stringify(user));
        localStorage.setItem('admin_access_token', token);
        localStorage.setItem('admin_access_token_expire_at', expire_at);
        return Promise.resolve();
      })
    ;
  },
  logout: () => {
    localStorage.removeItem('admin_fields');
    localStorage.removeItem('admin_access_token');
    localStorage.removeItem('admin_access_token_expire_at');
    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem('admin_access_token') && new Date(localStorage.getItem('admin_access_token_expire_at')) > new Date()) ? Promise.resolve() : Promise.reject(new Error('token expired')),
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('admin_fields');
      localStorage.removeItem('admin_access_token');
      return Promise.reject(new Error(status));
    }
    return Promise.resolve();
  },
  getPermissions: () => new Promise((resolve, reject) => {
    const user = JSON.parse(localStorage.getItem('admin_fields'));
    if (user && user.roles) {
      resolve(user.roles);
    } else {
      reject(new Error('user not exist'));
    }
  }),
};
