import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

export const TransferMoneyTransitionDialog = ({ isOpened, onClose, onSubmit }) => {
  const [gateway, setGateway] = useState();

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Money transfer.</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please, select payment gateway.
        </DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="gateway-select-label">Gateway</InputLabel>
          <Select
            labelId="gateway-select-label"
            value={gateway || ''}
            onChange={e => setGateway(e.target.value)}
          >
            {/* TODO: get vals from be */}
            <MenuItem value="selcom">Selcom</MenuItem>
            <MenuItem value="fake">Fake</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(gateway)} color="primary" disabled={!gateway}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TransferMoneyTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
